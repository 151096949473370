import { CSSProperties, useMemo } from "react";

import { Color } from "@api";

import { toColorVar } from "@utils/color";

export const BackgroundColor = ({
  color,
  opacity = 1,
}: {
  color: Color;
  opacity?: number;
}) => {
  const css = useMemo(
    (): CSSProperties => ({
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: -1,
      borderRadius: "inherit",
      background: toColorVar(color),
      opacity,
    }),
    [color, opacity]
  );

  return <div style={css} />;
};
