import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity } from "@state/generic";
import { useMe } from "@state/persons";

import { useSyncPathnameID } from "@utils/url";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Process } from "@ui/icon";
import AppPage from "@ui/page/app-page";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { StackContainer } from "@ui/sheet-layout";

import { RelatedProcessesPane } from "../process";
import { FormPane } from "./pane";
import { FormSubmissionsPane } from "./submissions";

interface Props {
  id: string;
  showBack?: boolean;
}

export default function FormPage({ id, showBack = true }: Props) {
  const me = useMe();
  const form = useLazyEntity<"form">(id);
  const [page] = useRegisterPage(id, form);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, form?.id);

  if (!form) {
    return <></>;
  }

  return (
    <AppPage page={page} title={form?.name} loading={!form}>
      <StackContainer>
        {showBack && <SmartBreadcrumbSheet />}

        <FormPane id={id} />

        <PaneManager size="secondary">
          <PaneItem id="submissions" title="Recent submissions" icon={Process}>
            <FormSubmissionsPane
              form={form}
              submitter={
                form.owner?.id === me?.id || form?.createdBy?.id === me.id
                  ? undefined
                  : me
              }
            />
          </PaneItem>

          <PaneItem id="processes" title="Processes" icon={Process}>
            <RelatedProcessesPane entityId={form.id} editable={false} />
          </PaneItem>
        </PaneManager>
      </StackContainer>
    </AppPage>
  );
}
