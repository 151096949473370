import { ReactNode } from "react";

import { Color } from "@api";

import { cx } from "@utils/class-names";
import { ComponentOrNode } from "@utils/react";

import { BackgroundColor } from "@ui/background-color";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon } from "@ui/icon";
import { SectionLabel, Text,TextMedium, TextSmall } from "@ui/text";

import styles from "./section.module.css";

export interface Props {
  title?: string | ReactNode;
  size?: "small" | "default" | "medium";
  icon?: ComponentOrNode;
  inset?: boolean;
  divider?: boolean;
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const Section = ({
  title,
  icon,
  children,
  className,
  actions,
  size = "small",
  inset = true,
  divider = true,
}: Props) => {
  return (
    <VStack fit="container" gap={6} className={cx(styles.section, className)}>
      <SpaceBetween
        gap={8}
        className={cx(styles.header, inset && styles.inset)}
      >
        <HStack gap={4} className={styles.noWrap}>
          {icon && <Icon icon={icon} />}
          {size === "medium" && <TextMedium subtle>{title}</TextMedium>}
          {size === "small" && <TextSmall subtle>{title}</TextSmall>}
          {size === "default" && <Text subtle>{title}</Text>}
        </HStack>
        {divider && <Divider />}
        {actions}
      </SpaceBetween>

      {children}
    </VStack>
  );
};

export const ColoredSection = ({
  title,
  icon,
  children,
  className,
  actions,
  size = "small",
  color,
  inset,
  divider = true,
}: Props & { color?: Color | "background" }) => {
  return (
    <Container
      fit="container"
      gap={6}
      inset={inset ? "horizontal" : "none"}
      stack="vertical"
      className={cx(
        styles.section,
        !color && styles.secondary,
        color === "background" && styles.background,
        className
      )}
    >
      {!!color && color !== "background" && (
        <BackgroundColor color={color} opacity={0.4} />
      )}

      {!!(title || actions) && (
        <SpaceBetween gap={8}>
          <HStack gap={4} align="flex-end">
            {icon && <Icon size="small" icon={icon} />}
            {size === "medium" && <TextMedium subtle>{title}</TextMedium>}
            {size === "small" && <SectionLabel subtle>{title}</SectionLabel>}
          </HStack>
          {actions}
        </SpaceBetween>
      )}

      {children}
    </Container>
  );
};
