import { isString } from "lodash";

import { DatabaseID, Person, Ref } from "@api";

import { useLazyEntities, useLazyEntity, useSearch } from "@state/generic";
import { useActiveWorkspaceId } from "@state/workspace";

import { cx } from "@utils/class-names";
import { Maybe } from "@utils/maybe";

import { PersonIcon } from "@ui/icon";

import { MultiProps, MultiSelect } from "./multi-select";
import { Select,SelectProps } from "./single-select";

import styles from "./select.module.css";

type PersonSelectProps = {
  value: Maybe<Ref>;
  source?: DatabaseID;
} & Omit<SelectProps<Person>, "toIcon" | "options" | "value">;

type PersonMultiSelectProps = {
  value: Maybe<Ref[]>;
  source?: DatabaseID;
} & Omit<MultiProps<Person>, "toIcon" | "options" | "value">;

export const PersonSelect = ({
  value,
  className,
  source,
  ...props
}: PersonSelectProps) => {
  const selected = useLazyEntity<"person">(value?.id);
  const workspaceId = useActiveWorkspaceId();
  const { results, setQuery } = useSearch(
    "person",
    source?.scope || workspaceId,
    { empty: true }
  );

  return (
    <Select
      value={selected}
      className={{
        ...(!isString(className) ? className : { dropdown: className }),
        popover: styles.personSelect,
      }}
      options={results as Person[]}
      onSearch={setQuery}
      {...props}
      toIcon={(person) => <PersonIcon person={person} />}
    />
  );
};

export const PersonMultiSelect = ({
  value,
  className,
  source,
  ...props
}: PersonMultiSelectProps) => {
  const selected = useLazyEntities<"person">(value || []);
  const workspaceId = useActiveWorkspaceId();
  const { results, setQuery } = useSearch(
    "person",
    source?.scope || workspaceId,
    { empty: true }
  );

  return (
    <MultiSelect
      value={selected}
      className={{
        select: cx(styles.personSelect),
        ...(!isString(className) ? className : { control: className }),
      }}
      options={results as Person[]}
      onSearch={setQuery}
      {...props}
      toIcon={(person) => <PersonIcon person={person} />}
    />
  );
};
