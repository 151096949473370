import { flatMap, orderBy, reduce, times } from "lodash";

import {
  Entity,
  hasDates,
  hasOrder,
  HasOrders,
  hasOrders,
  Orders,
  toTitleOrName,
} from "@api";

import { pushDirty } from "./array";
import { average } from "./math";
import { isDefined, Maybe, when } from "./maybe";
import { now } from "./now";
import { toNumberParts } from "./sorting";

// Function that takes two numbers (orders) and returns a list of numbers between them steps times
export const evenlyBetween = <T extends string | number, I>(
  orders: [T, T],
  steps: number,
  strat: OrderingStrat<T>
): T[] => {
  let [a, b] = orders;

  // Bump steps times to get the new max
  if (a === b) {
    b = reduce(times(steps + 1), strat.bump, b);
  }

  return reduce(
    times(Math.ceil(steps / 2)),
    (res, _, i) => {
      if (res.length >= steps + 2) {
        return res;
      }

      return flatMap(
        res,
        (v, i) => when(res[i + 1], (v2) => [v, strat.between(v, v2)]) || [v]
      );
    },
    [a, b]
  );
};

export const orderItems = <T extends Entity>(items: T[]) =>
  orderBy(items, (i) =>
    hasOrders(i)
      ? toOrder(i.orders)
      : hasOrder(i)
      ? i.order
      : hasDates(i)
      ? i.start
      : toTitleOrName(i)
  );

export const toOrders = (order: Maybe<Orders | string>) =>
  (typeof order === "string" ? { default: order } : order) ?? {};

export const toOrder = (order: Maybe<Orders>, scope: string = "default") => {
  const orders = toOrders(order);
  return orders[scope] || orders["default"] || "1.0";
};

export const setOrders = (
  orders: HasOrders["orders"] = {},
  scope: string,
  newOrder: string | number
) => ({
  ...orders,
  // Set default to new value if it had the same value as the scoped value
  ["default"]:
    !isDefined(orders["default"]) || orders["default"] === orders[scope]
      ? newOrder
      : orders["default"],
  [scope]: newOrder,
});

export const toNewOrders = (scope: string, newOrder: string | number) =>
  setOrders({}, scope, newOrder);

type OrderingStrat<T extends string | number> = {
  bump: (order: T) => T;
  between: (a: T, b: T) => T;
  drop: (order: T) => T;
};

// Order by the integer value + current date time + some magics
export const DateDecimalOrdering: OrderingStrat<number> = {
  bump: (order) => {
    const [digit, decimals] = toNumberParts(order);
    return Number(`${digit}.${now().getTime() + 1}`);
  },
  between: (a, b) => {
    const [digA] = toNumberParts(a);
    const [digB] = toNumberParts(b);
    return Number(
      `${average(Number(digA), Number(digB))}.${now().getTime() - 1}`
    );
    return average(Number(a), Number(b));
  },
  drop: (order) => {
    const [digit, decimals] = toNumberParts(order);
    return Number(`${digit}.${now().getTime() - 1}`);
  },
};

// For String alpha sorting, keep adding a level of depth compared to current order
export const AlphaDecimalOrdering: OrderingStrat<string> = {
  bump: (order) => {
    const [digit, decimals] = toNumberParts(order);
    return `${digit}.${decimals}1`;
  },
  between: (a, b) => {
    return String(average(Number(a), Number(b)));
  },
  drop: (order) => {
    const [digit, decimals] = toNumberParts(order);
    return `${digit}.${decimals}0`;
  },
};

// Pure and simple float supported numerical ordering
export const DecimalOrdering: OrderingStrat<number> = {
  bump: (order) => {
    const [digit, decimals] = toNumberParts(order);
    return Number(`${Number(digit) + 1}.${decimals}`);
  },
  between: (a, b) => {
    return average(Number(a), Number(b));
  },
  drop: (order) => {
    const [digit, decimals] = toNumberParts(order);
    return Math.max(Number(`${Number(digit) - 1}.${decimals}`), 0);
  },
};

// Basic bitch 💁‍♀️ ordering
export const IntegerOrdering: OrderingStrat<number> = {
  bump: (order) => order + 1,
  between: (a, b) => {
    return Math.round(average(Number(a), Number(b)));
  },
  drop: (order) => order - 1,
};

export const applyOrders =
  (from: string) =>
  <T extends HasOrders>(ts: T[]): HasOrders[] =>
    reduce(
      ts,
      ({ prev, all }, t) => {
        const order = AlphaDecimalOrdering.bump(prev);
        return {
          prev: order,
          all: pushDirty(all, { ...t, orders: { default: order } }),
        };
      },
      { prev: from, all: [] as T[] }
    ).all;
