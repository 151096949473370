import { isEmpty, reduce } from "lodash";

import { ID } from "@api";

import { ensureArray } from "./array";
import { Maybe } from "./maybe";

export type Transactable<T> = T & { transaction?: ID };

export interface UndoStack<T> {
  history: Transactable<T>[];
  undone: Transactable<T>[];
}

const empty = <T>() => [] as Transactable<T>[];

// Pop (takes first from array) but if in transaction then returns many
const popTransaction = <T>(ts: Transactable<T>[]) =>
  reduce(
    ts,
    ({ top, rest }, t, i) => {
      if (
        // Always include first (as it's a pop)
        i === 0 ||
        // include sequential if they are in the same transaction
        (ts[0]?.transaction && ts[0]?.transaction === t.transaction)
      ) {
        return { top: [...top, t], rest: rest };
      }
      return { top: top, rest: [...rest, t] };
    },
    { top: empty<T>(), rest: empty<T>() }
  );

export const newUndoStack = <T>(): UndoStack<T> => ({
  history: [],
  undone: [],
});

export const add = <T>(
  action: Transactable<T> | Transactable<T>[],
  stack: UndoStack<T>
): UndoStack<T> => {
  return { history: [...ensureArray(action), ...stack.history], undone: [] };
};

export const undo = <T>(stack: UndoStack<T>): [Maybe<T[]>, UndoStack<T>] => {
  const { top, rest } = popTransaction<T>(stack.history);

  if (isEmpty(top)) {
    return [undefined, stack];
  }

  return [top, { history: rest, undone: [...top, ...stack.undone] }];
};

export const redo = <T>(stack: UndoStack<T>): [Maybe<T[]>, UndoStack<T>] => {
  const { top, rest } = popTransaction<T>(stack.undone);

  if (isEmpty(top)) {
    return [undefined, stack];
  }

  return [top, { history: [...top, ...stack.history], undone: rest }];
};
