import { map } from "lodash";
import { useCallback, useState } from "react";
import Markdown from "react-markdown";
import { useRecoilValue } from "recoil";

import { EntityType, Person, Ref, RichText } from "@api";

import { useAssistantCreate, useCreateFromAIObject } from "@state/ai";
import {
  GenericItem,
  useLazyEntities,
  useRollbackTempChanges,
  useSaveTempChanges,
} from "@state/generic";
import { toFullName,useMe  } from "@state/persons";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany,OneOrMany } from "@utils/array";
import { cx } from "@utils/class-names";
import { formatHuman } from "@utils/date";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { now } from "@utils/now";
import { usePageSelection } from "@utils/selectable";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { render, toEngine } from "@ui/engine";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Assistant, Icon, PersonIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { DocumentEditor } from "@ui/rich-text";
import { LocationSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { Text, TextXLarge } from "@ui/text";

import styles from "./ai-add-work-dialog.module.css";

interface Props {
  allowed?: EntityType[];
  onSaved?: Fn<OneOrMany<Ref>, void>;
  onCancel?: Fn<void, void>;
}

export const AIAddWorkDialog = ({ onCancel, allowed, onSaved }: Props) => {
  // const [page] = useRegisterPage("ai-work-create", undefined);
  const pageId = usePageId();
  const workspaceId = useActiveWorkspaceId();
  const me = useMe();
  const [type, setType] = useState<EntityType>(allowed?.[0] || "task");
  const [location, setLocation] = useState<string>(me.id);
  const [rt, setRichText] = useState<RichText>({ html: "" });
  const [created, setCreated] = useState<Ref[]>([]);
  const mutate = useCreateFromAIObject(type, location, pageId, true);

  const addCreated = useCallback((e: OneOrMany<Ref>) => {
    setCreated((created) => [...created, ...ensureMany(e)]);
  }, []);

  const { generate, reply, loading } = useAssistantCreate(
    mutate,
    addCreated,
    location
  );
  const rollback = useRollbackTempChanges();
  const commit = useSaveTempChanges();

  const rollbackAll = useCallback(() => {
    setCreated((created) => {
      map(created, (e) => rollback(e.id));
      return [];
    });
  }, []);

  const handleDismiss = useCallback(() => {
    rollbackAll();
    onCancel?.();
  }, [created, rollbackAll, onCancel]);

  const handleCommit = useCallback(() => {
    map(created, (e) => commit(e.id));
    onSaved?.(created);
  }, [created, onSaved, rollbackAll]);

  const handleGenerate = useCallback(() => {
    rollbackAll();
    generate(location, type, rt);
  }, [generate, reply, rt, type, location]);

  return (
    <DialogSplit
      onDismiss={handleDismiss}
      className={{
        modal: styles.modal,
        left: styles.left,
        right: styles.right,
      }}
      side={
        <SpaceBetween
          className={styles.pane}
          width="container"
          height="container"
          direction="vertical"
          align="flex-start"
          gap={10}
        >
          <TextXLarge bold>Bulk AI Create</TextXLarge>
          <div className={styles.overflow}>
            <DocumentEditor
              placeholder="Dump raw data here and AI will organise it for you."
              scope={location}
              content={rt}
              onChanged={setRichText}
              newLineSpace="large"
            />
          </div>

          <SpaceBetween direction="horizontal">
            <Button subtle onClick={handleDismiss}>
              Cancel
            </Button>

            <HStack justify="flex-end" fit="container" gap={4}>
              <EntityTypeSelect
                value={type}
                onChange={setType}
                scope={location}
                portal
              />

              <LocationSelect
                fit="content"
                location={location}
                onChange={setLocation}
                source={undefined}
                showOpen={false}
                variant="default"
                showCaret={true}
              />
              <Button
                variant="primary"
                subtle={!loading && !!created?.length}
                disabled={location === workspaceId || !location}
                loading={loading}
                onClick={handleGenerate}
              >
                {created?.length ? "Retry" : "Preview Work"}
              </Button>

              {!loading && created?.length > 0 && (
                <Button variant="primary-alt" onClick={() => handleCommit()}>
                  Save All
                </Button>
              )}
            </HStack>
          </SpaceBetween>
        </SpaceBetween>
      }
      actions={<></>}
    >
      <div className={styles.inner}>
        {/* <PageSelectionContext.Provider value={selectionState}> */}
        {/* <MouseSelection {...drag} /> */}
        <WorkPreview work={created} loading={loading} onSave={handleCommit} />
        {/* </PageSelectionContext.Provider> */}
      </div>
    </DialogSplit>
  );
};

type WorkPreviewProps = {
  work: Ref[];
  loading?: boolean;
  onSave?: Fn<void, void>;
};

const WorkPreview = ({ work, loading, onSave }: WorkPreviewProps) => {
  const entities = useLazyEntities(work);
  const [selection, setSelection] = usePageSelection();

  return (
    <Container fit="container" stack="vertical" gap={10} padding="none">
      <FillSpace direction="vertical" height="container" width="container">
        {!loading && !entities?.length && (
          <Text subtle>No work created yet.</Text>
        )}
        {map(entities, (e) =>
          render(toEngine(e)?.asListItem, {
            key: e.id,
            item: e,
            selection,
            setSelection,
          })
        )}
        {loading && <Text subtle>Generating...</Text>}
      </FillSpace>
    </Container>
  );
};

export const SmartDefaultsAIAddWorkDialog = ({
  entityId,
  ...props
}: { entityId: Maybe<string> } & Props) => {
  const entity = useRecoilValue(GenericItem(entityId || ""));

  // TODO: Re-write using properties
  // const defaults = useMemo(
  //   () =>
  //     fallback(
  //       (): Maybe<Props["defaults"]> =>
  //         switchEnum(entity?.source?.type || "", {
  //           project: () => ({
  //             projects: when(entity, composel(toRef, ensureArray)),
  //           }),
  //           task: () => ({
  //             projects: when(
  //               (entity as Task)?.refs?.projects?.[0],
  //               composel(toRef, ensureArray)
  //             ),
  //           }),
  //           else: () => undefined,
  //         }),
  //       () =>
  //         when(
  //           isTemplateViewId(entityId || "")
  //             ? fromTemplateViewId(entityId || "")
  //             : undefined,
  //           ({ params }) => ({
  //             parent: when(params?.parent, (id) => ({ id })),
  //             team: when(params?.team, (id) => ({ id })),
  //             projects: when(params?.project, (p) => [{ id: p }]),
  //           })
  //         ),
  //       () => _defaults
  //     ),
  //   [entity?.source]
  // );

  return <AIAddWorkDialog {...props} />;
};

interface AICreateChatProps {
  location?: string;
  onProcess: Fn<Ref[], void>;
  onRollback: Fn<void, void>;
}

interface MessageProps {
  text: string;
  message?: string;
  from?: Person;
  loading?: boolean;
}

const Message = ({ text, from, message, loading }: MessageProps) => {
  return (
    <VStack className={cx(styles.note)} gap={10} fit="container">
      <VStack fit="container" gap={6}>
        <SpaceBetween fit="container">
          {!from && (
            <Label bold icon={<Icon icon={Assistant} />}>
              Ai Assistant
            </Label>
          )}
          {!!from && (
            <Label bold icon={<PersonIcon person={from} />}>
              {toFullName(from)}
            </Label>
          )}
          <Text subtle>{message || formatHuman(now())}</Text>
        </SpaceBetween>

        <Markdown className={cx(styles.updateBody, styles.unbound)}>
          {text}
        </Markdown>
      </VStack>
    </VStack>
  );
};
