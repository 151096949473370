import { filter, find, last, map } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";

import { toSearchable, View } from "@api";

import { useDropInView, useLazyItemsForView } from "@state/views";

import { cx } from "@utils/class-names";
import { useShortcut } from "@utils/event";
import { GroupedItems } from "@utils/grouping";
import { useClickAway, useDebouncedMemo } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { isEmptyRef } from "@utils/property-refs";
import { fuzzyMatch } from "@utils/search";
import { usePageSelection } from "@utils/selectable";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { DropTarget } from "@ui/entity-drag-drop";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ArrowCircleDown, CounterIcon, Icon } from "@ui/icon";
import { SearchInput } from "@ui/search-input";
import { Sheet } from "@ui/sheet-layout";
import { TextXLarge } from "@ui/text";

import styles from "./triage-pane.module.css";

export function TriagePane({ view }: { view: View }) {
  const pageId = usePageId();
  const ref = useRef<HTMLDivElement>(null);
  const closing = useRef(false);
  const mouseOutTimer = useRef<NodeJS.Timeout>();
  const { items } = useLazyItemsForView(view.id);
  const [selection, setSelection] = usePageSelection();
  const [open, _setOpen] = useState(false);
  const [search, setSearch] = useState<string>();
  const slowSearch = useDebouncedMemo(() => search, 300, [search]);
  const pushTo = usePushTo();
  const engine = useEngine(view.entity);
  const onReorder = useDropInView(view, pageId);
  const emptyGroup = useMemo(
    () =>
      find(items.grouped?.groups, (g) => isEmptyRef(g.value)) ||
      find(items.grouped?.hidden, (g) => isEmptyRef(g.value)),
    [items.grouped?.groups, items.grouped?.hidden]
  );
  const all = useMemo(
    () => (emptyGroup as Maybe<GroupedItems>)?.items || [],
    [items.grouped?.groups]
  );

  const toTriage = useMemo(() => {
    return search
      ? filter(all, (i) => fuzzyMatch(search, toSearchable(i)))
      : all;
  }, [all, slowSearch]);

  const setOpen = useCallback(
    (v: boolean, closeTime: number = 0) => {
      if (open === v) {
        return;
      }

      _setOpen(v);

      if (!v) {
        closing.current = true;
        setTimeout(() => {
          closing.current = false;
        }, closeTime); // Matches close animation in CSS
      }
    },
    [open, _setOpen]
  );

  const handleMouseOver = useCallback(() => {
    if (mouseOutTimer.current) {
      clearTimeout(mouseOutTimer.current);
    }

    if (!closing.current) {
      setOpen(true);
    }
  }, [closing, setOpen]);

  const handleMouseLeave = useCallback(() => {
    mouseOutTimer.current = setTimeout(() => {
      setOpen(false);
    }, 1000);
  }, [closing, setOpen]);

  useClickAway(ref, () => setOpen(false), true, [setOpen]);

  useShortcut("Escape", [() => open, () => setOpen(false)], [open]);

  return (
    <div className={styles.triagePaneContainer}>
      <Sheet
        size="full"
        className={cx(styles.sticky, open && styles.open)}
        ref={ref}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onDragLeave={handleMouseLeave}
      >
        <DropTarget
          position="after"
          item={last(all)}
          type={view.entity}
          parents={[]}
          group={emptyGroup}
        >
          <Container size="half" stack="vertical" gap={10}>
            <SpaceBetween fit="container">
              <HStack>
                <TextXLarge bold>Inbox to triage</TextXLarge>
                <CounterIcon
                  count={toTriage?.length}
                  color={toTriage?.length ? "blue_5" : "gray"}
                />
              </HStack>
              <HStack>
                <SearchInput search={search} setSearch={setSearch} />
                <Button
                  subtle
                  size="small"
                  icon={
                    <Icon
                      className={cx(styles.icon, !open && styles.rotate)}
                      icon={ArrowCircleDown}
                    />
                  }
                  onClick={() => setOpen(false, 300)}
                />
              </HStack>
            </SpaceBetween>

            <VStack>
              {map(toTriage, (item) =>
                render(
                  view?.layout === "card"
                    ? engine.asListCard
                    : engine.asListItem,
                  {
                    key: item.id,
                    item: item,
                    onOpen: pushTo,
                    onReorder: onReorder,
                    selection: selection,
                    setSelection: setSelection,
                    showProps: view.showProps,
                    group: last(items.grouped?.groups) as Maybe<GroupedItems>,
                    parents: [],
                  }
                )
              )}
            </VStack>
          </Container>
        </DropTarget>
      </Sheet>
    </div>
  );
}
