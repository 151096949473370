import { reduce } from "lodash";
import { atomFamily, selectorFamily } from "recoil";

import { EntityType, FetchOptions, ID } from "@api";

import { getStore } from "@state/generic";
import {
  getItem,
  modifySimpleStoreState,
  SimpleStoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { PointDate } from "@utils/date-fp";
import { Maybe, maybeMap } from "@utils/maybe";
import { isDefault } from "@utils/recoil";

import { appendKey, localStorageEffect } from "../local-storage-effect";
import { setFetchResults } from "./actions";

export type FetchResultsState = {
  ids: ID[];
  fetchedAt?: PointDate;
};

export const toFetchResultKey = (id: ID, archived: Maybe<boolean>) =>
  archived && !!id ? `${id}|archived` : id;

export const WorkspaceFetchResultsStoreAtom = atomFamily<
  SimpleStoreState<FetchResultsState>,
  ID
>({
  key: "WorkspaceFetchResultsStoreAtom",
  default: { dirty: [], lookup: {} },
  effects: (wid) => [
    localStorageEffect<SimpleStoreState<FetchResultsState>>({
      key: appendKey("traction.store.fetch-results", wid),
      props: ["lookup", "dirty"],
      clean: (state) => ({
        ...state,
        lookup: reduce(
          state.lookup,
          (acc, v, k) => (k?.includes("archived") ? acc : { ...acc, [k]: v }),
          {}
        ),
      }),
    }),
  ],
});

export const FetchResultsStoreAtom = WorkspaceWrappedAtom(
  "FetchResultsStoreAtom",
  WorkspaceFetchResultsStoreAtom
);

export const FetchResultsAtom = selectorFamily({
  key: "FetchResultsAtom",
  get:
    (id: ID) =>
    ({ get }) => {
      const { archived } = get(GlobalFetchOptionsAtom);
      const store = get(FetchResultsStoreAtom);
      return store.lookup?.[toFetchResultKey(id, archived)];
    },

  set:
    (id: ID) =>
    ({ set, get }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        const { archived } = get(GlobalFetchOptionsAtom);
        set(
          FetchResultsStoreAtom,
          modifySimpleStoreState(
            toFetchResultKey(id, archived),
            setFetchResults(newValue.ids, newValue.fetchedAt)
          )
        );
      }
    },
});

export const lastFetchedAt = selectorFamily({
  key: "lastFetchedAt",
  get:
    (id: ID) =>
    ({ get }) => {
      const results = get(FetchResultsAtom(id));
      return results?.fetchedAt;
    },
});

export const itemsForFetchResults = selectorFamily({
  key: "itemsForFetchResults",
  get:
    (
      params: Maybe<{
        key: string;
        type: EntityType;
      }>
    ) =>
    ({ get }) => {
      if (!params) {
        return [];
      }
      const { key, type } = params;
      const results = get(FetchResultsAtom(key));
      const store = get(getStore(type));
      return maybeMap(results?.ids, (id) => getItem(store, id));
    },
});

/*
 * Global Fetch Options
 */

export const WorkspaceGlobalFetchOptionsAtom = atomFamily<
  Partial<FetchOptions>,
  ID
>({
  key: "WorkspaceGlobalFetchOptionsAtom",
  default: { archived: false },
  effects: (wid) => [
    localStorageEffect<Partial<FetchOptions>>({
      key: appendKey("traction.store.fetch-options", wid),
    }),
  ],
});

export const GlobalFetchOptionsAtom = WorkspaceWrappedAtom(
  "GlobalFetchOptionsAtom",
  WorkspaceGlobalFetchOptionsAtom
);
