import { subMinutes } from "date-fns";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { PersonPresenceAtom } from "@state/realtime";

import { usePointDate } from "@utils/date-fp";
import { now } from "@utils/now";

import { PersonIcon, Props as PersonIconProps } from "@ui/icon/person";

type PersonStatusIconProps = PersonIconProps;

export const PersonStatusIcon = ({ person }: PersonStatusIconProps) => {
  const presence = useRecoilValue(PersonPresenceAtom(person.id));
  const online = useMemo(
    () =>
      usePointDate(
        presence?.updatedAt,
        (u) => !!u && u > subMinutes(now(), 5)
      ) ?? false,
    [presence]
  );
  return <PersonIcon person={person} online={online} />;
};
