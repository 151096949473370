import { sentenceCase } from "change-case";
import { filter as loFilter,last, map } from "lodash";
import { useEffect, useMemo } from "react";

import {
  AggregatePropRef,
  DatabaseID,
  Entity,
  EntityType,
  FilterQuery,
  ID,
  Pipeline,
  PropertyDef,
  PropertyRef,
  View,
} from "@api";

import {
  useOpenAppCommands,
  usePageUndoRedo,
  useRegisterPage,
} from "@state/app";
import { useLazyPropertyDef } from "@state/databases";
import { useLazyEntity, useLazyQuery, useNestedSource } from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useAddToRecents } from "@state/recents";
import { useEntityLabel } from "@state/settings";
import { toTemplateViewId, useLazyItemsForView } from "@state/views";

import { aggregateBy } from "@utils/aggregating";
import { next, omitEmpty } from "@utils/array";
import { passes } from "@utils/filtering";
import { useShowMore, useStickyState } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation, asTempUpdate, asUpdate } from "@utils/property-mutations";
import { getSetting, toFieldName, toLabel } from "@utils/property-refs";
import { fromScope, toChildLocation } from "@utils/scope";
import { usePageSelection } from "@utils/selectable";
import { plural } from "@utils/string";
import { useSyncPathnameID } from "@utils/url";

import { AddWorkButton } from "@ui/add-work-dialog";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { render, useEngine } from "@ui/engine";
import { FormsCreatingHere } from "@ui/engine/form";
import { SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon, Slash } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { useMutate } from "@ui/mutate";
import { PackageTag } from "@ui/package-label";
import AppPage from "@ui/page/app-page";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { WithSuggestedProps } from "@ui/suggested-props";
import { Heading, Text, TextSmall } from "@ui/text";
import SmartViewPane from "@ui/view-pane";

import styles from "./styles.module.css";

export const PipelinePage = ({ id }: { id: ID }) => {
  const pipeline = useLazyEntity<"pipeline">(id);
  const itemType = useMemo(
    () =>
      when(pipeline, (p) =>
        getSetting(p.settings, "child_type")
      ) as Maybe<EntityType>,
    [pipeline?.settings]
  );
  const [viewId, setViewId] = useStickyState<Maybe<string>>(
    () =>
      when(itemType, (t) =>
        toTemplateViewId("pipeline-default", {
          parent: id,
          entity: t,
        })
      ),
    `pipeline-view-${id}`
  );
  const view = useLazyEntity<"view">(viewId || "");

  const suggested = useMemo(
    () => omitEmpty([...(view?.aggregate || [])]) as PropertyRef[],
    [pipeline]
  );
  const [page] = useRegisterPage(id, pipeline);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, pipeline?.id);

  // Add to recents
  useAddToRecents(id);

  // When pipeline has loaded, but view is missing (could be deleted)
  useEffect(() => {
    if (pipeline && (!viewId || !view)) {
      setViewId(
        toTemplateViewId("pipeline-default", {
          parent: id,
          entity: getSetting<EntityType>(pipeline?.settings, "child_type"),
        })
      );
    }
  }, [pipeline?.id, view?.id, id]);

  if (!pipeline) {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideHeader
              id={id}
              item={pipeline}
              pageId={page.id}
              viewId={viewId}
              view={view}
              itemType={itemType}
            />

            <Main className={styles.main}>
              <WithSuggestedProps props={suggested}>
                {viewId && (
                  <SmartViewPane
                    viewId={viewId}
                    className={styles.viewPane}
                    onChangeView={(v) => setViewId(v.id)}
                    showTitle={false}
                    showViewsBar={false}
                  />
                )}
              </WithSuggestedProps>
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

const SideHeader = ({
  id,
  viewId,
  pageId,
  item,
  view,
  itemType,
}: {
  id: ID;
  viewId: Maybe<ID>;
  view: Maybe<View>;
  pageId: ID;
  item: Pipeline;
  itemType: Maybe<EntityType>;
}) => {
  const mutate = useMutate();
  const goTo = useGoTo();
  const engine = useEngine("pipeline");
  const installed = useHasPackages(id, [SystemPackages.Forms]);
  const itemsSource = useNestedSource(item, itemType);
  const [selection] = usePageSelection();
  const { items: allItems } = useLazyItemsForView(viewId || "");
  const items = useMemo(
    () =>
      selection?.selected?.size
        ? loFilter(allItems?.all, (i) => selection.selected?.has(i.id))
        : allItems?.all,
    [selection?.selected, allItems]
  );
  const itemLabel = useEntityLabel(itemType || "deal", item.source.scope, {
    case: "title",
  });

  // Setup custom view for this
  useEffect(() => {}, []);

  const allPipelines = useLazyQuery(
    "pipeline",
    useMemo(
      () => ({
        and: [
          {
            field: "location",
            type: "text",
            op: "ends_with",
            value: { text: last(fromScope(item.location)) },
          },
          {
            field: "id",
            type: "text",
            op: "does_not_equal",
            value: { text: item.id },
          },
        ],
      }),
      [item.location, item.id]
    ),

    { limit: 10, fetch: false }
  );
  const {
    visible: otherPipelines,
    hasMore,
    showMore,
  } = useShowMore(allPipelines, 3);

  const nestedSource = useNestedSource(
    item,
    getSetting(item?.settings, "child_type")
  );

  const openCmdK = useOpenAppCommands(item);

  return (
    <SideNav className={styles.nav}>
      <SpaceBetween direction="vertical">
        <VStack gap={10} width="container">
          <VStack gap={20} width="container">
            <Container gap={10} padding="none" inset="bottom" stack="vertical">
              <SpaceBetween>
                <MagicEmojiSelect
                  key={item.id}
                  entity={item}
                  size="xlarge"
                  emoji={item.icon || "🗓️"}
                  onChange={(emoji) =>
                    mutate(
                      asUpdate(
                        item,
                        asMutation({ field: "icon", type: "text" }, emoji)
                      )
                    )
                  }
                />
                <PackageTag type="pipeline" scope={item.source.scope} />
              </SpaceBetween>

              <EditableHeading
                key={item.id}
                text={item.name || ""}
                size="h2"
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(
                      asUpdate(
                        item,
                        asMutation({ field: "name", type: "text" }, i)
                      )
                    )
                  );
                }}
              />
            </Container>
          </VStack>

          <Container inset="horizontal" padding="none" fit="container">
            <SpaceBetween>
              <Button fit="container" icon={Slash} subtle onClick={openCmdK}>
                Modify
              </Button>

              <AddWorkButton
                fit="container"
                icon={PlusIcon}
                subtle
                defaults={{
                  refs: { roadmaps: [{ id: item.id }] },
                  location: toChildLocation(item.location, item.id),
                }}
                allowed={[nestedSource.type]}
              >
                Add new
              </AddWorkButton>
            </SpaceBetween>
          </Container>

          {installed[SystemPackages.Forms] && (
            <>
              <Divider margin="on" />
              <FormsCreatingHere item={item} />
            </>
          )}

          <Divider margin="on" />

          {!!view &&
            map(view?.aggregate, (agg) => (
              <ViewAggFilterStat
                key={agg.field}
                subtitle={`${sentenceCase(agg.method)} of ${
                  selection?.selected?.size ? "selected" : "all"
                } ${plural(itemLabel)}`}
                aggregate={agg}
                source={itemsSource}
                items={items}
                onClick={() =>
                  mutate(
                    asTempUpdate(
                      view,
                      asMutation(
                        { field: "aggregate", type: "json" },
                        map(view.aggregate, (a) =>
                          a.field === agg.field
                            ? {
                                ...a,
                                method: next(
                                  ["sum", "avg", "min", "max"],
                                  a.method,
                                  true
                                ),
                              }
                            : a
                        )
                      )
                    )
                  )
                }
              />
            ))}

          <ViewFilterStat
            title={`${itemLabel}`}
            subtitle={
              selection?.selected?.size
                ? `Selected ${plural(itemLabel)}`
                : `Total ${plural(itemLabel)}`
            }
            items={items}
            source={itemsSource}
          />

          <ViewFilterStat
            title={`New ${plural(itemLabel)}`}
            subtitle="In last 30 days"
            filter={{
              field: "createdAt",
              type: "date",
              op: "after",
              value: { formula: "=today-30d" },
            }}
            items={items}
            source={itemsSource}
          />

          <ViewFilterStat
            title={`Closed ${plural(itemLabel)}`}
            subtitle="In last 30 days"
            filter={{
              field: "stamps.status_done",
              type: "date",
              op: "after",
              value: { formula: "=today-30d" },
            }}
            items={items}
            source={itemsSource}
          />

          <Divider margin="on" />

          <LabelledPropertyValueList
            entity={item}
            onChange={(cs) => mutate(asUpdate(item, cs))}
            stack="vertical"
          />
        </VStack>

        {!!otherPipelines?.length && (
          <Menu>
            <MenuGroup label="Other Pipelines">
              {map(otherPipelines, (cal) =>
                render(engine.asMenuItem, {
                  key: cal.id,
                  item: cal,
                  onOpen: goTo,
                })
              )}
              {hasMore && <ShowMoreMenuItem onClick={showMore} />}
            </MenuGroup>
          </Menu>
        )}
      </SpaceBetween>
    </SideNav>
  );
};

export default PipelinePage;

type ViewFilterStatProps = {
  title: string;
  subtitle: string;
  filter?: FilterQuery;
  aggregate?: AggregatePropRef;
  def?: PropertyDef;
  items: Entity[];
  source: DatabaseID;
  onClick?: () => void;
};

const ViewAggFilterStat = ({
  aggregate: agg,
  source,
  ...rest
}: Omit<ViewFilterStatProps, "title" | "filter" | "format"> & {
  aggregate: AggregatePropRef;
}) => {
  const def = useLazyPropertyDef(source, agg);
  return (
    <ViewFilterStat
      title={toFieldName(agg) || "Value"}
      aggregate={agg}
      def={def}
      source={source}
      {...rest}
    />
  );
};

const ViewFilterStat = ({
  subtitle,
  title,
  aggregate,
  def,
  filter,
  onClick,
  items,
}: ViewFilterStatProps) => {
  const count = useMemo(() => {
    if (!aggregate) {
      return items.length;
    }

    const filtered = loFilter(items, (f) => !filter || !!passes(f, filter, {}));

    return aggregateBy(filtered, aggregate, def);
  }, [items]);

  return (
    <Card interactable={false} onClick={onClick}>
      <Text>{title}</Text>
      <Heading bold className={styles.statHeading}>
        {when(def?.format, (f) =>
          toLabel(
            {
              field: aggregate?.field || "any",
              type: "number",
              value: { number: count },
            },
            f
          )
        ) || count}
      </Heading>
      <TextSmall subtle>{subtitle}</TextSmall>
    </Card>
  );
};
