import { isString } from "lodash";
import { useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { ID, Ref } from "@api";

import { addToRecents } from "./actions";
import { RecentsAtom } from "./atoms";

const toId = (id: ID | Ref): ID => (isString(id) ? id : id.id);

export const useAddToRecents = (thing: ID | Ref) => {
  const setStore = useSetRecoilState(RecentsAtom);
  useEffect(() => {
    setStore(addToRecents(toId(thing)));
  }, [thing]);
};

export const useIsRecent = (id: ID | Ref) => {
  const { lookup } = useRecoilValue(RecentsAtom);

  return useMemo(() => !!lookup[toId(id)], [lookup, id]);
};
