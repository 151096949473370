import { map } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { useDebounce } from "use-debounce";

import { getUrlMeta, hasBody, hasResources,ID, Link, RichText } from "@api";

import { useLazyEntity } from "@state/generic";
import { useLazyGetNotes } from "@state/notes";
import { useAttachLink, useLazyGetResources } from "@state/resources";

import { Fn } from "@utils/fn";
import { extractLinks } from "@utils/link";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { all } from "@utils/promise";
import { isUrl, withoutParams } from "@utils/url";

import { usePageId } from "@ui/app-page";

interface Props {
  entityId: ID;
  onExtracted?: Fn<Link[], void>;
}

export const ResourceExtractor = ({ entityId, onExtracted }: Props) => {
  const pageId = usePageId();
  const entity = useLazyEntity(entityId);
  const resourceable = useMemo(
    () => (hasResources(entity) && hasBody(entity) ? entity : undefined),
    [entity]
  );
  const resources = useLazyGetResources(resourceable?.refs?.resources);
  const notes = useLazyGetNotes(resourceable?.refs?.notes);
  const attachLinks = useAttachLink(resourceable, pageId);
  const [slowBody, setSlowBody] = useDebounce(
    resourceable?.body?.markdown || resourceable?.body?.html,
    1000
  );

  useEffect(
    () => setSlowBody(resourceable?.body?.markdown || resourceable?.body?.html),
    [resourceable?.body]
  );

  const extract = useCallback(
    async (body: Maybe<RichText>) => {
      const existing = new Set(
        maybeMap(
          [
            ...map(resources, (r) => r.url),
            ...map(notes, (n) => n.links?.[0]?.url),
          ],
          (url) => url && withoutParams(url)
        )
      );
      const urls = when(body?.markdown || body?.html, extractLinks);
      const newUrls = maybeMap(urls || [], (u) =>
        !!u && !existing.has(withoutParams(u.url)) ? u : undefined
      );

      const links = await all(
        map(newUrls, (u) =>
          !u.text || isUrl(u.text)
            ? getUrlMeta(u.url)
            : { text: u?.text, url: u.url }
        )
      );

      onExtracted ? onExtracted(links) : attachLinks(links);
    },
    [resources]
  );

  useEffect(() => {
    if (entity && resourceable?.body && extract) {
      extract(resourceable?.body);
    }
  }, [slowBody]);

  return <></>;
};
