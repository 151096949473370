import * as notion from "./integrations/notion/mutations";
import * as traction from "./integrations/traction/mutations";
import { Entity, Error, Update } from "./types";

export { createPage, updatePage } from "./integrations/notion/mutations";
export * from "./integrations/traction/mutations";

export const persistComment = notion.persistComment;

export const persistDelete = async <T extends Entity>(
  u: Extract<Update<T>, { method: "delete" }>
) => {
  return await traction.persistDelete(u);
};

export const persistRestore = async <T extends Entity>(
  u: Extract<Update<T>, { method: "restore" }>
) => {
  return await traction.persistRestore(u);
};

export const persistUpdate = async <T extends Entity>(
  u: Extract<Update<T>, { method: "update" }>
) => {
  return await traction.persistUpdate(u);
};

export const persistNew = async <T extends Entity>(
  u: Extract<Update<T>, { method: "create" }>
) => {
  return await traction.persistNew(u);
};

export const persist = async <T extends Entity>(
  u: Update<T>[]
): Promise<(Entity | Error)[]> => {
  return await traction.persist(u);
};
