import { atomFamily, selectorFamily } from "recoil";

import { ID } from "@api";

import { WorkspaceWrappedAtom } from "@state/workspace/atoms";

import { Maybe } from "@utils/maybe";

import { TractionPresence } from "./types";

export interface PresenceStoreState {
  lookup: Record<string, TractionPresence>;
}

export const WorkspacePresenceStoreAtom = atomFamily<PresenceStoreState, ID>({
  key: "WorkspaceTeamPresenceAtom",
  default: { lookup: {} },
});

export const PresenceStoreAtom = WorkspaceWrappedAtom(
  "PresenceStoreAtom",
  WorkspacePresenceStoreAtom
);

export const PersonPresenceAtom = selectorFamily<
  Maybe<TractionPresence>,
  Maybe<ID>
>({
  key: "PersonPresenceAtom",
  get:
    (personId) =>
    ({ get }) =>
      !!personId ? get(PresenceStoreAtom).lookup[personId] : undefined,
});
