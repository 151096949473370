import { pick } from "lodash";
import { AtomEffect } from "recoil";

import { log } from "@utils/debug";
import { composel } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { isDefault } from "@utils/recoil";
import { isClient } from "@utils/ssr";

import { ID } from "./types";

// Open an IndexedDB connection
async function openDB(dbName: string, store: string): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);
    request.onerror = (event) => {
      reject("Database error: " + (event.target as IDBOpenDBRequest).error);
    };
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      resolve(db);
    };
    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;

      if (!db.objectStoreNames.contains(store)) {
        db.createObjectStore(store);
      }
    };
  });
}

// Get item from IndexedDB
async function getItem<T>(
  dbName: string,
  store: string,
  key: string
): Promise<Maybe<T>> {
  const db = await openDB(dbName, store);
  return new Promise((resolve, reject) => {
    if (!db.objectStoreNames.contains(store)) {
      return resolve(undefined);
    }

    try {
      const transaction = db.transaction([store], "readonly");
      const objectStore = transaction.objectStore(store);
      const request = objectStore.get(key);
      request.onsuccess = () => {
        resolve(request.result || undefined);
      };
      request.onerror = () => {
        reject("Error retrieving data");
      };
    } catch (err) {
      log(err, { dbName, store, key });
      reject("Error retrieving data.");
    }
  });
}

// Set item in IndexedDB
async function setItem<T>(
  dbName: string,
  store: string,
  key: string,
  value: T
): Promise<void> {
  const db = await openDB(dbName, store);
  return new Promise((resolve, reject) => {
    try {
      const transaction = db.transaction([store], "readwrite");
      const objectStore = transaction.objectStore(store);
      const request = objectStore.put(value, key);
      request.onsuccess = () => resolve();
      request.onerror = () => reject("Error saving data");
    } catch (err) {
      log(err, { dbName, store, key, value });
      reject("Error saving data.");
    }
  });
}

// Remove item from IndexedDB
async function removeItem(
  dbName: string,
  store: string,
  key: string
): Promise<void> {
  const db = await openDB(dbName, store);
  return new Promise((resolve, reject) => {
    if (!db.objectStoreNames.contains(store)) {
      return resolve();
    }

    const transaction = db.transaction([store], "readwrite");
    const objectStore = transaction.objectStore(store);
    const request = objectStore.delete(key);
    request.onsuccess = () => resolve();
    request.onerror = () => reject("Error deleting data");
  });
}

type PersistenceOptions<T> = {
  db: string;
  store: string;
  key: string;
  props?: Array<keyof T>;
  clean?: (val: Partial<T>) => Partial<T>;
  skipDefault?: boolean;
  default?: T;
};

export async function getStored<T>(
  db: string,
  store: string,
  key: string,
  defaultVal?: T,
  props?: (keyof T)[]
): Promise<Maybe<T>> {
  if (!isClient()) {
    return undefined;
  }
  const savedValue = await getItem<T>(db, store, key);

  if (savedValue !== null && savedValue !== undefined) {
    return {
      ...defaultVal,
      ...(props ? pick(savedValue, props) : savedValue),
    };
  }

  return defaultVal;
}

export const appendKey = (key: string, part: ID) => `${key}.${part}`;

export const indexedDbEffect =
  <T>(options: PersistenceOptions<T>): AtomEffect<T> =>
  ({ setSelf, onSet }) => {
    if (!isClient()) {
      return;
    }

    // Load from IndexedDB and merge with default
    (async () => {
      const storedData = await getStored(
        options.db,
        options.store,
        options.key,
        options.default
      );
      when(storedData, composel(options.clean || ((a) => a), setSelf));
    })();

    onSet(async (newValue, _, isReset) => {
      if (isReset || isDefault(newValue)) {
        await removeItem(options.db, options.store, options.key);
      } else {
        await setItem(
          options.db,
          options.store,
          options.key,
          options.props ? pick(newValue, options.props) : newValue
        );
      }
    });
  };
