import { Status } from "@api";

import { cx } from "@utils/class-names";
import { Maybe, when } from "@utils/maybe";

import { Button, Props as ButtonProps } from "@ui/button";
import { StatusIcon } from "@ui/icon/status";
import { StatusSelect } from "@ui/select";

import styles from "./status-button.module.css";

export { isBlocked,StatusIcon } from "@ui/icon/status";

export type Props = {
  status: Maybe<Status>;
  options: Status[];
  inset?: ButtonProps["inset"];
  subtle?: boolean;
  size?: ButtonProps["size"];
  onChange?: (n: Status, prev: Maybe<Status>) => void;
};

export const StatusButton = ({
  status,
  subtle = false,
  options,
  onChange,
  ...rest
}: Props) => (
  <StatusSelect
    value={status}
    options={(options as Status[]) || []}
    onChange={(v) => when(v, (value) => onChange?.(value, status))}
  >
    <Button
      variant="secondary"
      {...rest}
      subtle={subtle}
      icon={<StatusIcon status={status} />}
      className={cx(styles.button)}
    >
      <span className={styles.text}>{status?.name || "Not set"}</span>
    </Button>
  </StatusSelect>
);
