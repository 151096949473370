import { useCallback, useMemo, useState } from "react";

import {
  Entity,
  JsonArray,
  PropertyMutation,
  Ref,
  Schedule,
  Update,
} from "@api";

import {
  useCreateEntity,
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
} from "@state/generic";
import { ScheduleStoreAtom, useTempSchedule } from "@state/schedule";
import { useEntityLabels } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";

import { omitEmpty, OneOrMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { asMutation, asTempUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Dialog } from "@ui/dialog";
import { Divider } from "@ui/divider";
import { HStack, VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { RadioMenuGroup, RadioMenuItem } from "@ui/menu-item/radio";
import { showError } from "@ui/notifications";
import { TemplateSelect } from "@ui/select";
import { EntityTypeSelect } from "@ui/select/entity-type";

import { ScheduleRepeatOptions } from "./editor";

import styles from "./styles.module.css";

interface DialogProps {
  defaults: Partial<Omit<Schedule, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const ScheduleCreateDialog = ({
  defaults,
  onCancel,
  onSaved,
}: DialogProps) => {
  type Mode = "template" | "use-overrides" | "new-template";
  const pageId = usePageId();
  const wID = useActiveWorkspaceId();
  const [startFrom, setStartFrom] = useState<Mode>("template");
  const [templateRef, setTemplate] = useState<Ref>();
  const [name, setName] = useState("");
  const schedule = useTempSchedule(defaults, pageId);
  const type = schedule?.entity;
  const createTemplate = useCreateEntity(
    type || "task",
    schedule?.source.scope || wID
  );

  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const queue = useQueueUpdates(pageId);
  const toLabel = useEntityLabels(scope);
  const template = useLazyEntity(templateRef?.id);

  const { changes, save, rollback } = useLocalChanges(
    schedule?.id || "",
    ScheduleStoreAtom
  );
  const handleChange = (changes: OneOrMany<PropertyMutation<Schedule>>) =>
    schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>);

  const handleCreate = useCallback(() => {
    if (!schedule) {
      return showError("Not ready to create.");
    }

    if (!name?.length) {
      return showError("Enter a name for this work.");
    }

    let finalTemplate: Maybe<Ref> = template;

    if (startFrom === "new-template") {
      finalTemplate = createTemplate([
        asMutation({ field: "template", type: "text" }, "root"),
        asMutation({ field: "name", type: "text" }, name),
      ]);
    }

    const created = save([
      asTempUpdate(
        schedule,
        omitEmpty([
          asMutation({ field: "name", type: "text" }, name),

          // Store either the template reference or the final item name
          finalTemplate
            ? asMutation(
                { field: "useTemplate", type: "relation" },
                toRef(finalTemplate)
              )
            : // Use the name as the title to create in overrides
              asMutation({ field: "overrides", type: "json" }, [
                { field: "name", type: "text", value: { text: name } },
              ] as JsonArray),
        ])
      ),
    ]);

    if (created) {
      onSaved?.(toRef(created));
    }

    onCancel?.();
  }, [name, templateRef, template, scope, save]);

  const handleDismiss = useCallback(() => {
    rollback();
    onCancel?.();
  }, [rollback]);

  return (
    <Dialog
      title="Schedule recurring work"
      onDismiss={handleDismiss}
      actions={
        <HStack>
          <Button onClick={handleDismiss}>Cancel</Button>
          <Button
            disabled={!(name?.length || templateRef) || !type}
            variant="primary"
            onClick={handleCreate}
          >
            Create
          </Button>
        </HStack>
      }
    >
      <VStack gap={16} className={styles.body} fit="container">
        <Field label="What do you want to repeat?">
          <EntityTypeSelect
            plural={false}
            value={type}
            onChange={(type) =>
              handleChange(asMutation({ field: "entity", type: "text" }, type))
            }
            scope={scope}
            placeholder="Choose type of work..."
          />
        </Field>

        <Field label="Give it a name">
          <TextInput
            value={name}
            onChange={setName}
            updateOn="change"
            placeholder="Name..."
          />
        </Field>

        <Field label="Create new work from">
          <RadioMenuGroup
            value={startFrom}
            onChanged={(f) => !!f && setStartFrom(f as Mode)}
          >
            <RadioMenuItem
              text={`Simple ${toLabel(type, {
                case: "lower",
              })} (no template)`}
              value="use-overrides"
            />
            <RadioMenuItem text={`Existing template`} value="template" />
            {type && startFrom === "template" && (
              <Container padding="vertical" size="half" fit="container">
                <Container padding="left" size="half" fit="container">
                  <TemplateSelect
                    className={styles.control}
                    value={templateRef}
                    type={type}
                    scope={scope}
                    allowed={[type]}
                    allowNew={true}
                    onChange={setTemplate}
                    placeholder="Select template..."
                  />
                </Container>
              </Container>
            )}
            <RadioMenuItem text={`Create new template`} value="new-template" />
          </RadioMenuGroup>
        </Field>

        <Divider />

        {schedule && (
          <Field>
            <ScheduleRepeatOptions schedule={schedule} mutate={handleChange} />
          </Field>
        )}
      </VStack>
    </Dialog>
  );
};
