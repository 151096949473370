import {
  filter,
  find,
  findIndex,
  forEach,
  groupBy,
  includes,
  intersection,
  isArray,
  map,
  reduce,
  reverse as lodashReverse,
  uniq,
  uniqBy as _uniqBy,
  without,
} from "lodash";

import { Fn, Pred } from "./fn";
import { ID, isDefined, Maybe, Primitive, SafeRecord, when } from "./maybe";

export type OneOrMany<T> = T | T[];

// typescript type that extracts generic array type
export type InnerType<T> = T extends (infer U)[] ? U : T;

// Mutates the array passed in. Use when you want to avoid creating a new array
// in a reduce or map function that you created the initial value.
export const pushDirty = <T>(arr: T[], ...i: T[]) => {
  arr.push(...i);
  return arr;
};

export const pushDirty_ =
  <T>(arr: T[]) =>
  (...i: T[]) =>
    pushDirty(arr, ...i);

export const ensureArray = <T>(input: Maybe<OneOrMany<T>>): T[] =>
  !isDefined(input) ? [] : isArray(input) ? input : [input];
// Alias
export const ensureMany = ensureArray;

export const justOne = <T>(input: Maybe<OneOrMany<T>>): Maybe<T> =>
  isArray(input) ? input[0] : input;

export const pickIds = <T extends { id: string }>(
  things: Maybe<T[]>
): string[] => maybeMap(things || [], ({ id }) => id);

export const replace = <T>(arr: T[], index: number, val?: T): T[] => {
  const res = [...arr];
  val ? res.splice(index, 1, val) : res.splice(index, 1);
  return res;
};

// Lodash mutates the array, this one is pure 🧘
export const reverse = <T>(ts: T[]) => lodashReverse([...ts]);

export const uniqBy = <T>(
  news: T[],
  toKey: Fn<T, Primitive>,
  onConflict: "first" | "last" = "first"
): T[] =>
  onConflict === "first"
    ? _uniqBy(news, toKey)
    : reverse(_uniqBy(reverse(news), toKey));

export const prepend = <T>(t: T, arr: Maybe<T[]>) => [t, ...(arr || [])];

export const append = <T>(arr: Maybe<T[]>, t: T) => [...(arr || []), t];

export const omitEmpty = <T>(arr?: Array<Maybe<T>>): T[] =>
  filter(arr, isDefined);

export const omitFalsey = <T extends Object>(arr?: (Maybe<T> | false)[]): T[] =>
  filter(arr || [], (a) => !!a) as T[];

export const maybeMap = <T, R>(arr: T[], fn: Fn<T, Maybe<R>>): R[] =>
  reduce(
    arr,
    (res, curr) => {
      const r = fn(curr);
      return isDefined(r) ? pushDirty(res, r) : res;
    },
    [] as R[]
  );
export const maybeMap_ =
  <T, R>(fn: Fn<T, Maybe<R>>) =>
  (arr: T[]): R[] =>
    maybeMap(arr, fn);

export const maybeFilter = <T>(arr: Maybe<T>[], fn: Pred<T>): T[] =>
  reduce(
    arr,
    (res, curr) => {
      if (isDefined(curr) && fn(curr)) {
        return pushDirty(res, curr);
      }
      return res;
    },
    [] as T[]
  );

export const mapFind = <T, R>(arr: T[], map: Fn<T, Maybe<R>>): Maybe<R> =>
  reduce(arr, (found, next) => found ?? map(next), undefined as Maybe<R>);
export const findMap = mapFind; // Alias

export const pop = <T>([top, ...arr]: T[]) => [top, arr];

export const overlaps = <T>(arr: T[], arr2: T[]) =>
  intersection(arr, arr2).length > 0;

export const indexBy = <T>(
  items: T[],
  pick: (i: T) => Maybe<OneOrMany<string>>
): SafeRecord<string, T> => {
  const result: { [key: string]: T } = {};
  items.forEach((item) => {
    const keyOrKeys = pick(item);
    if (isDefined(keyOrKeys)) {
      ensureArray(keyOrKeys).forEach((key) => {
        result[key] = item;
      });
    }
  });
  return result;
};

export const indexById = <T extends { id: string }>(items: T[]) =>
  indexBy(items, (i) => i.id);

export const groupByMany = <T>(
  items: T[],
  toGroups: (i: T) => Maybe<string | string[]>
) =>
  reduce(
    items,
    (r, i) => {
      const keyOrKeys = toGroups(i);

      if (isArray(keyOrKeys)) {
        ensureArray(keyOrKeys).forEach((key) => {
          r[key] = pushDirty(r[key] || [], i);
        });
      } else if (isDefined(keyOrKeys)) {
        r[keyOrKeys] = pushDirty(r[keyOrKeys] || [], i);
      }

      return r;
    },
    {} as { [key: string]: Maybe<T[]> }
  );

export const lookup = <T, R = T>(
  items: T[],
  pick: (i: T) => Maybe<string | string[]>,
  fallback?: (k: string) => R
) => {
  const hash = indexBy(items, pick);
  return (key: string) => {
    const r = hash[key] || fallback?.(key);
    if (!r) {
      throw new Error(`Item not found for key (${key}).`);
    }
    return r;
  };
};

export const maybeLookup = <T>(
  items: T[],
  pick: (i: T) => Maybe<string | string[]>
): Fn<string, Maybe<T>> => {
  const hash = indexBy(items, pick);
  return (key) => hash[key];
};

export const maybeLookupById = <T extends { id: string }>(
  items: T[]
): Fn<string, Maybe<T>> => maybeLookup(items, (i) => i.id);

export const withoutBy = <T>(
  ts: T[],
  exclude: T | T[],
  comparable: Fn<T, Primitive>
) => {
  const blacklist = map(ensureArray(exclude), comparable);
  return filter(ts, (t) => !blacklist.includes(comparable(t)));
};

export const mapFirst = <T, R>(ts: T[], trans: Fn<T, R>): Maybe<R> =>
  reduce(ts, (r, t) => r || trans(t), undefined as Maybe<R>);

export const reduceAsync = <T, R>(
  ts: T[],
  fn: (r: R, i: T) => Promise<R>,
  initial: R
) =>
  reduce(
    ts,
    async (res, next) => await fn(await res, next),
    initial as Promise<R>
  );

export const next = <T>(ts: T[], curr: T, loop: boolean = true) =>
  loop ? ts[(ts.indexOf(curr) + 1) % ts.length] : ts[ts.indexOf(curr) + 1];

export const move = <T>(ts: T[], from: number, to: number) => {
  const res = [...ts];
  const length = ts.length;

  if (length === 0 || from === to || from < 0 || from >= length) {
    return ts;
  }

  if (to >= length) {
    to = length - 1;
  } else if (to < 0) {
    to = 0;
  }

  res.splice(to, 0, res.splice(from, 1)[0]);

  return res;
};

// Adds items to an array (returns) but compares by a key to ensure they are uniq. Always uses the new value on conflict.
export const uniqConcat = <T>(
  existing: T[],
  news: T[],
  toKey?: Fn<T, Primitive>
) =>
  // Lodash uniqBy always uses the first value on conflict
  toKey
    ? uniqBy([...news, ...existing], toKey, "first")
    : uniq([...news, ...existing]);

export const filterLimit = <T>(ts: T[], fn: Pred<T>, limit: number) =>
  reduce(
    ts,
    (res, t) => (res?.length >= limit ? res : fn(t) ? pushDirty(res, t) : res),
    [] as T[]
  );

export const isEmpty = <T>(arr: Maybe<T[]>): arr is undefined | [] =>
  !arr || arr.length === 0;

export const maybeMapMax = <T, R>(
  items: T[],
  max: number,
  fn: Fn<T, Maybe<R>>
): R[] =>
  reduce(
    items,
    (acc, item) => {
      if (acc.length >= max) {
        return acc;
      }

      return when(fn(item), (i) => pushDirty(acc, i)) || acc;
    },
    [] as R[]
  );

export const whenEmpty = <T>(arr: Maybe<T[]>, fallback: T[]): T[] =>
  isEmpty(arr) ? fallback : arr;

export const defsIndex = <T>(items: T[], item: T, throwMessage: string) => {
  const index = items.indexOf(item);
  if (index === -1) {
    throw new Error(throwMessage);
  }
  return index;
};

export const maybeFindIndex = <T>(ts: T[], pred: Pred<T>) => {
  const index = findIndex(ts, pred);
  return index >= 0 ? index : undefined;
};

// Finds the first item that passes the first predicate, else fallback to the next predicate
// However it just iterates through the array once
export const findPreferential = <T>(
  items: T[],
  ...preds: Pred<T>[]
): Maybe<T> => {
  const found = Array.from(Array(preds.length));

  forEach(items, (item) => {
    forEach(preds, (pred, i) => {
      if (!found[i] && pred(item)) {
        found[i] = item;
      }
    });
  });

  return find(found, isDefined);
};

export const dependencySort = <T extends { id: ID }>(
  items: T[],
  toParent: Fn<T, Maybe<ID>>
) => {
  const allIds = map(items, (i) => i.id);
  const byParent = groupBy(items, (i) => {
    const pId = toParent(i);
    return allIds?.includes(pId || "never") ? pId : "EMPTY";
  });

  // recursive function to collapse the tree by going down the branch
  const collapseTree = (parentId: ID): T[] => {
    const children = byParent[parentId] as Maybe<T[]>;
    if (!children) {
      return [];
    }

    return reduce(
      children,
      (res, child) => {
        return pushDirty(res, child, ...collapseTree(child.id));
      },
      [] as T[]
    );
  };

  return collapseTree("EMPTY");
};

export const toggle = <T>(ts: T[], t: T) =>
  includes(ts, t) ? without(ts, t) : append(ts, t);

export const isLastIndex = <T>(i: number, ts: Maybe<T[]>) =>
  i === (ts?.length || 0) - 1;

export const length = <T>(ts: Maybe<T[]>) => ts?.length || 0;

export const mapUniq = <T, R>(ts: Maybe<T[]>, fn: Fn<T, R>) =>
  reduce(
    ts,
    (res, t) => {
      const r = fn(t);
      return res?.includes(r) ? res : pushDirty(res, r);
    },
    [] as R[]
  );

export const firstAfter = <T>(ts: Maybe<T[]>, pred: Pred<T>) => {
  const index = findIndex(ts, pred);
  return index >= 0 ? ts?.[index + 1] : undefined;
};

export const uniqByMerge = <T>(
  existing: T[],
  toKey: Fn<T, Primitive>,
  merge: (a: T, b: T) => T
) => {
  const grouped = groupBy(existing, toKey);
  return map(grouped, (group) => reduce(group, merge));
};
