import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";

import { CheckIcon, Icon } from "@ui/icon";

import { MenuItem, MenuItemProps } from "./default";

import styles from "./styles.module.css";

export function CheckMenuItem({
  text,
  checked,
  onClick,
  onChecked,
  children,
  className,
  ...props
}: MenuItemProps & { checked: boolean; onChecked?: Fn<boolean, void> }) {
  return (
    <MenuItem
      icon={
        <Icon
          className={cx(props.disabled && styles.disabledCheck)}
          icon={<CheckIcon checked={checked} />}
        />
      }
      className={cx(className)}
      onClick={() => onChecked?.(!checked)}
      {...props}
    >
      {text || children}
    </MenuItem>
  );
}
