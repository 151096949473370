import { isEmpty } from "lodash";

import { Form } from "@api";

import { FormData } from "@state/form";
import { useLazyEntity } from "@state/generic";

import { useStickyState } from "@utils/hooks";
import { when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { CopyLinkButton } from "@ui/copy-link-button";
import { Divider } from "@ui/divider";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { ClipboardNotes, EditAlt, Icon, iconFromString } from "@ui/icon";
import { LocationBreadcrumb } from "@ui/location-button";
import { PackageTag } from "@ui/package-label";
import { RelationLabel } from "@ui/relation-label";
import { Sheet } from "@ui/sheet-layout";
import { Heading, Text } from "@ui/text";

import { FormSubmit } from "./submit";

interface Props {
  id: string;
}

export const FormPane = ({ id }: Props) => {
  const form = useLazyEntity<"form">(id);
  // @ts-ignore --- FormData <> JSON
  const [formData, setFormData] = useStickyState<FormData>(
    {},
    `form-draft-${id}`
  );

  if (!form) {
    return <></>;
  }

  return (
    <Sheet size="primary">
      <FormHeaderBar form={form} />

      <Container stack="vertical" gap={30}>
        <SpaceBetween align="flex-end">
          <VStack>
            <Icon
              size="large"
              icon={when(form.icon, iconFromString) || ClipboardNotes}
            />
            <Heading bold>{form.name}</Heading>
            <HStack gap={4}>
              <Text subtle>Form built by</Text>
              <RelationLabel subtle relation={form.owner || form.createdBy} />
            </HStack>
          </VStack>

          {!isEmpty(formData) && (
            <Button onClick={() => setFormData({})}>Restart</Button>
          )}
        </SpaceBetween>

        <Divider />

        <FormSubmit form={form} data={formData} onChanged={setFormData} />
      </Container>
    </Sheet>
  );
};

const FormHeaderBar = ({ form }: { form: Form }) => {
  const pushTo = usePushTo();
  return (
    <Container padding="both">
      <SpaceBetween>
        <Container
          inset="left"
          padding="none"
          fit="content"
          stack="horizontal"
          gap={0}
        >
          <LocationBreadcrumb
            editable={false}
            location={form.location}
            variant="full"
          />
        </Container>

        <HStack gap={3}>
          <HStack gap={2}>
            <PackageTag type={form.source.type} scope={form.source.scope} />
            <CopyLinkButton entity={form} size="small" />
            <Button
              size="small"
              icon={EditAlt}
              subtle
              onClick={() => pushTo("/builder")}
            >
              Builder
            </Button>
          </HStack>
        </HStack>
      </SpaceBetween>
    </Container>
  );
};
