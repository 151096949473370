import { Outcome } from "@api";

import { useLazyEntities } from "@state/generic";

import { withHandle } from "@utils/event";
import { asPropertyValueRef } from "@utils/property-refs";

import { WorkProgression } from "@ui/child-work-progression";
import { Container } from "@ui/container";
import { SpaceBetween } from "@ui/flex";
import { Label } from "@ui/label";
import { LocationLabel } from "@ui/location-button";
import { MenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";

import { MenuItemOpts } from "../types";

export const OutcomeMenuItem = ({
  item,
  onOpen,
  showProps,
  ...props
}: MenuItemOpts<Outcome>) => {
  const childTasks = useLazyEntities(item.refs?.tasks || [], false);
  return (
    <MenuItem {...props} onClick={withHandle(() => onOpen?.(item))}>
      <SpaceBetween>
        <Container padding="none" stack="horizontal" align="baseline" gap={4}>
          <PropertyValue
            variant="no-icon"
            size="tiny"
            inset={false}
            valueRef={asPropertyValueRef(
              { field: "status", type: "status" },
              { status: item.status }
            )}
            editable={false}
            source={item?.source}
          />
          <Label fit="content">{item?.title || "Loading..."}</Label>
          <LocationLabel
            showTeam={false}
            showIcons={false}
            size="small"
            subtle
            location={item.location}
          />
        </Container>
        {!!childTasks?.length && <WorkProgression items={childTasks} />}
      </SpaceBetween>
    </MenuItem>
  );
};
