import { Deal } from "@api";

import { useLazyPropertyValue, usePropertyValueRef } from "@state/databases";

import { cx } from "@utils/class-names";
import { formatDay } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { isEmptyRef } from "@utils/property-refs";

import { Divider } from "@ui/divider";
import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { PropertyLabel } from "@ui/property-label";
import { PropertyValueStack } from "@ui/property-value-stack";
import { RelationLabel } from "@ui/relation-label";
import { SelectableListCard } from "@ui/selectable-items";
import { StatusTag } from "@ui/tag";
import { Text, TextSmall } from "@ui/text";

import { EntityEngine } from "../fallback";
import { UIEngine } from "../types";
import { DealPane } from "./pane";

import styles from "./styles.module.css";

export const DealEngine: UIEngine<Deal> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    const dealValue = useLazyPropertyValue(item, {
      field: "value",
      type: "number",
    });
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <Label text={item.title} />
            <HStack>
              <PropertyLabel valueRef={dealValue} source={item.source} />
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListItem: EntityEngine.asListItem as UIEngine<Deal>["asListItem"],
  asListCard: (props) => {
    const { item, showProps, group } = props;

    const ownerProp = usePropertyValueRef(item, {
      field: "owner",
      type: "relation",
    });
    const value = useLazyPropertyValue(item, {
      field: "value",
      type: "number",
    });
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    return (
      <EntityContextMenu entity={props.item}>
        <SelectableListCard
          {...props}
          className={cx(styles.card, props.className)}
        >
          <VStack className={styles.bar} gap={10}>
            <SpaceBetween>
              <Ellipsis>
                <HStack gap={4}>
                  <VStack gap={0}>
                    {when(
                      item.refs?.companys?.[0] || item.refs?.contacts?.[0],
                      (c) => (
                        <>
                          <RelationLabel
                            size="small"
                            subtle
                            relation={c}
                            icon={false}
                          />
                        </>
                      )
                    )}
                    <Text>{item.title || "Deal"}</Text>
                  </VStack>

                  {item.start && (
                    <TextSmall subtle>
                      {usePointDate(item.start, formatDay)}
                    </TextSmall>
                  )}
                </HStack>
              </Ellipsis>

              {group?.def?.field !== "status" && status?.value?.status && (
                <StatusTag status={status.value.status} showIcon={false} />
              )}
            </SpaceBetween>

            <SpaceBetween>
              {ownerProp && !isEmptyRef(ownerProp) && (
                <PropertyLabel source={item.source} valueRef={ownerProp} />
              )}
              {value && !isEmptyRef(value) && (
                <PropertyLabel
                  subtle={false}
                  source={item.source}
                  valueRef={value}
                />
              )}
            </SpaceBetween>

            {!!showProps?.length && <Divider hideLast={true} />}

            <PropertyValueStack
              wrap
              item={item}
              editable={false}
              props={showProps}
              blacklist={["status", "value", "owner", "title", "icon"]}
              hideEmpty={props.hideEmpty}
            />
          </VStack>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },

  asPrimaryPane: DealPane,
};
