import {
  addDays,
  addMinutes,
  setHours,
  setMinutes,
  startOfDay,
  subDays,
} from "date-fns";
import { times } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { cx } from "@utils/class-names";
import { format, formatShort, formatTime, isBetween } from "@utils/date";
import { toCalDate, toPointDate } from "@utils/date-fp";
import { now } from "@utils/now";

import { CommandItem } from "@ui/command-menu";
import { HStack } from "@ui/flex";
import { Text } from "@ui/text";
import { TimeLabel } from "@ui/time-label";

import { useCommandSearch } from "../utils";
import { SetPropertyCommands } from "./types";

import styles from "./styles.module.css";

const SHOW_PAST = 5;

const DEFAULT_HOURS = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];

export const DateCommands = ({ mutate, property }: SetPropertyCommands) => {
  const search = useCommandSearch();
  const [mode, setMode] = useState<"date" | "time">("date");
  const [date, setDate] = useState<Date>();

  const start = useMemo(() => subDays(startOfDay(now()), SHOW_PAST), [search]);

  const handleSet = useCallback(
    (d: Date) => {
      const toDate =
        property.options?.mode === "point" ? toPointDate : toCalDate;

      if (mode === "time") {
        mutate({ date: toDate(d, "local") });
      } else if (property.format === "time" && mode === "date") {
        setDate(d);
        setMode("time");
      } else {
        mutate({ date: toDate(d, "local") });
      }
    },
    [mutate, property.format, mode]
  );

  const options = useMemo(() => {
    if (mode === "time") {
      return times(DEFAULT_HOURS.length * 2, (v) => {
        const hour = DEFAULT_HOURS[Math.floor(v / 2)];
        const option = setMinutes(
          setHours(date || now(), hour),
          v % 2 ? 30 : 0
        );
        const isNowish = isBetween(option, now(), addMinutes(now(), 30));
        const value = formatTime(option);

        return (
          <CommandItem
            key={toPointDate(option)}
            value={value}
            onClick={() => handleSet(option)}
            onSelectAction="close"
            className={cx(styles.dateOption, isNowish && styles.today)}
          >
            <HStack align="baseline" gap={4}>
              <TimeLabel size="default" fit="content" date={option} />{" "}
              {date && <Text subtle>{formatShort(date)}</Text>}
            </HStack>
          </CommandItem>
        );
      });
    }

    return times(365, (v) => {
      const isToday = v === SHOW_PAST;
      const option = addDays(start, v);
      const value =
        format(option, "dd MMM YYY, eeee") + (isToday ? " (Today)" : ``);

      return (
        <CommandItem
          key={toPointDate(option)}
          value={value}
          onClick={() => handleSet(option)}
          onSelectAction={property.format === "time" ? "none" : "close"}
          className={cx(styles.dateOption, isToday && styles.today)}
        >
          {format(option, "dd MMM YYY")}{" "}
          <Text subtle>{isToday ? "Today" : format(option, "eee")}</Text>
        </CommandItem>
      );
    });
  }, [start, mode]);

  return (
    <>
      {mode !== "time" && (
        <CommandItem
          value="clear unset value"
          onClick={() => mutate({ date: undefined })}
        >
          <Text subtle>Clear value</Text>
        </CommandItem>
      )}
      {options}
    </>
  );
};
