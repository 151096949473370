import { ReactNode, useCallback, useMemo } from "react";

import { Resource, toTitleOrName } from "@api";

import { useLazyEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { timeAgo } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { withHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { useDebouncedMemo } from "@utils/hooks";
import { asMutation } from "@utils/property-mutations";
import { useSlowSelected } from "@utils/selectable";
import { toDomain } from "@utils/url";

import { Button } from "@ui/button";
import { ContextDivider, ContextItem, ContextMenu } from "@ui/context-menu";
import { EditableText } from "@ui/editable-text";
import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, OpenIn, Pin, TrashAlt } from "@ui/icon";
import { Label } from "@ui/label";
import { LinkIcon } from "@ui/link-button";
import { MenuItem } from "@ui/menu-item";
import { OnHover } from "@ui/on-hover";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { TextSmall } from "@ui/text";

import { UIEngine } from "../types";

import styles from "./styles.module.css";

export const ResourceEngine: UIEngine<Resource> = {
  asMenuItem: function (props) {
    const { item: r, className, onOpen, onChange } = props;
    const creator = useLazyEntity(r.createdBy?.id);
    const editable = useMemo(() => !!onChange, [onChange]);
    const description = useMemo(
      () =>
        r.type === "file"
          ? creator
            ? `Uploaded by ${toTitleOrName(creator)}`
            : "Uploaded file"
          : !!r?.url
          ? toDomain(r?.url || "")
          : undefined,
      [creator, r?.url]
    );
    const onTogglePin = useCallback(
      () =>
        onChange?.(asMutation({ field: "pinned", type: "boolean" }, !r.pinned)),
      [onChange]
    );

    return (
      <EntityContextMenu entity={props.item}>
        <MenuItem
          className={cx(styles.item, props.className)}
          onClick={() => props.onOpen?.(props.item)}
        >
          <SpaceBetween>
            <FillSpace>
              <Ellipsis>
                <HStack gap={6}>
                  {r.url && (
                    <Icon
                      icon={
                        <LinkIcon url={r.url} icon={r.icon} type={r.type} />
                      }
                      className={styles.strong}
                    />
                  )}
                  <HStack gap={2}>
                    <Label>{r.name}</Label>
                    {description && <TextSmall subtle>{description}</TextSmall>}
                  </HStack>
                </HStack>
              </Ellipsis>
            </FillSpace>

            <HStack gap={0}>
              <Icon icon={OpenIn} />
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item: r, onOpen, className, onChange, selection } = props;

    const creator = useLazyEntity(r.createdBy?.id);
    const selected = useSlowSelected(r.id, selection);
    const editable = useDebouncedMemo(() => !!onChange && !!selected, 300, [
      selected,
    ]);
    const description = useMemo(
      () =>
        r.type === "file"
          ? creator
            ? `Uploaded by ${toTitleOrName(creator)}`
            : "Uploaded file"
          : !!r?.url
          ? toDomain(r?.url || "")
          : undefined,
      [creator, r?.url]
    );
    const onTogglePin = useCallback(
      () =>
        onChange?.(asMutation({ field: "pinned", type: "boolean" }, !r.pinned)),
      [onChange]
    );

    return (
      <ResourceContextMenu resource={r} {...props}>
        <OnHover.Trigger>
          <SelectableListCard
            className={cx(styles.tile, styles.item, className)}
            {...props}
          >
            <SpaceBetween>
              {r.url && (
                <Icon
                  icon={<LinkIcon url={r.url} icon={r.icon} type={r.type} />}
                  className={styles.strong}
                />
              )}

              <OnHover.Target show={selected}>
                <HStack gap={0}>
                  {editable && (
                    <Button
                      size="small"
                      subtle
                      className={cx(styles.pin, r.pinned && styles.pinned)}
                      onClick={withHandle(() => onTogglePin())}
                      icon={Pin}
                    />
                  )}
                  <Button
                    onClick={withHandle(() => onOpen?.(r))}
                    size="small"
                    iconRight={OpenIn}
                  >
                    Open
                  </Button>
                </HStack>
              </OnHover.Target>
            </SpaceBetween>

            <FillSpace className={styles.clip}>
              <VStack gap={6}>
                <EditableText
                  key={r.id}
                  text={r.name || ""}
                  updateOn="blur"
                  disabled={!editable}
                  blurOnEnter={true}
                  onChange={(val, old) =>
                    onChange?.({
                      field: "name",
                      type: "text",
                      value: { text: val },
                      prev: { text: old },
                    })
                  }
                />
                <HStack>
                  {description && <TextSmall subtle>{description}</TextSmall>}·
                  <TextSmall subtle>
                    updated {usePointDate(r.updatedAt, timeAgo)}
                  </TextSmall>
                </HStack>
              </VStack>
            </FillSpace>
          </SelectableListCard>
        </OnHover.Trigger>
      </ResourceContextMenu>
    );
  },
  asListItem: function ResourceListItem(props) {
    const { item: r, className, onOpen, onChange } = props;

    const selected = useSlowSelected(r.id, props.selection);
    const creator = useLazyEntity(r.createdBy?.id);
    const editable = useMemo(
      () => !!onChange && !!selected,
      [onChange, selected]
    );
    const description = useMemo(
      () =>
        r.type === "file"
          ? creator
            ? `Uploaded by ${toTitleOrName(creator)}`
            : "Uploaded file"
          : !!r?.url
          ? toDomain(r?.url || "")
          : undefined,
      [creator, r?.url]
    );
    const onTogglePin = useCallback(
      () =>
        onChange?.(asMutation({ field: "pinned", type: "boolean" }, !r.pinned)),
      [onChange]
    );

    return (
      <EntityContextMenu
        entity={props.item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <OnHover.Trigger>
          <SelectableListItem {...props} className={styles.item}>
            <SpaceBetween>
              <FillSpace className={styles.clip}>
                <HStack gap={6}>
                  {r.url && (
                    <Icon
                      icon={
                        <LinkIcon url={r.url} icon={r.icon} type={r.type} />
                      }
                      className={styles.strong}
                    />
                  )}
                  <EditableText
                    key={r.id}
                    text={r.name || ""}
                    placeholder="Untitled"
                    updateOn="blur"
                    disabled={!editable}
                    blurOnEnter={true}
                    onChange={(val, old) =>
                      onChange?.({
                        field: "name",
                        type: "text",
                        value: { text: val },
                        prev: { text: old },
                      })
                    }
                  />
                  {description && <TextSmall subtle>{description}</TextSmall>}
                </HStack>
              </FillSpace>

              <OnHover.Target show={selected}>
                <HStack gap={0}>
                  {editable && (
                    <Button
                      onClick={withHandle(() => onTogglePin?.())}
                      size="small"
                      subtle
                      className={cx(styles.pin, r.pinned && styles.pinned)}
                      icon={Pin}
                    />
                  )}
                  <Button
                    onClick={withHandle(() => onOpen?.(r))}
                    size="small"
                    iconRight={<Icon icon={OpenIn} />}
                  >
                    Open
                  </Button>
                </HStack>
              </OnHover.Target>
            </SpaceBetween>
          </SelectableListItem>
        </OnHover.Trigger>
      </EntityContextMenu>
    );
  },
};

interface ContextProps {
  resource: Resource;
  children: ReactNode;
  onOpen?: Fn<Resource, void>;
  onTogglePin?: Fn<Resource, void>;
  onDelete?: Fn<Resource, void>;
}

const ResourceContextMenu = ({
  resource,
  onOpen,
  onTogglePin,
  onDelete,
  children,
}: ContextProps) => (
  <ContextMenu
    actions={
      <>
        <ContextItem icon={OpenIn} onClick={() => onOpen?.(resource)}>
          Open
        </ContextItem>
        {onTogglePin && (
          <ContextItem icon={Pin} onClick={() => onTogglePin?.(resource)}>
            {resource?.pinned ? "Remove pin" : "Pin"}
          </ContextItem>
        )}
        {onDelete && (
          <>
            <ContextDivider />
            <ContextItem icon={TrashAlt} onClick={() => onDelete?.(resource)}>
              Delete
            </ContextItem>
          </>
        )}
      </>
    }
  >
    {children}
  </ContextMenu>
);
