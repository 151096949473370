import * as api from "@api";
import {
  DatabaseID,
  Entity,
  PropertyDef,
  PropertyRef,
  PropertyType,
} from "@api";

import { cachedFuncByWorkspace } from "@state/workspace";

import { PointDate } from "@utils/date-fp";
import { Fn } from "@utils/fn";
import { toMilliSeconds } from "@utils/time";

export const getPropertyDefinitionLoader = cachedFuncByWorkspace(
  () =>
    async <E extends Entity, P extends PropertyType>(
      db: DatabaseID,
      prop: PropertyRef<E, P>,
      onceCallback?: Fn<PropertyDef<E, P>, void>
    ): Promise<PropertyDef<E, P>> => {
      const res = await api.getPropertyDefinition(db, prop);
      onceCallback?.(res);
      return res;
    },
  toMilliSeconds("10 minutes"),
  ([db, prop, _cb]) => db?.scope + db?.type + prop.field
);

export const getPropertyDefinitionsLoader = cachedFuncByWorkspace(
  () =>
    async (
      db: DatabaseID,
      since?: PointDate,
      onceCallback?: Fn<PropertyDef[], void>
    ) => {
      const defs = await api.getPropertyDefinitions(db, since);
      onceCallback?.(defs);
      return defs;
    },
  toMilliSeconds("10 minutes"),
  ([db, since, _cb]) => db?.scope + db?.type + since
);

export const getPropertyValuesLoader = cachedFuncByWorkspace(
  () => api.getDatabasePropertyValues,
  toMilliSeconds("10 minutes"),
  ([db, prop]) => db?.scope + db?.type + prop.field
);
