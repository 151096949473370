import { useMemo } from "react";

import { Link } from "@api";

import { cx } from "@utils/class-names";
import { respectHandled, withHandle } from "@utils/event";
import { fallback,Fn } from "@utils/fn";
import { isNotion, isSlack } from "@utils/link";
import { ifDo } from "@utils/logic";
import { toDomain } from "@utils/url";

import { Button } from "@ui/button";
import { Ellipsis } from "@ui/ellipsis";
import { FillSpace, HStack, SpaceBetween } from "@ui/flex";
import { Icon, Pin, SlackColor } from "@ui/icon";
import { Label } from "@ui/label";
import { LinkIcon } from "@ui/link-button";
import { MenuItem, MenuItemProps } from "@ui/menu-item";
import { SlackText } from "@ui/slack-message";
import { TextSmall } from "@ui/text";

import styles from "./link-item.module.css";

interface Props {
  link: Link;
  description?: string;
  actions?: React.ReactNode;
  onClick?: Fn<Link, void>;
  onTogglePin?: Fn<Link, void>;
  showPin?: boolean;
  indent?: MenuItemProps["indent"];
  className?: string;
}

const toLogo = (r: Link) =>
  fallback(
    () => ifDo(isSlack(r.url), () => <SlackColor />),
    () => <LinkIcon url={r.url} icon={r.icon} />
  );

export const LinkItem = ({
  onClick,
  link: r,
  description: _description,
  actions,
  onTogglePin,
  showPin = true,
  className,
  indent,
}: Props) => {
  const icon = useMemo(() => toLogo(r), [r]);
  const description = useMemo(
    () =>
      _description || (r?.url && !isNotion(r?.url) && !isSlack(r?.url))
        ? toDomain(r?.url)
        : undefined,
    [_description, r?.url]
  );
  return (
    <MenuItem
      className={cx(styles.link, className)}
      onClick={respectHandled(() => onClick?.(r))}
      indent={indent}
    >
      <SpaceBetween>
        <FillSpace>
          <Ellipsis>
            <HStack gap={8}>
              <Label iconClassName={styles.strong} icon={icon} fit="content">
                {isSlack(r.url) ? <SlackText text={r.text} /> : r.text}
              </Label>
              {description && <TextSmall subtle>{description}</TextSmall>}
            </HStack>
          </Ellipsis>
        </FillSpace>
        {actions}
        {!actions && showPin && (
          <Button
            onClick={withHandle(() => onTogglePin?.(r))}
            size="small"
            subtle
            icon={
              <Icon
                className={cx(styles.pin, r.pinned && styles.pinned)}
                icon={<Pin />}
              />
            }
          />
        )}
      </SpaceBetween>
    </MenuItem>
  );
};
