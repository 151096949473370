import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragStartEvent,
  MouseSensor,
  useDraggable,
  useSensor,
} from "@dnd-kit/core";
import {
  restrictToHorizontalAxis,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import { ReactNode, useCallback } from "react";

import { ID } from "@api";

export type { DragEndEvent, DragMoveEvent, DragStartEvent };

export type DragContextProps = {
  children: ReactNode;
  type?: string;
  direction?: "horizontal" | "vertical" | "both";
  distance?: number;
  onDragStart?: (event: DragStartEvent) => void;
  onDragMove?: (event: DragMoveEvent) => void;
  onDragEnd?: (event: DragEndEvent) => void;
};

export const DragContext = ({
  type,
  children,
  direction = "both",
  distance = 3,
  onDragStart,
  onDragMove,
  onDragEnd,
}: DragContextProps) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: distance,
    },
  });

  const handleDragEnd = useCallback(
    (event: DragEndEvent) =>
      (!type || type === toDragType(event)) && onDragEnd?.(event),
    [type, onDragEnd]
  );
  const handleDragStart = useCallback(
    (event: DragStartEvent) =>
      (!type || type === toDragType(event)) && onDragStart?.(event),
    [type, onDragStart]
  );
  const handleDragMove = useCallback(
    (event: DragMoveEvent) =>
      (!type || type === toDragType(event)) && onDragMove?.(event),
    [type, onDragMove]
  );

  return (
    <DndContext
      sensors={[mouseSensor]}
      modifiers={
        direction === "horizontal"
          ? [restrictToHorizontalAxis]
          : direction === "vertical"
          ? [restrictToVerticalAxis]
          : []
      }
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragEnd={handleDragEnd}
    >
      {children}
    </DndContext>
  );
};

export const Draggable = ({
  id = "default",
  type,
  children,
}: {
  id?: ID;
  type?: string;
  children: ReactNode;
}) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: id,
    data: { type: type },
  });

  return (
    <div
      style={{ display: "contents" }}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};

export const toDragType = (
  event: DragMoveEvent | DragStartEvent | DragEndEvent
) => event.active.data.current?.type;
