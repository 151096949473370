import _merge from "deep-extend";
import {
  assign,
  Dictionary,
  isEmpty,
  isString,
  keys,
  pick,
  reduce,
  set as _set,
} from "lodash";

import { isDefined,Maybe } from "./maybe";

export type PartialRecord<K extends string, T> = Partial<Record<K, Maybe<T>>>;

export function set<T extends object, F extends keyof T>(
  thing: T,
  field: F,
  value: T[F]
): T;
export function set<T extends object>(thing: T, changes: Partial<T>): T;
export function set<T extends object>(thing: T, a1: keyof T | T, v?: any) {
  if (isString(a1)) {
    _set(thing, a1, v);
    return thing;
  } else {
    assign(thing, a1);
    return thing;
  }
}

export const merge = <T extends Object>(...args: Maybe<T>[]): T =>
  _merge({}, ...(args as Object[])) as T;

export const setDirty = set;

export const pick_ =
  <T extends object, K extends keyof T>(...keys: K[]) =>
  (thing: T) =>
    pick<T, K>(thing, ...keys);

/**
 * Returns an object with all the key/value pairs that are null or undefined are removed.
 * @param obj The object to strip null and undefined from.
 * @returns The stripped object.
 * @example
 * omitEmpty({ a: "", b: "asdas", c: false, d: null, e: undefined, f: 0 });
 * // { b: "asdas", c: false, f: 0 }
 */
type PartialWithoutNulls<T> = {
  [K in keyof T]?: Exclude<T[K], null>;
};

type NonPartialWithoutNulls<T> = {
  [K in keyof T]: Exclude<T[K], null>;
};

export const omitEmpty = <T, K extends keyof T>(
  obj: T
): PartialWithoutNulls<T> =>
  reduce(
    keys(obj) as K[],
    (v: PartialWithoutNulls<T>, key: K) => {
      if (isDefined(obj[key])) {
        // @ts-ignore
        v[key] = obj[key];
      }
      return v;
    },
    {} as PartialWithoutNulls<T>
  );

export const omitEmptyish = <T, K extends keyof T>(
  obj: T
): PartialWithoutNulls<T> =>
  reduce(
    keys(obj) as K[],
    (v: PartialWithoutNulls<T>, key: K) => {
      if (!!obj[key] && !isEmpty(obj[key])) {
        // @ts-ignore
        v[key] = obj[key];
      }
      return v;
    },
    {} as PartialWithoutNulls<T>
  );

export const withoutNulls = <T, K extends keyof T>(
  obj: T
): NonPartialWithoutNulls<T> =>
  reduce(
    keys(obj) as K[],
    (v: NonPartialWithoutNulls<T>, key: K) => {
      // @ts-ignore
      v[key] = obj[key] ?? undefined;

      return v;
    },
    {} as NonPartialWithoutNulls<T>
  );
export const omitNulls = withoutNulls;

export const maybeValues = <K extends string | number | symbol, T>(
  obj: Record<K, T | null | undefined>,
  pred?: (val: T, key: K) => boolean
): T[] => {
  const res: T[] = [];
  for (var key in obj) {
    const value = obj[key];
    if (isDefined(value) && (!pred || pred(value as T, key as K))) {
      res.push(value as T);
    }
  }
  return res;
};

// Accepts two dictionaries, one with the values and one with booleans to mask the values.
export const mask = <T>(lookup: Dictionary<T>, mask: Dictionary<boolean>) =>
  reduce(
    keys(mask),
    (acc, k) => (mask[k] ? { ...acc, [k]: lookup[k] } : acc),
    {} as Dictionary<T>
  );
