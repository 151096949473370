import { tail } from "lodash";
import { useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useUpdateEntity } from "@state/generic";
import { useAddToRecents } from "@state/recents";
import { useActiveSpace } from "@state/spaces";
import {
  toLabel as toTeamLabel,
  useLazyGetTeam,
  useToTeamRoute,
} from "@state/teams";

import { respectHandled } from "@utils/event";
import { useQueryParams, useStickyState } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { toNotionUrl } from "@utils/notion";
import { toPropertyValueRef } from "@utils/property-refs";
import { toSlackUrl } from "@utils/slack";
import { trimSlashes, useSyncPathnameID } from "@utils/url";

import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  Box,
  Cog,
  Icon,
  Notion,
  PaintTool,
  Redo,
  SlackColor,
  TagAlt,
  TeamIcon,
} from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { RouteMenuItem } from "@ui/menu-item";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { Redirect } from "@ui/redirect";
import { ColorSelect } from "@ui/select/color";
import { SettingsTeamPackages } from "@ui/settings-packages";
import SettingsTeam, {
  SettingsTeamProperties,
  SettingsTeamSchedules,
  SettingsTeamTemplates,
} from "@ui/settings-team";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { Heading } from "@ui/text";

import AppPage from "./app-page";

import styles from "./team-page.module.css";

interface Props {
  teamId: string;
  viewId?: string;
}

const TeamSettingsPage = ({ teamId: _teamId }: Props) => {
  const goTo = useGoTo();
  const location = useLocation();
  const params = useQueryParams();
  const team = useLazyGetTeam(_teamId);
  const space = useActiveSpace();
  // Team property/packages do not support saving temp ids, so make sure we're always using the real persisted id
  const teamId = useMemo(() => team?.id || _teamId, [_teamId, team?.id]);
  const mutate = useUpdateEntity(teamId);
  const [lastTeamPage, setLastTeamPage] = useStickyState<Maybe<string>>(
    undefined,
    `team-${teamId}-last-page`
  );
  const childRoute = useMemo(
    () => ({
      pathname:
        "/" + tail(trimSlashes(location.pathname).split("/"))?.join("/"),
    }),
    [location]
  );
  const [page] = useRegisterPage(teamId, team);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(_teamId, team?.id);

  // Add team to recently viewed
  useAddToRecents(teamId);

  const toRoute = useToTeamRoute(teamId);

  const isOverview = useMemo(
    () => location.pathname === toRoute() || location.pathname === toRoute("/"),
    [location.pathname]
  );

  const size = useMemo(() => {
    const pn = location?.pathname;
    return pn?.includes("v_") || pn?.includes("/packages")
      ? "full"
      : "primary-thicc";
  }, [location.pathname]);

  useEffect(() => {
    if (params.goTo === "last" && isOverview && lastTeamPage) {
      goTo(lastTeamPage, {}, { replace: true });
    }
  }, []);

  useEffect(() => {
    setLastTeamPage(location.pathname);
  }, [setLastTeamPage, location.pathname, isOverview]);

  if (params.goTo === "last") {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size={size} transparency="mid" interactable={false}>
          <PageLayout>
            <SideNav className={styles.nav}>
              <VStack direction="vertical" width="container" gap={20}>
                <Menu>
                  <VStack gap={6} className={styles.teamName}>
                    <SpaceBetween>
                      <ColorSelect
                        color="green"
                        onChange={(color) =>
                          mutate({
                            field: "color",
                            type: "text",
                            value: { text: color },
                          })
                        }
                      >
                        {team && (
                          <Icon icon={<TeamIcon team={team} />} size="xlarge" />
                        )}
                      </ColorSelect>
                      <HStack gap={0}>
                        {team?.notionId && (
                          <Button
                            icon={Notion}
                            subtle
                            onClick={respectHandled(() =>
                              when(team?.notionId, (n) => goTo(toNotionUrl(n)))
                            )}
                          />
                        )}
                        {when(
                          team &&
                            toPropertyValueRef(team, {
                              field: "settings.channel",
                              type: "text",
                            }),
                          ({ value }) => (
                            <Button
                              icon={SlackColor}
                              subtle
                              onClick={respectHandled(() =>
                                when(value.text, (n) => goTo(toSlackUrl(n)))
                              )}
                            />
                          )
                        )}
                      </HStack>
                    </SpaceBetween>
                    <Heading bold>{when(team, toTeamLabel)}</Heading>
                  </VStack>
                </Menu>

                <Menu>
                  <MenuGroup>
                    <RouteMenuItem
                      icon={Cog}
                      text="Details & Settings"
                      route={toRoute("/settings/data")}
                    />

                    <RouteMenuItem
                      icon={PaintTool}
                      text="Templates"
                      route={toRoute("/settings/templates")}
                    />
                    <RouteMenuItem
                      icon={Redo}
                      text="Recurring Work"
                      route={toRoute("/settings/schedules")}
                    />
                  </MenuGroup>

                  <MenuGroup label="Configuration">
                    <RouteMenuItem
                      text="Fields"
                      icon={TagAlt}
                      route={toRoute("/settings/fields")}
                    />

                    <RouteMenuItem
                      text="Packages"
                      icon={Box}
                      route={toRoute("/settings/packages")}
                    />
                  </MenuGroup>
                </Menu>
              </VStack>
            </SideNav>

            <Main className={styles.main}>
              <Routes location={childRoute}>
                <Route
                  path="/settings"
                  element={<Redirect to={toRoute("/settings/data")} />}
                />

                <Route
                  path="/settings/data"
                  element={<SettingsTeam teamId={teamId} />}
                />

                <Route
                  path="/settings/schedules"
                  element={<SettingsTeamSchedules teamId={teamId} />}
                />

                <Route
                  path="/settings/fields"
                  element={<SettingsTeamProperties teamId={teamId} />}
                />

                <Route
                  path="/settings/templates"
                  element={<SettingsTeamTemplates teamId={teamId} />}
                />

                <Route
                  path="/settings/packages"
                  element={<SettingsTeamPackages teamId={teamId} />}
                />
              </Routes>
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

export default TeamSettingsPage;
