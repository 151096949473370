import { addDays, startOfDay } from "date-fns";
import { filter, find, orderBy, some } from "lodash";

import { CreateOrUpdate, Entity, getForFilter, Sprint, Update } from "@api";

import {
  WorkflowAction,
  WorkflowContext,
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowSuggestion,
} from "@state/workflows";

import { durationInDays, toEndDate } from "@utils/date";
import { fromPointDate, useCalDate } from "@utils/date-fp";
import { useAsyncEffect } from "@utils/effects";
import { newID } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { maybeMap, safeAs } from "@utils/maybe";
import { now } from "@utils/now";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { inflateProperty } from "@utils/property-refs";

import { useMutate } from "@ui/mutate";
import { showSuccess } from "@ui/notifications";
import { ParentCloseDialog } from "@ui/parent-close-dialog";
import { ParentStartDialog } from "@ui/parent-start-dialog";

// Archive a sprint when completed
export const closeSprint: WorkflowAction<Sprint, Update<Entity>[]> = {
  id: "closeSprint",
  trigger: "ACTION",
  type: "sprint",
  icon: undefined,
  title: "Close sprint",

  allowed: ({ entity }, { props }) =>
    // Is in planning or not started
    ["in-progress"]?.includes(
      inflateProperty(entity, find(props, { field: "status" }))?.status
        ?.group || "not-started"
    ),

  collect: ({ data: { entity }, onCollected, onCancelled, context }) => (
    <ParentCloseDialog
      entity={entity}
      toParent={{ field: "refs.sprint", type: "relation" }}
      onCancel={onCancelled}
      onProcess={onCollected}
      context={context as WorkflowContext<Entity>}
    />
  ),
  execute: ({ entity, collected }, { session }) => {
    return collected as Update<Sprint>[];
  },
};

// Keep start, duration, and end dates in sync
export const sprintDatesDurationsSync: WorkflowDefinition<Sprint> = {
  id: "sprint-dates-duration-sync",
  trigger: "WILL_UPDATE",
  type: "sprint",
  allowed: ({ update }) =>
    // Only when updating sprints, not on creation
    update.method === "update" &&
    // Don't run when the update was from a workflow
    update.mode !== "workflow" &&
    // When ONLY changing either start, end or duration
    filter(
      update.changes,
      (c) => c.field === "start" || c.field === "end" || c.field === "duration"
    )?.length === 1,

  execute: ({ entity, update }, _context) => {
    const change = find(
      (update as CreateOrUpdate<Sprint>)?.changes,
      (c) => c.field === "start" || c.field === "end" || c.field === "duration"
    );

    if (change?.field === "duration") {
      return asUpdate(entity, [
        asMutation(
          { field: "end", type: "date" },
          useCalDate(entity.start, (d) =>
            toEndDate(d || now(), change.value.number || 0)
          )
        ),
      ]);
    }

    if (change?.field === "start") {
      return asUpdate(entity, [
        asMutation(
          { field: "end", type: "date" },
          useCalDate(change.value.date, (d) =>
            toEndDate(d || now(), entity.duration || 0)
          )
        ),
      ]);
    }

    if (change?.field === "end") {
      return asUpdate(entity, [
        asMutation(
          { field: "duration", type: "number" },
          useCalDate(change.value.date, (d) =>
            durationInDays(d || now(), fromPointDate(entity.start) || now())
          )
        ),
      ]);
    }

    return [];
  },
};

// Move subsequent sprints when a sprint dates is changed
export const moveSubsequentSprints: WorkflowSuggestion<Sprint> = {
  id: "move-subsequent-sprints",
  trigger: "SUGGEST",
  type: "sprint",
  allowed: ({ entity, update }) =>
    // Only when updating sprints, not on creation
    update.method === "update" &&
    // Not-templates
    !entity.template &&
    // Hack: This is not the right way to do this..
    // Need to exclude changes when coming from other actions or this one...
    !update.transaction &&
    // When changing any date or duration field
    some(update.changes, (c) => equalsAny(c.field, ["start", "end"])),

  suggestion: {
    id: "move-subsequent-sprints",
    text: "Update future sprint dates?",
    description:
      "Would you like to automatically change the dates for all future sprints?",
    options: [
      { title: "Ignore", id: "dismiss" },
      { title: "Update all", id: "move" },
    ],
  },

  collect: ({ data, onCollected, onCancelled, context }) => {
    const mutate = useMutate();

    useAsyncEffect(async () => {
      // Find all subsequent sprints
      const allAfter = await getForFilter(
        { type: "sprint", scope: data.entity.source.scope },
        {
          and: [
            {
              field: "id",
              op: "does_not_equal",
              type: "text",
              value: { text: data.entity.id },
            },
            {
              field: "status",
              op: "does_not_equal",
              type: "status",
              value: { status: { group: "done" } },
            },
            {
              field: "start",
              op: "greater_than",
              type: "date",
              value: {
                date:
                  // Use previous start date if available
                  find(safeAs<CreateOrUpdate>(data.update)?.changes, {
                    field: "start",
                  })?.prev?.date || data.entity.start,
              },
            },
          ],
        }
      );
      const sortedAfter = orderBy(allAfter, (a) => a.start);

      const transaction = newID();

      let lastEnd = data.entity.end;

      const updates = maybeMap(sortedAfter, (sprint, i) => {
        const newStart = useCalDate(
          lastEnd, // Start from the last end date
          (d) => d && startOfDay(addDays(d, 1))
        );
        const newEnd = useCalDate(
          newStart,
          (d) => d && toEndDate(d, sprint.duration || 14)
        );

        if (!newStart || !newEnd) return undefined;

        // Mutate 🤮 for next iteration
        lastEnd = newEnd;

        return asUpdate(
          sprint,
          [
            asMutation({ field: "start", type: "date" }, newStart),
            asMutation({ field: "end", type: "date" }, newEnd),
          ],
          // Prevents from continuous running
          transaction
        );
      });

      mutate(updates);
      showSuccess("Sprint dates updated.");
      onCollected([]);
    }, []);

    return <></>;
  },
  execute: () => {
    return [];
  },
};

// Archive a sprint when completed
export const startSprint: WorkflowAction<Sprint, Update<Entity>[]> = {
  id: "startSprint",
  trigger: "ACTION",
  type: "sprint",
  icon: undefined,
  title: "Start sprint",

  allowed: ({ entity }, { props }) =>
    // Is in planning or not started
    ["planning", "not-started"]?.includes(
      inflateProperty(entity, find(props, { field: "status" }))?.status
        ?.group || "not-started"
    ),
  collect: ({ data: { entity }, onCollected, onCancelled, context }) => (
    <ParentStartDialog
      entity={entity}
      toParentProp={{ field: "refs.sprint", type: "relation" }}
      context={context as WorkflowContext<Entity>}
      onCollected={onCollected}
      onCancelled={onCancelled}
    />
  ),
  execute: ({ entity, collected }, { session }) => {
    return collected as Update<Sprint>[];
  },
};

export const definitions: WorkflowDefinitionConfig<Sprint> = {
  triggers: [sprintDatesDurationsSync],
  actions: [startSprint, closeSprint],
  suggestions: [moveSubsequentSprints],
};

export default definitions;
