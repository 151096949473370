import { map } from "lodash";
import { useMemo } from "react";

import { isDefined, when } from "@utils/maybe";
import { toLabel } from "@utils/property-refs";

import { CommandItem } from "@ui/command-menu";

import { useCommandSearch } from "../utils";
import { SetPropertyCommands } from "./types";

const MINUTES = [1, 2, 3, 4, 5, 10, 15, 20, 25, 30, 40, 50, 60];

export const NumberCommands = ({ mutate, property }: SetPropertyCommands) => {
  const search = useCommandSearch();

  const options = useMemo(() => {
    let parsed = when(search || undefined, parseFloat);

    if (property.format === "minutes") {
      return (
        <>
          {!!parsed && !isNaN(parsed) && !MINUTES?.includes(parsed) && (
            <CommandItem
              value={`skip: ${parsed} minutes`}
              onClick={() => mutate({ number: parsed })}
            >
              {toLabel(
                { ...property, value: { number: parsed } },
                property.format
              )}
            </CommandItem>
          )}
          {map(MINUTES, (m) => (
            <CommandItem
              key={m}
              value={`${m} minutes`}
              onClick={() => mutate({ number: m })}
            >
              {toLabel({ ...property, value: { number: m } }, property.format)}
            </CommandItem>
          ))}
        </>
      );
    }

    if (!isDefined(parsed) || isNaN(parsed)) {
      return <></>;
    }

    if (property?.format === "percent" && parsed > 1) {
      parsed = parsed / 100;
    }

    return (
      <CommandItem
        key={`number-input`}
        value={`skip: ${search}`}
        onClick={() => mutate({ number: parsed })}
      >
        {property?.format
          ? toLabel({ ...property, value: { number: parsed } }, property.format)
          : parsed}
      </CommandItem>
    );
  }, [search]);

  return <>{options}</>;
};
