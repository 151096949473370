import { forwardRef,Ref } from "react";

import { cx } from "@utils/class-names";

import styles from "./list-item.module.css";

type Props = React.HTMLProps<HTMLLIElement> & {
  selected?: boolean;
  active?: boolean;
  selectable?: boolean;
};

export const ListItem = forwardRef(
  (
    {
      children,
      className,
      selectable = true,
      style,
      selected,
      active,
      ...rest
    }: Props,
    ref: Ref<HTMLLIElement>
  ) => {
    return (
      <li
        ref={ref}
        {...rest}
        className={cx(
          styles.item,
          selectable ? styles.selectable : styles.clickable,
          selected && styles.selected,
          active && styles.active,
          className
        )}
        style={style}
      >
        {children}
      </li>
    );
  }
);
