import { forEach } from "lodash";

import { Entity, HasLocation, Ref } from "@api";

import { pushDirty } from "@utils/array";
import { uniqRefs } from "@utils/relation-ref";
import { fromScope, takeUpTo } from "@utils/scope";

type NestedItems = {
  [location: string]: (Entity | Ref)[];
};

export function toNestedItems(entities: HasLocation[]): NestedItems {
  const initialMap: NestedItems = { "": [] };

  return entities.reduce((acc, entity) => {
    return insertEntity(acc, entity.location, entity);
  }, initialMap);
}

function insertEntity(
  acc: NestedItems,
  location: string,
  entity: Entity
): NestedItems {
  const parents = fromScope(location);

  forEach(parents, (parent, index) => {
    const pLoc = takeUpTo(location, parent);
    acc[pLoc] = uniqRefs(pushDirty(acc[pLoc] || [], { id: parent }), "first");
  });

  acc[location] = uniqRefs(pushDirty(acc[location] || [], entity), "last");

  return acc;
}
