import { flatMap, some, uniq } from "lodash";

import {
  DatabaseID,
  Entity,
  EntityType,
  EntityTypeMap,
  ID,
  PropertyDef,
  Ref,
  RelationRef,
  SettingsData,
  Update,
} from "@api";
import { EntityForType } from "@api/mappings";

import { SystemPackages } from "@state/packages";
import { persistedID, StoreState } from "@state/store";

import { ensureMany, omitEmpty } from "@utils/array";
import { Fn } from "@utils/fn";
import { maybeTypeFromId } from "@utils/id";
import { Maybe, SafeRecord, when } from "@utils/maybe";
import { merge } from "@utils/object";
import { getSetting, isAnyRelation } from "@utils/property-refs";
import { overlaps } from "@utils/scope";
import { throwStar } from "@utils/wildcards";

import { AllStores, NestableOverrides } from "./types";

type Nameable = Extract<Entity, { name: Maybe<string> }>;
type Titleable = Extract<Entity, { title: Maybe<string> }>;

export const toLabel = <T extends Entity>(e: T) =>
  (e as Nameable)?.name || (e as Titleable)?.title;

export const switchEntity = <T extends Entity, R>(
  entity: Maybe<T>,
  callbacks: { [K in EntityType]?: Fn<EntityTypeMap[K], R> } & {
    else?: Fn<T, R>;
  }
): Maybe<R> => entity && callbacks[entity?.source?.type]?.(entity as any); // trust me bro

export const toStore = <T extends EntityType, E extends EntityForType<T>>(
  stores: AllStores,
  type: T
): StoreState<E> => stores[type as EntityType] as any as StoreState<E>;

export const isInflated = (entity: Maybe<Entity | RelationRef | Ref>) =>
  !!(entity as Maybe<Entity>)?.source;

export const shouldSyncUpdate = <T extends Entity>(
  update: Update<T>,
  aliases: SafeRecord<ID, ID>
) =>
  // Don't sync team creates
  !(update.method === "create" && update.source.type === "team") &&
  // Only sync updates that have a persisted ID
  !!persistedID(update.id, aliases);

export const isUpdateRelevant = <T extends Entity>(
  update: Update<T>,
  allowed?: string[]
) => some(allowed, (s) => overlaps(update.source.scope, s));

export const toNestedTypes = <T extends Entity = Entity>(
  props: PropertyDef<T>[],
  settings?: SettingsData
): EntityType[] =>
  uniq(
    omitEmpty([
      ...flatMap(props, (p) =>
        isAnyRelation(p) && p.options?.hierarchy === "child"
          ? ensureMany(throwStar(p.options?.references))
          : []
      ),
      getSetting(settings, SystemPackages.Pages) ? "page" : undefined,
      "view",
      "note",
      "resource",
      "schedule",
    ])
  );

export const flattenFor = (
  all: NestableOverrides,
  thing: { id?: ID; source?: DatabaseID }
): Partial<Entity> =>
  merge<Partial<Entity>>(
    all["*"] as Partial<Entity>,
    (when(
      thing.source?.type || when(thing.id, maybeTypeFromId),
      (k) => all[k]
    ) || {}) as Partial<Entity>,
    (when(thing.id, (k) => all[k]) || {}) as Partial<Entity>
  );
