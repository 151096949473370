import { map, some, values } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { ID } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity } from "@state/generic";
import {
  SystemPackages,
  useAnyHasPackages,
  usePackageInstaller,
} from "@state/packages";
import { useMe } from "@state/persons";
import { useActiveSpace } from "@state/spaces";
import { toTemplateViewId } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { useStickyState } from "@utils/hooks";
import { isPersonId, maybeTypeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { useGoTo, usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { Centered, HStack, SpaceBetween, VStack } from "@ui/flex";
import { PackagesMarketplace } from "@ui/packages-markplace";
import { Main, PageLayout, TopBar } from "@ui/page-layout";
import { Sheet, WrapContainer } from "@ui/sheet-layout";
import { SpaceFilterSelect } from "@ui/space-filter-select";
import { Heading, Text, TextSmall, TextXLarge } from "@ui/text";
import { ViewCard } from "@ui/view-card";
import { ViewCardCompact } from "@ui/view-card-compact";

import AppPage from "./app-page";

import styles from "./home-page.module.css";

interface Props {}

const OutboxPage = ({}: Props) => {
  const me = useMe();
  const wId = useActiveWorkspaceId();
  const [page] = useRegisterPage("home", undefined);
  const space = useActiveSpace();
  const [showAll, setShowAll] = useStickyState<boolean>(
    () => true,
    `home-show-all`
  );

  usePageUndoRedo(page.id);

  // Ensure current user is always fetched on first load
  useLazyEntity(me.id, false);

  if (!me) {
    return <SpaceSetup />;
  }

  return (
    <AppPage page={page} title="Home">
      <PageLayout bar="top">
        <TopBar>
          <WrapContainer>
            <Container stack={"horizontal"} inset="bottom">
              <SpaceBetween>
                <HStack gap={20} fit="container">
                  <HStack>
                    <VStack gap={0}>
                      <Heading bold>Follow-up</Heading>
                      <Text subtle>
                        Work created by you for other people in the last month.
                      </Text>

                      {space.mode === "team" && (
                        <HStack gap={4}>
                          <Text subtle>Showing work from </Text>
                          <SpaceFilterSelect
                            value={showAll ? wId : space.id}
                            onChanged={(id) => setShowAll(id === wId)}
                            caret={true}
                            searchable={false}
                            casing="lower"
                          />
                        </HStack>
                      )}
                    </VStack>
                  </HStack>
                </HStack>
              </SpaceBetween>
            </Container>
            {/* </CoverImage> */}
          </WrapContainer>
        </TopBar>

        <Main>
          <HomePanes
            filter={
              space.mode === "team" ? (showAll ? wId : space.id) : space.id
            }
          />
        </Main>
      </PageLayout>
    </AppPage>
  );
};

interface HomePanesProps {
  filter: ID;
}

const HomePanes = ({ filter }: HomePanesProps) => {
  const pushTo = usePushTo();
  const goTo = useGoTo();
  const me = useMe();
  const workspaceId = useActiveWorkspaceId();

  const toCheck = useMemo(
    () =>
      switchEnum(maybeTypeFromId(filter) || "", {
        // Show all teams
        workspace: () => [workspaceId, ...map(me.teams || [], (t) => t.id)],
        // Individual team, inheriting from workspace
        team: () => [workspaceId, filter],
        // Individual person, not inheriting from workspace
        person: () => [me.id],
        // Otherwise workspace -_-
        else: () => [workspaceId],
      }),
    [workspaceId, filter]
  );
  const installed = useAnyHasPackages(toCheck, [
    SystemPackages.Meetings,
    SystemPackages.Outcomes,
    SystemPackages.Pages,
    SystemPackages.Content,
    SystemPackages.Events,
    SystemPackages.Deals,
    SystemPackages.Campaigns,
    SystemPackages.Sprints,
    SystemPackages.Tasks,
    SystemPackages.Projects,
  ]);

  const toId = useCallback(
    (template: string) =>
      toTemplateViewId(template, { parent: me.id, for: filter }),
    [me.id, filter]
  );

  const noneInstalled = useMemo(() => {
    return !some(values(installed), Boolean);
  }, [installed]);

  if (noneInstalled && !!isPersonId(filter)) {
    return <SpaceSetup />;
  }

  return (
    <WrapContainer>
      <SpaceBetween fit="container" align="stretch" gap={16}>
        <Sheet size="primary" mode="sizing" height="content">
          <VStack gap={10}>
            {!!installed[SystemPackages.Tasks] && (
              <ViewCard
                viewId={toId("tasks-by-me")}
                onOpen={pushTo}
                limit={5}
                size="full"
              />
            )}

            <ViewCard
              viewId={toId("notes-by-me")}
              onOpen={pushTo}
              size="full"
            />
          </VStack>
        </Sheet>
        <Sheet size="secondary" mode="sizing" height="content">
          <VStack fit="container">
            <></>
            {!!installed[SystemPackages.Meetings] && (
              <ViewCardCompact
                viewId={toId("actions-by-me")}
                onOpen={pushTo}
                empty="collapse"
                size="full"
              />
            )}
          </VStack>
        </Sheet>
      </SpaceBetween>
    </WrapContainer>
  );
};
export default OutboxPage;

const SpaceSetup = () => {
  const space = useActiveSpace();
  const [showingMarketplace, setShowingMarketplace] = useState(false);
  const { install, loading } = usePackageInstaller(space.id);
  const label = useMemo(
    () =>
      switchEnum(space.mode || "", {
        person: "Setup your private space",
        team: "Setup your team space",
        else: () => "Setup your space",
      }),
    [space.mode]
  );

  const handleInstallBasics = useCallback(() => {
    install(SystemPackages.Tasks);
    install(SystemPackages.Pages);
  }, [install]);

  return (
    <Container>
      {showingMarketplace && (
        <PackagesMarketplace
          scope={space.id}
          onClose={() => setShowingMarketplace(false)}
        />
      )}

      <Centered>
        <Card fit="content" className={styles.quickSetup}>
          <VStack gap={20}>
            <VStack gap={0}>
              <TextXLarge bold>{label}</TextXLarge>
              <Text subtle>
                Just like any team, you can install any packages in your private
                space.
              </Text>
            </VStack>

            <SpaceBetween direction="horizontal" className={styles.centered}>
              <Container stack="vertical" width="container" align="center">
                <VStack gap={0} align="center">
                  <Text bold>Install the Basics</Text>
                  <TextSmall subtle>
                    Start with a few common packages.
                  </TextSmall>
                </VStack>
                <Button
                  fit="content"
                  subtle
                  variant="primary"
                  onClick={handleInstallBasics}
                >
                  Install
                </Button>
              </Container>

              <Divider direction="vertical" className={styles.vertDivider} />

              <Container stack="vertical" width="container" align="center">
                <VStack gap={0} align="center">
                  <Text bold>Open Marketplace</Text>
                  <TextSmall subtle>
                    Install just exactly what you need.
                  </TextSmall>
                </VStack>
                <Button
                  fit="content"
                  onClick={() => setShowingMarketplace(true)}
                >
                  Open
                </Button>
              </Container>
            </SpaceBetween>
          </VStack>
        </Card>
      </Centered>
    </Container>
  );
};
