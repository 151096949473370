import { map, orderBy, take } from "lodash";
import { equals } from "lodash/fp";
import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { setRecoil } from "recoil-nexus";
import { useDebouncedCallback } from "use-debounce";

import {
  Entity,
  EntityType,
  hasOrders,
  isTemplateEntity,
  SearchOptions as ApiSearchOptions,
} from "@api";

import { setItems } from "@state/store";
import { useActiveWorkspaceId } from "@state/workspace";

import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { useRefState } from "@utils/hooks";
import { or } from "@utils/logic";
import { toOrder } from "@utils/ordering";
import { toBaseScope } from "@utils/scope";
import { hashable } from "@utils/serializable";

import { getStore } from "../atoms";
import { getEntitiesForSearch } from "../queries";
import { searchStore } from "../selectors";

const isTeamOrPerson = or(equals("team"), equals("person"));

export type SearchOptions = ApiSearchOptions & {
  debounce?: number; // Debounce time for search
  max?: number; // Max time that debounces can occure for
  empty?: boolean; // Whether to return results when query string is empty
};

export const useSearch = (
  type: EntityType,
  scope: string = "",
  options?: SearchOptions
) => {
  const [localQuery, _setLocalQuery] = useState("");
  const [apiQuery, apiQueryRef, _setApiQuery] = useRefState<string>("");
  const [loading, setLoading] = useState(false);

  const setLocalQuery = useDebouncedCallback(_setLocalQuery, 80, {
    maxWait: options?.debounce || 100,
  });

  const setApiQuery = useDebouncedCallback(
    _setApiQuery,
    options?.debounce || 400,
    { maxWait: options?.max ?? (options?.debounce || 400) * 2 }
  );

  const localResults = useRecoilValue(
    searchStore(
      !!localQuery || options?.empty !== false
        ? hashable({
            ...options,
            type,
            query: localQuery,
            limit: options?.limit || 20,
            scope,
            templates: options?.templates ?? isTemplateEntity(type) ?? false,
          })
        : undefined
    )
  );

  useAsyncEffect(async () => {
    if (!localQuery?.trim() && !options?.empty) {
      setLoading(false);
      return;
    }

    setLoading(true);

    const matches = await getEntitiesForSearch(type, localQuery, {
      limit: (options?.limit || 5) * 2,
      archived: false,
      ...options,
      templates: options?.templates ?? isTemplateEntity(type) ?? false,
    });

    setRecoil(getStore(type), setItems(matches));
    setLoading(false);
  }, [apiQuery, type, scope, options?.empty]);

  return useMemo(
    () => ({
      query: localQuery,
      loading,
      setQuery: (q: string) => {
        setLoading(!!q);
        setApiQuery(q);
        setLocalQuery(q);
      },
      results: localResults,
    }),
    [localQuery, loading, localResults]
  );
};

// Returns close-by (local) results when no search query is present
export const useSmartSearch = <R = Entity>(
  type: EntityType,
  scope?: string,
  opts?: SearchOptions & { toOption?: Fn<Entity, R> }
) => {
  const wID = useActiveWorkspaceId();
  const fullSearchScope = useMemo(
    () => (isTeamOrPerson(type) ? wID : toBaseScope(scope || wID)),
    [type, scope]
  );
  const nearbySearchScope = useMemo(
    () => (isTeamOrPerson(type) ? wID : scope),
    [type, scope]
  );

  // Full search never runs on empty, with base scope
  const { query, setQuery, loading, results } = useSearch(
    type,
    fullSearchScope,
    opts
  );
  // Nearby search only runs on empty, with original scope, looking for items close to this
  const { results: nearby } = useSearch(type, nearbySearchScope, {
    ...opts,
    empty: opts?.empty,
    archived: false,
    limit: (opts?.limit || 5) * 2,
    templates: opts?.templates,
  });

  const final = useMemo(
    () =>
      !!query?.trim() || !nearby?.length
        ? results
        : orderBy(nearby, (n) =>
            hasOrders(n) ? toOrder(n.orders, "default") : nearby.length
          ),
    [query, nearby, results]
  );

  return useMemo(
    () => ({
      query,
      loading,
      onSearch: setQuery,
      options: map(
        take(final, opts?.limit || final.length),
        (v) => (opts?.toOption || ((i) => i))(v) as R
      ),
    }),
    [query, opts?.toOption, setQuery, results, nearby, loading]
  );
};
