import { atomFamily } from "recoil";

import { Company, ID } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type CompanyStoreState = StoreState<Company>;

export const WorkspaceCompanyStoreAtom = atomFamily<CompanyStoreState, ID>({
  key: "WorkspaceCompanyStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "company")],
});

export const CompanyStoreAtom = WorkspaceWrappedAtom(
  "CompanyStoreAtom",
  WorkspaceCompanyStoreAtom
);
