import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useMe } from "@state/persons";
import { toTemplateViewId } from "@state/views";

import { useStickyState } from "@utils/hooks";
import { Maybe } from "@utils/maybe";

import AppPage from "@ui/page/app-page";
import { Main, PageLayout } from "@ui/page-layout";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import SmartViewPane from "@ui/view-pane";

export const MyMeetingsPage = () => {
  const me = useMe();
  const [viewId, setViewId] = useStickyState<Maybe<string>>(
    () =>
      toTemplateViewId("all-meetings", {
        parent: me.id,
        entity: "meeting",
      }),
    `meeting-view-id`
  );
  const [page] = useRegisterPage();
  usePageUndoRedo(page.id);

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <Main>
              {viewId && (
                <SmartViewPane
                  viewId={viewId}
                  onChangeView={(v) => setViewId(v.id)}
                  showTitle={false}
                  showViewsBar={false}
                  // showHeader={false}
                />
              )}
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};
