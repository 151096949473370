import { Entity, PropertyRef } from "@api";

import { useApplyTemplate } from "@state/generic";
import { useEntityLabels } from "@state/settings";

import { Button } from "@ui/button";
import { showSuccess } from "@ui/notifications";
import { TemplateSelect } from "@ui/select";
import { Text } from "@ui/text";

type Props = { entity: Entity; hasFieldSet?: PropertyRef };

export const ApplyTemplateButton = ({ entity, hasFieldSet }: Props) => {
  const toLabel = useEntityLabels(entity.source.scope);
  const { apply } = useApplyTemplate(entity, () =>
    showSuccess(
      `Template applied to ${toLabel(entity.source.type, {
        case: "lower",
        plural: false,
      })}`
    )
  );

  return (
    <TemplateSelect
      scope={entity?.source.scope}
      type={entity.source.type}
      allowed={[entity.source.type]}
      hasFieldSet={hasFieldSet}
      allowNew={false}
      value={undefined}
      onChange={(r) => r && apply(r)}
      closeOnSelect={true}
    >
      <Button subtle size="small">
        <Text subtle>Use template...</Text>
      </Button>
    </TemplateSelect>
  );
};
