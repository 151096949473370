import { BaseColor, Color, Shade } from "@api";

import { cachedFunc } from "./fn";
import { Maybe } from "./maybe";
import { toMilliSeconds } from "./time";

export type ColorPair = [BaseColor, Shade];

export const toColor = cachedFunc(
  (base: BaseColor, shade?: Shade): Color =>
    !shade || shade === 1 ? base : (`${base}_${shade}` as Color),
  toMilliSeconds("10 hours")
);

export const fromColor = cachedFunc((code: string): ColorPair => {
  const [color, shade] = code.split("_");
  return [color as BaseColor, (shade ? parseInt(shade) : 1) as Shade];
}, toMilliSeconds("10 hours"));

export const toColorVar = cachedFunc(
  (base: BaseColor | Color, shade?: number) => {
    const fallback = fromColor(base);
    const finalShade = shade || fallback?.[1];
    const finalColor = fallback?.[0] || base;

    return !finalShade || finalShade === 1
      ? `var(--color-user-${finalColor})`
      : `var(--color-user-${finalColor}-${finalShade})`;
  },
  toMilliSeconds("10 hours")
);

export const toInverseColorVar = cachedFunc(
  (base: BaseColor | Color, shade?: number) => {
    return (shade || fromColor(base)?.[1]) > 3
      ? "var(--color-text-on-white)"
      : "var(--color-text-inverse)";
  },
  toMilliSeconds("10 hours")
);

export const sansDefault = (color: Maybe<Color | "default">): Color =>
  !color || color === "default" ? "gray_5" : color;
