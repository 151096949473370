import { findLast, groupBy, isEmpty, keys, last, map, reduce } from "lodash";
import { useLayoutEffect, useMemo, useRef } from "react";

import {
  AggregatePropRef,
  Entity,
  GroupByProp,
  PropertyDef,
  PropertyMutation,
  Ref,
  View,
} from "@api";

import { useLazyPropertyDef } from "@state/databases";
import { getStore, useEntitySource, useQueueUpdates } from "@state/generic";
import { useQuickSearch } from "@state/quick-filters";
import { useStableViewKey } from "@state/store";
import { ID } from "@state/types";
import {
  isTriaging,
  useAddToView,
  useDefaultsForView,
  useDropInView,
  useLazyGetView,
  useLazyItemsForView,
  useUpdateView,
  ViewResults,
} from "@state/views";

import { ensureArray, justOne, OneOrMany, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import {
  GroupedGroups,
  GroupedItems,
  GroupedValue,
  isNested,
  NestedGroup,
} from "@utils/grouping";
import { useShowMore } from "@utils/hooks";
import { switchEnum } from "@utils/logic";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { isEmptyRef, toKey } from "@utils/property-refs";
import {
  SelectionState,
  SetSelectionState,
  usePageSelection,
} from "@utils/selectable";

import { AddEntityInput } from "@ui/add-entity-input";
import { usePageId } from "@ui/app-page";
import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { useItemDrop } from "@ui/entity-drag-drop";
import { HStack, VStack } from "@ui/flex";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { GroupColor, GroupHeading, HiddenGroups } from "@ui/nested-groups";
import { PropertyLabel } from "@ui/property-label";
import { useSuggestedProps } from "@ui/suggested-props";

import styles from "./cards.module.css";

interface Props {
  id: ID;
}

interface NestedItemsProps {
  view: View;
  group?: Maybe<NestedGroup<Entity>>;
  items?: ViewResults<Entity>;
  groupIndex: number;
  parents: GroupedValue[];
  aggregate?: [AggregatePropRef, PropertyDef];
  direction: "horizontal" | "vertical";
  colorGroup?: GroupByProp<Entity>;
  selection: SelectionState;
  setSelection: SetSelectionState;
  onAdded: (ts: OneOrMany<Ref>) => void;
  updateView: (changes: PropertyMutation<View>[]) => void;
}

export function CardLayout({ id }: Props) {
  const view = useLazyGetView(id);
  const updateView = useUpdateView(id, true);
  const { items } = useLazyItemsForView(id);
  const itemSource = useEntitySource(view?.entity, view?.source);
  const onAdded = useAddToView(id);
  const [selection, setSelection] = usePageSelection();
  const { isSearching } = useQuickSearch(id);
  const stackDirection = useMemo(
    () => (view?.grouping === "rows" ? "vertical" : "horizontal"),
    [view?.grouping]
  );
  const colorGroup = useMemo(
    () =>
      switchEnum(view?.grouping || "none", {
        rows: () => view?.group?.[0],
        columns: () => view?.group?.[0],
        columns_rows: () =>
          findLast(view?.group, (f) =>
            ["select", "multi_select", "status"]?.includes(f?.type || "")
          ) || last(view?.group),
        else: () => undefined,
      }),
    [view?.group, view?.grouping]
  );
  const hasSwimlanes = view?.grouping === "columns_rows" && !!view?.group?.[1];
  const aggregateProp = useLazyPropertyDef(
    itemSource,
    justOne(view?.aggregate)
  );
  const aggregateBy = useMemo(
    () =>
      when(justOne(view?.aggregate), (agg) =>
        agg && aggregateProp
          ? ([agg, aggregateProp] as [AggregatePropRef, PropertyDef])
          : undefined
      ),
    [view?.aggregate, aggregateProp]
  );

  useLayoutEffect(() => {
    // Get the height of all elements with data-sync-height, group them by the data-sync-height value and find the max height then set min-heigh on all of them
    const elements = document.querySelectorAll("[data-sync-height]");
    const groups = groupBy(elements, (el: HTMLElement) => {
      return el.getAttribute("data-sync-height");
    }) as Record<string, HTMLElement[]>;

    map(keys(groups), (key) => {
      if (key === "false" || !key) {
        return;
      }
      const els = groups[key];
      const maxHeight = reduce(
        els,
        (acc, el) => {
          el.style.minHeight = `0px`;
          return Math.max(acc, el.clientHeight);
        },
        0
      );
      const groupHeight = Math.max(
        maxHeight,
        key !== "group-heading" ? 100 : 50
      );
      els.forEach(
        (el: HTMLElement) => (el.style.minHeight = `${groupHeight}px`)
      );
    });
  }, [view, items]);

  if (!view) {
    return <></>;
  }

  if (!view.grouping || view.grouping === "none") {
    return (
      <Container size="half">
        <UngroupedItems
          view={view}
          items={items}
          groupIndex={0}
          colorGroup={colorGroup}
          parents={[]}
          updateView={updateView}
          onAdded={onAdded}
          selection={selection}
          setSelection={setSelection}
          direction={stackDirection}
        />
      </Container>
    );
  }

  return (
    <div className={cx(styles.stackContainer, styles[stackDirection])}>
      {hasSwimlanes && (
        <div>
          <div className={styles.spacer} data-sync-height="group-heading" />
          <div className={cx(styles.swimlanes)}>
            {map(
              (items?.grouped?.groups[0] as GroupedGroups)?.groups,
              (group) => {
                const key = toKey(group.value, group.def?.format);
                return (
                  <div key={key} className={styles.swimlane}>
                    <GroupColor group={group.value} dropping={false} />
                    <div data-sync-height={key}>
                      <PropertyLabel
                        className={styles.label}
                        valueRef={group.value}
                        format={group?.def?.format}
                        source={itemSource ?? view.source}
                      />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}

      {view.grouping &&
        maybeMap(
          items?.grouped?.groups,
          (grouped, i) =>
            (!isSearching || !isEmpty((grouped as GroupedItems).items)) &&
            // Hide empty group when triaging
            (!isTriaging(view) ||
              !isEmptyRef((grouped as GroupedItems).value)) && (
              <Group
                key={toKey(grouped.value, grouped.def?.format)}
                view={view}
                group={grouped}
                groupIndex={i}
                colorGroup={colorGroup}
                parents={[]}
                aggregate={aggregateBy}
                updateView={updateView}
                onAdded={onAdded}
                selection={selection}
                setSelection={setSelection}
                direction={stackDirection}
              />
            )
        )}

      {!!items?.grouped?.hidden?.length && (
        <HiddenGroups
          hidden={items.grouped.hidden}
          open={true}
          onChanged={({ values, ...group }) =>
            updateView([
              asMutation(
                { field: "group", type: "json" },
                maybeMap(
                  view?.group,
                  (g) => g && (g.field === group.field ? group : g)
                )
              ),
            ])
          }
          className={{
            container: styles.hiddenGroups,
            list: styles.hiddenGroups,
          }}
        />
      )}
    </div>
  );
}

function UngroupedItems({
  view,
  items,
  parents,
  onAdded,
  direction,
  colorGroup,
  groupIndex,
  ...rest
}: Omit<NestedItemsProps, "group">) {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const toStableKey = useStableViewKey(getStore(view?.entity || "task"));
  const onReorder = useDropInView(view, pageId);

  const mutate = useQueueUpdates(pageId);
  const itemSource = useEntitySource(view.entity, view?.source);
  const defaults = useDefaultsForView(view.id, undefined);
  const engine = useEngine(view.entity);
  const suggestedProps = useSuggestedProps();
  const showProps = whenEmpty(view?.showProps, suggestedProps || []);
  const paged = useShowMore(items?.sorted, 100);

  return (
    <VStack fit="container" className={styles.ungroupedContainer}>
      <HStack gap={4} fit="container" wrap className={styles.ungroupedItems}>
        {map(paged.visible, (t) =>
          render(engine.asListCard, {
            item: t,
            key: toStableKey(t.id),
            parents: parents,
            ...rest,
            className: styles.horItem,
            hideEmpty: view.settings?.hideEmptyFields,
            showProps: showProps,
            onReorder: onReorder,
            onOpen: pushTo,
            onChange: (change) => mutate(asUpdate(t, ensureArray(change))),
          })
        )}
      </HStack>

      <AddEntityInput
        className={styles.addInput}
        source={itemSource}
        defaults={defaults}
        onAdded={onAdded}
      />
    </VStack>
  );
}

function NestedItems({
  view,
  group,
  items,
  parents,
  onAdded,
  direction,
  colorGroup,
  groupIndex,
  ...rest
}: NestedItemsProps) {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const toStableKey = useStableViewKey(getStore(view?.entity || "task"));
  const onReorder = useDropInView(view, pageId);

  const mutate = useQueueUpdates(pageId);
  const itemSource = useEntitySource(view.entity, view?.source);
  const defaults = useDefaultsForView(
    view.id,
    group?.value ? [group.value, ...parents] : undefined
  );
  const engine = useEngine(view.entity);
  const is2Dim = view.grouping === "columns_rows";
  const suggestedProps = useSuggestedProps();
  const showProps = whenEmpty(view?.showProps, suggestedProps || []);

  const nestedRef = useRef<HTMLDivElement>(null);
  const [{ dropping }] = useItemDrop({
    ref: nestedRef,
    type: view.entity,
    group: group,
    parents: parents,
    item: last((group as Maybe<GroupedItems>)?.items),
    forcePosition: "after",
  });

  const isNesting = !!group && isNested(group);

  const paged = useShowMore(isNesting ? [] : group?.items || items?.sorted, 30);

  // Return nested groups
  if (isNesting) {
    return (
      <div className={styles.subGroups}>
        {map(group.groups, (innerGroup, i) => (
          <div
            key={toKey(innerGroup.value, innerGroup.def?.format)}
            className={cx(
              styles.subGroup,
              is2Dim && i === 0 && styles.firstRow,
              is2Dim && groupIndex === 0 && styles.firstColumn
            )}
          >
            <NestedItems
              view={view}
              group={innerGroup}
              parents={[...parents, group?.value]}
              onAdded={onAdded}
              direction={direction}
              colorGroup={colorGroup}
              groupIndex={groupIndex}
              {...rest}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div
      // Disable drop zone for sinlge grouped views
      ref={
        !!view.grouping || view.grouping === "columns_rows"
          ? nestedRef
          : undefined
      }
      data-sync-height={
        !!group && view.grouping === "columns_rows"
          ? toKey(group?.value, group.def?.format)
          : false
      }
      className={cx(styles.groupItems, dropping && styles.dropping)}
    >
      {(!view.grouping || view.grouping === "columns_rows") && (
        <GroupColor
          group={
            colorGroup?.field === group?.def?.field
              ? group?.value
              : last(parents)
          }
          dropping={!!dropping}
        />
      )}

      <Container
        padding="none"
        stack={view.grouping === "rows" ? "horizontal" : "vertical"}
        wrap={view.grouping === "rows"}
        gap={4}
        fit="container"
      >
        {map(paged.visible, (t) =>
          render(engine.asListCard, {
            item: t,
            key: toStableKey(t.id),
            group: group,
            parents: parents,
            ...rest,
            className: cx(view.grouping === "rows" && styles.horItem),
            hideEmpty: view.settings?.hideEmptyFields,
            showProps: showProps,
            onReorder: onReorder,
            onOpen: pushTo,
            onChange: (change) => mutate(asUpdate(t, ensureArray(change))),
          })
        )}

        {paged.hasMore && (
          <ShowMoreMenuItem count={paged.moreCount} onClick={paged.showMore} />
        )}
      </Container>

      <AddEntityInput
        className={styles.addInput}
        source={itemSource}
        defaults={defaults}
        onAdded={onAdded}
        size={view.grouping === "rows" ? "default" : "small"}
      />
    </div>
  );
}

function Group({
  view,
  group,
  parents,
  updateView,
  direction,
  aggregate,
  ...rest
}: NestedItemsProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  // Top group drag drop
  const [{ dropping }] = useItemDrop({
    type: view.entity,
    ref: containerRef,
    group: group,
    parents: parents,
    item: undefined,
  });

  return (
    <div ref={containerRef} className={cx(styles.group, styles[direction])}>
      {view.grouping !== "columns_rows" && (
        <GroupColor group={group?.value} dropping={!!dropping} />
      )}

      {!!group && (
        <GroupHeading
          className={styles.groupHeader}
          view={view}
          group={group}
          inset={false}
          aggregate={aggregate}
          onChanged={({ values, ...group }) =>
            updateView([
              asMutation(
                { field: "group", type: "json" },
                maybeMap(view?.group, (g) =>
                  g && g.field === group.field ? group : g
                )
              ),
            ])
          }
        >
          {view.grouping === "columns_rows" && (
            <GroupColor group={group.value} dropping={!!dropping} />
          )}
        </GroupHeading>
      )}

      <NestedItems
        view={view}
        group={group}
        parents={[]}
        updateView={updateView}
        direction={direction}
        {...rest}
      />
    </div>
  );
}
