import { useRecoilState } from "recoil";

import { GlobalFetchOptionsAtom, showArchived } from "@state/fetch-results";

import { Banner } from "@ui/banner";
import { Button } from "@ui/button";
import { HStack } from "@ui/flex";
import { ArchiveOpen, Icon } from "@ui/icon";
import { Text } from "@ui/text";

import styles from "./archive-banner.module.css";

export const ArchiveBanner = () => {
  const [fetchOptions, setFetchOptions] = useRecoilState(
    GlobalFetchOptionsAtom
  );

  if (!fetchOptions.archived) {
    return <></>;
  }

  return (
    <Banner
      icon={ArchiveOpen}
      text="Archive Open: Showing current and archived data."
      color="orange_2"
      className={styles.banner}
    >
      <HStack gap={6}>
        <Icon icon={ArchiveOpen} />
        <Text>Showing archived work only.</Text>
        <Button
          variant="link"
          className={styles.link}
          onClick={() => setFetchOptions(showArchived(false))}
        >
          Close archives
        </Button>
      </HStack>
    </Banner>
  );
};
