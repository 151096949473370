import { toArray, values } from "lodash";

import { envOption } from "./env";
import { HUMAN_OR_LOCAL_REGEX, isHumanId } from "./id";
import { Maybe, SafeRecord } from "./maybe";
import { toPath } from "./url";

const DOMAIN = envOption({
  local: "localhost:3001",
  dev: "dev.traction.team",
  prod: "traction.team",
});

export const isTraction = (url: Maybe<string>) =>
  url?.includes(DOMAIN) ?? false;
export const isWeb = (url: Maybe<string>) => url?.startsWith("http") ?? false;
export const isSlack = (url: Maybe<string>) => url?.includes("slack.") ?? false;
export const isNotion = (url: Maybe<string>) =>
  url?.includes("notion.") ?? false;
export const isGoogle = (url: Maybe<string>) =>
  url?.includes("google.") ?? false;

export const toTractionIds = (url: Maybe<string>): string[] =>
  isTraction(url)
    ? toPath(url || "")
        ?.split("/")
        .filter((p) => isHumanId(p))
    : [];

// function that parses a string and returns an array of links inside it
// it supports both markdown links [some text](http://google.com) and plain links http://google.com
// it also supports markdown images ![some text](http://google.com/image.png)
// it returns an array of objects with text and url properties
// it returns an empty array if there are no links

const toExtractedLink = (text: Maybe<string>, url: string) =>
  text === url ? { url, text: undefined } : { text, url };

const addNewValue = <T>(map: SafeRecord<string, T>, key: string, value: T) => {
  if (!map[key]) {
    map[key] = value;
  }
  return map;
};

export const extractLinks = (text: string) => {
  const links = {} as SafeRecord<
    string,
    Maybe<{ text: Maybe<string>; url: string }>
  >;
  const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const imageRegex = /!\[([^\]]+)\]\(([^)]+)\)/g;
  const plainUrlRegex = /\bhttps?:\/\/[^\s<>\[\]]+[\w\/](?<!\([^\s]*\))\b/g;
  // Links inside data-embed-url tags where the title is in data-embed-title
  const embedUrls =
    /<embed[^>]*data-embed-url="([^"]+)"[^>]+data-embed-title="([^"]+)"[^>]+>/g;
  // Links href tags
  const aTagUrls = /<a[^>]+href="([^"]+)"[^>]*>([^<]*)<\/a>/g;

  let match;

  // Extract all linnk types
  while ((match = embedUrls.exec(text))) {
    const [, url, text] = match;
    addNewValue(links, url, toExtractedLink(text, url));
  }
  while ((match = imageRegex.exec(text))) {
    const [, text, url] = match;
    addNewValue(links, url, toExtractedLink(text, url));
  }
  while ((match = aTagUrls.exec(text))) {
    const [, url, text] = match;
    addNewValue(links, url, toExtractedLink(text, url));
  }
  while ((match = markdownLinkRegex.exec(text))) {
    const [, text, url] = match;
    addNewValue(links, url, toExtractedLink(text, url));
  }
  while ((match = plainUrlRegex.exec(text))) {
    const url = match[0];
    addNewValue(links, url, toExtractedLink(undefined, url));
  }

  return values(links);
};

export const extractTractionIds = (text: string) =>
  toArray(text.match(HUMAN_OR_LOCAL_REGEX));
