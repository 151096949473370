import { FloatingMenuPluginProps } from "@tiptap/extension-floating-menu";
import {
  BubbleMenu,
  Editor,
  FloatingMenu,
  isTextSelection,
} from "@tiptap/react";
import { useCallback } from "react";

import { cx } from "@utils/class-names";

import { ActionMenu } from "@ui/action-menu";
import { Button, Props as ButtonProps } from "@ui/button";
import { Divider } from "@ui/divider";
import { HStack } from "@ui/flex";
import { Bold, Italic, Strike } from "@ui/icon";

import { isFocused } from "./utils";

import styles from "./menus.module.css";

export type ShouldShowPredicate = Exclude<
  FloatingMenuPluginProps["shouldShow"],
  undefined | null
>;
export interface HasEditorProps {
  editor: Editor;
}

export const FormatButton = ({
  active,
  ...rest
}: { active?: boolean } & ButtonProps) => (
  <Button
    size="small"
    {...rest}
    subtle
    variant={active ? "primary" : "secondary"}
  />
);

export const FormatActionButton = ({
  children,
  ...rest
}: { active?: boolean } & ButtonProps) => (
  <ActionMenu actions={children}>
    <FormatButton {...rest} />
  </ActionMenu>
);

// Add a empty paragraph to the end of tiptap editor
export const TrailingParagraph = ({
  editor,
  size,
}: HasEditorProps & { size: "small" | "large" | "xlarge" }) => {
  const onClick = useCallback(() => {
    editor.isEmpty
      ? editor.chain().focus().run()
      : editor
          .chain()
          .focus()
          .insertContentAt(editor.view.state.doc.content.size, "<p></p>")
          .run();
  }, [editor]);

  return (
    <p className={cx(styles.trailing, styles[size])} onClick={onClick}></p>
  );
};

export const FormattingMenu = ({ editor }: HasEditorProps) => {
  // show the floating when there is selected text
  const shouldShow = useCallback<ShouldShowPredicate>(
    ({ editor, view, state, oldState }) =>
      isTextSelection(view.state.selection) &&
      !view.state.selection.empty &&
      !editor.isActive("horizontalRule") &&
      !editor.isActive("embed"),
    [editor]
  );

  return (
    <BubbleMenu
      editor={editor}
      className={cx(styles.bubbleMenu)}
      tippyOptions={{ placement: "top", maxWidth: "100%" }}
      shouldShow={shouldShow}
    >
      <FormatButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        active={editor.isActive("bold")}
        icon={Bold}
      />
      <FormatButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        active={editor.isActive("italic")}
        icon={Italic}
      />
      <FormatButton
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        active={editor.isActive("strike")}
        icon={Strike}
      />
      <FormatButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        active={editor.isActive("heading", { level: 1 })}
      >
        H
      </FormatButton>

      <MenuDivider />

      <FormatButton
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        active={editor.isActive("code")}
      >
        code
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive("bulletList")}
      >
        bullets
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive("orderedList")}
      >
        list
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        active={editor.isActive("taskList")}
      >
        checklist
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        active={editor.isActive("blockquote")}
      >
        blockquote
      </FormatButton>
    </BubbleMenu>
  );
};

export const FixedMenu = ({ editor }: HasEditorProps) => {
  return (
    <HStack gap={0} className={styles.fixedMenu}>
      <FormatButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        active={editor.isActive("bold")}
        icon={Bold}
      />
      <FormatButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        active={editor.isActive("italic")}
        icon={Italic}
      />
      <FormatButton
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        active={editor.isActive("strike")}
        icon={Strike}
      />
      <FormatButton
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        active={editor.isActive("heading", { level: 1 })}
      >
        H
      </FormatButton>

      <MenuDivider />

      <FormatButton
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        active={editor.isActive("code")}
      >
        code
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        active={editor.isActive("bulletList")}
      >
        bullets
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        active={editor.isActive("orderedList")}
      >
        list
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        active={editor.isActive("blockquote")}
      >
        blockquote
      </FormatButton>
    </HStack>
  );
};

export const NewLineMenu = ({ editor }: HasEditorProps) => {
  // show the newlinemenu when in an empty paragraph
  const shouldShow = useCallback<ShouldShowPredicate>(
    ({ editor, view, state, oldState }) => {
      const { selection } = state;
      const { $anchor } = selection;
      const isRootDepth = $anchor.depth === 1;
      const isEmptyTextBlock =
        $anchor.parent.isTextblock &&
        !$anchor.parent.type.spec.code &&
        !$anchor.parent.textContent;

      return (
        !!selection.empty &&
        isRootDepth &&
        isEmptyTextBlock &&
        !!isFocused(editor) &&
        !$anchor.parent.content.size &&
        editor.isActive("paragraph") &&
        !editor.isActive("heading")
      );
    },
    [editor]
  );

  return (
    <FloatingMenu
      editor={editor}
      className={styles.newLineMenu}
      tippyOptions={{
        maxWidth: "100%",
        hideOnClick: true,
        onMount(instance) {
          instance.popper?.classList?.add(styles.interactDelay);
        },
      }}
      shouldShow={shouldShow}
    >
      <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      >
        # heading
      </FormatButton>
      <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().toggleTaskList().run()}
      >
        [] checkbox
      </FormatButton>
      <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        - bullets
      </FormatButton>
      <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        1. list
      </FormatButton>
      <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
      >
        ``` code
      </FormatButton>
      {/* <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
      >
        {">"} quote
      </FormatButton> */}
      <FormatButton
        className={styles.newLineButton}
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
      >
        --- divider
      </FormatButton>
      <FormatButton
        className={styles.newLineButton}
        onClick={() =>
          editor
            .chain()
            .focus()
            .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
            .run()
        }
      >
        table
      </FormatButton>
    </FloatingMenu>
  );
};

export const MenuDivider = () => (
  <Divider className={styles.bubbleDivider} direction="vertical" />
);
