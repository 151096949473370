import { capitalCase } from "change-case";
import { map } from "lodash";
import { Fragment } from "react";

import { Button, Props as ButtonProps } from "@ui/button";
import { Container } from "@ui/container";
import { HStack } from "@ui/flex";
import { Bullseye } from "@ui/icon";
import { SectionLabel } from "@ui/label";
import { Heading } from "@ui/text";

const BUTTON_VARIANTS = [
  "primary",
  "primary-alt",
  "secondary",
  "danger",
  "link",
] as Exclude<ButtonProps["variant"], undefined>[];

export default function ComponentLibraryPage() {
  return (
    <Container stack="vertical" gap={10} fit="container">
      <Heading>Buttons</Heading>
      <SectionLabel>Regular</SectionLabel>
      <HStack>
        {map(BUTTON_VARIANTS, (variant) => (
          <Fragment key={variant}>
            <Button size="regular" variant={variant}>
              {capitalCase(variant)}
            </Button>
            <Button size="regular" variant={variant} icon={Bullseye}>
              {capitalCase(variant)}
            </Button>
            <Button size="regular" variant={variant} icon={Bullseye} />
          </Fragment>
        ))}
      </HStack>

      <SectionLabel>Small</SectionLabel>
      <HStack>
        {map(BUTTON_VARIANTS, (variant) => (
          <Fragment key={variant}>
            <Button size="small" variant={variant}>
              {capitalCase(variant)}
            </Button>
            <Button size="small" variant={variant} icon={Bullseye}>
              {capitalCase(variant)}
            </Button>
            <Button size="small" variant={variant} icon={Bullseye} />
          </Fragment>
        ))}
      </HStack>

      <SectionLabel>Tiny</SectionLabel>
      <HStack>
        {map(BUTTON_VARIANTS, (variant) => (
          <Fragment key={variant}>
            <Button size="tiny" variant={variant}>
              {capitalCase(variant)}
            </Button>
            <Button size="tiny" variant={variant} icon={Bullseye}>
              {capitalCase(variant)}
            </Button>
            <Button size="tiny" variant={variant} icon={Bullseye} />
          </Fragment>
        ))}
      </HStack>
    </Container>
  );
}
