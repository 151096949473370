import { useCallback } from "react";

import { useUpdateEntity } from "@state/generic";
import { useMe } from "@state/persons";
import { ThemeMode, useSetting } from "@state/settings";

import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { Moon, PersonIcon, Sun } from "@ui/icon";

import { AppCommandsProps } from "./types";

export const SettingsCommands = (props: AppCommandsProps) => {
  const me = useMe();
  const goTo = useGoTo();

  const mutate = useUpdateEntity(me.id);

  const themeMode = useSetting<ThemeMode>(me.id, "settings.appThemeMode");
  const setThemeMode = useCallback(
    (mode: Maybe<ThemeMode>) =>
      mutate(
        asMutation({ field: "settings.appThemeMode", type: "text" }, mode)
      ),
    [mutate]
  );

  return (
    <CommandGroup label="Settings">
      <CommandItem
        value="Open personal settings"
        icon={<PersonIcon person={me} />}
        onClick={() => goTo("/settings/personal")}
      >
        Open personal settings
      </CommandItem>

      {themeMode !== "light" && (
        <CommandItem
          value="switch to light mode"
          icon={Sun}
          onClick={() => setThemeMode("light")}
        >
          Switch to light mode
        </CommandItem>
      )}
      {themeMode !== "dark" && (
        <CommandItem
          value="switch to dark mode"
          icon={Moon}
          onClick={() => setThemeMode("dark")}
        >
          Switch to dark mode
        </CommandItem>
      )}
    </CommandGroup>
  );
};
