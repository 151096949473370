import { filter, find, keys, uniq } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Connection, Edge, MarkerType,Node } from "reactflow";
import { useRecoilValue } from "recoil";

import { EntityType, ID } from "@api";

import {
  allPropertiesForTeam,
  allRelationsForTeam,
  toHierarchyPairs,
} from "@state/databases";
import { useInstalledEntities, useRelationCreateRemove } from "@state/packages";
import { useEntityLabels } from "@state/settings";

import { groupByMany, justOne, overlaps } from "@utils/array";
import { treeLayout, useLayoutStrategy } from "@utils/chart";
import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { maybeMap } from "@utils/maybe";
import { mapAll } from "@utils/promise";
import { toFieldName } from "@utils/property-refs";

import { Flow, withFlowProvider } from "@ui/flow";

import styles from "./team-schema-flow.module.css";

interface Props {
  teamId: ID;
  onSelected?: Fn<EntityType, void>;
  interactable?: boolean;
  className?: string;
}

const useGraphableInstalledEntities = (teamId: ID) => {
  const installed = useInstalledEntities(teamId);
  return useMemo(
    () =>
      filter(
        installed,
        (e) =>
          !equalsAny(e, [
            "meeting",
            "process",
            "form",
            "note",
            "page",
            "team",
            "workspace",
            "resource",
          ])
      ),
    [installed]
  );
};

export const TeamSchemaFlow = withFlowProvider(
  ({ teamId, onSelected, className, interactable = true }: Props) => {
    const { deleteConnection, createConnection } =
      useRelationCreateRemove(teamId);
    const toEntityLabel = useEntityLabels(teamId, { plural: true });
    const installed = useGraphableInstalledEntities(teamId);
    const allProps = useRecoilValue(allPropertiesForTeam(teamId));
    const props = useRecoilValue(allRelationsForTeam(teamId));
    const pairs = useMemo(() => toHierarchyPairs(props), [props]);
    const customCounts = useMemo(
      () =>
        groupByMany(
          filter(allProps, (p) => !p.system),
          (prop) => prop.entity
        ),
      [installed]
    );

    const strat = useMemo(
      () =>
        treeLayout(
          { width: 120, height: 80, hSpace: 1, vSpace: 5 },
          { orientation: "vertical" }
        ),
      []
    );
    const { nodes, edges, setData, ...flowProps } = useLayoutStrategy<{
      label: string;
      entity?: EntityType;
      selected?: boolean;
    }>(strat);
    const [selected, setSelected] = useState<string[]>([]);

    const handleConnect = useCallback(
      async (edge: Edge | Connection) => {
        await createConnection(
          edge.source as EntityType,
          edge.target as EntityType,
          "child"
        );
      },
      [setData]
    );

    const handleDelete = useCallback(
      async (connection: Edge[]) => {
        await mapAll(connection, async (edge) => {
          const pair = pairs[edge.id];
          if (pair) {
            await deleteConnection(pair);
          }
        });
      },
      [setData]
    );

    const onNodeClicked = useCallback((event: React.MouseEvent, node: Node) => {
      if (event?.defaultPrevented) {
        return;
      }

      onSelected?.(node?.data.entity);
      setSelected((s) =>
        s?.includes(node?.data.entity) ? [] : uniq([node.id])
      );
    }, []);

    useEffect(() => {
      setData({
        nodes: [
          ...maybeMap(installed, (type) => ({
            id: type,
            position: find(nodes, { id: type })?.position || { x: 0, y: 0 },
            draggable: interactable,
            data: {
              label: toEntityLabel(type),
              subTitle: `${customCounts[type]?.length || "No"} custom fields`,
              entity: type as EntityType,
            },
            type: "entity-type",
          })),
        ],

        edges: maybeMap(keys(pairs), (key) => {
          const [parent, child] = pairs[key];

          // Don't graph self-references
          if (!parent || parent.entity[0] === child.entity[0]) {
            return undefined;
          }

          return {
            id: key,
            source: parent.entity[0],
            target: justOne(parent.options?.references) || "",
            type: "step",
            data: {
              selected: overlaps(selected, [
                parent.entity[0],
                justOne(parent.options?.references),
              ]),
              label: parent.label || toFieldName(parent) || "",
            },
            markerEnd: {
              type: MarkerType.Arrow,
              color: "var(--color-primary)",
            },
          };
        }),
      });
    }, [installed, pairs, selected]);

    return (
      <Flow
        nodes={nodes}
        edges={edges}
        showControls={interactable}
        preventScrolling={!interactable}
        panOnDrag={!interactable}
        className={cx(className, !interactable && styles.readonly)}
        {...flowProps}
        onConnect={handleConnect}
        onEdgesDelete={handleDelete}
        onNodeClick={onNodeClicked}
      />
    );
  }
);
