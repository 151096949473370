import { atomFamily } from "recoil";

import { ID, KnowledgeBase } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type KnowledgeBaseStoreState = StoreState<KnowledgeBase>;

export const WorkspaceKnowledgeBaseStoreAtom = atomFamily<
  KnowledgeBaseStoreState,
  ID
>({
  key: "WorkspaceKnowledgeBaseStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "knowledgebase")],
});

export const KnowledgeBaseStoreAtom = WorkspaceWrappedAtom(
  "KnowledgeBaseStoreAtom",
  WorkspaceKnowledgeBaseStoreAtom
);
