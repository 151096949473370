import { last } from "lodash";
import { ReactNode, useCallback, useMemo, useState } from "react";

import {
  Entity,
  FileMeta,
  HasBody,
  hasBody,
  HasRefs,
  HasResources,
  Ref,
  RelationRef,
  RichText,
  toTitleOrName,
} from "@api";

import { useLazyPropertyDef } from "@state/databases";
import {
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { useAttachFile } from "@state/resources";
import { useEntityLabels } from "@state/settings";

import { justOne } from "@utils/array";
import { composel, Fn } from "@utils/fn";
import { Maybe, safeAs, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { append, isEmpty, isEqual, toHtml } from "@utils/rich-text";
import { fromScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { CreatePageButton } from "@ui/engine/page";
import { HStack, VStack } from "@ui/flex";
import { MagicPurple } from "@ui/icon";
import { Props as LabelProps } from "@ui/label";
import { ParentCard } from "@ui/parent-card";
import { ShowMoreOverflow } from "@ui/show-more-overflow";
import { Text } from "@ui/text";

import { AIAutoBrief } from "./ai-auto-brief";
import { RealTimeDocumentEditor } from "./real-time-field";
import { UploadFileButton } from "./upload-modal";

interface Props {
  entity: RelationRef;
  body: Maybe<RichText>;
  onBodyChanged?: Fn<RichText, void>;
  className?: string;
  placeholder?: string;
  showAll?: boolean;
  newLineSpace?: "small" | "large" | "xlarge";
}

type SectionProps = Props & {
  label?: string | ReactNode;
  labelSize?: LabelProps["size"];
};

export const PageBody = ({
  body,
  onBodyChanged,
  entity: ref,
  placeholder = "Write something...",
  className,
  showAll: _showAll = true,
  newLineSpace = "large",
}: Props) => {
  const pageId = usePageId();
  const goTo = useGoTo();
  const mutate = useUpdateEntity(ref.id);
  const entity = useLazyEntity(ref.id);
  const nested = useNestedSource(entity);
  const toEntityLabel = useEntityLabels(entity?.source.scope, {
    case: "title",
    plural: false,
  });
  const onFileUploaded = useAttachFile(entity as Maybe<HasResources>, pageId);

  const [showAutoBrief, setShowAutoBrief] = useState(false);
  const [showAll, setShowAll] = useState(_showAll);
  const def = useLazyPropertyDef(entity?.source, {
    field: "body",
    type: "rich_text",
  });
  const childScope = useNestedSource(entity);

  const handleChanged = useCallback(
    (rt: RichText) => {
      // Hasn't changed
      if (isEqual(rt, body)) {
        return;
      }

      if (onBodyChanged) {
        onBodyChanged(rt);
      } else {
        mutate(asMutation({ field: "body", type: "rich_text" }, rt));
      }
    },
    [mutate, body, onBodyChanged]
  );

  const handleFileUploaded = useCallback(
    (file: FileMeta) => {
      const markup = file.mimeType?.startsWith("image/")
        ? `<embed data-embed-type="image" data-embed-title="${file.name}" data-embed-url="${file.url}">`
        : `<p><embed data-embed-type="link" data-embed-title="${file.name}" data-embed-url="${file.url}" /></p>`;

      handleChanged(append(body, { html: markup }));
      onFileUploaded(file);
    },
    [onFileUploaded]
  );

  const handleNewPage = useCallback(
    (ref: Ref) => {
      const markup = `<a data-mention-id=${ref.id}>${
        when(safeAs<Entity>(ref), toTitleOrName) || ""
      }</a>`;
      handleChanged({ html: (when(body, toHtml) || "") + markup });
    },
    [goTo]
  );

  return (
    <VStack className={className} fit="container" gap={0}>
      <ShowMoreOverflow showAll={showAll}>
        <RealTimeDocumentEditor
          key={ref.id}
          entity={ref.id}
          field="body"
          scope={childScope?.scope}
          newLineSpace={newLineSpace}
          placeholder={placeholder}
          content={body}
          onChanged={handleChanged}
        />
        {showAutoBrief && def && nested && (
          <AIAutoBrief
            original={body}
            prop={def}
            source={nested}
            onDismiss={() => setShowAutoBrief(false)}
            onSaved={(rt) => {
              handleChanged(rt);
              setShowAutoBrief(false);
              setShowAll(true);
            }}
          />
        )}
      </ShowMoreOverflow>

      {childScope && (
        <HStack justify="flex-end" fit="container" gap={0}>
          <Button
            size="small"
            subtle
            icon={MagicPurple}
            onClick={() => setShowAutoBrief(true)}
          >
            <Text subtle>Doc Assist</Text>
          </Button>

          <UploadFileButton
            scope={childScope?.scope}
            onUploaded={handleFileUploaded}
          >
            <Text subtle>Upload files</Text>
          </UploadFileButton>

          <CreatePageButton scope={childScope.scope} onCreated={handleNewPage}>
            <Text subtle>New {toEntityLabel("page")}</Text>
          </CreatePageButton>
        </HStack>
      )}
    </VStack>
  );
};

export const PageBodySection = ({
  label: sectionLabel,
  labelSize = "medium",
  entity: ref,
  ...props
}: SectionProps) => {
  const entity = useLazyEntity(ref?.id);
  const goTo = useGoTo();
  const parentRef = useMemo(
    () =>
      when(safeAs<HasRefs>(entity)?.refs?.parent, justOne) ||
      when(entity?.source.scope, composel(fromScope, last, toRef)),
    [entity]
  );
  const parent = useLazyEntity(parentRef?.id);

  return (
    <>
      <CollapsibleSection
        title={sectionLabel || "Documentation"}
        labelSize={labelSize}
      >
        <PageBody {...props} entity={entity as HasBody} />
      </CollapsibleSection>

      {parent && hasBody(parent) && !isEmpty(parent.body) && (
        <CollapsibleSection
          title={"Parent " + (sectionLabel || "Documentation")}
          labelSize={labelSize}
        >
          <ParentCard
            id={parent.id}
            label={false}
            onClick={() => goTo(parent.id)}
            showProps={false}
          />
        </CollapsibleSection>
      )}
    </>
  );
};
