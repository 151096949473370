import { atomFamily } from "recoil";

import { Action, ID } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type ActionStoreState = StoreState<Action>;

export const WorkspaceActionStoreAtom = atomFamily<ActionStoreState, ID>({
  key: "WorkspaceActionStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "action")],
});

export const ActionStoreAtom = WorkspaceWrappedAtom(
  "ActionStoreAtom",
  WorkspaceActionStoreAtom
);
