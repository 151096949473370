import { Person } from "@api";

import { withHandle } from "@utils/event";

import { CoverImage } from "@ui/cover-image";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { VStack } from "@ui/flex";
import { PersonIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { ListCard } from "@ui/list-card";
import { MenuItem } from "@ui/menu-item";

import { UIEngine } from "../types";

import styles from "./styles.module.css";

export const PersonEngine: UIEngine<Person> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <MenuItem
        {...rest}
        onClick={withHandle(() => onOpen?.(item))}
        icon={<PersonIcon person={item} />}
        text={item.fullName || item.name}
      />
    );
  },
  asListCard: ({ item, onOpen, key, ...rest }) => (
    <EntityContextMenu key={key} entity={item}>
      <ListCard
        padding="none"
        selectable={false}
        onClick={() => onOpen?.(item)}
      >
        <CoverImage className={styles.cover} />

        <VStack className={styles.bar}>
          <PersonIcon person={item} />

          <Label className={styles.title}>
            {item.fullName || item.name || "Person"}
          </Label>
        </VStack>
      </ListCard>
    </EntityContextMenu>
  ),
  asListItem: (item, props) => <>Not supported</>,
};
