import axios from "axios";
import { find, some } from "lodash";
import { useEffect } from "react";

import { Contact } from "@api";

import { isCreateOrUpdate } from "@state/store";
import {
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowTrigger,
} from "@state/workflows";

import { omitEmpty } from "@utils/array";
import { log } from "@utils/debug";
import { isEmail } from "@utils/email";
import { when } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { EnvelopeAdd, Magic } from "@ui/icon";
import { showError, withLoading } from "@ui/notifications";

export const addEmail: WorkflowDefinition<Contact> = {
  id: "addEmail",
  trigger: "ACTION",
  type: ["contact"],
  icon: EnvelopeAdd,
  title: "Add email",

  allowed: ({ entity }, context) => !entity.email || !isEmail(entity.email),

  collect: [
    {
      field: "email",
      type: "text",
      // TODO: Add email format
      // format: "email",
    },
  ],

  execute: ({ entity, collected }, { props }) => {
    const email = collected?.[0]?.value.text;

    if (!email) {
      return [];
    }

    return asUpdate(
      entity,
      asMutation({ field: "email", type: "text" }, email)
    );
  },
};

export const enrich: WorkflowDefinition<Contact> = {
  id: "enrich",
  trigger: "ACTION",
  type: ["contact"],
  icon: Magic,
  title: "Enrich",

  allowed: ({ entity }, context) =>
    !!entity.email && isEmail(entity.email) && !entity.websites?.length,

  collect: ({ data: { entity }, onCollected, onCancelled }) => {
    useEffect(() => {
      withLoading(async () => {
        try {
          const { data: profile } = await axios.get<Profile>(
            "https://api.enrich.so/v1/api/person?email=" + entity.email,
            {
              // TODO: Regenerate token and add to env
              headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZmY2ZWU5MGY1M2JlN2ExZTUyYTQ5OCIsInR5cGUiOiJhcGkiLCJpYXQiOjE3MjgwMTYxMDd9.RVEj8X7eS9uasA9DmCB8y0EFB2DYFP1IunT-tDKmaXI`,
              },
            }
          );

          onCollected([
            asMutation({ field: "websites", type: "links" }, [
              { url: profile.linkedInUrl, text: "LinkedIn" },
            ]),

            asMutation(
              { field: "name", type: "text" },
              profile.displayName ||
                omitEmpty([profile.firstName, profile.lastName]).join(" ")
            ),

            asMutation(
              { field: "custom.bio", type: "text" },
              profile.occupation || profile.headline
            ),
            asMutation(
              { field: "custom.position", type: "text" },
              when(
                find(
                  profile.positions.positionHistory,
                  (h) => !!h.title && !!h.companyName
                ),
                (curr) => curr.title + " at " + curr.companyName
              )
            ),
            asMutation({ field: "avatar", type: "text" }, profile.photoUrl),
            asMutation(
              { field: "custom.city", type: "text" },
              profile.location
            ),
          ]);
        } catch (err) {
          showError("Failed to enrich contact.");
          log(err);
          onCancelled();
          throw err;
        }

        return "";
      });
    }, []);

    return <></>;
  },
  execute: ({ entity, collected }, { props }) => {
    return asUpdate(entity, collected);
  },
};

export const nameAsEmail: WorkflowTrigger<Contact> = {
  id: "nameAsEmail",
  trigger: "WILL_UPDATE",
  type: ["contact"],

  allowed: ({ entity, update }, context) =>
    !entity.email &&
    isCreateOrUpdate(update) &&
    !some(update.changes, { field: "email" }) &&
    some(
      update.changes,
      (c) => c.type === "text" && when(c.value.text, isEmail)
    ),

  execute: ({ entity }, { props }) => {
    return asUpdate(
      entity,
      asMutation({ field: "email", type: "text" }, entity.name)
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Contact> = {
  triggers: [nameAsEmail],
  suggestions: [],
  actions: [enrich, addEmail],
};

export default definitions;

type Profile = {
  displayName: string;
  firstName: string;
  lastName: string;
  headline: string;
  occupation: string;
  summary: string;
  location: string;
  photoUrl: string;
  linkedInUrl: string;
  connectionCount: number;
  isConnectionCountObfuscated: boolean;
  skills: string[];
  locale: {
    country: string;
    language: string;
  };
  schools: {
    educationsCount: number;
    educationHistory: {
      school: {
        schoolName: string;
        schoolLocation: string | null;
        schoolLogo: string;
        linkedInUrl: string | null;
      };
      degreeName: string | null;
      startEndDate: {
        start: {
          year: number;
        };
        end: {
          year: number | null;
        };
      };
      fieldOfStudy: string | null;
    }[];
  };
  positions: {
    positionsCount: number;
    positionHistory: {
      title: string;
      companyName: string;
      companyLocation: string | null;
      companyLogo: string;
      linkedInUrl: string | null;
      startEndDate: {
        start: {
          month: number;
          year: number;
        };
        end: {
          month?: number | null;
          year?: number | null;
        } | null;
      };
    }[];
  };
  skillEndorsements: any | null;
  userGeneratedContents: Record<string, unknown>;
  people_also_viewed: string[];
  recommendations: string[];
  languages: string[];
  accomplishment_organisations: string[];
  accomplishment_publications: string[];
  accomplishment_honors_awards: string[];
  accomplishment_patents: string[];
  accomplishment_courses: string[];
  accomplishment_projects: string[];
  accomplishment_test_scores: string[];
  volunteer_work: string[];
  certifications: string[];
  groups: string[];
  version: number;
};
