import { JsonObject, View } from "@api";

import { getViewTemplate } from "@state/templates";
import {
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowTrigger,
} from "@state/workflows";

import { stringify } from "@utils/filtering";
import { asLocal, newHumanId } from "@utils/id";
import { maybeMap, when } from "@utils/maybe";
import { asMutation, asUpdate, toMutation } from "@utils/property-mutations";
import { getPropertyValue, isEmptyValue } from "@utils/property-refs";

import { Download } from "@ui/icon";

import { fromTemplateViewId, isTemplateViewId } from "./utils";

const lazyCreateTmpViewsOnUpdateAttempt: WorkflowTrigger<View> = {
  id: "lazyCreateTmpViewsOnUpdateAttempt",
  trigger: "WILL_UPDATE",
  type: "view",
  allowed: ({ entity, update }, { props }) =>
    // When trying to update a template view but not in temp mode
    update.mode !== "temp" &&
    update.method === "update" &&
    !!when(entity?.id, isTemplateViewId),

  execute: ({ entity, update }, { props, stores }) => {
    // Turn all props into mutations, filtering out empty changes
    const changes = maybeMap(props, (p) => {
      const val = getPropertyValue(entity, p);
      const value = p.field === "alias" ? entity.id : val?.[p.type];
      return !isEmptyValue(value) ? toMutation(entity, p, value) : undefined;
    });

    return changes?.length
      ? [
          {
            id: asLocal(newHumanId("view")),
            method: "create",
            source: entity.source,
            changes: changes,
          },
        ]
      : [];
  },
};

// Assign task to somewone
export const updateFromTemplate: WorkflowDefinition<View> = {
  id: "updateFromTemplate",
  trigger: "ACTION",
  type: "view",
  icon: Download,
  title: "Update from template",

  allowed: ({ entity }) => {
    if (!entity.alias) {
      return false;
    }
    const { key } = fromTemplateViewId(entity.alias);
    const template = getViewTemplate(key);

    if (!template) {
      return false;
    }

    return stringify(entity.filter) !== stringify(template.filter);
  },

  execute: ({ entity }, context) => {
    if (!entity.alias) {
      return [];
    }

    const { key } = fromTemplateViewId(entity.alias);
    const template = getViewTemplate(key);

    if (!template) {
      return [];
    }

    return [
      asUpdate(
        entity,
        asMutation(
          { field: "filter", type: "json" },
          template?.filter as JsonObject
        )
      ),
    ];
  },
};

export const definitions: WorkflowDefinitionConfig<View> = {
  triggers: [lazyCreateTmpViewsOnUpdateAttempt],
  actions: [updateFromTemplate],
  suggestions: [],
};

export default definitions;
