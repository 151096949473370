import { DatabaseID, Resource, ResourceType } from "@api";

import { now } from "@utils/date-fp";
import { newLocalHumanId } from "@utils/id";
import { toLocation } from "@utils/scope";

// Get default value from local storage effect
export const newResource = (
  n: { source: DatabaseID } & Partial<Resource>
): Resource => ({
  id: newLocalHumanId("resource"),
  type: ResourceType.Link,
  name: undefined,
  url: undefined,
  icon: undefined,
  mimeType: undefined,
  pinned: false,
  refs: {
    refs: [],
    seenBy: [],
    followers: [],
  },
  custom: {},
  stamps: {},
  location: toLocation(n.source.scope),

  ...n,

  createdAt: now(),
  createdBy: undefined,
  updatedAt: now(),
  updatedBy: undefined,
  fetchedAt: undefined,
  deletedAt: undefined,
  deletedBy: undefined,
});
