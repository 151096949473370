import { filter, isFunction, isString, reduce, sample } from "lodash";

import { addInfo } from "./debug";

// Remove circular dep from ./fn
type Fn<T, R> = (t: T) => R;

export type WithRequired<T, K extends keyof T> = Required<Pick<T, K>> &
  Partial<T>;

export * from "./types";

export type Maybe<T> = T | undefined;

export type Nullable<T> = T | undefined | null;

export type Some<T> = Exclude<T, undefined | null>;

// Make the type have Maybe values
export type Optional<T> = { [P in keyof T]?: Maybe<T[P]> };

export const cast = <T>(thing: any) => thing as T;

export function whenNotEmpty<T, R>(arr: Maybe<T[]>): Maybe<T[]>;
export function whenNotEmpty<T, R>(arr: Maybe<T[]>, fn: Fn<T[], R>): Maybe<R>;
export function whenNotEmpty<T, R>(arr: Maybe<T[]>, fn?: Fn<T[], R>): Maybe<R> {
  if (!!arr && !!arr.length) {
    return fn ? fn(arr) : (arr as R);
  }
  return undefined;
}

export const when = <T, R>(
  thing: T | undefined | null,
  doWork: Fn<T, R>
): Maybe<R> => {
  if (thing !== undefined && thing !== null) {
    return doWork(thing);
  }
  return undefined;
};

export const when_ =
  <T, R>(doWork: Fn<T, R>) =>
  (thing: T | undefined | null): Maybe<R> => {
    if (thing !== undefined && thing !== null) {
      return doWork(thing);
    }
    return undefined;
  };

export const whenTruthy = <T, R>(
  thing: T,
  doWork: Fn<Exclude<T, false | undefined | null>, R>
): Maybe<R> => {
  if (thing) {
    return doWork(thing as Exclude<T, false | undefined | null>);
  }
  return undefined;
};

// Tricks typescript by always requiring an array with at least one thing in it
export function sampleOne<T>([t, ..._ts]: T[]): T {
  return sample(arguments[0]) || t;
}

// Tricks typescript by always requiring an array with at least one thing in it
export function firstOne<T>([t, ..._ts]: T[]): T {
  return t;
}

export const isDefined = <T>(v: Nullable<T>): v is T =>
  v !== undefined && v !== null;

export const maybe = <T>(t: Nullable<T>): Maybe<T> => t ?? undefined;

export const maybeMap = <T, R>(
  items: T[] = [],
  map: (t: T, i: number) => Nullable<R>
): R[] =>
  reduce(
    items,
    (agg, t, i) => {
      const r = map(t, i);
      if (isDefined(r)) {
        return [...agg, r];
      }
      return agg;
    },
    [] as R[]
  );

export const whenString = <T, R>(v: T | string, fn: Fn<string, R>) =>
  isString(v) ? fn(v) : undefined;

export const defs = <T>(
  t: Nullable<T>,
  error?: string | Fn<void, string>
): T => {
  if (!isDefined(t)) {
    addInfo({ t });
    throw new Error(
      error ? (isFunction(error) ? error() : error) : "Not found."
    );
  }
  return t;
};

export const ensure = defs;

export const filterDefined = <T>(arr: Maybe<T>[]) => filter(arr, isDefined);

export const required = <T>(v: Nullable<T>, onNull: Fn<void, string>): T => {
  if (!isDefined(v)) {
    throw new Error(onNull());
  }
  return v;
};

export const always = <T>(t: Nullable<T>): T =>
  required(t, () => "This should never get called.");

export const required_ =
  <T>(onErr: Fn<void, string>) =>
  (t: T): T =>
    required(t, onErr);

export const safeAs = <T>(t: any | T): Maybe<T> => t as Maybe<T>;
