import { addDays, addMonths, addWeeks, addYears, startOfDay } from "date-fns";

import { switchEnum } from "./logic";
import { now } from "./now";
import { plural } from "./string";

export interface FormulaParts {
  op: "+" | "-";
  freq: number;
  period: "d" | "w" | "m" | "y";
}

// Utils for formula property types

export const isFormula = (f: string) => f?.startsWith("=");

export const construct = ({ op, freq, period }: FormulaParts) =>
  `=today${op}${freq}${period}`;

export const deconstruct = (f: string): FormulaParts => {
  const [_, func, op, duration] = f.split(/\b/);
  const [__, times, period] = duration?.match(/(\d+)(\w)/) || [];

  if (func !== "today") {
    throw new Error("Invalid formula.");
  }

  return {
    op: op,
    freq: parseInt(times) || 0,
    period: period,
  } as FormulaParts;
};

export const toLabel = (formula: string) => {
  const { op, freq, period } = deconstruct(formula);

  return `${op === "+" ? "Next" : "Last"} ${freq} ${plural(
    switchEnum(period, {
      d: "day",
      w: "week",
      m: "month",
      y: "year",
      else: "period",
    }),
    freq
  )}`;
};

// Formulas are string in the format =today[+-][0-9]
// Only used for relative dates

const TRANSFORMERS: Record<string, (d: Date, p: number) => Date> = {
  d: addDays,
  w: addWeeks,
  m: addMonths,
  y: addYears,
};

export const evaluate = (f: string) => {
  if (!isFormula(f)) {
    throw new Error("Invalid formula.");
  }

  const { period, op, freq } = deconstruct(f);
  const today = startOfDay(now());

  return TRANSFORMERS[period]?.(today, (op === "-" ? -1 : 1) * freq) || today;
};
