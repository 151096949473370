import { filter, map } from "lodash";
import { useCallback, useState } from "react";

import { Link } from "@api";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { LinkIcon, PlusAlt, TrashAlt } from "@ui/icon";
import { LinkDialog } from "@ui/link-dialog";
import { Tooltip } from "@ui/tooltip";

import { HStack } from "./flex";

type Props = {
  links: Maybe<Link[]>;
  onChange?: Fn<Link[], void>;
  editable?: boolean;
};

export const LinkStack = ({
  links,
  onChange,
  editable = !!onChange,
}: Props) => {
  const [creating, setCreating] = useState(false);
  const goTo = useGoTo();

  const handleRemove = useCallback(
    (link: Link) => {
      onChange?.(filter(links, (l) => l.url !== link.url) || []);
    },
    [links, onChange]
  );

  return (
    <HStack gap={0}>
      {creating && (
        <LinkDialog
          title="Add a website"
          link={undefined}
          onCancel={() => setCreating(false)}
          onLinked={(l) => {
            setCreating(false);
            onChange?.([...(links || []), l]);
          }}
        />
      )}
      {map(links, (link) => (
        <Tooltip key={link.url} text={link.text || link.url}>
          <ContextMenu
            actions={
              <ContextItem
                text="Remove"
                icon={TrashAlt}
                onClick={() => handleRemove(link)}
              />
            }
          >
            <Button
              subtle
              icon={<LinkIcon icon={link.icon} url={link.url} />}
              size="small"
              onClick={() => goTo(link.url)}
            />
          </ContextMenu>
        </Tooltip>
      ))}
      {editable && (
        <Tooltip text="Add Link" delay={0}>
          <Button
            subtle
            size="small"
            icon={PlusAlt}
            onClick={() => setCreating(true)}
          />
        </Tooltip>
      )}
    </HStack>
  );
};
