import { upload } from "@vercel/blob/client";
import { useCallback } from "react";

import { WorkspaceConfig } from "@api";

import { useSession } from "@state/workspace";

import { formatShort, formatTime } from "@utils/date";
import { switchEnum } from "@utils/logic";
import { required } from "@utils/maybe";
import { now } from "@utils/now";
import { fromScope, toScope } from "@utils/scope";
import { toPath } from "@utils/url";

// Replaces # with - and removes all non-alphanumeric characters
// Otherwise breaks Vercel blob upload for some reason
const toFileSafe = (name: string) => name?.replaceAll("#", "-");

export const uploadFile = async (
  file: File,
  scope: string,
  session: WorkspaceConfig
) => {
  const name = switchEnum(file.name, {
    "image.png": () =>
      `From clipboard – ${formatShort(now())} at ${formatTime(now())}.png`,
    else: () => file.name,
  });

  const wID = required(session.workspace?.id, () => "Missing workspace id.");

  const newBlob = await upload(
    toScope(wID, ...fromScope(scope?.replace(wID + "/", "")), toFileSafe(name)),
    file,
    {
      access: "public",
      handleUploadUrl: "/api/upload",
      multipart: true,
      contentType: file.type,
      clientPayload: `Bearer ${session.token}`,
    }
  );

  return {
    name: name,
    path: toPath(newBlob.url),
    url: newBlob.url,
    mimeType: newBlob.contentType || file.type,
  };
};

export function useUpload(scope: string) {
  const session = useSession();

  return useCallback(
    async (file: File) => uploadFile(file, scope, session),
    [scope]
  );
}
