import { keys, map } from "lodash";

import { Fn } from "./fn";
import { switchEnum } from "./logic";
import { isDefined, Maybe } from "./maybe";
import { isClient } from "./ssr";

enum Env {
  Local = "local",
  Test = "test",
  Dev = "dev",
  Prod = "prod",
}

// All envs must be configured here so that next includes them
// https://nextjs.org/docs/basic-features/environment-variables
const CLIENT_VARS: Record<string, Maybe<string>> = {
  // SS & Client
  INFRA_ENV: process.env.INFRA_ENV || process.env.NEXT_PUBLIC_INFRA_ENV,
  HOST: process.env.HOST || process.env.NEXT_PUBLIC_HOST,
  DEBUGGING: process.env.DEBUGGING || process.env.NEXT_PUBLIC_DEBUGGING,
  VERSION: process.env.VERSION || process.env.NEXT_PUBLIC_VERSION,
  SUPABASE_URL:
    process.env.SUPABASE_URL || process.env.NEXT_PUBLIC_SUPABASE_URL,
  SUPABASE_KEY:
    process.env.SUPABASE_KEY || process.env.NEXT_PUBLIC_SUPABASE_KEY,
  GOOGLE_CLIENT_ID:
    process.env.GOOGLE_CLIENT_ID || process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,

  // Server-Side only
  NOTION_USERNAME: process.env.NOTION_USERNAME,
  NOTION_SECRET: process.env.NOTION_SECRET,
  BLOB_STORE_READ_WRITE_TOKEN: process.env.BLOB_STORE_READ_WRITE_TOKEN,
  BLOB_STORE_KEY: process.env.BLOB_STORE_KEY,
  SESSION_KEY_PRIVATE: process.env.SESSION_KEY_PRIVATE,
  SESSION_KEY_PUBLIC: process.env.SESSION_KEY_PUBLIC,
};

map(keys(CLIENT_VARS), (k) => {
  process.env[k] = CLIENT_VARS[k];
});

export const isDev = () =>
  getEnv() !== Env.Prod ||
  (isClient() && window.location.href?.includes("localhost:"));

export const getEnv = (): Env =>
  switchEnum(
    (process.env.INFRA_ENV || process.env.NEXT_PUBLIC_INFRA_ENV) as string,
    {
      test: Env.Test,
      prod: Env.Prod,
      production: Env.Prod,
      dev: Env.Dev,
      devevelopment: Env.Dev,
      local: Env.Local,
      else: Env.Dev,
    }
  );

export function getEnvVar(name: string): string;
export function getEnvVar(
  name: string,
  allowMissing: boolean = false
): Maybe<string> {
  if (isClient() && !isDefined(CLIENT_VARS[name])) {
    // If you want to use dynamic env vars on the client, you must prefix them with NEXT_PUBLIC_
    // and add them to the CLIENT_VARS object above.
    throw new Error(
      "Cannot dynamically access env vars on client, as NEXT_PUBLIC_* vars are bundled in at build time."
    );
  }

  const value =
    process.env[name] ??
    CLIENT_VARS[name] ??
    process.env["NEXT_PUBLIC_" + name] ??
    CLIENT_VARS["NEXT_PUBLIC_" + name];

  if (allowMissing && !isDefined(value)) {
    throw new Error(`Environment variable '${name}' missing.`);
  }

  return value;
}

export const envOption = <T>(options: { [key in Env]?: T } & { dev: T }): T =>
  options[getEnv()] || options["dev"];

export const inLocal = (fn: Fn<void, void>) => {
  if (getEnv() === Env.Local) {
    return fn();
  }
};
