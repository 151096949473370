import * as RadixTooltip from "@radix-ui/react-tooltip";
import { isString } from "lodash";
import { ReactNode } from "react";

import { TextSmall } from "@ui/text";

import styles from "./tooltip.module.css";

export interface Props {
  text: string | ReactNode;
  children: ReactNode;
  delay?: number;
  disabled?: boolean;
  hoverIn?: boolean;
  side?: "top" | "right" | "bottom" | "left";
}

export const Tooltip = ({
  delay,
  disabled,
  side,
  text,
  children,
  hoverIn = true,
}: Props) => (
  <RadixTooltip.Root delayDuration={delay} disableHoverableContent={!hoverIn}>
    <RadixTooltip.Trigger asChild>
      <span className={styles.refOnly}>{children}</span>
    </RadixTooltip.Trigger>
    {!disabled && (
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          className={styles.tooltip}
          sideOffset={2}
          side={side}
        >
          {isString(text) ? <TextSmall bold>{text}</TextSmall> : text}
          {/* <RadixTooltip.Arrow className={styles.arrow} /> */}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    )}
  </RadixTooltip.Root>
);
