import { atomFamily } from "recoil";

import { ID, Roadmap } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type RoadmapStoreState = StoreState<Roadmap>;

export const WorkspaceRoadmapStoreAtom = atomFamily<RoadmapStoreState, ID>({
  key: "WorkspaceRoadmapStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "roadmap")],
});

export const RoadmapStoreAtom = WorkspaceWrappedAtom(
  "RoadmapStoreAtom",
  WorkspaceRoadmapStoreAtom
);
