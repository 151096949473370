import { find } from "lodash";

import { Entity, HasRefs, Person } from "@api";

import { safeAs } from "./maybe";

export const isSeen = <T extends Entity>(thing?: T, me?: Person) =>
  !!me && !!find(safeAs<HasRefs>(thing)?.refs?.seenBy, { id: me?.id });

export const isUnseen = <T extends HasRefs>(thing?: T, me?: Person) =>
  !isSeen(thing, me);
