import { markdownToBlocks } from "@tryfabric/martian";
import { has, last, map } from "lodash";

import {
  Block,
  BlockReference,
  FlattenType,
  ID,
  RichMarkup,
  SelectOption,
} from "@api";
import {
  Page,
  PageOrDatabase,
  RichText,
  RichTextInput,
} from "@api/integrations/notion";

import { Maybe, when } from "@utils/maybe";

import { isUrl, toPath } from "./url";

export function richTextAsPlainText(
  richText: string | RichText | undefined
): string {
  if (!richText) {
    return "";
  }

  if (typeof richText === "string") {
    return richText;
  }

  return map(
    richText as Maybe<RichText[]>,
    (token: Exclude<RichText, Array<any>>) => token.plain_text
  ).join("");
}

export const getPageTitle = (page: Page) => {
  const title = Object.values(page.properties).find(
    (prop) => prop.type === "title"
  );
  if (!title || title.type !== "title") {
    throw new Error(`Page does not have title property: ${page.id}`);
  }
  return title.title;
};

export const maybeDate = (d: Maybe<string>) => (d ? new Date(d) : undefined);
export const parseDate = (d: string) => new Date(d);

export const toDirection = (dir: "asc" | "desc") =>
  dir === "asc" ? "ascending" : "descending";

export const toPlainText = richTextAsPlainText;

export const toTitle = (p: PageOrDatabase) => {
  if (p.object === "database") {
    return toPlainText(p.title);
  }
  return toPlainText(getPageTitle(p));
};

export const toIcon = (p: PageOrDatabase) => {
  switch (p?.icon?.type) {
    case "emoji":
      return p.icon?.emoji;
    case "external":
      return when(p?.icon?.external.url, (url) =>
        url?.startsWith("/") ? `https://notion.so${url}` : url
      );
    case "file":
      return p?.icon?.file.url;
    default:
      return undefined;
  }
};

export const toRichTextInput = (s: string): RichTextInput => [
  {
    type: "text",
    text: { content: s },
  },
];

export function getProperty(
  page: Page,
  { name, id }: { name: string; id?: string; type: keyof Page["properties"] }
): any {
  const property = page.properties[name];
  if (property && id ? id === property.id : true) {
    return property;
  }

  if (id) {
    return Object.values(page.properties).find(
      (property) => property.id === id
    );
  }

  return undefined;
}

/**
 * Get the value of property `propertyPointer` in `page`.
 * @returns The value of the property, or `undefined` if the property isn't found, or has a different type.
 * @category Property
 */
export function getPropertyValue<P, T>(
  page: Page,
  propertyPointer: {
    id?: string;
    name: string;
    type: keyof Page["properties"];
  },
  transform?: (propertyValue: P) => T
): T | undefined {
  const property = getProperty(page, propertyPointer);
  if (property && property.type === propertyPointer.type) {
    const propertyValue = (property as any)[propertyPointer.type];
    if (propertyValue !== undefined) {
      return transform ? transform(propertyValue) : propertyValue;
    }
  }
}

export const getDateProperty = (item: Page, prop: string) =>
  getPropertyValue<
    Extract<Page["properties"][string], { type: "date" }>["date"],
    Maybe<Date>
  >(item, { name: prop, type: "date" }, (d) => maybeDate(d?.start));

export const getTextProperty = (item: Page, prop: string) =>
  getPropertyValue<
    Extract<Page["properties"][string], { type: "rich_text" }>["rich_text"],
    string
  >(item, { name: prop, type: "rich_text" }, toPlainText);

export const getJSONProperty = <T>(item: Page, prop: string) =>
  getPropertyValue<
    Extract<Page["properties"][string], { type: "rich_text" }>["rich_text"],
    Maybe<T>
  >(
    item,
    { name: prop, type: "rich_text" },
    (d) => when(toPlainText(d) || undefined, JSON.parse) as Maybe<T>
  );

export const getSelectProperty = (item: Page, prop: string) =>
  getPropertyValue<
    Extract<Page["properties"][string], { type: "select" }>["select"],
    Maybe<SelectOption>
  >(item, { name: prop, type: "select" }, (v) =>
    when(v, (status) => ({
      color: status.color,
      name: status.name,
    }))
  );

export const isPartial = <F, P, K extends keyof F = keyof F>(
  thing: F | P,
  fullKey: K
): thing is P => !has(thing, fullKey);

export const isFull = <F, P, K extends keyof F = keyof F>(
  thing: F | P,
  fullKey: K
): thing is F => has(thing, fullKey);

export const isBlockRef = (b: FlattenType<RichMarkup>): b is BlockReference =>
  !(b as Block).type;

export const isBlock = (b: FlattenType<RichMarkup>): b is Block =>
  !!(b as Block).type;

export const toNotionUrl = (id: ID) =>
  `https://notion.so/${id.replace(/-/gi, "")}`;

export const fromNotionUrl = (url: string): Maybe<ID> =>
  isUrl(url) ? last(toPath(url)?.match(/([0-9a-f]{32})/gi)) : undefined;

export const toBlocks = (markdown: string) => markdownToBlocks(markdown);
