import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { Node } from "@tiptap/pm/model";
import { Selection } from "@tiptap/pm/state";
import { BubbleMenu, Editor, posToDOMRect } from "@tiptap/react";
import { CellSelection } from "prosemirror-tables";
import { findParentNodeClosestToPos } from "prosemirror-utils";
import { useCallback, useMemo } from "react";

import { cx } from "@utils/class-names";

import { Button } from "@ui/button";
import {
  AlignCenterH,
  ArrowCompressH,
  ColumnAddAfter,
  ColumnAddBefore,
  ColumnRemove,
  RowAddAfter,
  RowAddBefore,
  RowRemove,
  TableHeaderColumn,
  TableHeaderRow,
  TrashAlt,
} from "@ui/icon";
import { Text } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import {
  FormatButton,
  HasEditorProps,
  MenuDivider,
  ShouldShowPredicate,
} from "./menus";
import { isFocused } from "./utils";

import styles from "./menus.module.css";

export function isCellSelection<T>(selection: T | CellSelection) {
  return selection instanceof CellSelection;
}

const findParentTable = (selection: Selection, editor: Editor) => {
  return findParentNodeClosestToPos(
    selection?.$head,
    (node: Node) => node?.type === editor.schema.nodes.table
  );
};

const isHeadersEnabled = (table: Node, editor: Editor) => {
  const secondRowCell = table?.maybeChild(1)?.maybeChild(0);
  const columnHeadersEnabled =
    secondRowCell?.type === editor.schema?.nodes?.tableHeader;

  const firstRowSecondCell =
    table?.maybeChild(0)?.maybeChild(1) || table?.maybeChild(0)?.maybeChild(0);
  const rowHeadersEnabled =
    firstRowSecondCell?.type === editor.schema?.nodes?.tableHeader;

  return { rows: rowHeadersEnabled, columns: columnHeadersEnabled };
};

const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      // extend the existing attributes …
      ...this.parent?.(),

      // and add a new one …
      backgroundColor: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
});

const TableOptionsMenu = ({ editor }: HasEditorProps) => {
  const shouldShow = useCallback<ShouldShowPredicate>(
    ({ editor, view, state, oldState }) => {
      const { selection } = state;

      return (
        !!isFocused(editor) &&
        // Not table cell is selected
        !isCellSelection(selection) &&
        // Cursor is inside table but not selecting text
        !!selection.empty &&
        (editor.isActive("table") ||
          editor.isActive("tableCell") ||
          editor.isActive("tableHeader") ||
          editor.isActive("tableRow"))
      );
    },
    [editor]
  );

  const settings = useMemo(() => {
    const selection = editor.view.state?.selection;

    if (!selection) {
      return {};
    }

    const table = findParentTable(selection, editor);
    const enabled = table?.node && isHeadersEnabled(table?.node, editor);

    return {
      headers: enabled,
    };
  }, [editor, editor.view.state.selection]);

  if (!editor) {
    return null;
  }

  return (
    <BubbleMenu
      editor={editor}
      className={cx(styles.bubbleMenu)}
      tippyOptions={{
        placement: "top-end",
        maxWidth: "100%",
        // offset: [-10, -20],
        getReferenceClientRect: () => {
          const table = findParentTable(editor.view.state.selection, editor);
          return posToDOMRect(
            editor.view,
            table?.start || 0,
            table?.start || 0
          );
        },
      }}
      shouldShow={shouldShow}
    >
      {editor.can().splitCell() && (
        <>
          <FormatButton
            onClick={() => editor.chain().focus().splitCell().run()}
            icon={AlignCenterH}
          >
            Split cell
          </FormatButton>

          <MenuDivider />
        </>
      )}

      <Button subtle size="small" disabled>
        <Text subtle>Row</Text>
      </Button>

      {editor.can().toggleHeaderRow() && (
        <FormatButton
          onClick={() => editor.chain().focus().toggleHeaderRow().run()}
          icon={TableHeaderRow}
          active={settings.headers?.rows}
        />
      )}

      <Tooltip text="Add row before">
        <FormatButton
          onClick={() => editor.chain().focus().addRowBefore().run()}
          disabled={!editor.can().addRowBefore()}
          icon={RowAddBefore}
        />
      </Tooltip>
      <Tooltip text="Add row after">
        <FormatButton
          onClick={() => editor.chain().focus().addRowAfter().run()}
          disabled={!editor.can().addRowAfter()}
          icon={RowAddAfter}
        />
      </Tooltip>

      <Tooltip text="Delete row">
        <FormatButton
          onClick={() => editor.chain().focus().deleteRow().run()}
          disabled={!editor.can().deleteRow()}
          icon={RowRemove}
        />
      </Tooltip>

      <MenuDivider />

      <Button subtle size="small" disabled>
        <Text subtle>Column</Text>
      </Button>

      {editor.can().toggleHeaderColumn() && (
        <FormatButton
          onClick={() => editor.chain().focus().toggleHeaderColumn().run()}
          icon={TableHeaderColumn}
          active={settings.headers?.columns}
        />
      )}

      <Tooltip text="Add column before">
        <FormatButton
          onClick={() => editor.chain().focus().addColumnBefore().run()}
          disabled={!editor.can().addColumnBefore()}
          icon={ColumnAddBefore}
        />
      </Tooltip>

      <Tooltip text="Add column after">
        <FormatButton
          onClick={() => editor.chain().focus().addColumnAfter().run()}
          disabled={!editor.can().addColumnAfter()}
          icon={ColumnAddAfter}
        />
      </Tooltip>

      <Tooltip text="Delete column">
        <FormatButton
          onClick={() => editor.chain().focus().deleteColumn().run()}
          disabled={!editor.can().deleteColumn()}
          icon={ColumnRemove}
        />
      </Tooltip>

      <MenuDivider />

      <FormatButton
        onClick={() => editor.chain().focus().deleteTable().run()}
        disabled={!editor.can().deleteTable()}
        icon={TrashAlt}
      >
        Remove table
      </FormatButton>
    </BubbleMenu>
  );
};

const TableCellMenu = ({ editor }: HasEditorProps) => {
  const shouldShow = useCallback<ShouldShowPredicate>(
    ({ editor, view, state, oldState }) => {
      return isCellSelection(state.selection);
    },
    [editor]
  );

  if (!editor) {
    return null;
  }

  // TODO: Convert to a floating menu
  // with shouldShow

  return (
    <BubbleMenu
      editor={editor}
      className={cx(styles.bubbleMenu)}
      tippyOptions={{
        placement: "top-start",
        maxWidth: "100%",
        getReferenceClientRect: () => {
          const table = findParentTable(editor.view.state.selection, editor);
          return posToDOMRect(
            editor.view,
            table?.start || 0,
            table?.start || 0
          );
        },
      }}
      shouldShow={shouldShow}
    >
      <FormatButton
        onClick={() => editor.chain().focus().mergeCells().run()}
        disabled={!editor.can().mergeCells()}
        icon={ArrowCompressH}
      >
        Merge cells
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().splitCell().run()}
        disabled={!editor.can().splitCell()}
        icon={AlignCenterH}
      >
        Split cell
      </FormatButton>

      <FormatButton
        onClick={() => editor.chain().focus().deleteColumn().run()}
        disabled={!editor.can().deleteColumn()}
        icon={TrashAlt}
      >
        Delete column
      </FormatButton>
      <FormatButton
        onClick={() => editor.chain().focus().deleteRow().run()}
        disabled={!editor.can().deleteRow()}
        icon={TrashAlt}
      >
        Delete row
      </FormatButton>
    </BubbleMenu>
  );
};

export const TableMenuBar = ({ editor }: HasEditorProps) => {
  return (
    <>
      <TableOptionsMenu editor={editor} />
      <TableCellMenu editor={editor} />
    </>
  );
};

export const extensions = () => [
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  CustomTableCell,
];
