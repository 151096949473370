import { Maybe } from "@utils/maybe";

import { QuickFilter, ViewQuickFilterState } from "./atoms";

(filter: Maybe<QuickFilter>) => (state: ViewQuickFilterState) => ({
  ...state,
  peaking: filter?.id,
});

export const setQuickSearch =
  (search: Maybe<string>) => (state: ViewQuickFilterState) => ({
    ...state,
    search: search,
  });

export const toggleFilter =
  (filter: QuickFilter) => (state: ViewQuickFilterState) => ({
    ...state,
    selected: state?.selected === filter.id ? undefined : filter.id,
  });

export const selectFilter =
  (filter: QuickFilter) => (state: ViewQuickFilterState) => ({
    ...state,
    selected: filter.id,
  });
