import { useCallback, useState } from "react";

import { Entity, HasResources, Link } from "@api";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { Button, Props as ButtonProps } from "@ui/button";
import { LinkAdd } from "@ui/icon";
import {
  Props as ResourceCreateProps,
  ResourceCreateDialog,
} from "@ui/resource-create-dialog";

type Props = {
  defaultPinned?: boolean;
} & Pick<ResourceCreateProps, "type" | "mode"> &
  Pick<ButtonProps, "children" | "icon"> &
  (
    | { entity: HasResources; onAddLink?: Fn<Link, void> }
    | { entity: Entity; onAddLink: Fn<Link, void> }
  );

export const AddResourcesButton = ({
  entity,
  onAddLink,
  defaultPinned = true,
  type = "all",
  mode = "new-link",
  icon,
  children,
}: Props) => {
  const [collecting, setCollecting] = useState(false);

  const handleAdded = useCallback(
    (o: Maybe<Link>) => {
      o && onAddLink?.({ ...o, pinned: defaultPinned });
      setCollecting(false);
    },
    [onAddLink]
  );

  return (
    <>
      {collecting && (
        <ResourceCreateDialog
          entity={entity}
          link={undefined}
          type={type}
          mode={mode}
          onChanged={handleAdded}
          onCancel={() => setCollecting(false)}
        />
      )}

      <Button
        size="small"
        subtle
        icon={icon !== false ? icon || LinkAdd : undefined}
        onClick={() => setCollecting(true)}
      >
        {children || "Add a resource"}
      </Button>
    </>
  );
};
