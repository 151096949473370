import { filter, map, orderBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { GroupBase } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

import {
  DatabaseID,
  Entity,
  PropertyDef,
  PropertyRef,
  PropertyType,
} from "@api/types";

import { useLazyProperties } from "@state/databases";

import { maybeLookup } from "@utils/array";
import { cx } from "@utils/class-names";
import { useAsyncEffect } from "@utils/effects";
import { withHardHandle } from "@utils/event";
import { composel, Fn, isFunc } from "@utils/fn";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { pick_ } from "@utils/object";
import { LazyLatest } from "@utils/promise";
import { isVisibleProp, toFieldName } from "@utils/property-refs";
import { ComponentOrNode } from "@utils/react";

import { Button } from "@ui/button";
import { HStack } from "@ui/flex";
import { CheckIcon, SortAsc, SortDesc } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { PropertyDefLabel } from "@ui/property-def-label";
import { PropertyTypeIcon } from "@ui/property-type-icon";
import { MultiProps,MultiSelect } from "@ui/select";
import { PlaceholderText } from "@ui/text";

import { ManageFieldsFooter } from "./footers";

import styles from "./property.module.css";

type SortablePropRef = PropertyRef & {
  direction?: "asc" | "desc";
};

type PropSelectOption = {
  id: string;
  name: string;
  ref: SortablePropRef;
  def?: PropertyDef<Entity, PropertyType>;
  direction?: "asc" | "desc";
  onSortChanged?: Fn<"asc" | "desc", void>;
};

export type Props = {
  subtitle?: string;
  options?:
    | PropertyDef<Entity, PropertyType>[]
    | Fn<void, LazyLatest<PropertyDef<Entity, PropertyType>[]>>;
  value?: SortablePropRef[];
  onChanged?: Fn<Maybe<SortablePropRef[]>, void>;
  emptyOption?: boolean;
  portal?: boolean;
  icon?: ComponentOrNode;
  className?: string;
} & Omit<
  MultiProps<PropSelectOption>,
  "value" | "options" | "className" | "onChange" | "onCreate"
>;

const toOption = (
  ref: SortablePropRef,
  def?: PropertyDef,
  onSortChanged?: PropSelectOption["onSortChanged"]
) => ({
  id: ref.field,
  name: def?.label || toFieldName(def || ref) || ref.field,
  ref: ref,
  def: def,
  direction: ref.direction,
  onSortChanged: ref.direction ? onSortChanged : undefined,
});

export function PropertiesSelect({
  options: getOptions,
  placeholder,
  subtitle,
  value,
  icon,
  emptyOption,
  className,
  onChanged,
  createable,
  children,
  ...rest
}: Props) {
  const [options, setOptions] = useState<
    Maybe<PropertyDef<Entity, PropertyType>[]>
  >([]);
  const getProp = useMemo(
    () => maybeLookup(options || [], (p) => p.field),
    [options]
  );
  const selected = useMemo(
    () =>
      map(value, (s) =>
        toOption(s, getProp(s.field), (sort) => {
          if (!onChanged) {
            return;
          }

          onChanged(
            maybeMap(value, (v) =>
              v.field === s.field ? { ...v, direction: sort } : v
            )
          );
        })
      ),
    [value, getProp]
  );

  useAsyncEffect(async () => {
    if (isFunc(getOptions)) {
      const { current, latest } = getOptions();
      setOptions(current);
      setOptions(await latest());
    } else {
      setOptions(getOptions);
    }
  }, [getOptions]);

  const menuOptions: PropSelectOption[] = useMemo(() => {
    const getSelected = maybeLookup(selected, (s) => s.id);
    return map(options, (o) => getSelected(o.field) || toOption(o, o));
  }, [selected, options]);

  const handleChanged = useCallback(
    (items: Maybe<PropSelectOption[]>) => {
      const getDef = maybeLookup(menuOptions || [], (p) => p.ref.field);
      const newItems = maybeMap(items, (i) =>
        when(
          (getDef(i.id)?.def || i?.def || i.ref) as SortablePropRef,
          pick_("field", "type", "direction")
        )
      );
      onChanged?.(newItems);
    },
    [menuOptions, onChanged]
  );

  return (
    <MultiSelect
      {...rest}
      placeholder={placeholder}
      className={{ popover: styles.select }}
      options={menuOptions}
      value={selected}
      closeOnBlur={false}
      closeOnSelect={false}
      onChange={handleChanged}
      overrides={MULTI_OVERRIDES}
    >
      {children ?? (
        <div className={cx(styles.buttonContainer, className)}>
          <Button subtle size="small" className={styles.button}>
            <HStack>
              {selected.length > 1 &&
                when(selected[0]?.def, (def) => (
                  <>
                    <PropertyDefLabel prop={def} />
                    <Label>+ {selected.length - 1} other fields</Label>
                  </>
                ))}
              {selected.length === 1 &&
                when(selected[0]?.def, (def) => (
                  <PropertyDefLabel prop={def} />
                ))}
              {!selected.length && (
                <PlaceholderText>
                  {placeholder ?? "No fields..."}
                </PlaceholderText>
              )}
            </HStack>
          </Button>
        </div>
      )}
    </MultiSelect>
  );
}

export const ScopedPropertiesSelect = ({
  value,
  source,
  blacklist,
  ...rest
}: Omit<Props, "options" | "value"> & {
  value?: SortablePropRef[];
  source: DatabaseID;
  blacklist?: string[];
}) => {
  const props = useLazyProperties(source);

  const available = useMemo(
    () =>
      composel(
        (ts) =>
          filter(
            ts,
            (p: PropertyDef<Entity>) =>
              (isVisibleProp(p) || p.field === "location") &&
              !blacklist?.includes(p.field)
          ),
        (ts) =>
          orderBy(
            ts, // Preferred group by field types, sorts left to right here
            (p) =>
              `${[
                "status",
                "multi_select",
                "select",
                "date",
                "checklist",
                "relation",
                "relations",
              ].indexOf(p.type)}.${p.order}`,
            "asc"
          )
      )(props),
    [props]
  );

  return (
    <PropertiesSelect
      {...rest}
      options={available}
      value={value}
      footer={<ManageFieldsFooter {...source} />}
    />
  );
};

export default PropertiesSelect;

const MULTI_OVERRIDES: Partial<
  SelectComponents<PropSelectOption, true, GroupBase<PropSelectOption>>
> = {
  Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => (
    <div ref={innerRef} {...innerProps} key={data.id}>
      <MenuItem
        className={cx(styles.menuItem, isFocused && styles.focused)}
        icon={<CheckIcon checked={isSelected} />}
      >
        <PropertyDefLabel
          prop={(data.def || data.ref) as PropertyDef}
          icon={false}
          iconRight={when(data?.def?.type, (t) => (
            <PropertyTypeIcon type={t} />
          ))}
          actions={
            !!data.onSortChanged &&
            !!data.direction && (
              <Button
                size="tiny"
                subtle
                onClick={withHardHandle(() =>
                  data.onSortChanged?.(
                    data.direction === "asc" ? "desc" : "asc"
                  )
                )}
                icon={data.direction === "asc" ? SortAsc : SortDesc}
              />
            )
          }
        />
      </MenuItem>
    </div>
  ),
  MultiValueLabel: ({ innerProps, data }) => (
    <PropertyDefLabel key={data.id} prop={data.ref} {...innerProps} />
  ),
};
