import { findIndex, first, map } from "lodash";
import { useCallback } from "react";

import { Agenda, Meeting } from "@api";

import { useQueueUpdates } from "@state/generic";

import { maybeFindIndex, move } from "@utils/array";
import { DragRef, DragToRef } from "@utils/drag-drop";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { OnReorder } from "@ui/entity-drag-drop";

export const useReorderAgendas = (
  _meeting: Meeting,
  agendas: Agenda[]
): OnReorder<Agenda> => {
  const mutate = useQueueUpdates();

  return useCallback(
    (ref: DragRef<Agenda>[], to: DragToRef<Agenda>) => {
      const dragging = first(ref)?.entity;
      const reordered = move(
        agendas,
        findIndex(agendas, (a) => a.id === dragging),
        maybeFindIndex(agendas, (a) => a.id === to.entity) ?? agendas.length
      );

      mutate(
        map(reordered, (a, i) =>
          asUpdate(a, asMutation({ field: "order", type: "number" }, i + 1))
        )
      );
    },
    [agendas]
  );
};
