import { first, groupBy } from "lodash";
import { useCallback, useMemo } from "react";

import { HasAssigned, HasStatus, ID, Ref } from "@api";

import { useLazyPropertyDef } from "@state/databases";
import { useLazyEntity, useUpdateEntity } from "@state/generic";
import { useLazyItemsForView, useLazyViewsForParent } from "@state/views";

import { respectHandled } from "@utils/event";
import { useShowMore } from "@utils/hooks";
import { Maybe } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { toStatusPercents } from "@utils/status";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { ClickableSection } from "@ui/clickable-section";
import { HStack, ThirdSpace, VStack } from "@ui/flex";
import { CounterIcon, Icon } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import ProgressBar from "@ui/progress-bar";
import { RelationIcon } from "@ui/relation-label";
import { PersonMultiSelect } from "@ui/select";
import { Text,TextMedium } from "@ui/text";

interface PersonTasksCardsProps {
  person: Ref;
  items: HasStatus[];
  onClick?: (person: Ref, source: "person" | "task" | "outcome") => void;
}

export function PersonTasksCard({
  person: _person,
  items,
  onClick,
}: PersonTasksCardsProps) {
  const person = useLazyEntity<"person">(_person.id, false);
  const statusProp = useLazyPropertyDef(items?.[0]?.source, {
    field: "status",
    type: "status",
  });
  const percents = useMemo(
    () => toStatusPercents(items, statusProp),
    [items, statusProp]
  );

  return (
    <Card onClick={respectHandled(() => onClick?.(_person, "task"))}>
      <VStack fit="container">
        <HStack>
          <Icon size="medium" icon={<RelationIcon relation={person} />} />
          <TextMedium>{person?.name}</TextMedium>
        </HStack>
        <ProgressBar
          label={[`${items?.length || 0} tasks`]}
          showPercents={false}
          percent={percents}
        />
      </VStack>
    </Card>
  );
}

interface SprintPeopleCardsProps {
  sprintId: ID;
}

export function SprintPeopleCards({ sprintId }: SprintPeopleCardsProps) {
  const pushTo = usePushTo();
  const sprint = useLazyEntity<"sprint">(sprintId);
  const views = useLazyViewsForParent(sprintId, "task");
  const items = useLazyItemsForView(first(views)?.id || "");
  const mutate = useUpdateEntity(sprintId);
  const grouped = useMemo(() => {
    return groupBy(
      items.items.all as Maybe<HasAssigned[]>,
      (t) => t?.assigned?.id
    );
  }, [items]);

  const {
    visible: people,
    hasMore,
    limit,
    showMore,
  } = useShowMore(sprint?.people || [], 6);

  const handleClicked = useCallback((person: Ref, source: string) => {
    const view = first(views);
    if (view && source === "task") {
      pushTo(view, { expanded: person.id });
    }
  }, []);

  return (
    <ClickableSection
      title="People"
      labelSize="medium"
      actions={
        <PersonMultiSelect
          value={sprint?.people}
          onChange={(people) => {
            mutate(asMutation({ field: "people", type: "relations" }, people));
          }}
        >
          <Button subtle size="small">
            <Text subtle>Manage</Text>
          </Button>
        </PersonMultiSelect>
      }
    >
      <HStack wrap gap={6} fit="container">
        {people?.map((person) => (
          <ThirdSpace gap={6} key={person.id}>
            <PersonTasksCard
              key={person.id}
              person={person}
              items={grouped[person.id]}
              onClick={handleClicked}
            />
          </ThirdSpace>
        ))}

        {hasMore && (
          <MenuItem
            icon={
              <CounterIcon
                color="subtle"
                count={(sprint?.people?.length || 0) - limit}
              />
            }
            onClick={() => showMore()}
          >
            <Label subtle>Show more</Label>
          </MenuItem>
        )}
      </HStack>
    </ClickableSection>
  );
}
