import { groupBy, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { Entity, HasRefs, ID, Ref } from "@api";

import { useQueueUpdates } from "@state/generic";
import { Note, useLazyGetNotes } from "@state/notes";
import { useMe } from "@state/persons";

import { ensureArray, omitEmpty } from "@utils/array";
import { daysAgo } from "@utils/date";
import { fromPointDate } from "@utils/date-fp";
import { Fn } from "@utils/fn";
import { useShowMore } from "@utils/hooks";
import { switchEnum } from "@utils/logic";
import { Maybe, safeAs, when } from "@utils/maybe";
import { isUnseen } from "@utils/person";
import {
  asAppendMutation,
  asUpdate,
  toUpdate,
} from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { UpdateItem } from "@ui/engine/note";
import { VStack } from "@ui/flex";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { SlideInSheet } from "@ui/sheet-layout";
import { Text } from "@ui/text";

import { UpdateThread } from "./update-thread";

interface Props {
  refs: Maybe<Ref[]>;
  mode?: "important" | "unseen" | "all";
  hideOnEmpty?: boolean;
  onSelected?: Fn<Note, void>;
}

export const UpdatesSection = ({
  refs,
  mode = "important",
  hideOnEmpty = true,
  onSelected,
}: Props) => {
  const pageId = usePageId();
  const me = useMe();
  const notes = useLazyGetNotes(refs);
  const [selected, setSelected] = useState<Maybe<Note>>();
  const update = useQueueUpdates<Note>(pageId);
  const grouped = useMemo(
    () =>
      groupBy(notes, (n) =>
        n.pinned
          ? "pinned"
          : isUnseen(n, me) && daysAgo(fromPointDate(n.createdAt)) < 30
          ? "unseen"
          : "other"
      ),
    [notes]
  );
  const filtered = useMemo(
    () =>
      switchEnum(mode, {
        unseen: () => [
          ...(grouped?.unseen || []),
          ...(when(selected, ensureArray) || []),
        ],
        important: () => [
          ...(grouped?.unseen || []),
          ...(grouped?.pinned || []),
        ],
        all: () => notes,
      }),
    [grouped]
  );
  const showMore = useShowMore(filtered, 3);

  const onTogglePin = useCallback(
    (note: Note) =>
      update(
        toUpdate(note, { field: "pinned", type: "boolean" }, !note.pinned)
      ),
    [update]
  );

  const label = useMemo(() => {
    const parts = omitEmpty([
      when(grouped?.unseen?.length || undefined, (us) => `${us} Unseen`),
      when(grouped?.pinned?.length || undefined, (us) => `${us} Pinned`),
    ]);
    return parts?.length ? `Messages (${parts?.join(" ")})` : "Messages";
  }, [grouped, selected]);

  const handleSelected = useCallback(
    (note: Note) => {
      if (onSelected) {
        onSelected(note);
      } else {
        setSelected(note);
      }
    },
    [onSelected]
  );

  const handleMarkAllSeen = useCallback(
    (notes: Note[]) => {
      update(
        map(notes, (n) =>
          asUpdate(
            n,
            asAppendMutation({ field: "refs.seenBy", type: "relations" }, [
              toRef(me),
            ])
          )
        )
      );
    },
    [update]
  );

  if (hideOnEmpty && !filtered.length) {
    return <></>;
  }

  return (
    <CollapsibleSection
      title={label}
      labelSize="medium"
      actions={
        <Button subtle size="small" onClick={() => handleMarkAllSeen(filtered)}>
          <Text subtle>Mark as seen</Text>
        </Button>
      }
    >
      {selected && (
        <SlideInSheet
          size="secondary"
          height="container"
          visible={true}
          setVisible={(v) => !v && setSelected(undefined)}
        >
          <UpdateThread
            noteId={selected?.id}
            onClose={() => setSelected(undefined)}
          />
        </SlideInSheet>
      )}

      <VStack gap={8} wrap fit="container" align="flex-start">
        {map(showMore.visible, (u) => (
          <UpdateItem
            key={u.id}
            note={u}
            onClick={() => handleSelected(u)}
            showSource={false}
            onTogglePin={() => onTogglePin(u)}
          />
        ))}
        {showMore.hasMore && (
          <ShowMoreMenuItem
            onClick={showMore.showMore}
            count={showMore.moreCount}
          />
        )}
      </VStack>
    </CollapsibleSection>
  );
};

export const EntityUpdatesSection = ({
  id,
  entity,
  ...rest
}: { id: ID; entity: Entity } & Omit<Props, "refs">) => {
  return (
    <UpdatesSection refs={safeAs<HasRefs>(entity)?.refs?.notes} {...rest} />
  );
};
