import {
  differenceInMinutes,
  differenceInSeconds,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
} from "date-fns";
import { useEffect, useMemo, useState } from "react";
import stringToTime from "to-time";

import {
  convertToCalDate,
  convertToPointDate,
  ISODate,
  isPointDate,
  Method,
} from "./date-fp";
import { Maybe, when } from "./maybe";
import { now } from "./now";
import { fixed } from "./number";

export * from "./now";

export type Time = [number, number];
export type ISOTime = string;

// https://github.com/hafuta/to-time
export const toSeconds = (time: string): number => {
  const result = stringToTime(time).seconds();
  if (time && !result) {
    throw new Error(`Invalid ISO860 duration: ${time}`);
  }
  return result;
};

export const toMilliSeconds = (time: string): number => toSeconds(time) * 1000;

export const toMinutes = (seconds: number): number => Math.round(seconds / 60);

export const useTick = (interval: string) => {
  const milli = useMemo(() => toMilliSeconds(interval), [interval]);
  const [tick, setTick] = useState(0);
  useEffect(() => {
    const id = setInterval(() => setTick((tick) => tick + 1), milli);
    return () => clearInterval(id);
  }, []);
  return tick;
};

export const ensureUTC = () => {
  if (process.env.TZ !== "UTC") {
    throw new Error("Should be running in UTC.");
  }
};

const round = (num: number) => {
  const rounded = Math.floor(Math.abs(num));
  return `${rounded < 10 ? "0" : ""}${rounded}`;
};

export const formatOffsetInUTC = (offset: number) =>
  `${offset <= 0 ? "+" : "-"}${round(offset / 60)}${round(offset % 60)}`;

// Formats a timezone offset in the format +hh:mm or -hh:mm
export const formatOffsetInMilitary = (offset: number) =>
  `${offset <= 0 ? "+" : "-"}${round(offset / 60)}:${round(offset % 60)}`;

export const isLessThan = (timeA: Date, timeB: Date) =>
  timeA.getTime() < timeB.getTime();
export const isGreaterThan = (timeA: Date, timeB: Date) =>
  timeA.getTime() > timeB.getTime();

export const inFuture = (timeA: Date) => isGreaterThan(timeA, now());
export const inPast = (timeA: Date) => isLessThan(timeA, now());

export const format = ([hours, mins]: Time) =>
  hours < 12
    ? `${hours}:${fixed(mins)}am`
    : hours === 12
    ? `${12}:${fixed(mins)}pm`
    : `${hours - 12}:${fixed(mins)}pm`;

// Parses a time in the format hh:mm
export const parseTime = (time: ISOTime) =>
  time.split(":").map((n) => parseInt(n, 10)) as [number, number];

export const minutesAgo = (time: Maybe<Date>) =>
  time ? Math.abs(differenceInMinutes(now(), time)) : 0;

export const secondsAgo = (time: Maybe<Date>) =>
  time ? Math.abs(differenceInSeconds(now(), time)) : 0;

const applyPeriod = (hour: string, minute: string, period: string) => {
  let hourN = parseInt(hour);
  let minuteN = parseInt(minute || "0");

  // If the period is set to pm, then we should add 12 hours
  if (period?.startsWith("p") && hourN < 12) {
    hourN += 12;
  }

  // If no period is set, then we should default to the most useful times (8am -> 8pm)
  if (!period && hourN < 7) {
    hourN += 12;
  }

  return [hourN, minuteN];
};

const FUZZY_TIME_REGEX = /(\d{1,2})[:\s]?(\d{1,2})?\s*(a|p|am|pm)?/;

export const fuzzyParseTime = (input: string): Maybe<[number, number]> => {
  // Convert the string to lowercase and trim any extra spaces
  let timeStr = input.trim().toLowerCase();

  // Match patterns that include am/pm
  const match = timeStr.match(FUZZY_TIME_REGEX);

  // If the input doesn't match any of the expected patterns, return null
  if (!match) {
    return undefined;
  }

  const rawHour = match[1];
  const rawMins = match[2];
  const rawPeriod = match[3];

  // First application is just basic reading
  let result = applyPeriod(rawHour, rawMins, rawPeriod);

  // We're too high, try shifting the minute to the hour
  if (result[0] > 23 || rawMins === "0") {
    result = applyPeriod(
      rawHour.slice(0, 1),
      rawHour.slice(1) + rawMins,
      rawPeriod
    );
  }

  // 12:3 should assume it to be 12:30, not 12:03
  if (result[1] < 10 && (rawMins?.length === 1 || !rawMins)) {
    result[1] *= 10;
  }

  return result as [number, number];
};

export function toTime(date: Date): Time;
export function toTime(date: Maybe<Date>): Maybe<Time> {
  return when(date, (d) => [getHours(d), getMinutes(d)]);
}

export const toISOTime = (time: Time): ISOTime =>
  `${time[0].toString().padStart(2, "0")}:${time[1]
    .toString()
    .padStart(2, "0")}`;

export const withTime = (date: Date, time: Maybe<Time>) => {
  let res = new Date(date);
  res = setHours(res, time?.[0] || 0);
  res = setMinutes(res, time?.[1] || 0);
  return res;
};

export const keepTimeDirty = (date: Date, timeDate: Maybe<Date>) => {
  if (!timeDate) {
    return date;
  }

  const updated = withTime(date, toTime(timeDate));
  return updated;
};

const ensurePoint = (date: ISODate, method?: Method) =>
  isPointDate(date) ? date : convertToPointDate(date, method || "utc");

export const keepTime = (date: ISODate, timeDate: Maybe<ISODate>) => {
  if (!timeDate) {
    return date;
  }

  const [datePart] = ensurePoint(date, "utc")?.split("T");
  const [_, timePart] = ensurePoint(timeDate, "utc")?.split("T");

  const result = `${datePart}T${timePart}`;
  return isPointDate(date) ? result : convertToCalDate(result, "utc");
};
