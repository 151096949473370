import { EntityType, Pipeline } from "@api";

import { getSetting } from "@utils/property-refs";

import { EntityEngine } from "../fallback";
import { UIEngine } from "../types";
import { PipelineCreateDialog } from "./create-dialog";

export { PipelineCreateDialog } from "./create-dialog";
export { PipelinePage } from "./page";

export const PipelineEngine: UIEngine<Pipeline> = {
  asMenuItem: EntityEngine.asMenuItem as UIEngine<Pipeline>["asMenuItem"],
  asListCard: EntityEngine.asListCard as UIEngine<Pipeline>["asListCard"],
  asListItem: EntityEngine.asListItem as UIEngine<Pipeline>["asListItem"],
  asCreateDialog: PipelineCreateDialog,

  toViewDefaults: (_id, item) => ({
    group: [item.stageBy || { field: "status", type: "status" }],
    // Only add the sum aggregate if the child type is "deal"
    aggregate:
      getSetting<EntityType>(item?.settings, "child_type") === "deal"
        ? [{ field: "value", type: "number", method: "sum" }]
        : undefined,
  }),
};
