import { atomFamily } from "recoil";

import { ID, Meeting } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type MeetingStoreState = StoreState<Meeting>;

export const WorkspaceMeetingStoreAtom = atomFamily<MeetingStoreState, ID>({
  key: "WorkspaceMeetingStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "meeting")],
});

export const MeetingStoreAtom = WorkspaceWrappedAtom(
  "MeetingStoreAtom",
  WorkspaceMeetingStoreAtom
);
