import { useMemo, useState } from "react";

import { ID, PropertyRef, Task } from "@api";

import { useIsShowing, useLazyPropertyValue } from "@state/databases";

import { justOne, whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { useDebouncedMemo } from "@utils/hooks";
import { toMutation } from "@utils/property-mutations";
import { isEmpty as isEmptyRT } from "@utils/rich-text";
import { isSelected } from "@utils/selectable";

import { CodeLabel } from "@ui/code-label";
import { DueDate } from "@ui/due-date";
import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon, SubtasksIcon } from "@ui/icon";
import { SmartLocationLabel } from "@ui/location-button";
import { CheckListCompletion } from "@ui/property-checklist-section";
import { PropertyValueStack } from "@ui/property-value-stack";
import { BigScreens } from "@ui/responsive";
import { SelectableListItem } from "@ui/selectable-items";
import { SubListButton, SubListItems } from "@ui/sub-list-items";
import { TaskCheck } from "@ui/task-check";
import { Tooltip } from "@ui/tooltip";
import { useViewingWithin } from "@ui/viewing-within";

import { ListItemOpts } from "../types";

import styles from "./list-item.module.css";

const DEFAULT_PROPS: PropertyRef<Task>[] = [
  { field: "assigned", type: "relation" },
];

export function TaskListItem(props: ListItemOpts<Task>) {
  const {
    item: task,
    showProps,
    selection,
    setSelection,
    className,
    onChange,
    variant,
    showCode = true,
  } = props;
  const status = useLazyPropertyValue(task, {
    field: "status",
    type: "status",
  });
  const isShowing = useIsShowing(showProps);
  const parent = justOne(task?.refs?.parent)?.id;
  const [showSubs, setShowSubs] = useState(false);
  const selected = useMemo(
    () => !!selection && isSelected(selection, task.id),
    [selection, task.id]
  );
  const editable = useDebouncedMemo(() => !!selected, 300, [selected]);

  return (
    <>
      <EntityContextMenu
        entity={task}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem {...props} className={cx(styles.task, className)}>
          <SpaceBetween className={styles.upper} fit="container">
            <HStack className={styles.middle} gap={6} fit="container">
              <SubListButton
                {...props}
                open={showSubs}
                setOpen={setShowSubs}
                field="refs.subtasks"
              />

              <TaskCheck
                task={task}
                status={status.value.status}
                onChange={(v) =>
                  onChange?.(
                    toMutation(task, { field: "status", type: "status" }, v)
                  )
                }
              />

              {showCode && (
                <BigScreens>
                  <CodeLabel code={task.code} />
                </BigScreens>
              )}

              <div className={styles.alignText}>
                <EditableText
                  key={task.id}
                  disabled={!editable}
                  className={cx(styles.title)}
                  text={task.title || ""}
                  placeholder="Untitled"
                  blurOnEnter={true}
                  onChange={(v) =>
                    onChange?.(
                      toMutation(task, { field: "title", type: "text" }, v)
                    )
                  }
                />
                {!!parent && <ParentTaskIcon parent={parent} />}

                <SmartLocationLabel
                  size="small"
                  subtle
                  location={task.location}
                />
              </div>

              {task.end && !isShowing("end") && (
                <DueDate
                  date={task.end}
                  status={status.value.status?.group}
                  onClick={() => props.onOpen?.(task)}
                />
              )}

              {!isEmptyRT(task.checklist) && (
                <CheckListCompletion value={task.checklist} />
              )}
            </HStack>

            <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
              <PropertyValueStack
                item={task}
                props={whenEmpty(showProps, DEFAULT_PROPS)}
                variant={variant}
                onChange={onChange}
                hideEmpty={props.hideEmpty}
              />
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>

      {showSubs && <SubListItems {...props} field="refs.subtasks" />}
    </>
  );
}

const ParentTaskIcon = ({ parent }: { parent: ID }) => {
  const viewingWithin = useViewingWithin();

  if (viewingWithin?.includes(parent)) {
    return <></>;
  }

  return (
    <Tooltip text="Nested within">
      <Icon icon={SubtasksIcon} className={styles.flip} />
    </Tooltip>
  );
};
