import { Company } from "@api";

import { useUpdateEntity } from "@state/generic";

import { safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { Centered, Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EditableText } from "@ui/editable-text";
import { EntityHeaderBar } from "@ui/entity-header-bar";
import { EntityProperties } from "@ui/entity-properties";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, PersonIcon } from "@ui/icon";
import { LinkDialogButton } from "@ui/link-dialog";
import { LinkStack } from "@ui/link-stack";
import { Sheet } from "@ui/sheet-layout";
import { ShowMoreOverflow } from "@ui/show-more-overflow";
import { TemplateBanner } from "@ui/template-banner";
import { WorkflowActions } from "@ui/workflow-action-button";

import { PaneOpts } from "../types";

import styles from "./styles.module.css";

export const CompanyPane = ({ id, item }: PaneOpts<Company>) => {
  const mutate = useUpdateEntity(item.id, item.source.scope);

  if (!item) {
    return <></>;
  }

  return (
    <Sheet size="primary">
      {!!item.template && <TemplateBanner />}

      <VStack fit="container" gap={20}>
        <CompanyHeader company={item} />

        <Centered stack="vertical" gap={30} padding="horizontal">
          {/* <LabelledPropertyValueList
            entity={item}
            onChange={mutate}
            stack="horizontal"
          /> */}

          <EntityProperties entityId={id} />
        </Centered>
      </VStack>
    </Sheet>
  );
};

interface HeaderProps {
  company: Company;
}

const CompanyHeader = ({ company }: HeaderProps) => {
  const pageId = usePageId();
  const mutate = useUpdateEntity(company.id, pageId);

  if (!company) {
    return <h1>Not found.</h1>;
  }

  return (
    <VStack gap={40} fit="container">
      <EntityHeaderBar entity={company} padding="both" />

      <Centered padding="horizontal" fit="container">
        <Container
          padding="none"
          gap={20}
          fit="container"
          inset="left"
          stack="vertical"
        >
          <SpaceBetween gap={14} fit="container">
            <LinkDialogButton
              title="Add a logo"
              onLink={(l) =>
                l?.url &&
                mutate(asMutation({ field: "avatar", type: "text" }, l?.url))
              }
            >
              <Icon
                size="xlarge"
                className={styles.avatar}
                icon={
                  <PersonIcon size="xlarge" person={company} shape="square" />
                }
              />
            </LinkDialogButton>
            <VStack gap={0} fit="container">
              <LinkStack
                links={company.websites}
                onChange={(ls) =>
                  mutate(asMutation({ field: "websites", type: "links" }, ls))
                }
              />
              <EditableHeading
                key={company.id}
                text={company.name || ""}
                size="h2"
                placeholder="Company name"
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />
            </VStack>
          </SpaceBetween>

          <ShowMoreOverflow size={"small"} label="Show full bio" icon={false}>
            <EditableText
              key={company.id}
              text={safeAs<string>(company.custom?.bio) || ""}
              placeholder="Add short bio..."
              allowNewLines={true}
              className={styles.subline}
              subtle
              onChange={(text) => {
                when(text, (i) =>
                  mutate(asMutation({ field: "custom.bio", type: "text" }, i))
                );
              }}
            />
          </ShowMoreOverflow>

          {!company.template && (
            <HStack fit="container" gap={4}>
              <WorkflowActions size="small" entity={company} />
            </HStack>
          )}
        </Container>
      </Centered>
    </VStack>
  );
};
