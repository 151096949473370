import { Dictionary, flatten, keys, map, reduce, uniq, values } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Entity, EntityType, Ref } from "@api";

import {
  useLazyEntities,
  useManyNestedEntities,
  useQueueUpdates,
} from "@state/generic";
import { useMe } from "@state/persons";

import { now } from "@utils/date-fp";
import { Fn } from "@utils/fn";
import { newID } from "@utils/id";
import { maybeMap } from "@utils/maybe";
import { mask, maybeValues } from "@utils/object";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { plural } from "@utils/string";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Dialog } from "@ui/dialog";
import { FillSpace, HStack } from "@ui/flex";
import { Check, SpinnerIcon } from "@ui/icon";
import { Fields } from "@ui/input";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { CheckMenuItem, MenuItem } from "@ui/menu-item";
import { showSuccess } from "@ui/notifications";

interface Props {
  targets: Ref[];
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
}

type BaseProps = Props & {
  title: string;
  description: string;
  archiving?: boolean;
};

const BaseDialog = ({
  title,
  description,
  archiving,
  targets,
  onCancel,
  onComplete,
}: BaseProps) => {
  const pageId = usePageId();
  const me = useMe();
  const entities = useLazyEntities(targets);
  const parentTypes = useMemo(
    () => uniq(maybeMap(entities, (e) => e.source?.type)),
    [entities]
  );
  const { children, loading } = useManyNestedEntities(entities);
  const queue = useQueueUpdates(pageId);
  const [opts, setArchive] = useState<Partial<Record<EntityType, boolean>>>({});

  const hasChildren = useMemo(
    () => maybeValues(children as Record<EntityType, Entity[]>)?.length > 0,
    [children]
  );
  const label = archiving ? "Archive" : "Restore";

  const handleSubmit = useCallback(() => {
    if (!entities?.length) {
      return;
    }

    const allChildren = flatten(
      values(mask(children as Dictionary<Entity[]>, opts))
    );

    const transaction = newID();
    const changes = [
      asMutation(
        { field: "archivedAt", type: "date" },
        archiving ? now() : undefined
      ),
      asMutation(
        { field: "archivedBy", type: "relation" },
        archiving ? toRef(me) : undefined
      ),
    ];

    // Archive entity + all nested work
    queue(
      map([...entities, ...allChildren], (e) =>
        asUpdate(e, changes, transaction)
      )
    );

    // Callback
    onComplete?.();
  }, [queue, entities, opts]);

  useEffect(() => {
    setArchive(
      reduce(
        keys(children),
        (acc, k) => ({ ...acc, [k]: true }),
        {} as Partial<Record<EntityType, boolean>>
      )
    );
  }, [children]);

  if (!entities?.length) {
    return <></>;
  }

  return (
    <Dialog
      onDismiss={() => onCancel?.()}
      title={title}
      description={description}
      actions={
        <>
          <HStack gap={4} fit="container" justify="flex-end">
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}>
              {entities?.length === 1 ? label : `${label} all`}
            </Button>
          </HStack>
        </>
      }
    >
      <Container stack="vertical" padding="none" gap={20}>
        <FillSpace direction="vertical" fit="container">
          <Container gap={20} stack="vertical" fit="container" padding="none">
            <Fields>
              <Menu>
                <MenuGroup>
                  <CheckMenuItem checked={true} disabled onChecked={() => {}}>
                    {label} {entities?.length} selected{" "}
                    {map(parentTypes, (t) => plural(t, entities?.length))?.join(
                      "/"
                    )}
                  </CheckMenuItem>

                  {loading && (
                    <MenuItem disabled icon={SpinnerIcon}>
                      Collecting nested work...
                    </MenuItem>
                  )}

                  {!loading && !hasChildren && (
                    <MenuItem disabled icon={Check} text="No nested work" />
                  )}

                  {!loading &&
                    map(children, (values, type) => (
                      <CheckMenuItem
                        key={type}
                        checked={opts[type as EntityType] ?? false}
                        onChecked={() =>
                          setArchive((d) => ({
                            ...d,
                            [type]: !(opts[type as EntityType] ?? false),
                          }))
                        }
                      >
                        {label} {values?.length || 0} nested{" "}
                        {plural(type, values?.length || 0)}
                      </CheckMenuItem>
                    ))}
                </MenuGroup>
              </Menu>
            </Fields>
          </Container>
        </FillSpace>
      </Container>
    </Dialog>
  );
};

export const ArchiveDialog = ({ onComplete, ...props }: Props) => {
  const handleComplete = useCallback(() => {
    onComplete?.();

    showSuccess("Work archived for safe keeping.");
  }, [onComplete]);

  return (
    <BaseDialog
      title={"Confirm Archive"}
      description="Archived work is not deleted, it is kept for safe keeping. 'Open archives' at any time to see all archived work."
      archiving={true}
      {...props}
      onComplete={handleComplete}
    />
  );
};

export const UnArchiveDialog = ({ onComplete, ...props }: Props) => {
  const handleComplete = useCallback(() => {
    onComplete?.();

    showSuccess("Work restored from the archives.");
  }, [onComplete]);

  return (
    <BaseDialog
      title={"Confirm Restore"}
      description="Work will be visible everywhere outside of the archives."
      archiving={false}
      onComplete={handleComplete}
      {...props}
    />
  );
};
