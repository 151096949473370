import { filter, initial, last, take } from "lodash";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  Color,
  HasLocation,
  isSubRouter,
  isView,
  Link,
  toTitleOrName,
  View,
} from "@api";

import { useGetItemFromAnyStore, useLazyEntity } from "@state/generic";
import { useToViewTitle } from "@state/views";

import { cx } from "@utils/class-names";
import { toColorVar } from "@utils/color";
import { respectHandled } from "@utils/event";
import { Fn } from "@utils/fn";
import { isHumanId, isLocalID, isViewId, maybeTypeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { maybeMap, when } from "@utils/maybe";
import { useGoBack, useGoTo } from "@utils/navigation";
import { ComponentOrNode } from "@utils/react";
import { fromScope } from "@utils/scope";

import { Button } from "@ui/button";
import { ArrowCircleLeft, Icon } from "@ui/icon";

import Breadcrumb from "./breadcrumb";
import { Sheet } from "./sheet-layout";

import styles from "./breadcrumb-sheet.module.css";

interface Props {
  links: Partial<Link>[];
  mode?: "color" | "image" | "grey";
  background?: string;
  icon?: ComponentOrNode;
  onClick?: Fn<React.MouseEvent, void>;
  className?: string;
}

export const BreadcrumbSheet = ({
  links,
  mode: _mode = "grey",
  icon,
  background = _mode === "image" ? "/IMG_cover-default.jpg" : undefined,
  className,
  onClick,
}: Props) => {
  const mode = !!background ? "image" : _mode;
  return (
    <Sheet
      className={cx(styles.sheet, styles[mode], className)}
      height="container"
      size="content"
      transparency="mid"
      style={when(background, (b) => ({ background: toColorVar(b as Color) }))}
      onClick={onClick}
    >
      <Button
        subtle
        variant={"icon-only"}
        icon={
          icon || (
            <Icon
              className={styles.backIcon}
              icon={ArrowCircleLeft}
              size={"large"}
            />
          )
        }
        className={styles.back}
      />

      <Breadcrumb
        links={links}
        scrollable={false}
        className={styles.breadcrumb}
        itemClassName={styles.breadcrumbItem}
        linkClassName={styles.breadcrumbLink}
      />
    </Sheet>
  );
};

export const BackBreadcrumbSheet = () => {
  const goTo = useGoTo();
  const { canGoBack, returnPath } = useGoBack();
  const active = useLocation();

  const backLink = useMemo(() => {
    const route = active?.pathname?.startsWith("/task")
      ? active?.state?.return
      : active.pathname;
    const parts = filter(route?.split("/"), Boolean);
    const base = parts[0];

    return switchEnum(base, {
      home: () => ({ text: "Home", url: "/home" }),
      inbox: () => ({ text: "Inbox", url: "/inbox" }),
      person: () => ({ text: "Person", url: parts[1] }),
      else: () =>
        canGoBack
          ? { text: "Back", url: returnPath }
          : { text: "Home", url: "/home" },
    });
  }, [active]);

  return (
    <BreadcrumbSheet
      links={[backLink]}
      onClick={respectHandled((l) => goTo(backLink?.url))}
    />
  );
};

interface SmartProps {}

export const SmartBreadcrumbSheet = ({}: SmartProps) => {
  const goTo = useGoTo();
  const location = useLocation();
  const getItem = useGetItemFromAnyStore();
  const currentId = useMemo(
    () => last(location.pathname.split("/")),
    [location.pathname]
  );
  const current = useLazyEntity(
    isLocalID(currentId) || isHumanId(currentId) ? currentId : ""
  );
  const toViewTitle = useToViewTitle(isView(current) ? current : undefined);

  // Use hierarchy from the url if it exists, otherwise use the location hierarchy
  const hierarchy = useMemo(() => {
    // Get the hierarchy from the url ignoring the current item
    const pathHierarchy = initial(
      filter(window.location.pathname.split("/"), Boolean)
    );
    // Get the hierarchy from the location
    const locationHierarchy =
      when((current as HasLocation)?.location, fromScope) || [];

    const hierarchy =
      pathHierarchy?.length > 0 ? pathHierarchy : locationHierarchy;

    if (
      isViewId(currentId) &&
      isSubRouter(maybeTypeFromId(hierarchy[hierarchy.length - 1]))
    ) {
      return initial(hierarchy);
    }

    // When inside settings go back to root
    if (last(hierarchy) === "settings") {
      return take(hierarchy, 1);
    }

    return hierarchy;
  }, [location?.pathname, (current as HasLocation)?.location]);

  const parentId = useMemo(() => last(hierarchy), [hierarchy]);
  const parent = useLazyEntity(
    parentId && (isHumanId(parentId) || isLocalID(parentId))
      ? parentId
      : undefined
  );

  const links = useMemo(() => {
    if (!!hierarchy.length) {
      return maybeMap(hierarchy, (id) =>
        when(getItem(id), (e) => ({
          id: e.id,
          text:
            e.source.type === "view"
              ? toViewTitle(e as View)
              : toTitleOrName(e),
        }))
      );
    }

    if (parent) {
      return [
        {
          id: parent.id,
          text:
            parent?.source?.type === "view"
              ? toViewTitle(parent as View)
              : toTitleOrName(parent),
        },
      ];
    }
  }, [parentId, hierarchy]);

  const handleClicked = useCallback(() => {
    goTo(hierarchy);
  }, [hierarchy]);

  if (links?.length) {
    return (
      <BreadcrumbSheet
        mode={"grey"}
        links={links}
        onClick={respectHandled(handleClicked)}
      />
    );
  }

  return <BackBreadcrumbSheet />;
};
