import { find, map, reduce } from "lodash";
import { useMemo } from "react";

import { PropertyValueRef, Sprint } from "@api";

import { useLazyProperties, useLazyPropertyValue } from "@state/databases";

import { cx } from "@utils/class-names";
import { formatShort } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";
import { toPropertyValueRef } from "@utils/property-refs";

import { CodeLabel } from "@ui/code-label";
import { CoverImage } from "@ui/cover-image";
import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, iconFromString } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { StatusTag } from "@ui/tag";
import { Text } from "@ui/text";

import { ListItemOpts, UIEngine } from "../types";
import { SprintCreateDialog } from "./create-dialog";

import styles from "./styles.module.css";

function SprintListItem(props: ListItemOpts<Sprint> & { item: Sprint }) {
  const { item, className, onOpen, showProps, variant, onChange, ...rest } =
    props;
  const allProps = useLazyProperties(item.source, false);
  const visible = useMemo(
    () =>
      reduce(
        showProps || [
          { field: "status", type: "status" },
          { field: "start", type: "date" },
          { field: "end", type: "date" },
        ],
        (curr, p) => {
          if (["title", "code"].includes(p.field)) {
            return curr;
          }
          const def = find(allProps, (d) => p.field === d.field);
          if (!def) {
            return curr;
          }
          const val = toPropertyValueRef(item, def);

          return [...curr, val];
        },
        [] as PropertyValueRef<Sprint>[]
      ),
    [item, showProps, allProps]
  );

  return (
    <EntityContextMenu entity={item}>
      <SelectableListItem className={cx(styles.listItem, className)} {...props}>
        <SpaceBetween className={styles.upper}>
          <HStack className={styles.middle} gap={4}>
            {when(iconFromString(item.icon || "🏃‍♂️"), (i) => (
              <Icon icon={i} />
            ))}
            <CodeLabel code={item.code} />
            <EditableText
              key={item.id}
              text={item.name || ""}
              disabled={true}
              placeholder="Untitled sprint"
              blurOnEnter={true}
              onChange={(v) =>
                onChange?.(toMutation(item, { field: "name", type: "text" }, v))
              }
            />
          </HStack>

          <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
            {map(visible, (val) => (
              <PropertyValue
                key={val.field}
                valueRef={val}
                source={item.source}
                variant={variant || "unlabelled"}
                onChange={(c) =>
                  onChange?.({
                    field: val.field,
                    type: val.type,
                    value: c,
                    prev: val.value,
                  })
                }
              />
            ))}
          </HStack>
        </SpaceBetween>
      </SelectableListItem>
    </EntityContextMenu>
  );
}

export const SprintEngine: UIEngine<Sprint> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    const { value } = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const status = useMemo(() => value.status, [value]);

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <Label icon={when(item.icon, iconFromString)} text={item.name} />
            <HStack>
              {status && status.group === "in-progress" && (
                <StatusTag showIcon={false} status={status} />
              )}
              {item.start && item.end && (
                <Text subtle>
                  {useISODate(item.start, formatShort)}
                  {"->"}
                  {useISODate(item.end, formatShort)}
                </Text>
              )}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },

  asListCard: (props) => (
    <EntityContextMenu entity={props.item}>
      <SelectableListCard {...props}>
        <CoverImage className={styles.cover} />

        <VStack className={styles.bar}>
          {when(iconFromString(props.item.icon), (icon) => (
            <Icon icon={icon} />
          ))}

          <Label className={styles.title}>{props.item.name || "Sprint"}</Label>
        </VStack>
      </SelectableListCard>
    </EntityContextMenu>
  ),
  asListItem: SprintListItem,
  asCreateDialog: SprintCreateDialog,
};

export * from "./create-dialog";
export * from "./sprint-people-cards";
