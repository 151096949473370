import {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
} from "react";

import { Color } from "@api";

import { Maybe } from "@utils/maybe";

export interface ThemeState {
  color: Maybe<Color>;
  mode: "light" | "dark";
}

export const ThemeContext = createContext<Maybe<ThemeState>>(undefined);

export const useThemeContext = () => {
  return useContext(ThemeContext) || { color: undefined, mode: "light" };
};

export const WithTheme = ({
  color,
  mode,
  children,
}: ThemeState & { children: ReactNode }) => {
  const existing = useThemeContext();
  const context = useMemo(
    () => (color || mode ? { ...existing, color, mode } : existing),
    [color, mode, existing?.color, existing?.mode]
  );

  useLayoutEffect(() => {
    // Set data-theme on html
    const html = document.querySelector("html");

    if (!html) {
      return;
    }

    html.setAttribute("data-theme", mode);
  }, [mode, color, context]);

  return (
    <ThemeContext.Provider value={context}>{children}</ThemeContext.Provider>
  );
};
