import { first } from "lodash";
import { Fragment, useCallback, useMemo, useState } from "react";

import { useEditInAppCommands } from "@state/app";
import { useGetItemFromAnyStore } from "@state/generic";

import { cx } from "@utils/class-names";
import { useShortcut,validShortcut } from "@utils/event";
import { useStickyState } from "@utils/hooks";
import { isDefined, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { SelectionState, SetSelectionState } from "@utils/selectable";
import { toArray } from "@utils/set";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { EntityPreview } from "@ui/entity-preview";
import { HStack, SpaceBetween } from "@ui/flex";
import { ArrowLeft, ArrowRight, ArrowUpRight, KeySpace,Slash } from "@ui/icon";
import { Modal } from "@ui/modal";
import { Text } from "@ui/text";

import styles from "./selection-preview.module.css";

interface Props {
  selection: SelectionState;
  setSelection: SetSelectionState;
}

export const SelectionPreview = ({ selection }: Props) => {
  const goTo = usePushTo();
  const [preview, _setPreview] = useStickyState<boolean>(
    false,
    "selection-preview"
  );
  const [previewIndex, setPreviewIndex] = useState<number>(0);
  const setPreview = useCallback((p: boolean) => {
    _setPreview(p);
    setPreviewIndex(0);
  }, []);
  const getItem = useGetItemFromAnyStore();
  const selectedItems = useMemo(
    () => toArray(selection?.selected),
    [selection.selected]
  );
  const open = useMemo(
    () => preview && !!selectedItems?.length,
    [preview, selectedItems?.length]
  );

  const handleModify = useEditInAppCommands();

  const handleOpen = useCallback(() => {
    when(
      when(preview, (i) => selectedItems[previewIndex]) ?? first(selectedItems),
      goTo
    );
  }, [preview, selectedItems]);

  useShortcut(
    { key: "Space" },
    [
      (e) => validShortcut(e) && !!selectedItems?.length,
      () => setPreview(!preview),
    ],
    [selectedItems, open]
  );

  if (!selectedItems.length) {
    return <></>;
  }

  return (
    <Modal mode="passive" className={styles.modal} open={true}>
      <Container
        padding="none"
        stack="vertical"
        fit="content"
        gap={10}
        className={cx(styles.header, open && styles.open)}
      >
        <SpaceBetween>
          <Text className={styles.primary}>
            {open && `${(previewIndex || 0) + 1} of `}
            {selection?.selected?.size || 0} selected
          </Text>

          <HStack gap={0}>
            <Button
              subtle
              size="small"
              icon={Slash}
              onClick={() => handleModify()}
            >
              Modify
            </Button>
            {open && (
              <Button
                subtle
                size="small"
                icon={KeySpace}
                onClick={() => setPreview(false)}
              >
                Close
              </Button>
            )}
            {!open && (
              <Button
                subtle
                size="small"
                icon={KeySpace}
                onClick={() => setPreview(true)}
              >
                Preview
              </Button>
            )}
            <Button
              subtle
              size="small"
              icon={ArrowUpRight}
              onClick={handleOpen}
            >
              {open && "Open"}
            </Button>
          </HStack>
        </SpaceBetween>
        {open && (
          <>
            <Divider />
            {when(
              isDefined(preview) ? selectedItems[previewIndex] : undefined,
              (id) => {
                const i = previewIndex;
                const item = getItem(id);
                if (!item) return false;
                return (
                  <Fragment key={id}>
                    <EntityPreview entity={item} onOpen={handleOpen} />
                    {i !== selectedItems.length - 1 && (
                      <Divider direction="horizontal" />
                    )}
                  </Fragment>
                );
              }
            )}
            {selectedItems?.length > 1 && (
              <SpaceBetween>
                <Button
                  subtle
                  className={styles.navigate}
                  fit="container"
                  icon={ArrowLeft}
                  onClick={() =>
                    setPreviewIndex(Math.max((previewIndex ?? 0) - 1, 0))
                  }
                >
                  Previous
                </Button>
                <Divider direction="vertical" />
                <Button
                  subtle
                  className={styles.navigate}
                  fit="container"
                  iconRight={ArrowRight}
                  onClick={() =>
                    setPreviewIndex(
                      Math.min(
                        (previewIndex ?? 0) + 1,
                        selectedItems?.length - 1 || 0
                      )
                    )
                  }
                >
                  Next
                </Button>
              </SpaceBetween>
            )}
          </>
        )}
      </Container>
    </Modal>
  );
};
