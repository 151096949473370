import { flatMap, flatten, groupBy, map, uniq, values } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  DatabaseID,
  Entity,
  EntityType,
  getPropertyDefinition,
  HasLocation,
  HasStatus,
  PropertyRef,
  Ref,
  Update,
} from "@api";
import { EntityMap } from "@api/mappings";

import { allStatusesForTeam, findStatus } from "@state/databases";
import { getItemsWithinLoader } from "@state/generic/queries";
import { WorkflowContext } from "@state/workflows";

import { omitEmpty } from "@utils/array";
import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { maybeMap, safeAs, when } from "@utils/maybe";
import { all } from "@utils/promise";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { replacePart, toBaseScope } from "@utils/scope";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, SpaceBetween, VStack } from "@ui/flex";
import { SpinnerIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { RadioMenuGroup, RadioMenuItem } from "@ui/menu-item/radio";
import { showError } from "@ui/notifications";
import { EntitySelect } from "@ui/select";
import { Text } from "@ui/text";

interface Props {
  entity: HasStatus;
  toParent: PropertyRef<Entity, "relations"> | PropertyRef<Entity, "relation">;
  onProcess?: Fn<Update<Entity>[], void>;
  onComplete?: Fn<void, void>;
  onCancel?: Fn<void, void>;
  context: WorkflowContext<HasStatus>;
}

export const ParentCloseDialog = ({
  entity,
  toParent,
  onCancel,
  onProcess,
  onComplete,
  context: { props, stores },
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [openAction, setOpenAction] = useState("close");
  const [moveTo, setMoveTo] = useState<Ref>();
  const [complete, setComplete] = useState<EntityMap>({
    task: [],
    project: [],
  });
  const [incomplete, setIncomplete] = useState<EntityMap>({
    task: [],
    project: [],
  });
  const allStatuses = useRecoilValue(
    allStatusesForTeam(toBaseScope(entity.source.scope))
  );

  const nestedTypes = useMemo(
    () =>
      uniq(
        maybeMap(props, (p) =>
          ["relation", "relations"].includes(p.type) &&
          p.options?.hierarchy === "child"
            ? p.options?.references
            : undefined
        )
      ),
    [props]
  );

  const handleSubmit = useCallback(() => {
    if (openAction === "move" && !moveTo) {
      showError("Select a location to move the work to.");
      return;
    }

    onProcess?.(
      omitEmpty([
        // Mark the parent entity as done
        when(findStatus("done", props, entity.source), (dne) =>
          asUpdate(entity, [
            asMutation({ field: "status", type: "status" }, dne),
          ])
        ),

        // Mark all incompleted things as done
        ...(openAction === "close"
          ? maybeMap(flatten(values(incomplete) as Entity[][]), (e) =>
              when(findStatus("done", allStatuses, e.source), (dne) =>
                asUpdate(e, [
                  asMutation({ field: "status", type: "status" }, dne),
                ])
              )
            )
          : []),

        // Move all incompleted when action is set to move
        ...(openAction === "move" && moveTo
          ? map(flatten(values(incomplete) as Entity[][]), (e) =>
              asUpdate(
                e,
                safeAs<HasLocation>(e)?.location?.includes(entity.id)
                  ? [
                      asMutation(
                        toParent,
                        toParent.type === "relations"
                          ? [toRef(moveTo)]
                          : toRef(moveTo)
                      ),
                      asMutation(
                        { field: "location", type: "text" },
                        replacePart(
                          safeAs<HasLocation>(e)?.location || "",
                          entity.id,
                          moveTo.id
                        )
                      ),
                    ]
                  : asMutation(
                      toParent,
                      toParent.type === "relations"
                        ? [toRef(moveTo)]
                        : toRef(moveTo)
                    )
              )
            )
          : []),
      ])
    );

    onComplete?.();
  }, [complete, incomplete, openAction, moveTo, onProcess, entity]);

  useAsyncEffect(async () => {
    setLoading(true);
    const source = { ...entity.source, type: "task" } as DatabaseID;
    const statusProp = await getPropertyDefinition(source, {
      field: "status",
      type: "status",
    });
    const allChildren = flatMap(
      await all(
        nestedTypes.map((t) =>
          getItemsWithinLoader(entity.id, { type: t as EntityType }, toParent)
        )
      )
    );
    // Need a generic way to determine if a something is "completed" (could use timestamps when)
    // we have them later...
    const { complete, incomplete } = groupBy(allChildren, (t) => {
      const status = findStatus(
        (t as HasStatus)?.status?.id || "",
        [statusProp],
        source
      );
      // If child is not statusable then treat as completed
      return !status || status?.group === "done" ? "complete" : "incomplete";
    });

    setComplete((c) => ({
      ...c,
      [entity.source.type]: [entity],
      ...groupBy(complete || [], (t) => t.source.type),
    }));

    setIncomplete((c) => ({
      ...c,
      ...groupBy(incomplete || [], (t) => t.source.type),
    }));

    setLoading(false);
  }, []);

  const openCount = useMemo(
    () => flatten(values(incomplete) as Entity[][])?.length,
    [incomplete]
  );

  return (
    <DialogSplit
      title={`Close ${entity.source.type}`}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical" fit="container">
          <VStack>
            <Text subtle>
              Keep your workspace organised by closing finished work.
            </Text>
          </VStack>

          <EducationTip relevantTo={["project"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit}>
            Close
            {switchEnum(openAction, {
              move: " & Move",
              close: " All",
              else: "",
            })}{" "}
          </Button>
        </>
      }
    >
      <FillSpace>
        <Container gap={20} stack="vertical" fit="container">
          {loading && (
            <Label icon={SpinnerIcon} subtle>
              Collecting unfinished work...
            </Label>
          )}

          {!loading && !openCount && <Text>No nested work to complete.</Text>}

          {!loading && !!openCount && (
            <>
              <Text>What do you want to do with the unfinished work?</Text>

              <Menu>
                <MenuGroup>
                  <RadioMenuGroup
                    value={openAction}
                    onChanged={(s) => setOpenAction(s)}
                  >
                    <RadioMenuItem
                      text={`Close open work (${openCount})`}
                      value="close"
                    />
                    <RadioMenuItem
                      text={`Move open work (${openCount})`}
                      value="move"
                    />
                    {openAction === "move" && (
                      <Container padding="horizontal" fit="container">
                        <EntitySelect
                          type={entity.source.type}
                          value={moveTo}
                          onChange={(p) => setMoveTo(p)}
                          placeholder="Select location..."
                        />
                      </Container>
                    )}

                    <RadioMenuItem
                      text={`Do nothing (${openCount})`}
                      value="nothing"
                    />
                  </RadioMenuGroup>
                </MenuGroup>
              </Menu>
            </>
          )}
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
