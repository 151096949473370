import { format, isValid } from "date-fns";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";

import { cx } from "@utils/class-names";
import { formatDay, tryParse } from "@utils/date";
import { useShortcut } from "@utils/event";
import { fallback, Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { now } from "@utils/now";

import { Dropdown, useOpenState } from "@ui/dropdown";
import { SpaceBetween } from "@ui/flex";

import { Button } from "./button";
import { TimesCircle } from "./icon";
import { TextInput } from "./input";
import { PlaceholderText } from "./text";

import styles from "./date-picker.module.css";

const useClassNames = (className?: string) => {
  const isMorning = useMemo(() => now().getHours() < 12, []);
  return useMemo(
    () => ({
      root: cx(styles.root, className),
      caption: styles.head,
      caption_label: styles.title,
      nav_button: cx(styles.button, styles.navButton),
      cell: styles.cell,
      head_cell: styles.headCell,
      day: cx(styles.button, styles.day),
      day_today: cx(
        styles.button,
        styles.today,
        isMorning ? styles.morning : styles.afternoon
      ),
      day_outside: cx(styles.button, styles.dayOutside),
      day_selected: cx(styles.button, styles.daySelected),
      day_range_start: cx(styles.button, styles.daySelected),
      day_range_end: cx(styles.button, styles.daySelected),
      day_range_middle: cx(styles.button, styles.dayInRange),
    }),
    [className, isMorning]
  );
};

interface DatePickerProps {
  date: Maybe<Date>;
  required?: boolean;
  placeholder?: string;
  onChanged: Fn<Maybe<Date>, void>;
  onFocus?: Fn<void, void>;
  onBlur?: Fn<void, void>;
  className?: string;
}

const DATE_FORMAT = "d MMM yyyy";

export const tryParseDate = (input: string) =>
  fallback(
    // Dash format
    () => tryParse(input, "dd-MM-yy") || tryParse(input, "d-MM-yyyy"),

    // Slash format
    () =>
      tryParse(input, "dd/MM/yy") ||
      tryParse(input, "d/MM/yyyy") ||
      tryParse(input, "d/MMM/yy") ||
      tryParse(input, "d/MMM/yyyy"),

    // Space format
    () =>
      tryParse(input, "d MMM") ||
      tryParse(input, "d MMM") ||
      tryParse(input, "d MMM yy") ||
      tryParse(input, "d MMM yyyy")
  );

export function DateInput({
  date,
  placeholder,
  onChanged,
  onFocus,
  onBlur,
  className,
}: DatePickerProps) {
  const [changed, setChanged] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [valid, setValid] = useState(true);

  useShortcut(
    "Enter",
    () => {
      if (valid) {
        onChanged?.(
          inputValue?.trim() !== "" ? tryParseDate(inputValue) : undefined
        );
      }
    },
    [onChanged]
  );

  useEffect(() => {
    setInputValue(when(date, (d) => format(d, DATE_FORMAT)) || "");
  }, [date]);

  const onInputChanged = useCallback((value: string) => {
    setInputValue(value);
    setValid(!value || isValid(tryParseDate(value)));
    setChanged(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (changed) {
      onChanged?.(
        inputValue?.trim() !== "" ? tryParseDate(inputValue) : undefined
      );
    }
    onBlur?.();
  }, [changed, inputValue, onChanged, onBlur]);

  return (
    <TextInput
      className={cx(styles.dateInput, className)}
      placeholder={placeholder || "Pick a date..."}
      autoFocus={false}
      value={inputValue}
      updateOn="change"
      selectOnFocus={true}
      onChange={onInputChanged}
      onFocus={onFocus}
      onBlur={handleBlur}
    />
  );
}

export function DatePicker({
  date,
  onChanged,
  className,
  required = false,
}: DatePickerProps) {
  const classNames = useClassNames(className);

  return (
    <DayPicker
      selected={date}
      mode="default"
      onDayClick={(d: Maybe<Date>) => onChanged(d)}
      classNames={classNames}
    />
  );
}

type DateInputPickerProps = DatePickerProps & {
  open?: boolean;
  onClose?: Fn<void, void>;
  portal?: boolean;
  children?: ReactNode;
};

export const DateInputPicker = ({
  date,
  children,
  ...props
}: DateInputPickerProps) => {
  const [open, _setOpen] = useOpenState(props.open ?? false);

  const setOpen = (open: boolean) => {
    _setOpen(open);
    if (!open) {
      props.onClose?.();
    }
  };

  const setDate = (date: Maybe<Date>) => {
    props.onChanged?.(date);

    if (open) {
      setOpen(false);
    }
  };

  useShortcut("Escape", [() => true, () => setOpen(false)], [setOpen]);

  return (
    <Dropdown
      className={{
        dropdown: cx(styles.dropdown, props.className),
        popover: styles.datePopover,
      }}
      open={open}
      portal={props.portal}
      setOpen={setOpen}
      closeOnEscape={true}
      trigger={
        children || (
          <Button fit="container" className={styles.control} subtle>
            {when(date, formatDay) || (
              <PlaceholderText>
                {props.placeholder || "Select a date..."}
              </PlaceholderText>
            )}
          </Button>
        )
      }
    >
      <SpaceBetween>
        <DateInput
          className={styles.input}
          date={date}
          required={props.required}
          onChanged={setDate}
          placeholder={props.placeholder}
        />
        <Button icon={TimesCircle} subtle onClick={() => setDate(undefined)} />
      </SpaceBetween>

      <DatePicker date={date} onChanged={setDate} required={props.required} />
    </Dropdown>
  );
};

interface DateRangeProps {
  dates: DateRange;
  onChanged: Fn<Maybe<DateRange>, void>;
  placeholder?: string;
  className?: string;
}

export function DateRangePicker({
  dates,
  onChanged,
  className,
}: DateRangeProps) {
  const classNames = useClassNames(className);

  return (
    <DayPicker
      selected={dates}
      mode="range"
      initialFocus={true}
      onSelect={onChanged}
      showOutsideDays
      classNames={classNames}
    />
  );
}
