import { cx } from "@utils/class-names";
import { ComponentOrNode } from "@utils/react";

import { Icon } from "@ui/icon";
import { Tooltip } from "@ui/tooltip";

import { MenuItem, MenuItemProps } from "./default";

import styles from "./styles.module.css";

export type CollapsibleMenuItemProps = MenuItemProps & {
  icon: ComponentOrNode;
  collapsed: boolean;
};

export const CollapsibleMenuItem = ({
  children,
  collapsed,
  className,
  icon,
  iconRight,
  ...props
}: CollapsibleMenuItemProps) => {
  // Don't show tooltips for collapse dmenu item since overlay animates above
  return (
    <Tooltip text={children} side="right" delay={collapsed ? 2000 : 700}>
      <MenuItem
        {...props}
        icon={collapsed ? undefined : icon}
        iconRight={collapsed ? undefined : iconRight}
        wrapLabel={!collapsed}
        highlight="shadow"
        className={cx(collapsed && styles.collapsed, className)}
      >
        {collapsed && <Icon icon={icon} />}
        {!collapsed && children}
      </MenuItem>
    </Tooltip>
  );
};
