import { customAlphabet } from "nanoid";
import { v4 } from "uuid";

import { EntityType, ID } from "@api";

import { switchEnum } from "./logic";
import { Maybe, required, when } from "./maybe";

const nanoid = customAlphabet(
  "0123456789ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnpqrstuvwxyz"
);

export const uuid = () => v4();

export const isUUID = (id: string) =>
  (
    id.match(
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    ) || []
  ).length > 0;

export const cid = (length: number) => nanoid(length);

export const shortId = cid;

export const newID = () => Math.random().toString(16).substring(2, 8);

export const asLocal = (id: string) => `[${id}]`;

export const fromLocal = (id: string) => id.replace(/[\[\]]/gi, "");

export const newLocalHumanId = (entity?: string) =>
  asLocal(when(entity, newHumanId) || newID());

export const isTemplateId = (id: Maybe<string>) =>
  id?.startsWith("v_tmp") ?? false;

export const isLocalID = (id: Maybe<string>) => id?.startsWith("[") ?? true;

export const isPersistedID = (id: string) => !isLocalID(id);

export const toLocalParts = (id: string): [string, Maybe<string>] => {
  const [unsaved, persisted] = id.replace("[", "").split("]");
  return [unsaved, persisted];
};

export const toReactKey = (id: string) =>
  isLocalID(id) ? toLocalParts(id)[0] : id;

export const asUUID = (id: string) =>
  id.indexOf("-") === -1
    ? id.replace(/^(\w{8})(\w{4})(\w{4})(\w{4})(.*)/gi, "$1-$2-$3-$4-$5")
    : id;

export const newADDDID = (): string => {
  const nums = Math.random().toString().substring(2, 5);
  const seed = Math.random().toString(36).substring(2);
  const char = seed.search(/[a-zA-Z]/gi);
  return seed[char].toUpperCase() + nums;
};

// Update on new entity

export const newHumanId = (entity: string) =>
  switchEnum(entity, {
    workspace: () => `w_${cid(6)}`,
    project: () => `p_${cid(6)}`,
    campaign: () => `cm_${cid(6)}`,
    calendar: () => `ca_${cid(6)}`,
    content: () => `c_${cid(6)}`,
    backlog: () => `bl_${cid(6)}`,
    roadmap: () => `rm_${cid(6)}`,
    sprint: () => `sp_${cid(6)}`,
    team: () => `tm_${cid(6)}`,
    job: () => `tj_${cid(10)}`,
    person: () => `u_${cid(6)}`,
    person_workspace: () => `uw_${cid(6)}`,
    task: () => `t_${cid(8)}`,
    outcome: () => `o_${cid(8)}`,
    note: () => `n_${cid(8)}`,
    resource: () => `r_${cid(8)}`,
    page: () => `d_${cid(8)}`,
    process: () => `ps_${cid(8)}`,
    knowledgebase: () => `kb_${cid(8)}`,
    form: () => `fm_${cid(8)}`,
    meeting: () => `m_${cid(8)}`,
    agenda: () => `ag_${cid(8)}`,
    action: () => `ac_${cid(8)}`,
    schema: () => `sc_${cid(8)}`,
    schedule: () => `sd_${cid(8)}`,
    workflow: () => `wf_${cid(8)}`,
    pipeline: () => `pl_${cid(8)}`,
    event: () => `e_${cid(8)}`,
    request: () => `re_${cid(8)}`,
    view: () => `v_${cid(8)}`,
    company: () => `co_${cid(8)}`,
    contact: () => `ct_${cid(8)}`,
    deal: () => `dl_${cid(8)}`,
    else: () => {
      throw new Error(`Unknown entity ID mapping ${entity}.`);
    },
  });

export const maybeTypeFromId = <T extends string>(id: Maybe<ID>): Maybe<T> =>
  switchEnum(id?.split("_")[0]?.replace(/[\[\]]/gi, "") || "", {
    t: () => "task",
    o: () => "outcome",
    tm: () => "team",
    ca: () => "calendar",
    cm: () => "campaign",
    c: () => "content",
    d: () => "page",
    kb: () => "knowledgebase",
    fm: () => "form",
    ps: () => "process",
    u: () => "person",
    bl: () => "backlog",
    sc: () => "schema",
    sp: () => "sprint",
    sd: () => "schedule",
    tj: () => "job",
    uw: () => "person_workspace",
    w: () => "workspace",
    p: () => "project",
    r: () => "resource",
    rm: () => "roadmap",
    m: () => "meeting",
    ag: () => "agenda",
    ac: () => "action",
    n: () => "note",
    v: () => "view",
    wf: () => "workflow",
    pl: () => "pipeline",
    e: () => "event",
    re: () => "request",
    co: () => "company",
    ct: () => "contact",
    dl: () => "deal",
    else: () => undefined,
  }) as Maybe<T>;

export const typeFromId = <T extends string = string>(id: string) =>
  required(maybeTypeFromId(id), () => `Invalid traction id (${id}).`) as T;

// Uses regex to determine if the passed in ID is one of our human Ids and has at least 6 characters
export const HUMAN_ID_REGEX = /\b[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})\b/g;

// Uses regex to determine if the passed in ID is one of our human Ids or a local id [v_1234]
export const HUMAN_OR_LOCAL_REGEX =
  /\b[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})\b|\[[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})\]/g;

export const HUMAN_ID_REGEX_EXACT = /^[a-z]{1,2}_(tmp-\S*|[a-zA-Z0-9]{4,13})$/g;

export const isHumanId = (id: Maybe<string>) =>
  !!id && !!id.match(HUMAN_ID_REGEX_EXACT);

// TODO: Could clean this up with a map of types to prefixes
export const isTypeId = (type: EntityType) => (id: string) =>
  maybeTypeFromId(id) === type;

// Extract ids but support any prefix
export const extractIDs = (text: string) => {
  const regexPattern = /\b(?:[a-z]{1,3})_[A-Za-z0-9]{6,13}\b/g;
  return text.match(regexPattern)?.filter(Boolean);
};

// Special checks for higher perf code paths
export const isTeamId = (id: Maybe<string>) => !!id?.startsWith("tm_");
export const isViewId = (id: Maybe<string>) => !!id?.startsWith("v_");
export const isWorkspaceId = (id: Maybe<string>) => !!id?.startsWith("w_");
export const isPersonId = (id: Maybe<string>) => !!id?.startsWith("u_");
export const isNoteId = (id: Maybe<string>) => !!id?.startsWith("n_");
