import { atomFamily, selectorFamily } from "recoil";

import { ID, Team } from "@api";

import {
  getItem,
  indexedDBStorageForStore,
  setItem,
  StoreState,
} from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

import { isDefault } from "@utils/recoil";

export type TeamStoreState = StoreState<Team>;

const dbStoreDefault = () => ({
  updatedAt: undefined,
  lookup: {},
  unsaved: [],
});

export const WorkspaceTeamStoreAtom = atomFamily<TeamStoreState, ID>({
  key: "WorkspaceTeamStoreAtom",
  default: dbStoreDefault(),
  effects: (wid) => [indexedDBStorageForStore(wid, "team")],
});

export const TeamStoreAtom = WorkspaceWrappedAtom(
  "TeamStoreAtom",
  WorkspaceTeamStoreAtom
);

export const TeamAtom = selectorFamily({
  key: "TeamAtom",
  get:
    (id: ID) =>
    ({ get }) =>
      getItem(get(TeamStoreAtom), id),
  set:
    (_id: ID) =>
    ({ set }, newValue) => {
      if (!!newValue && !isDefault(newValue)) {
        set(TeamStoreAtom, setItem(newValue));
      }
    },
});
