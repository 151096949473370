import { addMinutes, roundToNearestMinutes, startOfDay } from "date-fns";
import { useCallback, useEffect,useMemo, useState } from "react";

import {
  Entity,
  EntityType,
  ID,
  Meeting,
  PropertyMutation,
  Ref,
  Schedule,
  Update,
} from "@api";

import { autoName, useAiUseCase } from "@state/ai";
import {
  useCreateFromObject,
  useCreateFromTemplate,
  useEntityState,
  useLazyEntity,
  useLocalChanges,
  useQueueUpdates,
} from "@state/generic";
import { isInProgress } from "@state/meetings";
import { useMe } from "@state/persons";
import { ScheduleStoreAtom, useTempSchedule } from "@state/schedule";
import { useActiveWorkspaceId } from "@state/workspace";

import { OneOrMany } from "@utils/array";
import { formatTime } from "@utils/date";
import {
  convertToCalDate,
  convertToPointDate,
  ensureISODate,
  fromPointDate,
  toCalDate,
  toDirtyDate,
  toPointDate,
  useISODate,
} from "@utils/date-fp";
import { useAsyncEffect } from "@utils/effects";
import { composel, Fn } from "@utils/fn";
import { useSlowMemo } from "@utils/hooks";
import { isPersonId } from "@utils/id";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import { now } from "@utils/now";
import { merge } from "@utils/object";
import { asMutation, asTempUpdate, asUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { uniqRefs } from "@utils/relation-ref";
import { keepTime, keepTimeDirty, toTime, withTime } from "@utils/time";

import { usePageId } from "@ui/app-page";
import { Banner } from "@ui/banner";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DateInputPicker } from "@ui/date-picker";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { CheckIcon, EmojiIcon, PaintTool, Redo } from "@ui/icon";
import { Field, TextInput } from "@ui/input";
import { Label } from "@ui/label";
import { showError } from "@ui/notifications";
import { Section } from "@ui/section";
import { LocationSelect, PersonMultiSelect, TemplateSelect } from "@ui/select";
import { Text, TextSmall } from "@ui/text";
import { DurationPicker, TimePicker } from "@ui/time-picker";

import { ScheduleRepeatOptions } from "../schedule/editor";

import styles from "./styles.module.css";

interface Props {
  defaults?: Partial<Omit<Meeting, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const MeetingCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wID = useActiveWorkspaceId();
  const me = useMe();
  const [smartName, setSmartName] = useState(true);
  const showName = !smartName;
  const [suggestedName, setSuggestedName] = useState("");
  const [creating, setCreating] = useState(false);
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );

  const [repeat, setRepeat] = useState(false);
  const { run, loading } = useAiUseCase(autoName);
  const [showingTemplate, setShowingTemplate] = useState(false);
  const [fromTemplate, setFromTemplate] = useEntityState<"meeting">();
  const [scheduleId, setScheduleId] = useState<string>();
  const { save: saveSchedule, rollback: rollbackSchedule } = useLocalChanges(
    scheduleId || "",
    ScheduleStoreAtom
  );
  const schedule = useLazyEntity(scheduleId);

  const [meeting, _setMeeting] = useState<Partial<Meeting>>(() => {
    const duration = 60;
    const time = roundToNearestMinutes(now(), {
      nearestTo: 30,
      roundingMethod: "ceil",
    });
    const start = keepTimeDirty(
      when(ensureISODate(defaults?.start), fromPointDate) || now(),
      time
    );

    return {
      ...defaults,
      owner: toRef(me),
      refs: { ...defaults?.refs, people: [toRef(me)] },
      start: toPointDate(start),
      duration,
      end: toPointDate(addMinutes(start, duration)),
      source: { type: "meeting", scope },
    };
  });

  const create = useCreateFromObject("meeting", scope);
  const createFromTemplate = useCreateFromTemplate(
    { type: "meeting", scope: meeting?.location || scope },
    (ref) => {
      onSaved?.(ref);
      setCreating(false);
    }
  );

  const setMeeting = useCallback(
    (changes: Partial<Meeting>) =>
      _setMeeting((r) =>
        changes?.start
          ? merge(r, {
              ...changes,
              end: toPointDate(
                addMinutes(
                  toDirtyDate(changes.start),
                  changes.duration || meeting.duration || 0
                )
              ),
            })
          : merge(r, changes)
      ),
    [_setMeeting, meeting.duration]
  );

  const setDuration = useCallback(
    (d: number) => {
      setMeeting({
        start: meeting?.start || toPointDate(now()),
        duration: d,
        end: when(meeting?.start, (start) =>
          useISODate(start, (date) => addMinutes(date, d))
        ),
      });
    },
    [meeting?.start]
  );

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    if (!meeting.purpose || !meeting.start || !meeting.refs?.people?.length) {
      showError("Fill in all required fields.");
      return;
    }

    if (repeat && !schedule) {
      showError("Cant find schedule.");
      return;
    }

    setCreating(true);

    const start = meeting.start;

    const merged = {
      ...meeting,
      name: smartName && suggestedName ? suggestedName : meeting.name,
      status: isInProgress(meeting as Meeting) ? { id: "INP" } : { id: "UPC" },
      // Clear start from meeting when creating as template for schedule.
      start: !!(repeat && schedule) ? undefined : start,
      refs: {
        ...meeting.refs,

        // If there is a template, then merge the people in here...
        people: uniqRefs([
          ...(meeting.refs?.people || []),
          ...(safeAs<Meeting>(fromTemplate)?.refs?.people || []),
        ]),
      },
    };

    // Creating from template
    if (fromTemplate) {
      createFromTemplate.create(fromTemplate, {
        overrides: { [fromTemplate?.id]: merged },
      });

      return;
    }

    // Creating from scratch
    const [saved] = create([merged]);

    // Creating with a schedule (not possible with fromTemplate)
    if (repeat && schedule) {
      saveSchedule([
        asUpdate(schedule, [
          asMutation(
            { field: "useTemplate", type: "relation" },
            { id: saved.id }
          ),
          asMutation(
            { field: "from", type: "date" },
            when(start, (s) =>
              useISODate(convertToCalDate(s, "local"), startOfDay)
            )
          ),
          asMutation(
            { field: "name", type: "text" },
            saved.name || merged.name || suggestedName || ""
          ),
        ]),
      ]);
    }

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
    setCreating(false);
  }, [
    create,
    meeting,
    suggestedName,
    fromTemplate,
    smartName,
    onSaved,
    repeat,
    schedule,
    saveSchedule,
  ]);

  const handleDismiss = useCallback(() => {
    rollbackSchedule?.();
    onCancel?.();
  }, [rollbackSchedule]);

  const slowPurpose = useSlowMemo(() => meeting.purpose, 800, [
    meeting?.purpose,
  ]);

  useAsyncEffect(async () => {
    if (!meeting.name && !!meeting.purpose?.trim()) {
      const suggested = await run({ entity: meeting });
      setSuggestedName(suggested);
    }
  }, [slowPurpose]);

  return (
    <DialogSplit
      title={"New meeting"}
      onDismiss={handleDismiss}
      side={
        <SpaceBetween direction="vertical" align="flex-start">
          <Text subtle>Meet with purpose and clarity.</Text>
          <EducationTip relevantTo={["meeting"]} />
        </SpaceBetween>
      }
      actions={
        <SpaceBetween>
          <Label
            subtle
            icon={<CheckIcon checked={!showName} />}
            onClick={() => setSmartName((n) => !n)}
          >
            Auto-name meeting
          </Label>
          <HStack>
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={onCreate} loading={creating}>
              Create meeting
            </Button>
          </HStack>
        </SpaceBetween>
      }
    >
      <FillSpace direction="vertical">
        {meeting.template && (
          <Banner variant="rounded" fit="container" color="blue">
            Creating as template
          </Banner>
        )}
        <Container gap={20} stack="vertical" fit="container">
          <>
            <Field label="Location">
              <LocationSelect
                fit="container"
                location={meeting.location}
                allowed={["team"]}
                onChange={(l) => setMeeting({ location: l })}
                source={meeting.source}
                className={styles.control}
                showOpen={false}
                variant="full"
                showCaret={true}
              />
            </Field>

            <Field label="Purpose">
              <VStack gap={2}>
                <TextInput
                  value={safeAs<string>(meeting.purpose) || ""}
                  onChange={(t) => setMeeting({ purpose: t })}
                  updateOn="change"
                  autoFocus={true}
                  placeholder="What is the purpose of this meeting?"
                />
                {!showName && (
                  <Container
                    padding="left"
                    size="half"
                    onClick={() => setSmartName((n) => !n)}
                  >
                    {suggestedName && (
                      <TextSmall subtle>Name: {suggestedName}</TextSmall>
                    )}
                    {!suggestedName && <TextSmall subtle> </TextSmall>}
                  </Container>
                )}
              </VStack>
            </Field>

            {showName && (
              <Field label="Name">
                <TextInput
                  value={meeting.name || ""}
                  onChange={(t) => setMeeting({ name: t })}
                  placeholder={suggestedName || "Give this meeting a name..."}
                  updateOn="change"
                  autoFocus={false}
                />
              </Field>
            )}

            <Field label="People">
              <PersonMultiSelect
                className={{ trigger: styles.control }}
                value={meeting.refs?.people}
                onChange={(vs) =>
                  setMeeting({ refs: { people: maybeMap(vs, toRef) } })
                }
                placeholder="Select people..."
              />
            </Field>

            {(meeting.refs?.people?.length || 0) > 1 &&
              meeting.location &&
              isPersonId(meeting.location) && (
                <Banner
                  icon={<EmojiIcon emoji="⚠️" />}
                  text="This is only visible to you. Change above location to a team."
                  variant="rounded"
                  align="left"
                  fit="container"
                  color="yellow"
                />
              )}

            <SpaceBetween fit="container" gap={12}>
              <Field label="Day">
                {/* TODO: Convert to ISODate */}
                <DateInputPicker
                  date={when(meeting.start, fromPointDate)}
                  onChanged={(v) => {
                    // Convert to ISO Date since date picker is returning a PointDate but using local 00:00 to reference the day...
                    return (
                      v &&
                      setMeeting({
                        start: meeting.start
                          ? keepTime(
                              convertToPointDate(toCalDate(v, "local"), "utc"),
                              meeting.start
                            )
                          : toPointDate(v),
                      })
                    );
                  }}
                  placeholder="Pick a day"
                />
              </Field>
              <Field label="Time">
                <TimePicker
                  time={
                    meeting.start
                      ? toTime(fromPointDate(meeting.start))
                      : undefined
                  }
                  onChange={(time) =>
                    time &&
                    setMeeting({
                      start: useISODate(
                        meeting.start,
                        (s) => s && withTime(s, time)
                      ),
                    })
                  }
                >
                  <Button
                    className={styles.control}
                    subtle
                    size="small"
                    fit="container"
                  >
                    <Text subtle={!meeting.start}>
                      {when(
                        meeting.start,
                        composel(fromPointDate, formatTime)
                      ) || "Pick time"}
                    </Text>
                  </Button>
                </TimePicker>
              </Field>
              <Field label="Duration">
                <DurationPicker
                  mins={meeting.duration || 0}
                  onChange={(d) => d && setDuration(d)}
                >
                  <Button
                    className={styles.control}
                    subtle
                    size="small"
                    fit="container"
                  >
                    {meeting.duration} mins
                  </Button>
                </DurationPicker>
              </Field>
            </SpaceBetween>

            {showingTemplate && (
              <Field label="Using template" fit="container">
                <TemplateSelect
                  scope={meeting.location}
                  type="meeting"
                  allowed={["meeting"]}
                  allowNew={false}
                  value={fromTemplate}
                  onChange={setFromTemplate}
                  closeOnSelect={true}
                  placeholder="No template..."
                  className={{ trigger: styles.control }}
                />
              </Field>
            )}

            {repeat && (
              <>
                <MeetingScheduleConfig
                  meeting={meeting as Meeting}
                  onSchedule={(id) => {
                    if (id) {
                      setScheduleId(id);
                      setMeeting({ template: "root" });
                    } else {
                      setScheduleId(undefined);
                      setMeeting({ template: undefined });
                      rollbackSchedule?.();
                      setRepeat(false);
                    }
                  }}
                />
              </>
            )}

            {!repeat && !showingTemplate && (
              <HStack gap={4}>
                <Button
                  size="small"
                  subtle
                  icon={Redo}
                  onClick={() => setRepeat(true)}
                >
                  <Text subtle>Repeat</Text>
                </Button>
                <Button
                  size="small"
                  subtle
                  icon={PaintTool}
                  onClick={() => setShowingTemplate(true)}
                >
                  <Text subtle>Use Template</Text>
                </Button>
              </HStack>
            )}
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};

const MeetingScheduleConfig = ({
  meeting,
  onSchedule,
}: {
  meeting: Meeting;
  onSchedule: Fn<Maybe<ID>, void>;
}) => {
  const pageId = usePageId();
  const queue = useQueueUpdates(pageId);
  const scheduleDefaults = useMemo(
    () => ({
      from: meeting.start,
      location: meeting.location,
      entity: "meeting" as EntityType,
    }),
    [meeting.start, meeting.location, meeting.source.scope]
  );
  const schedule = useTempSchedule(scheduleDefaults, pageId);
  const handleChange = useCallback(
    (changes: OneOrMany<PropertyMutation<Schedule>>) =>
      schedule && queue(asTempUpdate(schedule, changes) as Update<Entity>),
    [schedule]
  );

  useEffect(() => {
    if (schedule?.id) {
      onSchedule(schedule?.id);
    }
  }, [!!schedule?.id]);

  if (!schedule) {
    return <></>;
  }

  return (
    <Section
      title="Recurring Schedule"
      size="default"
      actions={
        <Button size="small" subtle onClick={() => onSchedule(undefined)}>
          <Text subtle>Revert</Text>
        </Button>
      }
    >
      <ScheduleRepeatOptions
        mutate={handleChange}
        schedule={schedule}
        showStart={false}
      />
    </Section>
  );
};
