import { ReactNode } from "react";

import { WithTheme } from "@ui/theme";

export const PublicPage = ({ children }: { children: ReactNode }) => {
  return (
    <WithTheme color="gray_5" mode="light">
      {children}
    </WithTheme>
  );
};
