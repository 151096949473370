import { sum } from "lodash";

import { AggregatePropRef, Entity, PropertyDef } from "@api";

import { Maybe, maybeMap } from "./maybe";
import { getPropertyValue } from "./property-refs";

export const aggregateBy = <T extends Entity>(
  items: T[],
  agg: AggregatePropRef,
  def: Maybe<PropertyDef>
): number => {
  const values = maybeMap(
    items,
    (item) => getPropertyValue<Entity>(item, agg)?.number
  );

  if (!values?.length) {
    return 0;
  }

  switch (agg.method) {
    case "sum":
      return sum(values);
    case "avg":
      return sum(values) / values.length;
    case "min":
      return Math.min(...values);
    case "max":
      return Math.max(...values);
    default:
      return values.length;
  }
};
