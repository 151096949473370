import { useRef } from "react";
import { useDebouncedCallback } from "use-debounce";

import { useShortcut } from "@utils/event";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { TextInput } from "@ui/input";

import styles from "./search-input.module.css";

interface Props {
  search: Maybe<string>;
  setSearch: Fn<Maybe<string>, void>;
  autoFocus?: boolean;
  onBlur?: () => void;
}

export const SearchInput = ({
  search,
  setSearch,
  autoFocus = false,
  onBlur,
}: Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const onChange = useDebouncedCallback(setSearch, 100, { leading: true });

  useShortcut(
    { command: true, key: "KeyF" },
    // Focus on command + f
    () => ref.current?.focus(),
    [ref.current]
  );

  return (
    <TextInput
      ref={ref}
      variant="secondary"
      clearOnEscape={true}
      placeholder="Find..."
      updateOn="change"
      autoFocus={autoFocus}
      className={styles.input}
      onBlur={onBlur}
      value={search || ""}
      onChange={onChange}
    />
  );
};
