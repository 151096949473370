import { isString, last } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { ClearIndicatorProps } from "react-select";

import {
  DatabaseID,
  EntityType,
  hasLocation,
  isPerson,
  isTeam,
  RelationRef,
} from "@api";

import { useGetItemFromAnyStore } from "@state/generic";
import { useMe } from "@state/persons";
import { useActiveWorkspaceId } from "@state/workspace";

import { composel,Fn } from "@utils/fn";
import { maybeTypeFromId } from "@utils/id";
import { isDefined, Maybe, when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";
import { fromScope, toBaseScope, toParentScope, toScope } from "@utils/scope";

import { Button } from "@ui/button";
import {
  ButtonProps as LocationButtonProps,
  LocationButton,
} from "@ui/location-button";
import { GlobalEntitySelect, RelationSelectProps } from "@ui/select";

import { Option } from "./single-select";

type LocationSelectProps = {
  onChange: Fn<string, void>;
  source: Maybe<DatabaseID>;
  allowed?: EntityType[];
} & Omit<LocationButtonProps, "className"> &
  Omit<RelationSelectProps, "options" | "onChange" | "value">;

export const PrivateIndicator = <T extends Option, M extends boolean>({
  selectOption,
}: ClearIndicatorProps<T, M>) => {
  const me = useMe();
  return (
    <Button
      size="small"
      subtle
      onClick={() => selectOption({ id: me.id } as T)}
    >
      Private
    </Button>
  );
};

export const LocationSelect = ({
  location,
  onChange,
  variant,
  source,
  allowed,
  open: _open,
  setOpen: _setOpen,
  className,
  ...rest
}: LocationSelectProps) => {
  const me = useMe();
  const wID = useActiveWorkspaceId();
  const getItem = useGetItemFromAnyStore();

  const localOpen = useState(rest.defaultOpen || false);
  const [open, setOpen] = isDefined(_setOpen) ? [_open, _setOpen] : localOpen;

  const filter = useMemo((): Partial<DatabaseID> => {
    // When private then default to picking a team
    if (location === me?.id) {
      return { type: "team", scope: wID };
    }

    const parentType =
      when(toParentScope(location), maybeTypeFromId<EntityType>) || "team";
    return {
      type: parentType === "team" ? undefined : parentType,
      scope: toBaseScope(location || wID),
    };
  }, [location]);

  const value = useMemo(
    () => composel(fromScope, last, toRef)(location),
    [location]
  );

  const handleChange = useCallback(
    (value: Maybe<RelationRef>) => {
      const newParent = when(value?.id || me.id, getItem);

      if (!newParent) {
        return undefined;
      }

      // Team and person don't nest the location
      const newLocation =
        hasLocation(newParent) && !isTeam(newParent) && !isPerson(newParent)
          ? toScope(newParent.location, newParent.id)
          : toScope(newParent.id);

      return onChange(newLocation);
    },
    [me, onChange]
  );

  if (!location) {
    return <></>;
  }

  return (
    <GlobalEntitySelect
      key="entity-selector"
      value={value}
      open={open}
      setOpen={setOpen}
      closeOnBlur={false}
      allowed={allowed || "*"}
      type={filter.type}
      scope={filter.scope}
      onChange={handleChange}
      portal={true}
      overrides={{ ClearIndicator: PrivateIndicator }}
      {...rest}
    >
      <LocationButton
        location={location}
        variant={variant}
        {...rest}
        className={isString(className) ? className : className?.trigger}
      />
    </GlobalEntitySelect>
  );
};
