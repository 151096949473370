import { DatabaseID } from "@api";

import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { extractTeam } from "@utils/scope";

import { Box } from "@ui/icon";
import { SectionLabel } from "@ui/label";
import { Tag } from "@ui/tag";

import styles from "./package-label.module.css";

type TagProps = DatabaseID & {
  plural?: boolean;
  disabled?: boolean;
};

export const PackageTag = ({ type, scope, plural, disabled }: TagProps) => {
  const toEntityLabel = useEntityLabels(scope, { plural });
  const goTo = useGoTo();
  return (
    <Tag
      className={styles.packageTag}
      onClick={() =>
        !disabled &&
        when(extractTeam(scope), (t) => goTo(`/${t}/settings/fields`))
      }
      icon={Box}
      name={toEntityLabel(type)}
    />
  );
};

export const PackageLabel = ({
  type,
  scope,
  format = (s) => s,
}: DatabaseID & { format?: Fn<string, string> }) => {
  const toEntityLabel = useEntityLabels(scope);
  return (
    <SectionLabel className={styles.blue} text={format(toEntityLabel(type))} />
  );
};
