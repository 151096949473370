import { Content } from "@api";

import { useLazyPropertyValue } from "@state/databases";
import { useUpdateEntity } from "@state/generic";

import { cx } from "@utils/class-names";
import { formatShort } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";
import { useSlowSelected } from "@utils/selectable";

import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Icon, iconFromString } from "@ui/icon";
import { SectionLabel } from "@ui/label";
import { PropertyValue } from "@ui/property-value";
import { PropertyValueStack } from "@ui/property-value-stack";
import { SelectableListCard } from "@ui/selectable-items";

import { ListCardOpts } from "../types";

import styles from "./styles.module.css";

export const ContentCard = (ps: ListCardOpts<Content>) => {
  const { item, showProps, selection, setSelection, className, onChange } = ps;
  const selected = useSlowSelected(item.id, selection);
  const mutate = useUpdateEntity(item.id);
  const statusProp = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  const ownerProp = useLazyPropertyValue(item, {
    field: "owner",
    type: "relation",
  });
  const publishProp = useLazyPropertyValue(item, {
    field: "publish",
    type: "date",
  });
  const editable = useSlowSelected(item.id, selection);

  return (
    <EntityContextMenu
      entity={item}
      selection={selection}
      setSelection={setSelection}
    >
      <SelectableListCard {...ps} className={cx(styles.task, className)}>
        <SpaceBetween className={styles.upper}>
          <HStack gap={4}>
            {when(iconFromString(item.icon), (i) => (
              <Icon icon={i} />
            ))}
            {publishProp && (
              <PropertyValue
                source={item.source}
                editable={false}
                valueRef={publishProp}
              >
                <SectionLabel>
                  {item.publish
                    ? useISODate(item.publish, formatShort)
                    : "Not Set"}
                </SectionLabel>
              </PropertyValue>
            )}
          </HStack>
          <HStack gap={0}>
            {statusProp && (
              <PropertyValue
                source={item.source}
                onChange={(v) => mutate(asMutation(statusProp, v.status))}
                valueRef={statusProp}
                parent={item}
                variant="icon-only"
              />
            )}
            {ownerProp && ownerProp.value.relation && (
              <PropertyValue
                source={item.source}
                onChange={(v) => mutate(asMutation(ownerProp, v.relation))}
                valueRef={ownerProp}
                variant="icon-only"
              />
            )}
          </HStack>
        </SpaceBetween>

        <VStack
          className={styles.middle}
          gap={0}
          fit="container"
          align="stretch"
        >
          <EditableText
            key={item.id}
            text={item.name || ""}
            textSize="default"
            placeholder="Untitled"
            disabled={!editable}
            blurOnEnter={true}
            onChange={(v) =>
              onChange?.(asMutation({ field: "name", type: "text" }, v))
            }
          />

          <PropertyValueStack
            wrap
            item={item}
            props={showProps}
            editable={selected}
            blacklist={["status", "assigned", "title", "icon", "code"]}
            onChange={onChange}
            hideEmpty={ps.hideEmpty}
          />
        </VStack>
      </SelectableListCard>
    </EntityContextMenu>
  );
};
