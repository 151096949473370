import { map, uniq } from "lodash";
import { useMemo } from "react";

import { CommandItem } from "@ui/command-menu";

import { useCommandSearch } from "../utils";
import { SetPropertyCommands } from "./types";

// List of commmon emojiis
// prettier-ignore
const COMMON: string[] = [
  "👍","💬","📅","📝","✅","🚀","⚡","🔔","📈","📊",
  "🔍","🔒","🔑","🛠️","💡","📤","📥","🕒","👥","🏆",
  "✉️","🛣️","🗺️","🧘","🐣","🎨","🎙️","📰","📱","💻",
];

export const IconCommands = ({ mutate }: SetPropertyCommands) => {
  const search = useCommandSearch();

  const options = useMemo(() => {
    return map(search?.length ? uniq([search, ...COMMON]) : COMMON, (emoji) => {
      return (
        <CommandItem
          key={`icon-${emoji}`}
          value={`emoji ${emoji}`}
          onClick={() => mutate({ text: emoji })}
        >
          {emoji}
        </CommandItem>
      );
    });
  }, [search]);

  return <>{options}</>;
};
