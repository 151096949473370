import { filter, map, take, without } from "lodash";

import { Entity, EntityRef, ID, PropertyDef, Update } from "@api";

import { Maybe, when } from "@utils/maybe";
import { add, UndoStack } from "@utils/undo";

import {
  AppCommandsAction,
  AppCommandsMode,
  AppCommandsState,
  AppLocationState,
  AppPage,
} from "./types";

export const setAppCommands =
  (commands: AppCommandsState) => (state: AppLocationState) => ({
    ...state,
    commands: commands,
  });

export const updatePage =
  (pageId: ID, changes: Partial<Omit<AppPage, "id">>) =>
  (state: AppLocationState) => ({
    ...state,
    history: map(state.history, (p) =>
      p.id === pageId ? { ...p, ...changes } : p
    ),
  });

export const pushPage = (page: AppPage) => (state: AppLocationState) => ({
  ...state,
  history: [...without(state.history, page), page],
});

export const popPage = (page?: AppPage) => (state: AppLocationState) => ({
  ...state,
  history: page
    ? filter(state.history, (p) => p.id !== page.id)
    : take(state.history, state.history.length - 1),
});

export const setPageEntity = (entity: EntityRef) => (state: Maybe<AppPage>) =>
  when(state, (s) => ({ ...s, entity: entity }));

export const setPageSelected =
  (selected: EntityRef[]) =>
  (state: Maybe<AppPage>): Maybe<AppPage> =>
    when(state, (s) => ({ ...s, selected: selected }));

// Cmdk menu actions

export const setCommands =
  (commands: Partial<AppCommandsState>) =>
  (state: Maybe<AppCommandsState>): Maybe<AppCommandsState> => ({
    ...state,
    ...commands,
  });

export const setCommandsOpen = (open: boolean, editing?: Entity) =>
  setCommands(
    // Clear out the editing/property state on close
    open
      ? {
          open: open,
          mode: editing ? "commands" : "default",
          editing: editing,
          action: undefined,
        }
      : {
          open: false,
          mode: "default",
          editing: undefined,
          action: undefined,
        }
  );

export const setCommandsAction = (
  action: AppCommandsAction,
  open: boolean = true,
  entity?: Maybe<EntityRef>
) => setCommands({ action, open, editing: entity, property: undefined });

export const setCommandsSearching = (searching: boolean = true) =>
  setCommands({ mode: searching ? "searching" : "default", open: true });

export const setMode = (mode: AppCommandsMode) =>
  setCommands({ mode, open: true });

export const closePropertyEdit = () =>
  setCommands({ mode: "default", property: undefined });

export const editPropertyInCmdK = (
  prop: Maybe<PropertyDef<Entity>>,
  entity?: EntityRef
) =>
  prop?.field === "location"
    ? setCommandsAction("move_location", false, entity)
    : setCommands({
        ...when(entity, (e) => ({ editing: e })),
        mode: "property",
        open: true,
        property: prop,
      });

// Page UndoStack Actions

export const setUndoStack =
  (stack: UndoStack<Update<Entity>>) =>
  (state: Maybe<AppPage>): Maybe<AppPage> =>
    when(state, (s) => ({ ...s, undoStack: stack }));

export const addToStack =
  <T extends Entity = Entity>(update: Update<T> | Update<T>[]) =>
  (state: Maybe<AppPage>): Maybe<AppPage> =>
    when(state, (s) => ({
      ...s,
      undoStack: add(update as Update<Entity>, s.undoStack),
    }));
