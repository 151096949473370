import { groupBy, map, orderBy, toPairs } from "lodash";
import { useMemo, useState } from "react";

import { Action, Entity, Meeting, Update } from "@api";

import { useQueueUpdates } from "@state/generic";
import { useRelatedActions } from "@state/meetings";

import { justOne } from "@utils/array";
import { daysAgo, timeAgo, toStartOfDay } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { asUpdate } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { CheckIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { PaneContainer, PaneHeader } from "@ui/pane-header";
import { Sheet } from "@ui/sheet-layout";

import { render, useEngine } from "..";

import styles from "./styles.module.css";

interface Props {
  meeting: Meeting;
}

export const MeetingActionsPane = ({ meeting }: Props) => {
  const pageId = usePageId();
  const pushTo = usePushTo();
  const engine = useEngine("action");
  const [showDone, setShowDone] = useState(false);
  const actions = useRelatedActions(meeting, showDone);
  const mutate = useQueueUpdates(pageId);

  // Group by created day
  const grouped = useMemo(() => {
    return toPairs(
      groupBy(
        orderBy(actions, (a) => a.assigned?.id),
        (action) => useISODate(action.createdAt, toStartOfDay)
      )
    );
  }, [actions]);

  return (
    <Sheet size="secondary" className={styles.meetingPane}>
      <PaneHeader
        title="Actions"
        actions={
          <Label
            subtle
            fit="content"
            iconRight={<CheckIcon checked={showDone} />}
            onClick={() => setShowDone(!showDone)}
          >
            Show completed
          </Label>
        }
      />

      <PaneContainer>
        <Menu>
          {map(grouped, ([key, actions]) => (
            <MenuGroup
              key={key}
              label={useISODate(key, (d) => (daysAgo(d) < 1 ? "" : timeAgo(d)))}
            >
              {map(actions, (item) =>
                render(engine.asMenuItem, {
                  key: item.id,
                  item: item,
                  showLocation: false,
                  onOpen: (a) =>
                    pushTo(
                      when(safeAs<Action>(a)?.refs?.meeting, justOne) || a
                    ),
                  onChange: (cs) =>
                    mutate(asUpdate(item, cs) as Update<Entity>),
                })
              )}
            </MenuGroup>
          ))}
        </Menu>
      </PaneContainer>
    </Sheet>
  );
};
