import { forwardRef,ReactNode, Ref } from "react";

import { cx } from "@utils/class-names";

import styles from "./menu.module.css";

interface Props {
  children?: ReactNode;
  className?: string;
}

export const Menu = forwardRef(
  ({ children, className }: Props, ref: Ref<HTMLDivElement>) => {
    return (
      <div ref={ref} className={cx(styles.menu, className)}>
        {children}
      </div>
    );
  }
);
