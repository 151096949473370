import { sentenceCase } from "change-case";
import { useMemo } from "react";

import { Entity, PropertyDef, RichText } from "@api";

import { useQueueUpdates } from "@state/generic";

import { isEmpty, toCounts } from "@utils/checklist";
import { Maybe } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { CollapsibleSection } from "@ui/collapsible-section";
import { HStack } from "@ui/flex";
import { ChecklistIcon, Icon } from "@ui/icon";
import ProgressBar from "@ui/progress-bar";
import { ChecklistEditor } from "@ui/rich-text";
import { SectionLabel } from "@ui/text";

interface Props {
  entity: Entity;
  def: PropertyDef<Entity, "checklist">;
  value: Maybe<RichText>;
}

export const CheckListCompletion = ({ value }: { value: Maybe<RichText> }) => {
  const counts = useMemo(() => toCounts(value), [value]);
  return (
    <HStack gap={0}>
      <Icon size="small" icon={ChecklistIcon} />
      <SectionLabel>
        {counts.complete}/{counts.total}
      </SectionLabel>
    </HStack>
  );
};

export const ChecklistSection = ({ entity, def, value }: Props) => {
  const pageId = usePageId();
  const mutate = useQueueUpdates(pageId);
  const counts = useMemo(() => toCounts(value), [value]);
  const title = useMemo(() => def.label || sentenceCase(def.field), [def]);

  return (
    <CollapsibleSection
      labelSize="medium"
      title={title}
      actions={
        <HStack>
          <ProgressBar
            label={`Checked ${counts.complete}/${counts.total}`}
            percent={counts.percent}
          />
        </HStack>
      }
    >
      <ChecklistEditor
        content={value?.html || ""}
        onChanged={(html) =>
          mutate(
            asUpdate(entity, [
              asMutation(def, isEmpty({ html }) ? undefined : { html }),
            ])
          )
        }
      />
    </CollapsibleSection>
  );
};
