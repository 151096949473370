import { CSSProperties, ReactNode } from "react";

import { cx } from "@utils/class-names";
import { Maybe } from "@utils/maybe";

import styles from "./styles.module.css";

interface Props {
  url: Maybe<string>;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

export const ImageIcon = ({ url, className, style, children }: Props) => (
  <span
    className={cx(styles.image, className)}
    style={url ? { ...style, backgroundImage: `url(${url})` } : style}
  >
    {children}
  </span>
);
