import { map } from "lodash";

import { DatabaseID, Entity } from "@api";

import {
  useActionWorkflows,
  useRunWorkflowState,
  WorkflowAction,
  WorkflowData,
} from "@state/workflows";

import { withHandle } from "@utils/event";
import { useShowMore } from "@utils/hooks";

import { Button,Props as ButtonProps } from "@ui/button";
import { Text } from "@ui/text";

import { WorkflowCollectDialog } from "./workflow-collect-dialog";

interface Props<T extends Entity> {
  action: WorkflowAction<T>;
  data: WorkflowData<T>;
  source: DatabaseID;
  variant: ButtonProps["variant"];
  inset?: boolean;
  size?: ButtonProps["size"];
}

export const WorkflowActionButton = <T extends Entity>({
  action,
  source,
  data,
  variant = "secondary",
  inset = false,
  size,
}: Props<T>) => {
  const { run, collecting, cancelCollecting, context } = useRunWorkflowState(
    source.type,
    data
  );

  return (
    <>
      {collecting && context && (
        <WorkflowCollectDialog
          action={action}
          data={data}
          source={source}
          context={context}
          onCollected={run}
          onCancel={cancelCollecting}
        />
      )}
      <Button
        variant={variant}
        inset={inset}
        icon={action.icon}
        onClick={withHandle(() => run(action))}
        loading={!!collecting}
        size={size}
      >
        {action.title}
      </Button>
    </>
  );
};

interface WorkflowActionsProps<T extends Entity> {
  entity: T;
  highlightFirst?: boolean;
  showAll?: boolean;
  size?: ButtonProps["size"];
}

export const WorkflowActions = <T extends Entity>({
  entity,
  showAll,
  highlightFirst = true,
  size,
}: WorkflowActionsProps<T>) => {
  const [workflowActions, actionData] = useActionWorkflows(entity);
  const { visible, hasMore, showMore, moreCount } = useShowMore(
    workflowActions,
    3,
    showAll
  );

  if (!workflowActions?.length) {
    return <></>;
  }

  return (
    <>
      {map(
        visible,
        (a, i) =>
          actionData && (
            <WorkflowActionButton
              key={a.id}
              variant={
                a.variant ||
                (highlightFirst && i === 0 ? "primary" : "secondary")
              }
              size={size}
              action={a}
              data={actionData}
              source={entity.source}
            />
          )
      )}
      {hasMore && (
        <Button subtle onClick={showMore}>
          <Text subtle>+{moreCount}</Text>
        </Button>
      )}
    </>
  );
};
