import { first } from "lodash";
import { useState } from "react";

import { Content, PropertyRef } from "@api";

import { useIsShowing, useLazyPropertyValue } from "@state/databases";
import { useLazyEntities } from "@state/generic";

import { whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { formatShort } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { toMutation } from "@utils/property-mutations";
import { useSlowSelected } from "@utils/selectable";

import { WorkProgression } from "@ui/child-work-progression";
import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { Icon, iconFromString } from "@ui/icon";
import { Label, SectionLabel } from "@ui/label";
import { SmartLocationLabel } from "@ui/location-button";
import { MenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { PropertyValueStack } from "@ui/property-value-stack";
import { SelectableListItem } from "@ui/selectable-items";
import { SubListButton, SubListItems } from "@ui/sub-list-items";

import { ListItemOpts, UIEngine } from "../types";
import { ContentCard } from "./card";
import ContentPane from "./pane";

import styles from "./styles.module.css";

const DEFAULT_PROPS: PropertyRef<Content>[] = [
  { field: "publish", type: "date" },
];

function ContentListItem(ps: ListItemOpts<Content>) {
  const { item, className, showProps, onChange, selection, setSelection } = ps;
  const [showSubs, setShowSubs] = useState(false);
  const editable = useSlowSelected(item.id, selection);
  const childTasks = useLazyEntities(item.refs?.tasks || [], false);
  const isShowing = useIsShowing(showProps);

  return (
    <>
      <EntityContextMenu
        entity={item}
        selection={selection}
        setSelection={setSelection}
      >
        <SelectableListItem className={cx(styles.listItem, className)} {...ps}>
          <SpaceBetween className={styles.upper}>
            <HStack className={styles.middle} gap={4}>
              <SubListButton
                {...ps}
                open={showSubs}
                setOpen={setShowSubs}
                field="refs.tasks"
              />
              {item.publish && !isShowing("publish") && (
                <SectionLabel>
                  {useISODate(item.publish, formatShort)}
                </SectionLabel>
              )}
              {when(iconFromString(item.icon), (i) => (
                <Icon icon={i} />
              ))}
              <EditableText
                key={item.id}
                text={item.name || ""}
                placeholder="Untitled"
                disabled={!editable}
                blurOnEnter={true}
                onChange={(v) =>
                  onChange?.(
                    toMutation(item, { field: "name", type: "text" }, v)
                  )
                }
              />

              <SmartLocationLabel
                size="small"
                subtle
                location={item.location}
              />
            </HStack>

            <HStack gap={0}>
              <PropertyValueStack
                className={styles.rowDetails}
                justify="flex-end"
                gap={2}
                item={item}
                props={whenEmpty(showProps, DEFAULT_PROPS)}
                onChange={onChange}
                hideEmpty={ps.hideEmpty}
              />
              {!!childTasks?.length && <WorkProgression items={childTasks} />}
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>

      {showSubs && <SubListItems {...ps} field="refs.tasks" />}
    </>
  );
}

export const ContentEngine: UIEngine<Content> = {
  asMenuItem: function ({ className, item, showProps, onOpen, ...rest }) {
    const childTasks = useLazyEntities(item.refs?.tasks || [], false);
    const valueRef = useLazyPropertyValue(
      item,
      first(showProps) || {
        field: "publish",
        type: "date",
      }
    );

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          className={cx(className)}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <HStack gap={4}>
              <Label icon={when(item.icon, iconFromString)} text={item.name} />
              <SmartLocationLabel
                showIcons={false}
                showTeam={false}
                subtle
                size="small"
                location={item.location}
              />
            </HStack>

            <HStack>
              <PropertyValue
                valueRef={valueRef}
                source={item.source}
                variant="icon-only"
                editable={false}
              />
              {!!childTasks?.length && <WorkProgression items={childTasks} />}
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: ContentCard,
  asListItem: ContentListItem,
  asPrimaryPane: ContentPane,
};
