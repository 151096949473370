import { Entity } from "@api";
import { TypeForEntity } from "@api/mappings";

import { ensureMany, overlaps } from "@utils/array";
import { Fn } from "@utils/fn";

import {
  WorkflowAction,
  WorkflowDefinition,
  WorkflowSuggestion,
  WorkflowTrigger,
  WorkflowTriggerDidUpdate,
  WorkflowTriggerWillUpdate,
} from "./types";

export const isAction = <T extends Entity>(
  d: WorkflowDefinition<T>
): d is WorkflowAction<T> => d.trigger === "ACTION";

export const isChange = <T extends Entity>(
  d: WorkflowDefinition<T>
): d is WorkflowTrigger<T> => d.trigger === "WILL_UPDATE";

export const whenSuggestionWorkflow = <E extends Entity, R>(
  def: WorkflowDefinition<Entity> | WorkflowDefinition<E>,
  type: TypeForEntity<E>,
  fn: Fn<WorkflowSuggestion<E>, R>
) =>
  def.trigger === "SUGGEST" && overlaps(ensureMany(def.type), [type, "*"])
    ? fn(def as WorkflowSuggestion<E>)
    : undefined;

export const whenWillUpdate = <E extends Entity, R>(
  def: WorkflowDefinition<Entity> | WorkflowDefinition<E>,
  type: TypeForEntity<E>,
  fn: Fn<WorkflowTriggerWillUpdate<E>, R>
) =>
  def.trigger === "WILL_UPDATE" && overlaps(ensureMany(def.type), [type, "*"])
    ? fn(def as WorkflowTriggerWillUpdate<E>)
    : undefined;

export const whenDidUpdate = <E extends Entity, R>(
  def: WorkflowDefinition<Entity> | WorkflowDefinition<E>,
  type: TypeForEntity<E>,
  fn: Fn<WorkflowTriggerDidUpdate<E>, R>
) =>
  def.trigger === "DID_UPDATE" && overlaps(ensureMany(def.type), [type, "*"])
    ? fn(def as WorkflowTriggerDidUpdate<E>)
    : undefined;

export const whenActionWorkflow = <E extends Entity, R>(
  def: WorkflowDefinition<Entity> | WorkflowDefinition<E>,
  type: TypeForEntity<E>,
  fn: Fn<WorkflowAction<E>, R>
) =>
  def.trigger === "ACTION" && overlaps(ensureMany(def.type), [type, "*"])
    ? fn(def as WorkflowAction<E>)
    : undefined;
