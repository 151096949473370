import { sentenceCase } from "change-case";
import { filter, find, first, map } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { EntityType, ID, PropertyRef } from "@api";

import { useEntityLabels } from "@state/settings";
import {
  useLazyItemsForView,
  useLazyViewsForParent,
  useToViewTitle,
} from "@state/views";

import { composel } from "@utils/fn";
import { equalsAny } from "@utils/logic";
import { Maybe, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { plural } from "@utils/string";

import { Button } from "@ui/button";
import { ClickableSection } from "@ui/clickable-section";
import { CollapsibleSection } from "@ui/collapsible-section";
import { render, useEngine } from "@ui/engine";
import { HStack } from "@ui/flex";
import { AngleDownIcon, ArrowUpRight, ViewIcon } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { RelationSelect } from "@ui/select";
import {
  Props as ViewResultsListProps,
  ViewResultsList,
} from "@ui/view-results-list";

type ViewRelationProps = Omit<ViewResultsListProps, "viewId"> & {
  sectionLabel?: string;
  parentId: ID;
  childType: EntityType;
};

const toSectionShowProps = (props: Maybe<PropertyRef[]>) =>
  props &&
  filter(
    props,
    (p) => !equalsAny(p.type, ["relations", "property", "properties", "json"])
  );

export const ViewRelationsSection = ({
  parentId,
  childType,
  sectionLabel,
  showProps: _showProps,
  ...rest
}: ViewRelationProps) => {
  const pushTo = usePushTo();
  const views = useLazyViewsForParent(parentId || "", childType, true);
  const [view, setView] = useState(() => first(views));
  const toLabel = useEntityLabels(view?.source?.scope || "");
  const toViewTitle = useToViewTitle(view);

  const showProps = useMemo(
    () => toSectionShowProps(_showProps || view?.showProps),
    [view?.showProps, _showProps]
  );

  const handleViewOpen = useCallback(() => view && pushTo(view), [view?.id]);

  const title = useMemo(
    () =>
      sectionLabel ||
      when(view?.entity, composel(toLabel, sentenceCase, plural)) ||
      "Work",
    [sectionLabel, view?.entity]
  );

  useEffect(() => {
    if (!view || view?.for?.id !== parentId) {
      setView(first(views));
    }
  }, [parentId, views]);

  return (
    <>
      <ClickableSection
        title={title}
        onClick={handleViewOpen}
        labelSize="medium"
        actions={
          <HStack gap={0}>
            {view && (
              <Button
                icon={
                  <ViewIcon layout={view?.grouping || view?.layout || "list"} />
                }
                size="small"
                subtle
                iconRight={ArrowUpRight}
                onClick={() => pushTo(view)}
              >
                {toViewTitle(view) || "Board"}
              </Button>
            )}

            <RelationSelect
              portal={true}
              value={view}
              onChange={(v) => setView(find(views, { id: v?.id }))}
              clearable={false}
              options={views}
              caret={true}
            >
              <Button size="small" subtle icon={AngleDownIcon} />
            </RelationSelect>
          </HStack>
        }
      >
        {view && (
          <ViewResultsList
            viewId={view.id}
            showFilter={false}
            limit={5}
            {...rest}
            showProps={showProps}
          />
        )}
      </ClickableSection>
    </>
  );
};

export const ViewRelationsMenu = ({
  parentId,
  childType,
  sectionLabel,
  showProps: _showProps,
  ...rest
}: ViewRelationProps) => {
  const pushTo = usePushTo();
  const views = useLazyViewsForParent(parentId || "", childType, true);
  const [view, setView] = useState(() => first(views));
  const toLabel = useEntityLabels(view?.source?.scope || "");
  const { items } = useLazyItemsForView(view?.id || "");

  const engine = useEngine(view?.entity);

  const title = useMemo(
    () =>
      sectionLabel ||
      when(view?.entity, composel(toLabel, sentenceCase, plural)) ||
      "Work",
    [sectionLabel, view?.entity]
  );

  useEffect(() => {
    if (!view || view?.for?.id !== parentId) {
      setView(first(views));
    }
  }, [parentId, views]);

  if (!view || !engine) {
    return <></>;
  }

  return (
    <>
      <CollapsibleSection title={title} padded={false}>
        <Menu>
          <MenuGroup>
            {map(items.sorted, (r) =>
              render(engine.asMenuItem, {
                key: r.id,
                item: r,
                iconRight: ArrowUpRight,
                onOpen: pushTo,
              })
            )}
          </MenuGroup>
        </Menu>
      </CollapsibleSection>
    </>
  );
};
