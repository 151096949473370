import { flatMap, map } from "lodash";
import { useMemo, useState } from "react";

import { Entity, PropertyDef, SingleFilterQuery } from "@api";

import { ensureMany } from "@utils/array";
import { isEmptyFilter, toFilterOption } from "@utils/filtering";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";

import { MultiSelect } from "@ui/select";

import { OpHeader } from "./op-header";
import { TriggerButton } from "./trigger-button";

export interface Props {
  filter: SingleFilterQuery;
  definition: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  editing?: boolean;
}

export default function PropertyFilterSelect({
  filter,
  definition,
  onChanged,
  editing,
}: Props) {
  const [open, setOpen] = useState(false);
  const fitlerType = filter.type;
  if (
    fitlerType !== "select" &&
    fitlerType !== "multi_select" &&
    fitlerType !== "status"
  ) {
    throw new Error(
      "Can only use SelectPropertyFilter with select or status types."
    );
  }

  const selected = useMemo(
    () =>
      when(filter.values?.[fitlerType], (vs) =>
        map(vs, (v) => toFilterOption(definition, v))
      ) ??
      when(filter.value?.[fitlerType], (v) => [toFilterOption(definition, v)]),
    [definition, filter]
  );

  const options = useMemo(
    () =>
      map(definition.values[fitlerType], (v) => toFilterOption(definition, v)),
    [definition]
  );

  const isEmpty = isEmptyFilter(filter);

  return (
    <MultiSelect
      open={open}
      setOpen={setOpen}
      value={selected}
      options={!isEmpty ? options : []}
      searchable={!isEmpty}
      defaultOpen={editing}
      portal={true}
      closeOnBlur={false}
      onChange={(values) => {
        onChanged?.({
          ...filter,
          value: undefined,
          values: {
            [fitlerType]: flatMap(values, (v) =>
              map(ensureMany(v?.value[fitlerType]), toRef)
            ),
          },
        });
      }}
      placeholder={`Filter values...`}
      header={
        <OpHeader
          filter={filter}
          prop={definition}
          onChanged={onChanged}
          setOpen={setOpen}
        />
      }
    >
      <TriggerButton filter={filter} prop={definition} onChanged={onChanged} />
    </MultiSelect>
  );
}
