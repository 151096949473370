import { atomFamily } from "recoil";

import { Content, ID } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type ContentStoreState = StoreState<Content>;

export const WorkspaceContentStoreAtom = atomFamily<ContentStoreState, ID>({
  key: "WorkspaceContentStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "content")],
});

export const ContentStoreAtom = WorkspaceWrappedAtom(
  "ContentStoreAtom",
  WorkspaceContentStoreAtom
);
