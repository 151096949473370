import { HasRefs, Page, Ref } from "@api";

import { useNestedEntitiesOfType } from "@state/generic";
import { useTemplateViewId } from "@state/views";

import { useShowMore } from "@utils/hooks";
import { Maybe } from "@utils/maybe";

import { CollapsibleSection } from "@ui/collapsible-section";
import { Container } from "@ui/container";
import { BrowserLayout } from "@ui/view-layouts";

interface Props {
  refs: Maybe<Ref[]>;
  entity?: HasRefs;
  mode?: "important" | "unseen" | "pinned" | "all";
  sectionLabel?: string;
  hideOnEmpty?: boolean;
  onClick?: (update: Page) => void;
}

export const PagesSection = ({
  entity,
  refs,
  sectionLabel,
  mode = "all",
  hideOnEmpty = false,
  onClick: _onClick,
}: Props) => {
  const pages = useNestedEntitiesOfType(entity, "page");
  const items = useShowMore(pages.children, 5);
  const viewId = useTemplateViewId("nested-pages", { parent: entity?.id });

  if (!entity) {
    return <></>;
  }

  if (hideOnEmpty && !items.visible.length) {
    return <></>;
  }

  return (
    <CollapsibleSection
      labelSize="medium"
      title={`${sectionLabel || "Pages"}`}
      padded={false}
    >
      <Container padding="none" inset="horizontal">
        <BrowserLayout id={viewId} />
      </Container>
    </CollapsibleSection>
  );
};
