import { MarkType, NodeType } from "@remirror/pm/model";
import { Editor, Node, NodeConfig } from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import {
  mergeAttributes,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { find as findLink } from "linkifyjs";
import { find, first, isString } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { isView, Link, Ref, View } from "@api";

import { usePersistedId } from "@state/generic";
import { toShortTitle } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { justOne, OneOrMany } from "@utils/array";
import { cx } from "@utils/class-names";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { isViewId } from "@utils/id";
import { extractTractionIds } from "@utils/link";
import { Maybe, safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { EmptyState } from "@ui/empty-state";
import { render, useEngine } from "@ui/engine";
import { HStack } from "@ui/flex";
import { Icon, PlusIcon, Search, ViewIcon } from "@ui/icon";
import { ViewSelect } from "@ui/select/view";
import { TextLarge } from "@ui/text";
import { ViewCardCompact } from "@ui/view-card-compact";

import styles from "./board-extension.module.css";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    board: {
      setBoard: (options: { id: string; title?: string }) => ReturnType;
    };
  }
}

export interface BoardOptions {
  scope: string;
}

export const BoardExtension = Node.create<NodeConfig<BoardOptions>>({
  name: "board",
  // priority: 1000,
  excludes: "_",

  group: "block",

  inline: false,

  atom: true,

  selectable: true,

  draggable: false,

  addOptions() {
    return {
      ...this.parent?.(),
      scope: "",
    };
  },

  addProseMirrorPlugins() {
    return [
      pasteHandler({
        type: this.type,
        editor: this.editor,
        handle: (link) => {
          const viewId = find(extractTractionIds(link.url), isViewId);
          if (viewId) {
            this.editor.commands.setBoard({
              id: viewId,
              title: link.text,
            });
            return true;
          }
          return false;
        },
      }),
    ];
  },

  addCommands() {
    return {
      setBoard:
        (options) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const node = this.type.create({
            id: options.id,
            title: options.title,
          });

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
    };
  },

  addAttributes() {
    return {
      id: {
        default: "",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-board-id"),
        renderHTML: (attributes) => {
          return {
            "data-board-id": attributes.id,
          };
        },
      },

      title: {
        default: "",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-board-title"),
        renderHTML: (attributes) => {
          return {
            "data-board-title": attributes.title,
          };
        },
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(BoardComp);
  },

  // Rules for extracting from html
  parseHTML() {
    return [
      {
        tag: "embed",
        priority: 2001, // Beats normal embed
        excludes: "_",

        getAttrs: (element) => {
          if (isString(element) || !element.hasAttribute("data-board-id")) {
            return false;
          }

          // Above attribute parsing extracts all values
          return {};
        },
      },
    ];
  },
  renderHTML(props) {
    const { HTMLAttributes } = props;
    return [
      "embed",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});

export const BoardComp = (props: NodeViewProps) => {
  const pushTo = usePushTo();
  const wID = useActiveWorkspaceId();
  const scope = props.extension.options.scope || wID;
  const id = useMemo(
    () => props.node.attrs.id as string,
    [props.node.attrs.id]
  );
  const persisted = usePersistedId(id);
  const defaults = useMemo(
    () =>
      ({
        location: scope,
        source: { type: "view", scope: scope },
      } as Partial<View>),
    [scope]
  );

  const handleNewView = useCallback(
    (ref: OneOrMany<Ref | View>) => {
      const view = justOne(ref);

      props.updateAttributes({
        id: view?.id,
        title: isView(ref) ? toShortTitle(ref) : "",
      });
    },
    [props.editor]
  );

  // After the view is created, update the component
  useEffect(() => {
    if (!!persisted && id !== persisted) {
      props.updateAttributes({ id: persisted });
    }
  }, [id, persisted]);

  return (
    <NodeViewWrapper>
      {!id && (
        <Card className={cx(styles.card, props.selected && styles.selected)}>
          <div
            onClick={withHardHandle(() => {})}
            onMouseDown={withHardHandle(() => {})}
            onMouseUp={withHardHandle(() => {})}
          >
            <EmptyState>
              <Icon size="large" icon={ViewIcon} />
              <TextLarge bold>Create or link a work board...</TextLarge>
              <HStack>
                <ViewCreateButton defaults={defaults} onSaved={handleNewView}>
                  Create new
                </ViewCreateButton>

                <ViewSelect
                  scope={scope}
                  placeholder="Select existing..."
                  onChange={(vs) => when(first(vs), handleNewView)}
                >
                  <Button icon={Search} subtle>
                    Link existing
                  </Button>
                </ViewSelect>
              </HStack>
            </EmptyState>
          </div>
        </Card>
      )}
      {id && (
        <ViewCardCompact
          viewId={id}
          className={cx(styles.card, props.selected && styles.selected)}
          as="card"
          empty="show"
          limit={5}
          onOpen={pushTo}
        />
      )}
    </NodeViewWrapper>
  );
};

export const ViewCreateButton = ({
  children,
  defaults,
  onSaved,
  onCancel,
}: {
  defaults: Partial<View>;
  onSaved: Fn<OneOrMany<Ref>, void>;
  onCancel?: Fn<void, void>;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const engine = useEngine("view");

  function withClose<A>(fn: Maybe<Fn<A, void>>) {
    return (a: A) => {
      fn?.(a);
      setOpen(false);
    };
  }

  return (
    <>
      {open &&
        render(engine.asCreateDialog, {
          defaults: defaults,
          onSaved: withClose(onSaved),
          onCancel: withClose(onCancel),
        })}

      <Button subtle icon={PlusIcon} onClick={() => setOpen(true)}>
        {children || "New view"}
      </Button>
    </>
  );
};

type PasteHandlerOptions = {
  editor: Editor;
  type: MarkType | NodeType;
  handle: Fn<Link, boolean>;
};

export function pasteHandler({
  editor,
  handle,
  type,
}: PasteHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey("handlePasteBoard"),
    props: {
      handlePaste: (view, event, slice) => {
        const { state } = view;
        const { selection } = state;

        // Leave to link hyperlinking when there is a selection
        if (!selection.empty) {
          return false;
        }

        // Skip if the user is pasting with shift key pressed
        if (
          safeAs<{ input: { shiftKey: boolean } }>(editor.view)?.input?.shiftKey
        ) {
          return false;
        }

        let textContent = "";

        slice.content.forEach((node) => {
          textContent += node.textContent;
        });

        const link = findLink(textContent).find(
          (item) => item.isLink && item.value === textContent
        );

        if (!textContent || !link || !link.isLink) {
          return false;
        }

        return handle({ url: link.href, text: link.value });
      },
    },
  });
}
