import { useMemo } from "react";

import { BaseColor } from "@api";

import { useThemeColor, useThemeMode } from "@state/settings";

import { fromColor, toColorVar } from "@utils/color";

export function GlassBackground({ children }: { children: React.ReactNode }) {
  const themeColor = useThemeColor();
  const themeMode = useThemeMode();

  const [base, shade] = useMemo(
    () => (!themeColor ? ["white", 5] : fromColor(themeColor)),
    [themeColor]
  );

  const colorVar = useMemo(
    () => toColorVar(base as BaseColor, shade),
    [base, shade]
  );
  const [fadeFrom, fadeTo] = useMemo(
    () => (themeMode === "dark" ? [8, 9] : [7, 8]),
    [themeMode]
  );

  return (
    <div
      style={useMemo(
        () => ({
          backgroundImage:
            colorVar &&
            `
            linear-gradient(180deg, rgba(var(--color-rgb-background), 0.${fadeFrom}), rgba(var(--color-rgb-background), 0.${fadeTo})), 
            linear-gradient(180deg, ${colorVar}, ${colorVar})
          `,
          backgroundColor: "FBFBFB",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          heading: "100%",
        }),
        [themeColor, colorVar]
      )}
    >
      {children}
    </div>
  );
}
