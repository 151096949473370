import { map } from "lodash";
import { compose } from "lodash/fp";
import { useCallback } from "react";
import { RecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { Entity, ID, Update } from "@api";

import { ensureArray } from "@utils/array";
import { Fn } from "@utils/fn";

import { applyUpdate, queueUpdate, queueUpdates,setItem } from "./actions";
import { StoreState } from "./atoms";
import { stableID } from "./utils";

export function useStableViewKey<T extends Entity>(
  atom: RecoilState<StoreState<T>>
) {
  const store = useRecoilValue(atom);

  return useCallback((id: ID) => stableID(id, store.aliases), [store]);
}

// Replace usage with just QueueUpdateEffect?
export function useQueueCreateEffect<T extends Entity>(
  atom: RecoilState<StoreState<T>>
) {
  const setStore = useSetRecoilState(atom);

  return useCallback(
    async (def: T, update: Update<T>) => {
      setStore(compose(setItem(def), queueUpdate(update)));
    },
    [setStore]
  );
}

export function useQueueDeleteEffect<T extends Entity>(
  atom: RecoilState<StoreState<T>>
) {
  const setStore = useSetRecoilState(atom);

  return useCallback(
    async (_def: T, update: Update<T>) => {
      setStore(queueUpdate(update));
    },
    [setStore]
  );
}

export function useQueueUpdateEffect<T extends Entity>(
  atom: RecoilState<StoreState<T>>,
  onQueue?: Fn<Update<T>, void>
) {
  const setStore = useSetRecoilState(atom);

  return useCallback(
    (update: Update<T> | Update<T>[]) => {
      const updates = ensureArray(update);
      setStore(queueUpdates(updates));
      map(updates, onQueue);
    },
    [setStore]
  );
}

export function useApplyUpdateEffect<T extends Entity>(
  atom: RecoilState<StoreState<T>>
) {
  const setStore = useSetRecoilState(atom);

  return useCallback(
    async (update: Update<T>) => {
      setStore(applyUpdate(update));
    },
    [setStore]
  );
}
