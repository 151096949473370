import confetti from "canvas-confetti";
import { last } from "lodash";
import { RefObject, useCallback } from "react";

import { Status } from "@api";

import { switchEnum } from "./logic";
import { when } from "./maybe";

export const isWorthy = (status?: Status) =>
  status &&
  (status.name === "Done" ||
    (status.group === "done" && (!status.color || status.color === "green")));

export const explode = (
  element?: HTMLElement,
  overrides?: confetti.Options
) => {
  const button =
    element?.getBoundingClientRect() ||
    last(
      Array.from(document.querySelectorAll(':hover, [aria-selected="true"]'))
    )?.getBoundingClientRect() ||
    last(
      Array.from(document.querySelectorAll(':hover, [aria-selected="true"]'))
    )?.getBoundingClientRect() ||
    last(
      Array.from(document.querySelectorAll(":focus"))
    )?.getBoundingClientRect();

  confetti({
    gravity: 0.45,
    angle: 0,
    spread: 360,
    startVelocity: 30,
    particleCount: 200,
    ticks: 100,
    origin: when(button, (b) => {
      const body = document.body.getBoundingClientRect();
      return {
        x: (b.left + b.width / 2) / body.width,
        y: b.top / body.height, // Since drifting down, shoot from the top middle of the element
      };
    }) || { x: 0.5, y: 0.5 },
    ...overrides,
  });
};

export const magicDust = (
  element?: HTMLElement,
  size?: "default" | "large"
) => {
  const opts = switchEnum(size || "default", {
    default: {
      particleCount: 20,
      decay: 0.6,
      scalar: 0.3,
      angle: 90,
      startVelocity: 20,
      ticks: 40,
    },
    large: {
      particleCount: 60,
      decay: 0.8,
      scalar: 0.3,
      angle: 90,
      spread: 180,
      startVelocity: 30,
      ticks: 60,
    },
  });

  explode(element, {
    colors: ["#8800E1", "#EDD2FF"],
    shapes: ["star"],
    ...opts,
  });
};

export const useMagicDust = (
  ref: RefObject<HTMLElement>,
  size?: "default" | "large"
) => {
  return useCallback(
    () => ref?.current && magicDust(ref.current, size),
    [ref, size]
  );
};
