import { GoogleAuthenticate } from "@ui/authenticate-buttons";
import { Divider } from "@ui/divider";
import { VStack } from "@ui/flex";
import { TractionHorizontal } from "@ui/icon";
import { AuthLayout } from "@ui/page-layout";
import { Sheet } from "@ui/sheet-layout";
import { Text, TextLarge } from "@ui/text";

import { PublicPage } from "./public-page";

import styles from "./public.module.css";

export default function LoginPage() {
  return (
    <PublicPage>
      <AuthLayout>
        <VStack>
          <Sheet className={styles.authCard} height="content">
            <VStack gap={30}>
              <div className={styles.logoCenter}>
                <TractionHorizontal />
              </div>
              <VStack gap={2}>
                <TextLarge bold>Login</TextLarge>
                <Text subtle>
                  Traction accounts are linked to either your slack or notion
                  workspace.
                </Text>
              </VStack>

              <Divider />

              <VStack fit="container">
                <GoogleAuthenticate
                  fit="container"
                  className={styles.authButton}
                >
                  Continue with Google
                </GoogleAuthenticate>
              </VStack>

              <Divider />

              <VStack>
                <Text subtle>
                  By continuing, you agree to Traction&rsquo;s{" "}
                  <a href="/legal/terms">Terms of Service</a> and{" "}
                  <a href="/legal/privacy">Privacy Policy</a>.
                </Text>
              </VStack>
            </VStack>
          </Sheet>
        </VStack>
      </AuthLayout>
    </PublicPage>
  );
}
