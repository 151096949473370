import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { ReactNode } from "react";

import { Fn } from "@utils/fn";

import { HStack, VStack } from "@ui/flex";

import { Label } from "./label";

import styles from "./radio.module.css";

interface RadioItemProps {
  value: string;
  name?: string;
  children?: ReactNode;
  disabled?: boolean;
}

interface RadioGroupProps {
  value: string;
  onChanged?: Fn<string, void>;
  children: ReactNode;
}

export const RadioItem = ({
  name,
  children,
  value,
  disabled,
}: RadioItemProps) => (
  <HStack gap={6}>
    <RadixRadioGroup.Item
      id={name || value}
      value={value}
      disabled={disabled}
      className={styles.radioItem}
    >
      <RadixRadioGroup.Indicator className={styles.radioIndicator} />
    </RadixRadioGroup.Item>
    {!!(name || children) && (
      <Label htmlFor={name || value}>{name || children}</Label>
    )}
  </HStack>
);

export const RadioGroup = ({ value, onChanged, children }: RadioGroupProps) => (
  <RadixRadioGroup.Root defaultValue={value} onValueChange={onChanged}>
    <VStack>{children}</VStack>
  </RadixRadioGroup.Root>
);
