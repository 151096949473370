import * as Popover from "@radix-ui/react-popover";
import { PopoverContentProps } from "@radix-ui/react-popover";
import { isString, split } from "lodash";
import { ReactNode, useMemo, useState } from "react";

import { cx } from "@utils/class-names";
import { useShortcut, withHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { isDefined } from "@utils/maybe";

import styles from "./dropdown.module.css";

type Position = "bottom-left" | "bottom-right";

export interface Props {
  open: boolean;
  setOpen: Fn<boolean, void>;
  trigger: ReactNode;
  children: ReactNode;
  closeOnEscape?: boolean;
  closeOnClickAway?: boolean;
  position?: Position;
  className?: string | { dropdown?: string; popover?: string };
  portal?: boolean;
}

export const useOpenState = (
  open: boolean = false,
  setOpen?: Fn<boolean, void>
) => {
  const local = useState(open ?? false);
  return isDefined(setOpen) ? ([open, setOpen] as const) : local;
};

export const Dropdown = ({
  open,
  setOpen,
  trigger,
  className,
  portal = true, // Always portals now... shouuld remove from props
  children,
  position = "bottom-left",
  closeOnEscape = true,
  closeOnClickAway = true,
}: Props) => {
  const { side, align } = useMemo(() => {
    const [p0, p1] = split(position, "-");
    return {
      side: p0 as PopoverContentProps["side"],
      align: (!p1
        ? "center"
        : p1 === "right"
        ? "end"
        : "start") as PopoverContentProps["align"],
    };
  }, [position]);

  // Close when escaping from empty filter input
  useShortcut(
    "Escape",
    (e) => {
      if (closeOnEscape && !e.defaultPrevented) {
        setOpen(false);
      }
    },
    [closeOnEscape]
  );

  const content = (
    <Popover.Content
      className={styles.popoverContent}
      side={side}
      align={align}
      onInteractOutside={(e) => !closeOnClickAway && e.preventDefault()}
    >
      <div
        data-selectable-ignore-clicks="true"
        className={cx(
          styles.dropdown,
          isString(className) ? className : className?.popover
        )}
      >
        {children}
      </div>
    </Popover.Content>
  );

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <div
          className={cx(
            styles.container,
            isString(className) ? className : className?.dropdown
          )}
        >
          <div
            className={styles.buttonShield}
            onClick={withHandle(() => setOpen(!open))}
          >
            {trigger}
          </div>
        </div>
      </Popover.Trigger>

      {portal ? <Popover.Portal>{content}</Popover.Portal> : content}
    </Popover.Root>
  );
};
