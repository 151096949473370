import {
  filter,
  find,
  flatMap,
  get,
  isString,
  keys,
  map,
  reduce,
  set,
  some,
} from "lodash";

import {
  CreateOrUpdate,
  Entity,
  EntityType,
  HasArchivedAt,
  ID,
  JsonObject,
  Link,
  PropertyMutation,
  PropertyType,
  PropertyValueRef,
  Ref,
  RestoreUpdate,
  Update,
} from "@api";
import { EntityForType } from "@api/mappings";

import { indexedDbEffect } from "@state/indexeddb-storage-effect";
import { appendKey, localStorageEffect } from "@state/local-storage-effect";

import {
  ensureArray,
  ensureMany,
  groupByMany,
  OneOrMany,
  pushDirty,
  reverse,
  uniqBy,
  withoutBy,
} from "@utils/array";
import { asTimestamp } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { debug } from "@utils/debug";
import { composel, Fn, Pred } from "@utils/fn";
import { HUMAN_OR_LOCAL_REGEX, isLocalID, isTemplateId } from "@utils/id";
import { equalsAny, switchEnum } from "@utils/logic";
import { Maybe, maybeMap, safeAs, when } from "@utils/maybe";
import {
  fromPropertyValueRefs,
  isRefs,
  setValueRef,
} from "@utils/property-refs";
import { fromScope, toScope } from "@utils/scope";
import { inPast, now } from "@utils/time";

import { StoreState } from "./atoms";

export const withQueuedTimestamp = <T extends Entity>(
  update: Update<T>,
  index: number = 0
): Update<T> => ({
  ...update,
  // When multiple updates for the same entity are created we need uniq timestamps...
  // Probably need a uniq update ID rather than relying on entity id + timestamp...
  queued: now().getTime() + index,
});

export const withPersistedTimestamp = <T extends Entity>(
  update: Update<T>,
  saved: Maybe<T>
): Update<T> => ({
  ...update,
  persisted: asTimestamp(saved?.updatedAt || now()),
});

export const getItem = <T extends Entity>(store: StoreState<T>, id: ID) => {
  // Support 2 degrees of aliases (tmp -> local -> persisted)
  const alias = store.aliases?.[id];
  return (
    when(alias, (aka) => store.lookup?.[aka]) ||
    when(alias && store.aliases?.[alias], (aka) => store.lookup?.[aka]) ||
    store.lookup[id]
  );
};

export const isCreateOrUpdate = <T extends Entity>(
  update: Update<T>
): update is CreateOrUpdate<T> => !!(update as CreateOrUpdate<T>).changes;

export const asCreateOrUpdate = <T extends Entity>(update: Update<T>) =>
  safeAs<CreateOrUpdate<T>>(update);

export const findMutation = <T extends Entity>(
  update: Update<T>,
  field: string | Pred<PropertyMutation<T>>
): Maybe<PropertyMutation<T>> =>
  find(
    asCreateOrUpdate(update)?.changes,
    isString(field) ? (c) => c.field === field : field
  );

export const updatesForId = <T extends Entity>(id: ID, store: StoreState<T>) =>
  filter(
    [
      ...(store.history || []),
      ...(store.updating || []),
      ...(store.unsaved || []),
    ],
    (u) => u.id === id || u.id === aliasedID(id, store.aliases)
  );

export const tempUpdatesForId = <T extends Entity>(
  id: ID,
  store: StoreState<T>
) =>
  filter(
    store.unsaved || [],
    (u) =>
      u.mode === "temp" &&
      (u.id === id || u.id === aliasedID(id, store.aliases))
  );

export const pendingUpdatesForId = <T extends Entity>(
  id: ID,
  store: StoreState<T>
) =>
  filter(
    [...(store.updating || []), ...(store.unsaved || [])],
    (u) => u.id === id || u.id === aliasedID(id, store.aliases)
  );

export const applyMutation = <T extends Entity>(
  thing: Maybe<T>,
  update: Update<T>
) => {
  // Don't apply the update if the thing is newer
  // If this is causing issues for you it's probably because you updated something in the DB and your terminal is not set to UTC
  if (
    !!thing?.updatedAt &&
    !!update.persisted &&
    asTimestamp(thing.updatedAt) >= update.persisted
  ) {
    // debug("Skipping update because updatedAt is later...", { update, thing });
    return thing;
  } else {
    // debug("Re-applying update to item.", { id: update.id, update, thing });
  }

  return switchEnum(update.method, {
    delete: () => undefined,
    restore: () => (update as RestoreUpdate<T>).restored,
    else: () =>
      thing &&
      reduce(
        (update as CreateOrUpdate<T>).changes,
        (t: T, change) =>
          switchEnum(change?.op || "set", {
            // Support op=add for PropertyMutations
            add: () =>
              set(
                t,
                change.field,
                uniqBy(
                  [
                    ...ensureArray(get(t, change.field) || []),
                    ...ensureArray(change.value?.[change.type] as Ref[]),
                  ],
                  (r) => r.id,
                  "first"
                )
              ),
            remove: () =>
              set(
                t,
                change.field,
                withoutBy(
                  ensureArray(get(t, change.field) || []),
                  change.value?.[change.type] as Ref[],
                  (r) => r.id
                )
              ),
            // Everything else is a set for now
            else: () => {
              // Single relations inside of the *.refs.* payload are stored as arrays not single refs
              if (
                isRefs(change.field as string) &&
                change.type === "relation"
              ) {
                return set(
                  t,
                  change.field,
                  !change.value?.relation ? [] : [change.value?.relation]
                );
              }

              return setValueRef<T>(t, change as PropertyValueRef);
            },
          }),

        // Deep copy of the object including custom fields and refs
        {
          ...thing,
          refs: { ...(thing as { refs: JsonObject }).refs },
          custom: { ...(thing as { custom: JsonObject }).custom },
          settings: { ...(thing as { settings: JsonObject }).settings },
        }
      ),
  });
};

export const applyMutations = <T extends Entity>(
  thing: Maybe<T>,
  updates: Update<T>[]
): Maybe<T> => reduce(updates, (t, u) => applyMutation(t, u), thing);

export const mergeUpdates = <T extends Entity>(
  updates: Update<T>[]
): Maybe<Update<T>> => {
  if (!updates.length) {
    return undefined;
  }

  const allChanges = flatMap(updates, (u) =>
    u.method === "update" || u.method === "create" ? u.changes : []
  );

  return reduce(
    updates,
    (res, u) => {
      if (!res) {
        return u;
      }

      switch (u.method) {
        case "delete":
          return {
            id: res.id,
            source: res.source,
            method: "delete",
            transaction: res.transaction || u.transaction,
            previous: fromPropertyValueRefs(u.id, u.source, allChanges),
          };

        // Not mergeable
        case "apply":
        case "create":
          return {
            id: res.id,
            source: res.source,
            method: "create",
            transaction: res.transaction || u.transaction,
            changes: allChanges,
          };

        case "restore":
          return switchEnum<Update<T>["method"], Update<T>>(res.method, {
            create: () => ({
              ...res,
              changes: allChanges,
            }),
            else: () => ({
              id: res.id,
              source: res.source,
              method: "restore",
              transaction: res.transaction || u.transaction,
              restored: fromPropertyValueRefs(u.id, u.source, allChanges),
            }),
          });

        // Update keep previous method with added changes
        case "update":
          return switchEnum<Update<T>["method"], Update<T>>(res.method, {
            delete: () => ({
              ...res,
              previous: fromPropertyValueRefs(res.id, res.source, allChanges),
            }),
            restore: () => ({
              ...res,
              restored: fromPropertyValueRefs(res.id, res.source, allChanges),
            }),
            else: () => ({
              ...res,
              changes: allChanges,
            }),
          });
      }
    },
    undefined as Maybe<Update<T>>
  );
};

export const addChanges = <T extends Entity>(
  update: Update<T>,
  changes: OneOrMany<PropertyMutation<T>>
): Update<T> => {
  if (!isCreateOrUpdate(update)) {
    return update;
  }

  return {
    ...update,
    changes: [...(update.changes || []), ...ensureMany(changes)],
  };
};

type Aliases = Record<string, Maybe<string>>;

export const aliasedID = (id: ID, aliases: Aliases = {}) => aliases?.[id];

// Returns unpersted ids for any ID so that we can use them in the UI without changing when saving
// Prefers template IDs over local IDS over persisted IDS
export const stableID = (id: ID, aliases: Aliases = {}) => {
  const alias = aliasedID(id, aliases);

  if (!alias) return id;

  const alias2 = alias && aliasedID(alias, aliases);

  // Prefer template IDs over local IDS
  if (id && isTemplateId(id)) return id;
  if (alias && isTemplateId(alias)) return alias;
  if (alias2 && isTemplateId(alias2)) return alias2;

  // Then prefer local ids [v_]
  if (alias && isLocalID(alias)) return alias;
  if (alias2 && isLocalID(alias2)) return alias2;
  if (id && isLocalID(id)) return id;

  return id;
};

// Always returned a saved ID if it exists
export const persistedID = (id: ID, aliases: Aliases = {}) => {
  // ID is already persisted
  if (id && !isLocalID(id) && !isTemplateId(id)) {
    return id;
  }

  // There is a persisted alias
  const alias = aliasedID(id, aliases);
  if (alias && !isLocalID(alias) && !isTemplateId(alias)) {
    return alias;
  }

  // Second degree alias
  const alias2 = alias && aliasedID(alias, aliases);
  if (alias2 && !isLocalID(alias2) && !isTemplateId(alias2)) {
    return alias2;
  }

  // No persisted ID for this
  return undefined;
};

export const isLocalUnpersisted = <T extends Entity>(
  id: ID,
  aliases: Aliases = {}
) => (isLocalID(id) || isTemplateId(id)) && !persistedID(id, aliases);

export const localIdsReferenced = <T extends Entity>(c: Update<T>) => {
  const ids: string[] = [];

  if (isLocalID(c.id)) {
    pushDirty(ids, c.id);
  }

  map(isCreateOrUpdate(c) ? c.changes : [], (c) => {
    // Referenced by relation fields
    if (c.type === "relation" || c.type === "relations") {
      pushDirty(
        ids,
        ...maybeMap(ensureArray(c.value?.[c.type]), (v) =>
          isLocalID(v.id) ? v.id : undefined
        )
      );
    }

    // Location fields referencing unsaved IDs
    if (c.field === "location" && c.value?.text?.includes("[")) {
      pushDirty(ids, ...filter(fromScope(c.value.text), (id) => isLocalID(id)));
    }
  });

  return ids;
};

const _mutReferencesLocalIDs = <T extends Entity>(
  c: PropertyMutation<T>,
  aliases: Aliases
): string | boolean | undefined => {
  switch (c.field) {
    // Location fields referencing unsaved IDs
    case "location":
      return (
        c.value?.text?.includes("[") &&
        find(fromScope(c.value.text), (id) => isLocalUnpersisted(id, aliases))
      );

    case "orders":
      return JSON.stringify(c.value.json)
        ?.match(HUMAN_OR_LOCAL_REGEX)
        ?.find((id) => isLocalUnpersisted(id, aliases));
  }

  switch (c.type) {
    case "relation":
    case "relations":
      return find(ensureArray(c.value?.[c.type]), (v) =>
        isLocalUnpersisted(v.id, aliases)
      )?.id;

    // Link ids or urls reference unsaved IDs
    case "link":
    case "links":
      return safeAs<Link>(
        find(
          ensureArray(c.value?.[c.type]),
          (v) =>
            (!!v.id && isLocalUnpersisted(v.id, aliases)) ||
            v.url
              ?.match(HUMAN_OR_LOCAL_REGEX)
              ?.some((id) => isLocalUnpersisted(id, aliases))
        )
      )?.url;

    // Rich text fields referencing unsaved IDs in mention/embeds
    case "rich_text":
      return c.value.rich_text?.html
        ?.match(HUMAN_OR_LOCAL_REGEX)
        ?.find((id) => isLocalUnpersisted(id, aliases));
  }

  return false;
};

export const mutReferencesLocalIDs = <T extends Entity>(
  c: PropertyMutation<T>,
  aliases: Aliases
): boolean => {
  const blocker = _mutReferencesLocalIDs(c, aliases);
  if (blocker) {
    debug("Blocking id:", blocker);
  }
  return !!blocker;
};

export const referencesLocalIDs = <T extends Entity>(
  update: Update<T>,
  aliases: Aliases
) =>
  // Is a local template that is not created yet
  (isTemplateId(update.id) && isLocalUnpersisted(update.id, aliases)) ||
  // Has relation changes referencing an unsaved ID
  (isCreateOrUpdate(update) &&
    some(update.changes, (c) => mutReferencesLocalIDs(c, aliases)));

export const referencesID = <T extends Entity>(update: Update<T>, id: ID) =>
  // Has relation changes referencing an unsaved ID
  isCreateOrUpdate(update) &&
  some(
    update.changes,
    (c) =>
      (c.type === "relation" || c.type === "relations") &&
      some(ensureArray(c.value?.[c.type]), (v) => v.id === id)
  );

export const nextUpdatesToProcess = <T extends Entity>(
  unsaved: Update<T>[],
  aliases: Record<string, Maybe<string>> = {}
) =>
  reduce(
    unsaved || [],
    (res, update) => {
      const { updates, entities } = res;

      if (updates.length > 100) {
        return res;
      }

      if (update.mode === "temp") {
        // Ignore local updates
        return res;
      }

      // Block updates where entity/field is being updated
      if (entities.includes(update.id)) {
        debug("Blocked update due to entity currently being updated.");
        return res;
      }

      // Block updates with unpersisted id
      if (
        update.method === "update" &&
        isLocalUnpersisted(update.id, aliases)
      ) {
        debug("Blocked update to unpersisted entity.");
        return res;
      }

      // Block updates referencing unsaved ids
      if (referencesLocalIDs(update, aliases)) {
        debug(
          "Blocked update due to entity referencing unpersisted ids.",
          update,
          aliases
        );
        return res;
      }

      // Is a reattempt and is ready to retry
      if (usePointDate(update.nextAttempt, (d) => !!d && inPast(d))) {
        return {
          // Only add if ready to retry
          updates: [...updates, update],
          // Block other changes for this entity regardless if ready to retry
          entities: [...entities, update.id],
        };
      }
      // Not ready to re-attempt but we should still block it to later updates don't go through
      else if (!!update.attempt) {
        debug(
          "Blocked update due to entity not ready to retry.",
          update.nextAttempt,
          now()
        );
        return {
          // Don't add the update to retry
          updates: updates,
          // Block other changes for this entity regardless if ready to retry
          entities: [...entities, update.id],
        };
      }

      return {
        updates: [...updates, update],
        entities: [...entities, update.id],
      };
    },
    { updates: [] as Update<T>[], entities: [] as string[] }
  ).updates;

// Replace any property reference to an old ID
export const replaceRelationRefs = <T extends Entity>(
  c: PropertyMutation<T>,
  aliases: Record<string, Maybe<string>>
): PropertyMutation<T> =>
  switchEnum(
    equalsAny(c.field as string, ["location", "useTemplate", "alias", "orders"])
      ? (c.field as string)
      : c.type,
    {
      // Always keep useTemplate pointing to the original template
      useTemplate: () => c,

      location: () => {
        return some(keys(aliases), (k) => c.value?.text?.includes(k))
          ? {
              ...c,
              value: {
                text: toScope(
                  ...map(fromScope(c.value.text), (id) => aliases[id] || id)
                ),
              },
            }
          : c;
      },

      orders: () => {
        const stringed = when(c.value.json, (js) => JSON.stringify(js));
        return stringed && some(keys(aliases), (k) => stringed.includes(k))
          ? {
              ...c,
              value: {
                json: JSON.parse(
                  stringed.replace(
                    HUMAN_OR_LOCAL_REGEX,
                    (id) => aliases[id] || id
                  )
                ),
              },
            }
          : c;
      },

      // If the entity has an alias property (views) replace any occurances of ids
      alias: () => {
        return reduce(
          keys(aliases),
          (c, k) =>
            c.value?.text?.includes(k)
              ? {
                  ...c,
                  value: {
                    text: c.value?.text?.replaceAll(k, aliases[k] || k),
                  },
                }
              : c,
          c
        );
      },
      relation: () => ({
        ...c,
        value: {
          relation: c.value.relation?.id
            ? {
                ...c.value.relation,
                id:
                  when(c.value.relation.id, (id) => aliases[id]) ||
                  c.value.relation.id,
              }
            : undefined,
        },
      }),
      relations: () => ({
        ...c,
        value: {
          relations: map(c.value.relations, (r) =>
            r.id && !!aliases[r.id] ? { id: aliases[r.id] || r.id } : r
          ),
        },
      }),
      else: c,
    }
  );

export const withReplacedRelationRefs = <T extends Entity>(
  update: Update<T>,
  aliases: Record<string, Maybe<string>>
): Update<T> =>
  isCreateOrUpdate(update)
    ? {
        ...update,
        changes: map(update.changes, (c) => replaceRelationRefs(c, aliases)),
      }
    : update;

// Extracts all local Ids (any-string-with.[some_id]) from a string and replaces it with the persisted ID
export const replaceLocalIds = (
  input: string,
  toPersistedId: Fn<string, Maybe<string>>
) => input.replace(/(\[[^\]]+\])/g, (_, id) => toPersistedId(id) || id);

// Create an update with the newly persisted ID
export const withPersistedID = <T extends Entity>(
  update: Update<T>,
  aliases: Record<string, Maybe<string>>
): Update<T> =>
  ({
    ...update,
    id: persistedID(update.id, aliases) || update.id,
    changes: map((update as CreateOrUpdate<T>).changes, (c) =>
      switchEnum<PropertyType, PropertyMutation<T>>(c.type, {
        text: () =>
          ["location", "alias"].includes(c.field as string) &&
          c.value.text?.includes("[")
            ? {
                ...c,
                value: {
                  text: replaceLocalIds(c.value.text, (s) =>
                    persistedID(s, aliases)
                  ),
                },
              }
            : c,

        json: () => {
          const stringed = JSON.stringify(c.value.json);
          return stringed?.includes("[")
            ? {
                ...c,
                value: {
                  json: JSON.parse(
                    replaceLocalIds(stringed, (s) => persistedID(s, aliases))
                  ),
                },
              }
            : c;
        },

        rich_text: () =>
          c.value.rich_text?.html?.includes("[")
            ? {
                ...c,
                value: {
                  rich_text: {
                    ...c.value.rich_text,
                    html: replaceLocalIds(c.value.rich_text.html, (s) =>
                      persistedID(s, aliases)
                    ),
                  },
                },
              }
            : c,

        link: () =>
          c.value.link?.id?.includes("[") || c.value.link?.url?.includes("[")
            ? {
                ...c,
                value: {
                  link: {
                    ...c.value.link,
                    id: when(
                      c.value.link.id,
                      (id) => persistedID(id, aliases) || id
                    ),
                    url: replaceLocalIds(c.value.link.url, (s) =>
                      persistedID(s, aliases)
                    ),
                  },
                },
              }
            : c,

        links: () =>
          c.value.links?.some(
            (l) => l.id?.includes("[") || l.url?.includes("[")
          )
            ? {
                ...c,
                value: {
                  links: map(c.value.links, (l) => ({
                    ...l,
                    id: when(l.id, (id) => persistedID(id, aliases) || id),
                    url: replaceLocalIds(l.url, (s) => persistedID(s, aliases)),
                  })),
                },
              }
            : c,

        relation: () => ({
          ...c,
          value: {
            relation: {
              ...c.value.relation,
              id:
                when(c.value.relation?.id, (id) =>
                  isLocalID(id) ? aliases[id] : id
                ) ||
                c.value.relation?.id ||
                "",
            },
          },
        }),
        relations: () => ({
          ...c,
          value: {
            relations: map(c.value.relations, (r) =>
              r.id && isLocalID(r.id) ? { id: aliases[r.id] || r.id } : r
            ),
          },
        }),
        else: c,
      })
    ),
  } as Update<T>);

export const reverseUpdate = <T extends Entity>(
  update: Update<T>
): Update<T> => {
  switch (update.method) {
    case "delete":
      return { ...update, method: "restore", restored: update.previous };

    case "restore":
      return { ...update, method: "delete", previous: update.restored };

    case "create":
      return {
        ...update,
        method: "delete",
        previous: fromPropertyValueRefs<T>(
          update.id,
          update.source,
          update.changes
        ),
      };

    case "update":
    case "apply":
      return {
        ...update,
        changes: map(update.changes, (c) => ({
          ...c,
          value: c.prev,
          prev: c.value,
        })),
      } as Update<T>;
  }
};

export const reverseUpdates = <T extends Entity>(
  updates: Update<T>[]
): Update<T>[] => map(reverse(updates), reverseUpdate);

export const isStore = <O, E extends Entity>(
  s: StoreState<E> | O
): s is StoreState<E> => !!(s as StoreState<E>).lookup;

export const cleanLocalAliases = <E extends Entity>(
  s: Partial<StoreState<E>>
): Partial<StoreState<E>> => {
  const pendingIds = groupByMany(s.unsaved || [], localIdsReferenced);
  return {
    ...s,
    aliases: reduce(
      keys(s.aliases),
      (res, k) => {
        const v = s.aliases?.[k];
        const isReferenced =
          !!pendingIds[k]?.length || (v && !!pendingIds[v]?.length);
        const eitherLocal = isLocalID(k) || (!!v && isLocalID(v));

        // If either of them are local and not referenced we can remove them
        if (eitherLocal && !isReferenced) {
          return res;
        }

        // Else keep them
        return { ...res, [k]: v };
      },
      {}
    ),
  };
};

export const cleanArchivedData = <E extends Entity>(
  s: Partial<StoreState<E>>
): Partial<StoreState<E>> => {
  return {
    ...s,
    lookup: reduce(
      s.lookup,
      (res, v, k) =>
        // Ignore archived entities
        !!safeAs<HasArchivedAt>(v)?.archivedAt ? res : { ...res, [k]: v },
      {}
    ),
  };
};

export const localStorageForStore = <T extends EntityType>(
  wId: ID,
  type: T
) => {
  return localStorageEffect<StoreState<EntityForType<T>>>({
    key: appendKey(`traction.store.${type}`, wId),
    props: ["lookup", "unsaved", "aliases"],
    clean: composel(cleanLocalAliases, cleanArchivedData),
  });
};

// Version 1 schema
// ```
// database = w_*
//  1 store = stores
//   * rows = task | workspace | action | ...
// ```

export const indexedDBStorageForStore = <T extends EntityType>(
  wId: ID,
  type: T
) => {
  return indexedDbEffect<StoreState<EntityForType<T>>>({
    db: wId,
    store: "stores",
    key: type,
    props: ["lookup", "unsaved", "aliases"],
    clean: composel(cleanLocalAliases, cleanArchivedData),
  });
};
