import { filter, first, flatMap, map, pick } from "lodash";
import { useCallback, useEffect,useMemo, useState } from "react";

import { Backlog, Entity, EntityType, Ref } from "@api";

import { useLazyProperties } from "@state/databases";
import {
  useCreateFromObject,
  useEntitySource,
  useQueueUpdates,
} from "@state/generic";
import { useCreateView } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { newID } from "@utils/id";
import { maybeMap } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { getSetting, isAnyRelation, setSetting } from "@utils/property-refs";
import {
  extractTeam,
  toChildLocation,
  toLocation,
  toScope,
} from "@utils/scope";
import { withoutStar } from "@utils/wildcards";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { showError } from "@ui/notifications";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { EntityTypeSelect } from "@ui/select/entity-type";
import PropertySelect from "@ui/select/property";
import { Text } from "@ui/text";

interface Props {
  defaults: Partial<Omit<Backlog, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const BacklogCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wId = useActiveWorkspaceId();
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wId,
    [defaults?.source?.scope]
  );
  const create = useCreateFromObject("backlog", scope);
  const createView = useCreateView(extractTeam(scope));
  const mutate = useQueueUpdates();
  const [backlog, setBacklog] = useState<Partial<Backlog>>({
    icon: "📋",
    ...defaults,
  });
  const childSource = useEntitySource(
    getSetting<EntityType>(backlog.settings, "child_type"),
    backlog.source
  );
  const _props = useLazyProperties<EntityType>(childSource);
  const props = useMemo(
    () =>
      filter(_props, (p) =>
        ["select", "multi_select", "number"]?.includes(p.type)
      ),
    [_props]
  );
  const relations = useLazyProperties(backlog?.source);
  const roadmappable = useMemo(
    () =>
      flatMap(relations, (p) =>
        isAnyRelation(p) &&
        p.options?.references !== "view" &&
        p.options?.hierarchy === "child"
          ? ensureMany(withoutStar(p?.options?.references))
          : []
      ),
    [relations]
  );
  const fields = useMemo(() => {
    const fieldNames = map(backlog.fields, "field");
    return filter(props, (p) => fieldNames?.includes(p.field));
  }, [backlog.fields, props]);

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    const [saved] = create([backlog]);
    const transaction = newID();

    // Inbox view
    const inbox = createView(
      {
        name: "Inbox",
        layout: "list",
        filter: {
          and: [
            {
              field: "status",
              type: "status",
              op: "does_not_equal",
              values: { status: [{ group: "done" }, { group: "in-progress" }] },
            },
            {
              or: map(backlog.fields, (f) => ({
                ...pick(f, "field", "type"),
                op: "is_empty",
              })),
            },
          ],
        },
        for: { id: saved.id },
        entity: childSource?.type,
        location: toChildLocation(toLocation(saved.source.scope), saved.id),
      },
      transaction
    );

    // Prioritizing views
    const views = maybeMap(fields, (f, i) =>
      createView(
        {
          name: f.label || "Priority " + (i + 1),
          layout: "list",
          grouping: "rows",
          group: [{ ...pick(f, "field", "type"), hideEmpty: false }],
          entity: childSource?.type,
          filter: {
            field: "status",
            type: "status",
            op: "does_not_equal",
            value: { status: { group: "done" } },
          },
          for: { id: saved.id },
          location: toScope(saved.source.scope, saved.id),
        },
        transaction
      )
    );

    // Save the inbox and views on the backlog
    mutate(
      asUpdate(
        saved as Pick<Entity, "id" | "source">,
        [
          asMutation({ field: "inbox", type: "relation" }, { id: inbox?.id }),
          asMutation({ field: "views", type: "relations" }, [
            { id: inbox?.id },
            ...map(views, (v) => ({ id: v.id })),
          ]),
        ],
        transaction
      )
    );

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [create, backlog, fields, onSaved]);

  useEffect(() => {
    const defaultt = first(roadmappable);
    if (!defaultt) {
      return;
    }

    setBacklog((r) => ({
      ...r,
      settings: setSetting(r.settings, "child_type", defaultt),
    }));
  }, [roadmappable]);

  return (
    <DialogSplit
      title={"New backlog"}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          <Text subtle>
            Backlogs are about organising any kind of work, whether be big or
            small.
          </Text>
          <EducationTip relevantTo={["backlog"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate}>
            Create backlog
          </Button>
        </>
      }
    >
      <FillSpace direction="vertical">
        <Container gap={20} stack="vertical" fit="container">
          <>
            {backlog.icon && (
              <Field layout="horizontal">
                <MagicEmojiSelect
                  key={backlog.id || "new-backlog"}
                  entity={backlog as Backlog}
                  size="xlarge"
                  emoji={backlog.icon}
                  onChange={(icon) => setBacklog((r) => ({ ...r, icon }))}
                />
              </Field>
            )}

            <Field label="Name">
              <TextInput
                value={backlog.name || ""}
                onChange={(t) => setBacklog((b) => ({ ...b, name: t }))}
                updateOn="change"
                autoFocus={true}
                placeholder="Enter backlog name..."
              />
            </Field>

            <Field label="Organising">
              <EntityTypeSelect
                value={getSetting<EntityType>(backlog.settings, "child_type")}
                allowed={roadmappable}
                portal
                scope={scope}
                onChange={(entity) =>
                  setBacklog((r) => ({
                    ...r,
                    settings: setSetting(r.settings, "child_type", entity),
                  }))
                }
              />
            </Field>

            <SpaceBetween gap={10}>
              <Field label="Organize by">
                {map(fields, (f) => (
                  <PropertySelect
                    key={f.field}
                    options={props}
                    emptyOption={false}
                    value={f}
                    placeholder="Change this field..."
                    portal={true}
                    onChanged={(ref) =>
                      ref
                        ? setBacklog((b) => ({
                            ...b,
                            fields: map(backlog.fields, (f) =>
                              f.field === ref.field
                                ? { ...ref, direction: "asc" }
                                : f
                            ),
                          }))
                        : setBacklog((b) => ({
                            ...b,
                            fields: filter(
                              backlog.fields,
                              (ff) => ff.field !== f.field
                            ),
                          }))
                    }
                  />
                ))}

                <PropertySelect
                  options={props}
                  placeholder="Choose fields..."
                  portal={true}
                  onChanged={(p) =>
                    p &&
                    setBacklog((b) => ({
                      ...b,
                      fields: [
                        ...(backlog.fields || []),
                        { ...p, direction: "asc" },
                      ],
                    }))
                  }
                />
              </Field>
            </SpaceBetween>
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
