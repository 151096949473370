import { map } from "lodash";

import {
  DatabaseID,
  Entity,
  EntityRef,
  PropertyMutation,
  PropertyMutationOperation,
  PropertyRef,
  PropertyType,
  PropertyValue,
  Update,
} from "@api";
import { TypeForEntity } from "@api/mappings";

import { isCreateOrUpdate } from "@state/store";

import { ensureArray, OneOrMany } from "./array";
import { asLocal, newHumanId } from "./id";
import { isDefined, when } from "./maybe";
import { asValue, flattenProps, getPropertyValue } from "./property-refs";

export const isUpdate = <R, T extends Entity>(
  t: Update<T> | R
): t is Update<T> => !!(t as Update<T>).method;

export const withPrevValue = <T extends Entity>(
  entity: T,
  change: PropertyMutation<T>
): PropertyMutation<T> => ({
  ...change,
  prev: change.prev || getPropertyValue(entity, change),
});

export const withPrevValues = <T extends Entity>(
  entity: T,
  update: Update<T>
): Update<T> =>
  isCreateOrUpdate(update)
    ? {
        ...update,
        changes: map(update.changes, (c) => withPrevValue(entity, c)),
      }
    : update;

export const asMutation = <T extends Entity, P extends PropertyType>(
  prop: PropertyRef<T, P>,
  value: PropertyValue[P],
  prev?: PropertyValue[P]
): PropertyMutation<T, P> => ({
  field: prop.field,
  type: prop.type,
  value: asValue(prop.type, value),
  prev: when(prev ?? undefined, (p) => asValue(prop.type, p)),
});

export const toMutation = <T extends Entity, P extends PropertyType>(
  t: T | Partial<T>,
  prop: PropertyRef<T, P>,
  value?: PropertyValue[P]
): PropertyMutation<T, P> => ({
  field: prop.field,
  type: prop.type,
  value: isDefined(value)
    ? asValue(prop.type, value)
    : getPropertyValue(t, prop),
  prev: isDefined(value) ? getPropertyValue(t, prop) : undefined,
});

export const asAppendMutation = <
  T extends Entity,
  P extends Extract<PropertyType, "relations" | "multi_select" | "json">
>(
  prop: PropertyRef<T, P>,
  value: PropertyValue[P],
  op: PropertyMutationOperation = "add"
): PropertyMutation<T, P> => ({
  field: prop.field,
  type: prop.type,
  op,
  value: { [prop.type]: value },
  prev: undefined,
});

export const toUpdate = <T extends Entity, P extends PropertyType>(
  entity: T,
  prop: PropertyRef<T, P>,
  newValue: PropertyValue[P],
  prevValue?: PropertyValue[P],
  transaction?: string
): Update<T> => ({
  id: entity.id,
  source: entity.source as DatabaseID,
  method: "update",
  transaction,
  changes: [
    {
      type: prop.type,
      field: prop.field as keyof T,
      value: asValue(prop.type, newValue),
      prev:
        when(prevValue, (v) => asValue(prop.type, v)) ||
        getPropertyValue(entity, prop),
    },
  ],
});

export const asCreateUpdate = <T extends Entity>(
  source: DatabaseID<TypeForEntity<T>>,
  changes: OneOrMany<PropertyMutation<T>>,
  transaction?: string
): Update<T> => ({
  id: asLocal(newHumanId(source.type)),
  source: source,
  method: "create",
  transaction,
  changes: ensureArray(changes),
});

export const asUpdate = <T extends Entity>(
  entity: Pick<Entity, "id" | "source">,
  changes: OneOrMany<PropertyMutation<T>>,
  transaction?: string
): Update<T> => ({
  id: entity.id,
  source: entity.source as DatabaseID,
  method: "update",
  transaction,
  changes: ensureArray(changes),
});

export const asTempUpdate = <T extends Entity>(
  entity: Omit<EntityRef, "type">,
  changes: OneOrMany<PropertyMutation<T>>,
  transaction?: string
): Update<T> => ({
  ...asUpdate(entity as Pick<Entity, "id" | "source">, changes, transaction),
  mode: "temp",
});

export const asDeleteUpdate = <T extends Entity>(
  entity: T,
  transaction?: string
): Update<T> => ({
  id: entity.id,
  source: entity.source,
  method: "delete",
  previous: entity,
  transaction,
});

export const flattenChanges = flattenProps;

export const flattenUpdate = <T extends Entity>(update: Update<T>) =>
  update.method === "update" || update.method === "create"
    ? flattenChanges(update.changes, {
        id: update.id,
        source: update.source,
      } as Partial<T>)
    : {};
