import { CSSProperties, useMemo } from "react";

import { Color } from "@api";

import { cx } from "@utils/class-names";
import { sansDefault, toColorVar, toInverseColorVar } from "@utils/color";
import { Fn } from "@utils/fn";

import styles from "./styles.module.css";

export interface CounterProps {
  count?: number;
  color?: "default" | "subtle" | Color;
  className?: string;
  style?: CSSProperties;
  onClick?: Fn<React.MouseEvent, void>;
}

export const CounterIcon = ({
  count,
  color = "gray",
  className,
  style,
  onClick,
}: CounterProps) => {
  const colors = useMemo(() => {
    if (color === "subtle") {
      return style;
    }
    const final = sansDefault(color);
    return {
      ...style,
      background: toColorVar(final),
      color: toInverseColorVar(final),
    };
  }, [style, color]);

  return (
    <span
      className={cx(styles.icon, styles.counter, className)}
      onClick={onClick}
      style={style}
    >
      <span
        className={cx(styles.count, color === "subtle" && styles.subtle)}
        style={colors}
      >
        {count}
      </span>
    </span>
  );
};
