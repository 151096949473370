import { UseModelOptions } from "@prisma";
import * as db from "@prisma";

import { Maybe, SafeRecord } from "@utils/maybe";

import { RequestContext, SecureRequestContext } from "./context";
import { PropertyMutationInput, UpdateInput } from "./server.codegen";

export * from "./context";
export * from "./json-fields";
export * from "./server.codegen";

export type ID = string;

export type SystemSchema = Pick<db.Schema, "type" | "field" | "entity"> &
  Partial<db.Schema>;

interface DefaultCreateProps {
  id: string;
  workspace: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
}

export interface Repo<T, G> {
  create: (
    p: Omit<Partial<T>, "id" | "workspace"> & DefaultCreateProps,
    c: RequestContext,
    o?: UseModelOptions
  ) => Promise<T>;
  validate?: (
    u: UpdateInput,
    p: PropertyMutationInput
  ) => Promise<PropertyMutationInput>;
  update?: (
    id: string,
    p: Partial<T>,
    c: RequestContext,
    o?: UseModelOptions
  ) => Promise<T>;
  delete?: (id: string, c: RequestContext, o?: UseModelOptions) => Promise<T>;
  restore?: (id: string, c: RequestContext, o?: UseModelOptions) => Promise<T>;
  schemas?: (c: RequestContext) => SystemSchema[];
  version?: Date;
  toPermissions?: (
    t: Partial<T>,
    c: SecureRequestContext
  ) => Maybe<{ read: string[]; write: string[] }>;
  toGraph: (t: T) => G;
}

export type SchemaRepo = {
  schemas: (
    t: db.EntityType,
    overrides?: SafeRecord<string, Partial<SystemSchema>>
  ) => SystemSchema[];
};
