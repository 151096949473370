import { map } from "lodash";

import { DatabaseID, ID, PropertyMutation, Task, Update } from "@api";

import { now } from "@utils/date-fp";
import { newADDDID,newLocalHumanId } from "@utils/id";
import { getPropertyValue } from "@utils/property-refs";

export const toTaskUpdate = (
  task: Task,
  changes: PropertyMutation<Task>[],
  transaction?: ID
) =>
  ({
    id: task.id,
    source: task.source,
    method: "update",
    transaction,
    // Set prev value from passed in task, as convenience
    changes: map(changes, (c) => ({
      ...c,
      prev: c.prev ?? getPropertyValue(task, c),
    })),
  } as Update<Task>);

export const newTask = (
  t: { source: DatabaseID } & Partial<Task>
): Partial<Task> & Pick<Task, "id" | "source"> => ({
  notionId: undefined,
  title: undefined,
  summary: undefined,
  body: undefined,
  status: undefined,
  blocked: undefined,
  orders: undefined,
  start: undefined,
  end: undefined,
  assigned: undefined,
  links: undefined,

  refs: undefined,
  custom: undefined,

  createdAt: now(),
  updatedAt: now(),
  fetchedAt: undefined,
  createdBy: undefined,
  updatedBy: undefined,
  ...t,
  id: newLocalHumanId("task"),
  code: newADDDID(),
});
