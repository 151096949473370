import { find, merge, some } from "lodash";
import { useEffect } from "react";

import { Entity, hasDates, HasRefs, HasTemplate, Schedule } from "@api";

import { findAvailableStatuses } from "@state/databases";
import {
  WorkflowDefinition,
  WorkflowDefinitionConfig,
  WorkflowSuggestion,
} from "@state/workflows";

import { justOne } from "@utils/array";
import { equalsAny } from "@utils/logic";
import { safeAs } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";

import { SchedulePublishDialog } from "@ui/engine/schedule";
import { PauseCircle, PlayCircle } from "@ui/icon";
import { Redirect } from "@ui/redirect";

export const setScheduleLive: WorkflowDefinition<Schedule> = {
  id: "setScheduleLive",
  trigger: "ACTION",
  type: "schedule",
  icon: PlayCircle,
  title: "Set Live",

  allowed: ({ entity }) => entity.status?.group === "planning",

  collect({ data, onCollected, onCancelled, context }) {
    return (
      <SchedulePublishDialog
        scheduleId={data.entity.id}
        onComplete={() => onCollected([])}
        onCancel={onCancelled}
      />
    );
  },

  execute: ({ entity }, context) => {
    return [];
  },
};

export const pauseSchedule: WorkflowDefinition<Schedule> = {
  id: "pauseSchedule",
  trigger: "ACTION",
  type: "schedule",
  icon: PauseCircle,
  title: "Pause",
  variant: "secondary",

  allowed: ({ entity }) => entity.status?.group === "in-progress",

  execute: ({ entity }, context) => {
    const statuses = findAvailableStatuses(context.props, entity.source);
    const status = find(statuses, (s) => s.group === "planning");

    if (!status) {
      return;
    }

    return asUpdate(
      entity,
      asMutation({ field: "status", type: "status" }, status)
    );
  },
};

// When changing the date fields on somethign that has a repeat schedule,
// suggest to them to update the schedule as well
export const suggestUpdatingSchedule: WorkflowSuggestion<Entity> = {
  id: "suggestUpdatingSchedule",
  trigger: "SUGGEST",
  type: "*",
  allowed: ({ entity, update }) =>
    update.method === "update" &&
    some(update.changes, (c) =>
      equalsAny(c.field, ["start", "end", "publish"])
    ) &&
    hasDates(entity) &&
    !!justOne(entity.refs?.repeat) &&
    !(entity as HasTemplate)?.template,

  suggestion: {
    id: "update-schedule",
    text: "Update future occurances too?",
    description: "You will need to update the recurring schedule.",
    options: [
      { title: "Ignore", id: "dismiss" },
      { title: "Open Schedule", id: "open" },
    ],
  },

  collect: ({ data: { entity, update }, onCancelled }) => {
    const schedule = justOne(safeAs<HasRefs>(entity)?.refs?.repeat);

    useEffect(() => {
      onCancelled();
    }, []);

    return schedule ? <Redirect to={`/${schedule.id}`} /> : <></>;
  },

  execute: ({ entity }, { stores, props }) => {
    return [];
  },
};

export const definitions = merge(
  {
    actions: [setScheduleLive, pauseSchedule],
  } as WorkflowDefinitionConfig<Schedule>,
  {
    suggestions: [suggestUpdatingSchedule],
  } as WorkflowDefinitionConfig<Entity>
);

export default definitions;
