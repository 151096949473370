import { filter, flatMap, last, map } from "lodash";
import { Fragment, ReactNode, useMemo } from "react";

import { ID, Ref } from "@api";

import { useMoveLocation } from "@state/app";
import { useMe } from "@state/persons";

import { cx } from "@utils/class-names";
import { withHandle, withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { Maybe, maybeMap, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { toRef } from "@utils/property-refs";
import { fromScope, toScope } from "@utils/scope";

import { Button, Props as PureButtonProps } from "@ui/button";
import { HStack } from "@ui/flex";
import { AngleDownIcon, ArrowUpRight, EyeSlash, Icon } from "@ui/icon";
import { Label, Props as PureLabelProps } from "@ui/label";
import { Text } from "@ui/text";
import { useViewingWithin } from "@ui/viewing-within";

import { RelationIcon, RelationLabel, RelationTooltip } from "./relation-label";

import styles from "./location-button.module.css";

export type ButtonProps = {
  location: Maybe<string>;
  showCaret?: boolean;
  showOpen?: boolean;
  showTeam?: boolean;
  variant?: "compact" | "default" | "full";
} & Omit<PureButtonProps, "variant">;

export type LabelProps = {
  location: Maybe<string>;
  showTeam?: boolean | ID;
  showIcons?: boolean;
  subtle?: boolean;
  size?: PureLabelProps["size"];
  className?: string;
  variant?: "compact" | "default" | "full";
};

export type BreadcrumbsProps = {
  editable?: boolean;
  trailing?: ReactNode;
  onOpen?: Fn<Ref, void>;
} & LabelProps;

export const LocationButton = ({
  location,
  variant = "default",
  showCaret = true,
  showOpen = true,
  showTeam = variant === "full",
  ...rest
}: ButtonProps) => {
  const me = useMe();
  const goTo = useGoTo();
  const isPrivate = useMemo(
    () => location?.includes(me?.id),
    [me?.id, location]
  );

  if (!location) {
    return <></>;
  }

  if (isPrivate) {
    return (
      <HStack>
        <Button
          size="small"
          subtle
          iconRight={showCaret ? AngleDownIcon : undefined}
          {...rest}
          icon={variant === "compact" ? <Icon icon={EyeSlash} /> : undefined}
        >
          {variant !== "compact" && (
            <Label icon={EyeSlash} subtle>
              Private
            </Label>
          )}
        </Button>
      </HStack>
    );
  }

  return (
    <Button
      size="small"
      subtle
      iconRight={showCaret ? AngleDownIcon : undefined}
      className={styles.button}
      {...rest}
      onClick={(e) => {
        if (!e.defaultPrevented) {
          rest.onClick?.(e);
        }
      }}
    >
      <HStack gap={6}>
        <LocationLabel
          location={location}
          showTeam={showTeam}
          variant={variant}
        />
        {showOpen && (
          <Button
            size="tiny"
            inset={false}
            onClick={withHandle(() =>
              when(last(fromScope(location)), (id) => goTo({ id }))
            )}
            icon={ArrowUpRight}
          ></Button>
        )}
      </HStack>
    </Button>
  );
};

export const LocationLabel = ({
  location,
  variant = "full",
  showTeam = false,
  showIcons = true,
  subtle = false,
  size = "default",
  className,
}: LabelProps) => {
  const me = useMe();
  const relations = useMemo(
    () =>
      when(location, (l) => {
        const parts = fromScope(l);
        return maybeMap(parts, (i) =>
          showTeam || !i?.startsWith("tm_") || parts?.length === 1
            ? toRef(i)
            : undefined
        );
      }) || [],
    [location]
  );
  const isPrivate = useMemo(
    () => location?.startsWith(me?.id),
    [me?.id, location]
  );

  if (!location) {
    return <></>;
  }

  return (
    <HStack gap={6} className={className}>
      {map(relations, (relation, i) => {
        const isLast = i === relations.length - 1;

        if (isPrivate && i === 0) {
          return (
            <Fragment key={relation?.id}>
              <Label size={size} icon={showIcons ? EyeSlash : undefined} subtle>
                Private
              </Label>
              {!isLast && <Text subtle>/</Text>}
            </Fragment>
          );
        }

        return (
          <Fragment key={relation.id}>
            <RelationTooltip relation={relation}>
              {variant === "full" || (variant === "default" && isLast) ? (
                <RelationLabel
                  className={cx(
                    styles.label,
                    subtle && styles.subtle,
                    !isLast && styles.notTooLong
                  )}
                  icon={showIcons}
                  relation={relation}
                  size={size}
                  fit="content"
                />
              ) : (
                <Icon size={size} icon={<RelationIcon relation={relation} />} />
              )}
            </RelationTooltip>
            {!isLast && <Text subtle>/</Text>}
          </Fragment>
        );
      })}
    </HStack>
  );
};

export const SmartLocationLabel = ({
  location,
  showIcons = false,
  ...props
}: LabelProps) => {
  const parentParts = useMemo(() => fromScope(location), [location]);
  const viewingWithin = useViewingWithin();
  const showLocation = useMemo(() => {
    const allContextParts = flatMap(viewingWithin, fromScope);
    return toScope(
      ...filter(parentParts, (part) => !allContextParts.includes(part))
    );
  }, [viewingWithin, parentParts]);

  return showLocation ? (
    <LocationLabel {...props} showIcons={showIcons} location={showLocation} />
  ) : (
    <></>
  );
};

export const LocationBreadcrumb = ({
  location,
  variant = "full",
  showTeam = true,
  subtle = false,
  size = "default",
  editable = true,
  trailing,
  onOpen,
  className,
}: BreadcrumbsProps) => {
  const me = useMe();
  const goTo = useGoTo();
  const moveLocation = useMoveLocation();
  const relations = useMemo(
    () =>
      when(location, (l) => {
        const parts = fromScope(l);
        return maybeMap(parts, (i) =>
          showTeam || !i?.startsWith("tm_") || parts?.length === 1
            ? toRef(i)
            : undefined
        );
      }) || [],
    [location]
  );
  const isPrivate = useMemo(
    () => location?.includes(me?.id),
    [me?.id, location]
  );

  if (!location) {
    return <></>;
  }

  return (
    <HStack gap={0} className={cx(className, styles.faintButton)}>
      {map(relations, (relation, i) => {
        const isLast = i === relations.length - 1;
        return (
          <Fragment key={relation.id}>
            <RelationTooltip relation={relation}>
              {variant === "full" || (variant === "default" && isLast) ? (
                <Button
                  size="small"
                  subtle
                  disabled={relation.id === me.id}
                  onClick={withHardHandle(() => (onOpen || goTo)(relation))}
                  className={cx(
                    styles.breadcrumbButton,
                    subtle && styles.subtle
                  )}
                >
                  {isPrivate && i === 0 ? (
                    <Label icon={EyeSlash} text="Private" subtle />
                  ) : (
                    <RelationLabel
                      className={cx(
                        styles.label,
                        subtle && styles.subtle,
                        !isLast && styles.notTooLong
                      )}
                      relation={relation}
                      size={size}
                      fit="content"
                    />
                  )}
                </Button>
              ) : (
                <Icon size={size} icon={<RelationIcon relation={relation} />} />
              )}
            </RelationTooltip>

            {!isLast ? (
              <Text subtle className={styles.slash}>
                /
              </Text>
            ) : editable ? (
              <Button
                size="small"
                subtle
                onClick={moveLocation}
                icon={AngleDownIcon}
              />
            ) : (
              <> </>
            )}
          </Fragment>
        );
      })}
      {trailing}
    </HStack>
  );
};
