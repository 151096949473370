import { isString } from "lodash";
import { ReactNode } from "react";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";

import { HStack } from "@ui/flex";
import { Modal } from "@ui/modal";
import { Text,TextXLarge } from "@ui/text";

import { Container } from "./container";

import styles from "./dialog-split.module.css";

export interface Props {
  title?: string | ReactNode;
  description?: string | ReactNode;
  actions?: ReactNode;
  className?: string | Partial<{ modal: string; left: string; right: string }>;
  children: ReactNode;
  side: ReactNode;
  onDismiss?: Fn<void, void>;
  mode?: "blocking" | "passive";
}

export const DialogSplit = ({
  children,
  title,
  description,
  actions,
  onDismiss,
  side,
  className,
}: Props) => (
  <Modal
    open={true}
    onOpenChanged={(open) => !open && onDismiss?.()}
    className={cx(
      styles.dialog,
      styles.split,
      isString(className) ? className : className?.modal
    )}
    padding="none"
  >
    <HStack
      className={styles.container}
      justify="flex-start"
      align="flex-start"
      fit="container"
      gap={0}
    >
      <Container
        stack="vertical"
        className={cx(
          styles.split,
          styles.splitLeft,
          !isString(className) && className?.left
        )}
        fit="container"
      >
        {title && isString(title) ? (
          <TextXLarge bold={true}>{title}</TextXLarge>
        ) : (
          title
        )}
        {description &&
          (isString(description) ? <Text>{description}</Text> : description)}
        {side}
      </Container>

      <Container
        stack="vertical"
        className={cx(
          styles.split,
          styles.splitRight,
          !isString(className) && className?.right
        )}
      >
        <div className={cx(styles.content, styles.fullWidth)}>{children}</div>
        {!!actions && (
          <HStack
            gap={12}
            justify="flex-end"
            className={cx(styles.fullWidth, styles.actions)}
          >
            {actions}
          </HStack>
        )}
      </Container>
    </HStack>
  </Modal>
);
