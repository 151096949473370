import { last, map } from "lodash";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { Link as LinkType, Ref } from "@api";

import { cx } from "@utils/class-names";
import { safeAs } from "@utils/maybe";

import { Label } from "@ui/label";
import { RelationIcon } from "@ui/relation-label";

import { HStack } from "./flex";
import { LinkButton } from "./link-button";

import styles from "./breadcrumb.module.css";

export interface Props {
  links: Partial<LinkType>[];
  className?: string;
  itemClassName?: string;
  linkClassName?: string;
  clickable?: boolean;
  scrollable?: boolean;
}

export default function Breadcrumb({
  links,
  className,
  itemClassName,
  linkClassName,
  clickable = true,
  scrollable = false,
}: Props) {
  return (
    <HStack
      className={className}
      overflow={scrollable ? "scroll" : "visible"}
      gap={4}
    >
      {map(links, (m) => (
        <Fragment key={m.url || m.text}>
          <span
            className={cx(
              itemClassName,
              styles.breadcrumb,
              !clickable && styles.disabled
            )}
          >
            {m.url ? (
              <Link
                to={m.url}
                target={m.url?.startsWith("http") ? "_blank" : "_self"}
              >
                <LinkButton
                  className={cx(linkClassName, styles.linkButton)}
                  text={m.text || ""}
                  url={m.url}
                  icon={m.icon}
                />
              </Link>
            ) : (
              <Label
                className={linkClassName}
                icon={
                  !!safeAs<Ref>(m)?.id ? (
                    <RelationIcon relation={m as Ref} />
                  ) : undefined
                }
              >
                {m.text}
              </Label>
            )}
          </span>
          {m !== last(links) && <Label className={styles.seperator}>/</Label>}
        </Fragment>
      ))}
    </HStack>
  );
}
