import { ReactNode } from "react";

import { Entity, Ref, toTitleOrName } from "@api";

import { useLazyRelation } from "@state/generic";

import { Maybe } from "@utils/maybe";

import { Props as TooltipProps,Tooltip } from "@ui/tooltip";

type Props = {
  relation: Maybe<Ref>;
  children: ReactNode;
} & Omit<TooltipProps, "text">;

export const RelationTooltip = ({ relation, children }: Props) => {
  const inflated = useLazyRelation(relation) as Maybe<Entity>;

  if (!inflated) {
    return <>{children}</>;
  }

  return <Tooltip text={toTitleOrName(inflated)}>{children}</Tooltip>;
};
