import { useGoogleLogin } from "@react-oauth/google";
import { useCallback, useState } from "react";
import { useSetRecoilState } from "recoil";

import { authorize, Integration } from "@api";

import { AuthScope } from "@graph/types";

import {
  addWorkspaceConfig,
  WorkspaceSessionStateAtom,
} from "@state/workspace";

import { log } from "@utils/debug";
import { redirect } from "@utils/url";

import { Button } from "@ui/button";
import { Google } from "@ui/icon";
import { showError } from "@ui/notifications";

export const GoogleAuthorize = () => {
  const [loading, setLoading] = useState(false);
  const setWorkspace = useSetRecoilState(WorkspaceSessionStateAtom);

  const onAuthed = useCallback(async ({ code }: { code: string }) => {
    if (loading || !code) {
      return;
    }

    setLoading(true);
    try {
      const config = await authorize(
        code,
        Integration.Google,
        AuthScope.Person
      );

      if (!config) {
        throw new Error("No config after create.");
      }

      setWorkspace(addWorkspaceConfig(config));

      // Allow recoil to sync
      setTimeout(() => redirect("/home"), 100);

      setLoading(false);
    } catch (err) {
      log(err);
      showError((err as Error).message);
    }
  }, []);

  const startAuth = useGoogleLogin({
    flow: "auth-code",
    onSuccess: onAuthed,
    scope:
      "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/drive.metadata.readonly https://www.googleapis.com/auth/calendar.events",
  });

  return (
    <Button icon={Google} onClick={startAuth}>
      Authorize Google
    </Button>
  );
};
