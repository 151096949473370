import { map } from "lodash";

import { Entity, PropertyDef, PropertyMutation, PropertyRef } from "@api";

import { useVisiblePropertyRefs } from "@state/databases";

import { OneOrMany } from "@utils/array";
import { Fn, Pred } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { HStack, StackHorizontalProps } from "@ui/flex";

import { PropertyValue, Props as PropertyValueProps } from "./property-value";

type Props<T extends Entity> = {
  item: T;
  props: Maybe<PropertyRef<T>[] | Pred<PropertyDef<T>>>;
  editable?: boolean;
  blacklist?: string[];
  hideEmpty?: boolean;
  variant?: PropertyValueProps<T>["variant"];
  onChange?: Fn<OneOrMany<PropertyMutation<T>>, void>;
} & Omit<StackHorizontalProps, "onChange" | "children">;

export function PropertyValueStack({
  item,
  props,
  blacklist,
  onChange,
  variant,
  hideEmpty,
  editable = true,
  ...rest
}: Props<Entity>) {
  const { visible } = useVisiblePropertyRefs(item, props, {
    blacklist,
    hideEmpty,
  });

  if (!props || !visible.length) {
    return <></>;
  }

  return (
    <HStack gap={0} {...rest}>
      {map(visible, (val) => (
        <PropertyValue
          key={String(val.field)}
          parent={item}
          source={item.source}
          valueRef={val}
          inset={true}
          editable={!!onChange && editable}
          variant={
            variant || (val.type === "status" ? "labelled" : "unlabelled")
          }
          onChange={(c) =>
            onChange?.({
              field: val.field,
              type: val.type,
              value: c,
              prev: val.value,
            } as PropertyMutation<Entity>)
          }
        />
      ))}
    </HStack>
  );
}
