import {
  DependencyList,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { CallbackInterface, useRecoilCallback } from "recoil";

import { log } from "./debug";
import { Fn } from "./fn";
import { Maybe } from "./maybe";

export function useAsyncEffect(
  fn: Fn<void, Promise<void | Maybe<Fn<void, Promise<void>>>>>,
  watch: DependencyList
) {
  useEffect(() => {
    let cleanup: Maybe<Fn<void, void>>;
    (async () => {
      try {
        cleanup = (await fn()) || undefined;
      } catch (e) {
        log("Unhandled error in async effect", e);
      }
    })();

    return () => {
      cleanup?.();
    };
  }, watch);
}

export function useAsyncCleanupEffect(
  fn: () => Fn<void, Promise<void>> | void,
  watch: DependencyList
) {
  useEffect(() => {
    const cleanup = fn();
    return () => {
      if (cleanup) {
        (async () => await cleanup())();
      }
    };
  }, watch);
}

export const useRecoilCleanup = (
  fn: (i: CallbackInterface) => () => Promise<void>,
  deps: DependencyList
) => {
  const cleanup = useRecoilCallback(fn);

  useAsyncCleanupEffect(() => {
    return cleanup;
  }, deps);
};

export function useComponentSize<T extends HTMLElement>() {
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  });
  const ref = useRef<T>(null);

  const onResize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const newHeight = ref.current.offsetHeight;
    const newWidth = ref.current.offsetWidth;

    if (newHeight !== size.height || newWidth !== size.width) {
      setSize({
        height: newHeight,
        width: newWidth,
      });
    }
  }, [size.height, size.width]);

  useLayoutEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect();
  }, [ref, onResize]);

  return {
    ref,
    ...size,
  };
}
