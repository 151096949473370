import { first, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { Entity, ID, Ref, View } from "@api";

import { useEntitySource, useQueueUpdates } from "@state/generic";
import { useEntityLabels } from "@state/settings";
import {
  toShortTitle,
  useDefaultsForView,
  useLazyGetView,
  useLazyItemsForView,
} from "@state/views";

import { cx } from "@utils/class-names";
import { respectHandled, withHandle, withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { GroupedItems, toViewingWithinScope } from "@utils/grouping";
import { useShowMore } from "@utils/hooks";
import { whenTrue } from "@utils/logic";
import { maybeMap, when } from "@utils/maybe";
import { useGoTo, usePushTo } from "@utils/navigation";
import { asUpdate } from "@utils/property-mutations";
import { isAnyRelation, toKey } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { TextStack } from "@ui/ellipsis";
import { CreateDialogOpts, getEngine, render, toEngine } from "@ui/engine";
import { HStack, SpaceBetween } from "@ui/flex";
import { ArrowUpRight, CounterIcon, PlusIcon } from "@ui/icon";
import { Label } from "@ui/label";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem } from "@ui/menu-item";
import { PropertyLabel } from "@ui/property-label";
import { Sheet, SheetProps } from "@ui/sheet-layout";
import { Text } from "@ui/text";
import { WithViewingWithin } from "@ui/viewing-within";

import styles from "./view-card-compact.module.css";

type Props = {
  viewId: ID;
  limit?: number;
  as?: "card" | "sheet";
  size?: SheetProps["size"];
  onOpen?: Fn<Ref, void>;
  empty?: "show" | "hide" | "collapse";
  editable?: boolean;
  className?: string;
};

export const ViewCardCompact = ({
  viewId,
  className,
  onOpen,
  size,
  empty,
  as = "sheet",
  ...props
}: Props) => {
  const { items } = useLazyItemsForView(viewId);
  const view = useLazyGetView(viewId);
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const toLabel = useEntityLabels(view?.source.scope);
  const [creating, setCreating] = useState(false);

  const title = useMemo(() => {
    if (!view) {
      return "Loading...";
    }
    if (view.name) {
      return view.name;
    }
    return `${toLabel(view.entity, { plural: true })} ${toShortTitle(
      view
    ).toLowerCase()}`;
  }, [view?.name]);

  const hasCreateDialog = useMemo(
    () => !!when(view?.entity, (t) => getEngine(t)?.asCreateDialog),
    [view?.entity]
  );

  const handleHeaderClicked = useCallback(() => {
    if (!view) {
      return;
    }

    if (onOpen) {
      onOpen(view);
    } else {
      goTo(view);
    }
  }, [onOpen, view]);

  if (empty === "hide" && !items.all?.length) {
    return <></>;
  }

  return (
    <>
      {hasCreateDialog && view && creating && (
        <CreateFromViewDialog
          view={view}
          onCancel={() => setCreating(false)}
          onSaved={(created) => {
            pushTo(created);
            setCreating(false);
          }}
        />
      )}
      <Sheet
        className={cx(
          as === "sheet" && styles.sheet,
          as === "card" && styles.card,
          empty === "collapse" && !items.all?.length && styles.collapsed,
          className
        )}
        transparency="none"
        height={empty === "collapse" ? "content" : "container"}
        size={size || "full"}
        onDoubleClick={respectHandled(handleHeaderClicked)}
      >
        <SpaceBetween className={styles.header}>
          <TextStack>
            <Text bold className={styles.title}>
              {title}
            </Text>
            <Text bold subtle>
              {items?.sorted?.length || "No"}{" "}
              {toLabel(view?.entity, {
                case: "lower",
                plural: items?.all?.length > 1 || items?.all?.length === 0,
              })}
            </Text>
          </TextStack>

          <HStack gap={2}>
            {hasCreateDialog && (
              <Button
                subtle
                icon={PlusIcon}
                size="small"
                className={styles.onHover}
                onClick={withHandle(() => setCreating(true))}
              >
                New
              </Button>
            )}
            <Button
              subtle
              variant="primary"
              icon={ArrowUpRight}
              size="small"
              className={styles.onHover}
              onClick={() => view && onOpen?.(view)}
            >
              Open
            </Button>
          </HStack>
        </SpaceBetween>

        <ViewMenuList
          viewId={viewId}
          onOpen={onOpen}
          {...props}
          empty={empty === "show" ? "show" : "hide"}
        />
      </Sheet>
    </>
  );
};

export const ViewMenuList = (props: Props) => {
  const { viewId, empty = "show", size } = props;
  const goTo = useGoTo();
  const view = useLazyGetView(viewId);
  const { items } = useLazyItemsForView(viewId);
  const nestedSource = useEntitySource(view?.entity || "task", view?.source);

  if (!view || (empty === "hide" && !items.all?.length)) {
    return <></>;
  }

  return (
    <Container
      className={cx(styles.list, size && styles[size])}
      stack="vertical"
      padding="none"
      width="container"
      height={empty === "collapse" ? "content" : "container"}
      gap={0}
      onClick={withHardHandle(() => {})} // Prevent clicks from bubbling up
    >
      <Menu>
        {!items.grouped && (
          <MenuGroup>
            <MenuItemsList
              {...props}
              view={view}
              items={items.sorted || items.all}
            />
          </MenuGroup>
        )}
        {!!items.grouped &&
          maybeMap(items.grouped.groups as GroupedItems[], (group) =>
            whenTrue(!!group.items?.length, () => (
              <MenuGroup
                key={toKey(group.value)}
                label={
                  <PropertyLabel
                    size="small"
                    className={styles.propertyLabel}
                    valueRef={group.value}
                    source={nestedSource}
                    onClick={() =>
                      isAnyRelation(group.value) &&
                      when(
                        group.value.value.relation ||
                          first(group.value.value.relations),
                        goTo
                      )
                    }
                    icon={false}
                    subtle
                  />
                }
              >
                <WithViewingWithin scope={toViewingWithinScope(group)}>
                  <MenuItemsList {...props} view={view} items={group.items} />
                </WithViewingWithin>
              </MenuGroup>
            ))
          )}
      </Menu>
    </Container>
  );
};

const MenuItemsList = ({
  items,
  limit,
  view,
  onOpen,
}: Props & { view: View; items: Entity[] }) => {
  const mutate = useQueueUpdates();
  const { visible } = useShowMore(items, limit || 100);

  return (
    <>
      {map(visible, (item) =>
        render(toEngine(item)?.asMenuItem, {
          key: item.id,
          item: item,
          showProps: view?.showProps,
          onOpen: onOpen,
          onChange: (changes) => mutate(asUpdate(item, changes)),
        })
      )}

      {limit && items.length > limit && (
        <MenuItem
          icon={<CounterIcon color="subtle" count={items?.length - limit} />}
          onClick={() => view && onOpen?.(view)}
        >
          <Label subtle>Show all</Label>
        </MenuItem>
      )}
    </>
  );
};

const CreateFromViewDialog = ({
  view,
  onCancel,
  onSaved,
}: { view: View } & Omit<CreateDialogOpts<Entity>, "defaults">) => {
  const viewDefaults = useDefaultsForView(view.id) || {};
  const nestedSource = useEntitySource(view.entity || "task", view.source);
  const defaults = useMemo(
    () => ({
      ...viewDefaults,
      location: view.source.scope,
      source: nestedSource,
    }),
    [viewDefaults, nestedSource]
  );
  const CreateDialog = useMemo(
    () => when(view?.entity, (t) => getEngine(t)?.asCreateDialog),
    [view?.entity]
  );

  if (!CreateDialog) {
    return <></>;
  }

  return (
    <CreateDialog defaults={defaults} onCancel={onCancel} onSaved={onSaved} />
  );
};
