import { flatMap, reduce, uniq, uniqBy, values } from "lodash";
import { useCallback, useMemo } from "react";

import {
  Color,
  DatabaseID,
  Entity,
  EntityType,
  HasLocation,
  HasSettings,
  ID,
  PropertyType,
  PropertyValue,
  SettingsData,
  Team,
} from "@api";

import { useLazyProperties } from "@state/databases";
import {
  useGetItemFromAnyStore,
  useLazyEntities,
  useLazyEntity,
  useNestedEntities,
  useQueueUpdates,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useActiveSpace } from "@state/spaces";
import { useActiveWorkspaceId, useCurrentWorkspace } from "@state/workspace";

import { ensureMany } from "@utils/array";
import { Fn } from "@utils/fn";
import { Maybe, maybeMap, Primitive, safeAs, when } from "@utils/maybe";
import { omitEmpty as omitEmptyObj, set as setObj } from "@utils/object";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { getPropertyValue, isRelations, toRef } from "@utils/property-refs";
import { uniqRefs } from "@utils/relation-ref";
import { fromScope } from "@utils/scope";

import { ThemeMode } from "./types";
import { toEntityLabel, ToLabelOptions } from "./utils";

export function useRelatedEntities(entityId: Maybe<ID>) {
  const entity = useLazyEntity(entityId);
  const props = useLazyProperties(entity?.source);
  const nested = useNestedEntities(entity);

  const directRefs = useMemo(
    () =>
      when(entity, (entity) =>
        uniqRefs([
          ...maybeMap(fromScope(entity.source.scope), toRef),

          { id: entity.id },

          ...flatMap(props, (p) => {
            const valueRef = getPropertyValue<Entity, PropertyType>(entity, p);
            return isRelations(p) ? ensureMany(valueRef?.[p.type]) : [];
          }),
        ])
      ) ?? [],
    [entity]
  );

  const referenced = useLazyEntities(directRefs);

  const children = useMemo(
    () => flatMap(values(nested.children), (vs) => (vs as Entity[]) ?? []),
    [nested.children]
  );

  return useMemo(
    () => uniqBy([...(referenced || []), ...children], (e) => e.id),
    [referenced, children]
  );
}

export function useEntityHierarchy(entityId: ID) {
  const workspaceId = useActiveWorkspaceId();
  const entity = useLazyEntity(entityId);
  return useMemo(
    () =>
      uniq([
        workspaceId,
        ...fromScope(
          (entity as Maybe<HasLocation>)?.location || entity?.source?.scope
        ),
        entityId,
      ]),
    [
      entityId,
      workspaceId,
      (entity as HasLocation)?.location,
      entity?.source?.scope,
    ]
  );
}

export function useEntityParents(entityID: ID, source?: DatabaseID) {
  const hierarchy = useEntityHierarchy(entityID);
  const getItem = useGetItemFromAnyStore();

  return useMemo(() => maybeMap(hierarchy, getItem), [hierarchy]);
}

export function useInheritedSettings(hierarchy: ID[]): SettingsData {
  const workspace = useCurrentWorkspace();
  const getItem = useGetItemFromAnyStore();
  return useMemo(
    () =>
      reduce(
        hierarchy,
        (s, id) => {
          const val = id === workspace?.id ? workspace : getItem(id);
          return (
            when((val as Maybe<HasSettings>)?.settings, (settings) =>
              setObj(s, omitEmptyObj(settings))
            ) || s
          );
        },
        {}
      ),
    [workspace, hierarchy, getItem]
  );
}

export function useScopeSettings(scope?: string): SettingsData {
  const workspaceId = useActiveWorkspaceId();
  const hierarchy = useMemo(
    () => (!scope ? [workspaceId] : [workspaceId, ...fromScope(scope)]),
    [scope]
  );
  return useInheritedSettings(hierarchy) as SettingsData;
}

export function useEntitySettings(id?: string) {
  const hierarchy = useEntityHierarchy(id || "");
  return useInheritedSettings(hierarchy);
}

export function useSetting<T extends Primitive>(entityId: ID, key: string) {
  const settings = useEntitySettings(entityId);
  const cleaned = key?.startsWith("settings.")
    ? key.replace("settings.", "")
    : key;
  return settings[cleaned] as T;
}

export function useSettingState<T extends PropertyType>(
  entityId: ID,
  key: string,
  type: T,
  pageId?: string
) {
  const item = useLazyEntity(entityId);
  const mutate = useQueueUpdates(pageId);
  const value = useMemo(
    () => safeAs<HasSettings>(item)?.settings?.[key],
    [item]
  );
  const setValue = useCallback(
    (v: Maybe<T>) => {
      item &&
        mutate(
          asUpdate(
            item,
            asMutation(
              { field: `settings.${key}`, type: type || "text" },
              v as PropertyValue[T]
            )
          )
        );
    },
    [item, mutate, key]
  );
  return [value, setValue] as [
    PropertyValue[T],
    Fn<Maybe<PropertyValue[T]>, void>
  ];
}

export function useEntityLabels(scope: Maybe<string>, opts1?: ToLabelOptions) {
  const workspaceId = useActiveWorkspaceId();
  const hierarchy = useMemo(() => [workspaceId, ...fromScope(scope)], [scope]);
  const settings = useInheritedSettings(hierarchy);

  return useCallback(
    (type: Maybe<EntityType>, opts2?: ToLabelOptions) => {
      const options = { ...opts1, ...opts2 };
      return toEntityLabel(type, options, settings);
    },
    [settings, opts1?.plural, opts1?.case]
  );
}

export const useEntityLabel = (
  type: EntityType,
  scope: Maybe<string>,
  options?: ToLabelOptions
): string => {
  const getLabel = useEntityLabels(scope, options);
  return useMemo(
    () => getLabel(type),
    [type, getLabel, options?.plural, options?.case]
  );
};

export const useThemeColor = (): Maybe<Color> => {
  const me = useMe();
  const space = useActiveSpace();

  const userColor = useSetting<Color>(me.id, "appColor");
  const useTeamColor = useSetting<boolean>(me.id, "useTeamColor");
  return useMemo(
    () => (useTeamColor && safeAs<Team>(space.entity)?.color) || userColor,
    [space.entity, useTeamColor, userColor]
  );
};

export const useThemeMode = (): Maybe<ThemeMode> => {
  const me = useMe();
  return useSetting<ThemeMode>(me.id, "appThemeMode") || "light";
};
