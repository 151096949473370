import { some } from "lodash";
import { useCallback, useMemo,useState } from "react";

import { Entity, HasIcon, HasLocation, HasName, HasTitle } from "@api";

import { useLazyProperties } from "@state/databases";
import { useCreateFromObject } from "@state/generic";
import { useEntityLabels } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";

import { required, safeAs } from "@utils/maybe";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { FillSpace, HStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { Modal } from "@ui/modal";
import { showError } from "@ui/notifications";
import { LocationSelect } from "@ui/select";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { TextXLarge } from "@ui/text";

import { CreateDialogOpts } from "../types";

import styles from "./styles.module.css";

export const FallbackCreateDialog = ({
  onCancel,
  onSaved,
  defaults,
}: CreateDialogOpts<Entity>) => {
  const wID = useActiveWorkspaceId();
  const scope = useMemo(
    () =>
      defaults?.source?.scope || safeAs<HasLocation>(defaults)?.location || wID,
    [defaults?.source?.scope, safeAs<HasLocation>(defaults)?.location]
  );
  const type = useMemo(
    () =>
      required(
        defaults?.source?.type,
        () => "Source type is required for fallback create dialog."
      ),
    [defaults?.source?.type]
  );
  const source = useMemo(() => ({ type, scope }), [type, scope]);
  const toEntityLabel = useEntityLabels(scope);
  const create = useCreateFromObject(type, scope);
  const props = useLazyProperties(source);
  const hasIcon = useMemo(
    () => some(props, (p) => p.field === "icon"),
    [props]
  );

  const [entity, setEntity] = useState<Partial<Entity>>(
    () =>
      ({
        icon: "🗺️",
        ...defaults,
        source: { type, scope },
      } as Partial<Entity>)
  );

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    const [saved] = create([entity]);

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [create, entity, onSaved]);

  return (
    <Modal open={true} onOpenChanged={(o) => !o && onCancel?.()}>
      <FillSpace direction="vertical">
        <Container
          className={styles.createContainer}
          padding="none"
          gap={20}
          stack="vertical"
          fit="container"
        >
          <TextXLarge bold>Quick create</TextXLarge>

          {hasIcon && (
            <Field layout="horizontal">
              <MagicEmojiSelect
                key={entity.id || "new-entity"}
                entity={entity as HasIcon}
                size="xlarge"
                emoji={safeAs<HasIcon>(entity)?.icon}
                onChange={(icon) => setEntity((r) => ({ ...r, icon }))}
              />
            </Field>
          )}

          <Field label="Name">
            <TextInput
              value={
                safeAs<HasName>(entity)?.name ||
                safeAs<HasTitle>(entity)?.title ||
                ""
              }
              onChange={(t) => setEntity((r) => ({ ...r, name: t }))}
              updateOn="change"
              autoFocus={true}
              placeholder={`Give this ${toEntityLabel(type)} a name...`}
            />
          </Field>

          <Field label="Location">
            <LocationSelect
              fit="container"
              location={entity.source?.scope}
              source={source}
              variant="full"
              className={styles.control}
              onChange={(location) =>
                setEntity(
                  (r) =>
                    ({
                      ...r,
                      location,
                      source: { type, scope: location },
                    } as Entity)
                )
              }
            />
          </Field>

          <HStack justify="flex-end" fit="container">
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={onCreate}>
              Create
            </Button>
          </HStack>
        </Container>
      </FillSpace>
    </Modal>
  );
};
