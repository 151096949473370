import { atomFamily } from "recoil";

import { ID, Workflow } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type WorkflowStoreState = StoreState<Workflow>;

export const WorkspaceWorkflowStoreAtom = atomFamily<WorkflowStoreState, ID>({
  key: "WorkspaceWorkflowStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "workflow")],
});

export const WorkflowStoreAtom = WorkspaceWrappedAtom(
  "WorkflowStoreAtom",
  WorkspaceWorkflowStoreAtom
);
