import { keys, last, reduce } from "lodash";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";

import {
  mergePresenceState,
  PresenceStoreAtom,
  TractionPresence,
  useRealtimePresence,
} from "@state/realtime";
import { useActiveWorkspaceId } from "@state/workspace";

import { now } from "@utils/date-fp";
import { setDirty } from "@utils/object";

import { showLog } from "@ui/notifications";

interface Props {}

export function PresenceSync({}: Props) {
  const workspaceId = useActiveWorkspaceId();
  const setStore = useSetRecoilState(PresenceStoreAtom);

  const { update } = useRealtimePresence(workspaceId, (p) => {
    showLog("Presence updated.");
    const merged = reduce(
      keys(p),
      (acc, key) => {
        const presence = last(p[key]);
        if (presence) {
          setDirty(acc, key, presence);
        }
        return acc;
      },
      {} as Record<string, TractionPresence>
    );
    setStore(mergePresenceState(merged));
  });

  useEffect(() => {
    update({ since: now() });
  }, []);

  return <></>;
}
