import { reduce } from "lodash";

import { Maybe } from "./maybe";

export const percent = (n: Maybe<number>, total: Maybe<number>) =>
  total ? ((n || 0) / total) * 100 : 0;

export const sum = (...nums: Maybe<number>[]) =>
  reduce(nums, (acc, n) => acc + (n || 0), 0);

export const average = (...nums: Maybe<number>[]) => sum(...nums) / nums.length;

export const abs = (n: Maybe<number>) => Math.abs(n || 0);
