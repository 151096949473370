import { last, map } from "lodash";
import { useState } from "react";

import { PropertyRef,Schedule } from "@api";

import { useLazyPropertyValue } from "@state/databases";
import { useLazyEntities, useUpdateEntity } from "@state/generic";
import { toSentence, useScheduleEntityType } from "@state/schedule";
import { useEntityLabels } from "@state/settings";

import { whenEmpty } from "@utils/array";
import { cx } from "@utils/class-names";
import { formatShort } from "@utils/date";
import { useISODate } from "@utils/date-fp";
import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { isSelected } from "@utils/selectable";
import { plural } from "@utils/string";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DueDate } from "@ui/due-date";
import { EditableText } from "@ui/editable-text";
import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { AngleDownIcon, AngleUp, Cog, EllipsisH } from "@ui/icon";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { PropertyValue } from "@ui/property-value";
import { PropertyValueStack } from "@ui/property-value-stack";
import { RelationLabel, RelationMenuItem } from "@ui/relation-label";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";

import { ListItemOpts, UIEngine } from "../types";
import { ScheduleCreateDialog } from "./create-dialog";
import { ScheduleEditor } from "./editor";
import SchedulePage from "./page";
import { SchedulePane } from "./pane";

import styles from "./styles.module.css";

const DEFAULT_PROPS: PropertyRef<Schedule>[] = [];

export { ScheduleCreateDialog } from "./create-dialog";
export {
  ScheduleButton,
  ScheduleConfiguration,
  ScheduleEditor,
} from "./editor";
export { SchedulePublishDialog } from "./publish-dialog";
export {
  ScheduleInstancesPane,
  TemplateSchedulesPane,
} from "./secondary-panes";

function ScheduleListItem(props: ListItemOpts<Schedule>) {
  const goTo = useGoTo();
  const { item, className, showProps, onChange } = props;
  const template = item.useTemplate;
  const mutate = useUpdateEntity(item.id);
  const status = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });

  return (
    <EntityContextMenu
      entity={item}
      selection={props.selection}
      setSelection={props.setSelection}
    >
      <SelectableListItem {...props} className={cx(styles.listItem, className)}>
        <SpaceBetween fit="container" gap={4}>
          <PropertyValue
            inset
            variant="icon-only"
            valueRef={status}
            onChange={(s) =>
              mutate(asMutation({ field: "status", type: "status" }, s.status))
            }
            source={item.source}
          />
          <FillSpace>
            <Ellipsis>
              <HStack>
                {template ? (
                  <Text>
                    {item.name}{" "}
                    <Text subtle>{toSentence(item)} create from template </Text>
                    <Button variant="link" size="small">
                      <RelationLabel
                        fit="content"
                        subtle
                        icon={false}
                        relation={template}
                      />
                    </Button>
                  </Text>
                ) : (
                  <Text>
                    {item.name}{" "}
                    <Text subtle>
                      {toSentence(item)} create with {item.overrides?.length}{" "}
                      {plural("value", item.overrides?.length || 1)}
                    </Text>
                  </Text>
                )}
              </HStack>
            </Ellipsis>
          </FillSpace>

          <PropertyValueStack
            justify="flex-end"
            gap={2}
            item={item}
            props={whenEmpty(showProps, DEFAULT_PROPS)}
            hideEmpty={props.hideEmpty}
            onChange={onChange}
          />

          {when(
            item.useTemplate?.id || last(item.instances)?.id,
            (parentId) => (
              <ScheduleEditor
                schedule={item}
                parentId={parentId}
                source={item?.source}
                portal={true}
              >
                <Button subtle icon={Cog} />
              </ScheduleEditor>
            )
          )}
        </SpaceBetween>
      </SelectableListItem>
    </EntityContextMenu>
  );
}

export const ScheduleEngine: UIEngine<Schedule> = {
  asPrimaryPane: SchedulePane,
  asPage: SchedulePage,

  asMenuItem: function ({ item, onOpen, ...rest }) {
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });
    const { value: next } = useLazyPropertyValue(item, {
      field: "next",
      type: "date",
    });

    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          wrapLabel={false}
        >
          <SpaceBetween>
            <PropertyValue
              inset
              variant="icon-only"
              valueRef={status}
              editable={false}
              source={item.source}
            />
            <FillSpace>
              <Ellipsis>
                <HStack>
                  {!!item.useTemplate ? (
                    <Text>
                      {item.name}{" "}
                      <Text subtle>
                        {toSentence(item)} create from template{" "}
                      </Text>
                    </Text>
                  ) : (
                    <Text>
                      {item.name}{" "}
                      <Text subtle>
                        {toSentence(item)} create with {item.overrides?.length}{" "}
                        {plural("value", item.overrides?.length || 1)}
                      </Text>
                    </Text>
                  )}
                </HStack>
              </Ellipsis>
            </FillSpace>

            <HStack>{next?.date && <DueDate date={next?.date} />}</HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;
    const mutate = useUpdateEntity(item.id);
    const selected = isSelected(props.selection, item.id);
    const status = useLazyPropertyValue(item, {
      field: "status",
      type: "status",
    });

    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListCard {...props}>
          <Container size="half">
            <VStack fit="container" gap={0}>
              <SpaceBetween fit="container">
                <HStack gap={6}>
                  {!!item.name && (
                    <EditableText
                      disabled={!selected}
                      text={item.name}
                      textSize="medium"
                      onChange={(n) =>
                        mutate(asMutation({ field: "name", type: "text" }, n))
                      }
                    />
                  )}
                </HStack>

                <HStack>
                  {status.value.status?.group !== "in-progress" ? (
                    <WorkflowActions
                      size="small"
                      showAll={false}
                      entity={item}
                    />
                  ) : (
                    <PropertyValue
                      inset
                      variant="icon-only"
                      valueRef={status}
                      onChange={(s) =>
                        mutate(
                          asMutation(
                            { field: "status", type: "status" },
                            s.status
                          )
                        )
                      }
                      source={item.source}
                    />
                  )}

                  {/* {when(
                    item.useTemplate?.id || last(item.instances)?.id,
                    (parentId) => (
                      <ScheduleEditor
                        schedule={item}
                        parentId={parentId}
                        source={item?.source}
                        portal={true}
                      >
                        <Button inset size="small" subtle icon={Cog} />
                      </ScheduleEditor>
                    )
                  )} */}
                </HStack>
              </SpaceBetween>

              <HStack>
                {!!item.useTemplate ? (
                  <Text>
                    <Text subtle>{toSentence(item)} using template </Text>
                  </Text>
                ) : (
                  <Text>
                    <Text subtle>
                      {toSentence(item)} using {item.overrides?.length}{" "}
                      {plural("value", item.overrides?.length || 1)}
                    </Text>
                  </Text>
                )}
              </HStack>
            </VStack>
          </Container>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: ScheduleListItem,
  asCreateDialog: ScheduleCreateDialog,
};

function ScheduleInstances({ schedule }: { schedule: Schedule }) {
  const goTo = useGoTo();
  const toLabel = useEntityLabels(schedule.source.scope, { case: "lower" });
  const type = useScheduleEntityType(schedule);
  const [open, setOpen] = useState(false);
  const instances = useLazyEntities((open && schedule.instances) || []);

  if (!schedule.instances?.length) {
    return (
      <ShowMoreMenuItem icon={EllipsisH} inset disabled={true}>
        <Text subtle>No {plural(toLabel(type))} scheduled yet...</Text>
      </ShowMoreMenuItem>
    );
  }

  return (
    <>
      {!open && (
        <ShowMoreMenuItem
          icon={AngleDownIcon}
          inset
          disabled={!schedule.instances?.length}
          onClick={() => setOpen((t) => !t)}
        >
          <Text subtle>
            Scheduled {schedule.instances?.length}{" "}
            {plural(toLabel(type), schedule.instances?.length)}
            {!!schedule.last && (
              <> up to {useISODate(schedule.last, formatShort)}</>
            )}
          </Text>
        </ShowMoreMenuItem>
      )}
      {open && (
        <ShowMoreMenuItem
          icon={AngleUp}
          inset
          onClick={() => setOpen((t) => !t)}
        >
          <Text subtle>
            Hide {instances?.length}{" "}
            {plural(toLabel(type), schedule.instances?.length)}
          </Text>
        </ShowMoreMenuItem>
      )}

      {map(instances, (ref) => (
        <RelationMenuItem
          key={ref.id}
          relation={ref}
          onOpen={(r) => r && goTo(r)}
        />
      ))}
    </>
  );
}
