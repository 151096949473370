import { map } from "lodash";

import { EntityType, Ref } from "@api";

import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { typeFromId } from "@utils/id";

import { Button } from "@ui/button";
import { Card } from "@ui/card";
import { Container } from "@ui/container";
import { ContextItem, ContextMenu } from "@ui/context-menu";
import { HStack, SpaceBetween } from "@ui/flex";
import { ArrowUpRight, PinSlash, PlusIcon } from "@ui/icon";
import { RelationLabel } from "@ui/relation-label";
import { GlobalEntitySelect } from "@ui/select";
import { SectionLabel, Text } from "@ui/text";

import styles from "./pinned-section.module.css";

export interface PinnedItemsProps {
  items: Ref[];
  onOpen: Fn<Ref, void>;
  onPin: Fn<Ref, void>;
  onUnpin: Fn<Ref, void>;
  scope: string;
}

export const PinnedItems = ({
  items,
  scope,
  onOpen,
  onPin,
  onUnpin,
}: PinnedItemsProps) => {
  const toEntityLabel = useEntityLabels(scope);
  return (
    <Container padding="none" stack="vertical" gap={2} fit="container">
      <Container padding="none" inset="top" fit="container">
        <SpaceBetween>
          <SectionLabel text={items?.length ? "Pinned" : ""} />

          <GlobalEntitySelect
            value={undefined}
            scope={scope}
            onChange={(r) => r && onPin(r)}
            showOtherTeams={true}
            allowed={"*"}
            showTeam={false}
            portal={true}
          >
            <Button icon={PlusIcon} subtle size="small">
              <Text subtle>Add pin</Text>
            </Button>
          </GlobalEntitySelect>
        </SpaceBetween>
      </Container>

      <HStack gap={6} wrap fit="container">
        {map(items, (item) => (
          <ContextMenu
            key={item.id}
            actions={
              <>
                <ContextItem icon={PinSlash} onClick={() => onUnpin(item)}>
                  Unpin
                </ContextItem>
              </>
            }
          >
            <Card
              onClick={() => onOpen?.(item)}
              className={styles.pinnedItem}
              fit="container"
            >
              <RelationLabel
                fit="container"
                relation={item}
                iconRight={ArrowUpRight}
              />
              <Text subtle>
                {toEntityLabel(typeFromId<EntityType>(item.id))}
              </Text>
            </Card>
          </ContextMenu>
        ))}
      </HStack>
    </Container>
  );
};
