/* @ts-ignore */

// Modified from below. Messy af, not me.
// Markdown-it plugin to render GitHub-style task lists; see
//
// https://github.com/blog/1375-task-lists-in-gfm-issues-pulls-comments
// https://github.com/blog/1825-task-lists-in-all-markdown-documents

import { PluginWithOptions } from "markdown-it";

let disableCheckboxes: boolean = true;

const taskLists: PluginWithOptions<{}> = (md) => {
  md.core.ruler.after("inline", "tiptap-task-lists", (state) => {
    const tokens = state.tokens;
    for (let i = 2; i < tokens.length; i++) {
      if (isTodoItem(tokens, i)) {
        const checked = todoify(tokens[i], state.Token);
        attrSet(tokens[i - 2], "data-type", "taskItem");
        attrSet(tokens[i - 2], "data-checked", checked ? "true" : "false");
        attrSet(tokens[parentToken(tokens, i - 2)], "data-type", "taskList");
      }
    }
  });
};

function attrSet(token: any, name: string, value: string): void {
  const index = token.attrIndex(name);
  const attr = [name, value];

  if (index < 0) {
    token.attrPush(attr);
  } else {
    token.attrs[index] = attr;
  }
}

function parentToken(tokens: any[], index: number): number {
  const targetLevel = tokens[index].level - 1;
  for (let i = index - 1; i >= 0; i--) {
    if (tokens[i].level === targetLevel) {
      return i;
    }
  }
  return -1;
}

function isTodoItem(tokens: any[], index: number): boolean {
  return (
    isInline(tokens[index]) &&
    isParagraph(tokens[index - 1]) &&
    isListItem(tokens[index - 2]) &&
    startsWithTodoMarkdown(tokens[index])
  );
}

function todoify(token: any, TokenConstructor: any) {
  const { checkbox, checked } = makeCheckbox(token, TokenConstructor);
  token.children.unshift(checkbox);
  // Remove [ ] or [x] from the start of the token content
  token.children[1].content = token.children[1].content.slice(3)?.trim();
  token.content = token.content.slice(3);

  // TODO: possibly need to wrap all non-input children in a div
  return checked;
}

function makeCheckbox(token: any, TokenConstructor: any): any {
  const checkbox = new TokenConstructor("html_inline", "", 0);
  const disabledAttr = disableCheckboxes ? ' disabled=""' : "";
  const checked =
    token.content.indexOf("[x] ") === 0 || token.content.indexOf("[X] ") === 0;
  const checkedAttr = checked ? ' checked=""' : "";

  // Crete input checkbox
  checkbox.content =
    "<input" + checkedAttr + disabledAttr + ' type="checkbox">';

  // Wrap checkbox in a label
  checkbox.content = "<label>" + checkbox.content + "<span></span></label>";

  return { checkbox, checked };
}

function isInline(token: any): boolean {
  return token.type === "inline";
}
function isParagraph(token: any): boolean {
  return token.type === "paragraph_open";
}
function isListItem(token: any): boolean {
  return token.type === "list_item_open";
}

function startsWithTodoMarkdown(token: any): boolean {
  // leading whitespace in a list item is already trimmed off by markdown-it
  return (
    token.content.indexOf("[ ] ") === 0 ||
    token.content.indexOf("[x] ") === 0 ||
    token.content.indexOf("[X] ") === 0
  );
}

export default taskLists;

/*

<h1>Heading</h1>
<ul class="contains-task-list">
<li class="task-list-item"><input class="task-list-item-checkbox" disabled="" type="checkbox"> Unchecked item</li>
<li class="task-list-item"><input class="task-list-item-checkbox" checked="" disabled="" type="checkbox"> Checked item</li>
<li class="task-list-item"><input class="task-list-item-checkbox" disabled="" type="checkbox"> <strong>Unchecked parent:</strong> formatted
<ul class="contains-task-list">
<li class="task-list-item"><input class="task-list-item-checkbox" disabled="" type="checkbox"> Unchecked child</li>
</ul>
</li>

</ul>

*/
