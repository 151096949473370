import { useEffect, useMemo, useState } from "react";

import { Color, Job, JobStatus } from "@api";

import { useLazyEntity } from "@state/generic";
import { useCreateNextForSchedule } from "@state/schedule";
import { useLazyAllTeams } from "@state/teams";

import { debug,log } from "@utils/debug";
import { useAsyncEffect } from "@utils/effects";
import { switchEnum } from "@utils/logic";
import { Maybe, Primitive } from "@utils/maybe";
import { asMutation, asUpdate } from "@utils/property-mutations";
import { sentenceCase } from "@utils/string";

import { SpaceBetween } from "@ui/flex";
import { Icon, SpinnerIcon, StatusIcon } from "@ui/icon";
import { withLoading } from "@ui/notifications";
import { Tag } from "@ui/tag";

import { JobDefinition } from "./types";

export const ScheduleJobs: JobDefinition = {
  accepts: (j: Job) => j.key?.startsWith("sd_"),
  runner: function ({ job, once, onCompleted, onFailed }) {
    const scheduleId = job.data?.id as Maybe<string>;
    const { create, ready } = useCreateNextForSchedule(
      scheduleId || "",
      onCompleted
    );

    useEffect(() => {
      if (!scheduleId) {
        return onFailed();
      }

      if (!ready) {
        debug("Not ready to create next schedule job.");
        return;
      }

      once(() => {
        try {
          create();
        } catch (err) {
          log(err);
          onFailed();
        }
      });
    }, [job.id, ready, scheduleId, create]);

    return <RunnerStatus status={job.status} />;
  },
};

export const ClosePastMeetings: JobDefinition = {
  accepts: (j: Job) =>
    j.key?.startsWith("m_") && j.data?.action === "close-past",

  runner: ({ job, once, onCompleted, mutate }) => {
    const meetingId = job.data?.id as Maybe<string>;
    const meeting = useLazyEntity<"meeting">(meetingId);

    useEffect(() => {
      if (!meeting) {
        return;
      }

      once(() => {
        mutate(
          asUpdate(
            meeting,
            asMutation({ field: "status", type: "status" }, { id: "FNS" })
          )
        );
        onCompleted();
      });
    }, [job.id, meeting]);

    return <RunnerStatus status={job.status} />;
  },
};

export const SuccessTestJob: JobDefinition = {
  accepts: (j: Job) => j.key?.startsWith("test-job-success-"),
  runner: ({ job, once, onCompleted, onFailed, mutate }) => {
    const [completed, setCompleted] = useState(false);
    const teams = useLazyAllTeams();

    useEffect(() => {
      if (!teams.length) {
        debug("Waiting for data.");
        return;
      }

      once(async () => {
        debug("Running test job once.");
        await withLoading(
          new Promise<Primitive>((resolve) => setTimeout(resolve, 10000))
        );

        debug("Test job completed.");
        setCompleted(true);
      });
    }, [teams?.length]);

    useEffect(() => {
      if (completed) {
        onCompleted();
      }
    }, [completed]);

    return <RunnerStatus status={job.status} />;
  },
};

export const FailTestJob: JobDefinition = {
  accepts: (j: Job) => j.key?.startsWith("test-job-fail-"),
  runner: ({ job, once, onCompleted, onFailed, mutate }) => {
    // Fake load some data
    const teams = useLazyAllTeams();

    useAsyncEffect(async () => {
      once(async () => {
        await withLoading(
          new Promise<Primitive>((resolve) => setTimeout(resolve, 5000))
        );

        onFailed();
      });
    }, [teams]);

    return <RunnerStatus status={job.status} />;
  },
};

export const RUNNERS = [
  ScheduleJobs,
  FailTestJob,
  SuccessTestJob,
  ClosePastMeetings,
];

const RunnerStatus = ({ status }: { status: JobStatus }) => {
  const color = useMemo(
    () =>
      switchEnum<JobStatus, Color>(status, {
        queued: "blue",
        running: "yellow",
        completed: "green",
        failed: "red",
      }),
    [status]
  );
  return (
    <SpaceBetween>
      FUNC
      <Tag color={color}>{sentenceCase(status)}</Tag>
      {status === "running" && <Icon icon={SpinnerIcon} />}
      {status === "completed" && (
        <Icon icon={<StatusIcon status={{ group: "done" }} />} />
      )}
    </SpaceBetween>
  );
};
