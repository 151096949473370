import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { ReactNode } from "react";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { VStack } from "@ui/flex";
import { Label } from "@ui/label";

import { MenuItem, MenuItemProps } from "./default";

import styles from "../radio.module.css";

type RadioItemProps = {
  value: string;
  children?: ReactNode;
} & MenuItemProps;

interface RadioGroupProps {
  value: Maybe<string>;
  onChanged?: Fn<string, void>;
  children: ReactNode;
}

export const RadioMenuItem = ({
  text,
  children,
  value,
  ...rest
}: MenuItemProps & RadioItemProps) => (
  <MenuItem
    icon={
      <RadixRadioGroup.Item
        id={text || value}
        value={value}
        className={styles.radioItem}
      >
        <RadixRadioGroup.Indicator className={styles.radioIndicator} />
      </RadixRadioGroup.Item>
    }
    {...rest}
  >
    <Label htmlFor={text || value}>{text || children}</Label>
  </MenuItem>
);

export const RadioMenuGroup = ({
  value,
  onChanged,
  children,
}: RadioGroupProps) => (
  <RadixRadioGroup.Root value={value} onValueChange={onChanged}>
    <VStack gap={0}>{children}</VStack>
  </RadixRadioGroup.Root>
);
