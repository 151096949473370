import { merge } from "lodash";
import { createContext, ReactNode, useContext, useMemo } from "react";

import { Entity } from "@api";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { ComponentOrNode } from "@utils/react";

interface MenuItemData {
  id: string;
  label: string;
  icon?: ComponentOrNode;
  onClick: Fn<Entity, void>;
}

export interface SuggestedActionsState {
  items: MenuItemData[];
}

export const SuggestedActionsContext =
  createContext<Maybe<SuggestedActionsState>>(undefined);

export const useSuggestedActionsContext = () => {
  return useContext(SuggestedActionsContext);
};

export const useSuggestedActions = () => {
  return useContext(SuggestedActionsContext)?.items;
};

export const WithSuggestedActions = ({
  items,
  children,
}: SuggestedActionsState & { children: ReactNode }) => {
  const existing = useSuggestedActionsContext();
  const context = useMemo(() => merge(existing, { items }), [items, existing]);
  return (
    <SuggestedActionsContext.Provider value={context}>
      {children}
    </SuggestedActionsContext.Provider>
  );
};
