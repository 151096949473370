import { map } from "lodash";

import { Event } from "@api";

import { useLazyPropertyValue, useVisiblePropertyRefs } from "@state/databases";

import { cx } from "@utils/class-names";
import { formatDay } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { withHandle } from "@utils/event";
import { isEmptyRef } from "@utils/property-refs";

import { Ellipsis } from "@ui/ellipsis";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { FillSpace, HStack, SpaceBetween, VStack } from "@ui/flex";
import { MenuItem } from "@ui/menu-item";
import { PropertyLabel } from "@ui/property-label";
import { PropertyValue } from "@ui/property-value";
import { SelectableListCard, SelectableListItem } from "@ui/selectable-items";
import { Text, TextSmall } from "@ui/text";

import { MeetingDivider } from "../meeting/comps";
import { ListItemOpts, UIEngine } from "../types";
import { EventPane } from "./pane";

import styles from "./styles.module.css";

export const EventEngine: UIEngine<Event> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          className={styles.relative}
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
        >
          <SpaceBetween>
            <HStack align="stretch">
              <MeetingDivider entity={item} />
              <HStack gap={4}>
                <Text>{item.name}</Text>
                {item.start && (
                  <Text subtle>{usePointDate(item.start, formatDay)}</Text>
                )}
              </HStack>
            </HStack>
          </SpaceBetween>
        </MenuItem>
      </EntityContextMenu>
    );
  },
  asListCard: (props) => {
    const { item } = props;

    const ownerProp = useLazyPropertyValue(item, {
      field: "owner",
      type: "relation",
    });
    return (
      <EntityContextMenu entity={props.item}>
        <SelectableListCard
          {...props}
          className={cx(styles.card, props.className)}
        >
          <SpaceBetween direction="horizontal" gap={6} align="stretch">
            <MeetingDivider className={styles.fill} entity={item} />

            <VStack className={styles.bar} gap={0}>
              <SpaceBetween>
                <Ellipsis>
                  <HStack gap={4}>
                    <Text>{item.name || "Event"}</Text>
                    {item.start && (
                      <TextSmall subtle>
                        {usePointDate(item.start, formatDay)}
                      </TextSmall>
                    )}
                    {item.end && item.start && (
                      <TextSmall subtle>{"->"}</TextSmall>
                    )}
                    {item.end && (
                      <TextSmall subtle>
                        {usePointDate(item.end, formatDay)}
                      </TextSmall>
                    )}
                  </HStack>
                </Ellipsis>

                {ownerProp && !isEmptyRef(ownerProp) && (
                  <PropertyLabel
                    source={item.source}
                    valueRef={ownerProp}
                    variant="icon-only"
                  />
                )}
              </SpaceBetween>
            </VStack>
          </SpaceBetween>
        </SelectableListCard>
      </EntityContextMenu>
    );
  },
  asListItem: function EntityListItem(props: ListItemOpts<Event>) {
    const { item, showProps, variant, onChange } = props;
    const { visible } = useVisiblePropertyRefs(item, showProps, {
      blacklist: ["name", "status", "start", "end"],
      hideEmpty: props.hideEmpty,
    });
    return (
      <EntityContextMenu
        entity={item}
        selection={props.selection}
        setSelection={props.setSelection}
      >
        <SelectableListItem
          {...props}
          className={cx(styles.relative, props.className)}
        >
          <SpaceBetween className={styles.upper}>
            <MeetingDivider className={styles.fill} entity={item} />

            <FillSpace>
              <HStack gap={4}>
                <Text>{item.name}</Text>
                {item.start && (
                  <TextSmall subtle>
                    {usePointDate(item.start, formatDay)}
                  </TextSmall>
                )}
                {item.end && item.start && item.start !== item.end && (
                  <TextSmall subtle>{"->"}</TextSmall>
                )}
                {item.end && item.start !== item.end && (
                  <TextSmall subtle>
                    {usePointDate(item.end, formatDay)}
                  </TextSmall>
                )}
              </HStack>
            </FillSpace>

            <HStack className={styles.rowDetails} justify="flex-end" gap={2}>
              {map(visible, (val) => (
                <PropertyValue
                  key={val.field}
                  valueRef={val}
                  source={item.source}
                  variant={variant || "unlabelled"}
                  onChange={(c) =>
                    onChange?.({
                      field: val.field,
                      type: val.type,
                      value: c,
                      prev: val.value,
                    })
                  }
                />
              ))}
            </HStack>
          </SpaceBetween>
        </SelectableListItem>
      </EntityContextMenu>
    );
  },

  asPrimaryPane: EventPane,
};
