import { ReactNode } from "react";
import { ControlProps, GroupBase, MenuProps } from "react-select";

import { cx } from "@utils/class-names";

import { HStack, SpaceBetween } from "@ui/flex";
import { Menu } from "@ui/menu";

import { Option } from "./single-select";

import styles from "./select.module.css";

export const SplitControl =
  <T extends Option, M extends boolean>(left?: ReactNode, right?: ReactNode) =>
  ({ children, className, innerProps, innerRef }: ControlProps<T, M>) =>
    (
      <div
        ref={innerRef}
        {...innerProps}
        className={cx(styles.spitControl, className)}
        tabIndex={-1}
      >
        <SpaceBetween gap={2}>
          {left}
          {children}
          {right}
        </SpaceBetween>
      </div>
    );

export const SplitMenu =
  <T extends Option, M extends boolean>(top: ReactNode, side?: ReactNode) =>
  (mp: MenuProps<T, M, GroupBase<T>>) => {
    return (
      <div
        ref={mp.innerRef}
        {...mp.innerProps}
        className={styles.splitMenu}
        tabIndex={-1}
      >
        {top && <div className={styles.top}>{top}</div>}

        <HStack gap={0} align="stretch" fit="container">
          <Menu className={styles.left}>{side}</Menu>

          <Menu className={styles.right}>{mp.children}</Menu>
        </HStack>
      </div>
    );
  };
