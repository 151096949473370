import { filter, map } from "lodash";
import { useCallback, useMemo, useState } from "react";

import { EntityType, ID, Person } from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useInstalledEntities } from "@state/packages";
import { useMe } from "@state/persons";
import { useEntityLabels } from "@state/settings";
import { useActiveSpace } from "@state/spaces";
import { useLazyViewsForFilter } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { Fn } from "@utils/fn";
import { useShowMore, useStickyState } from "@utils/hooks";
import { isTemplateId, isWorkspaceId } from "@utils/id";
import { equalsAny } from "@utils/logic";
import { Maybe } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";

import { Container } from "@ui/container";
import { render, useEngine } from "@ui/engine";
import { ViewCreateDialog } from "@ui/engine/view";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PlusIcon } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { MenuItem, ShowMoreMenuItem } from "@ui/menu-item";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { SpaceFilterSelect } from "@ui/space-filter-select";
import { Heading, Text } from "@ui/text";
import ViewPane from "@ui/view-pane";

import AppPage from "./app-page";

import styles from "./my-work-page.module.css";

export const MyWorkPage = ({ viewId }: { viewId: Maybe<ID> }) => {
  const me = useMe();
  const goTo = useGoTo();

  const setViewId = useCallback((id: string) => {
    goTo(["/boards", id]);
  }, []);

  const [page] = useRegisterPage();
  usePageUndoRedo(page.id);

  return (
    <AppPage page={page}>
      <StackContainer>
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideHeader
              pageId={page.id}
              active={viewId}
              onActiveChanged={setViewId}
              me={me}
            />

            <Main className={styles.main}>
              {viewId && (
                <ViewPane
                  viewId={viewId}
                  className={styles.viewPane}
                  onChangeView={(v) => setViewId(v.id)}
                  showOtherViews={false}
                />
              )}
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

type SideHeaderProps = {
  me: Person;
  active: Maybe<ID>;
  onActiveChanged: Fn<ID, void>;
  pageId: ID;
};

const SideHeader = ({ me, ...rest }: SideHeaderProps) => {
  const wId = useActiveWorkspaceId();
  const entities = useInstalledEntities(me.id);
  const [creating, setCreating] = useState<EntityType>();
  const space = useActiveSpace();
  const [showAll, setShowAll] = useStickyState<boolean>(
    true,
    `my-boards-show-all`
  );

  return (
    <SideNav className={styles.nav}>
      {creating && (
        <ViewCreateDialog
          defaults={{
            entity: creating,
            for: undefined,
            location: me.id,
            source: { type: "view", scope: me.id },
          }}
          onSaved={(saved) => {
            setCreating(undefined);
            rest.onActiveChanged?.(saved.id);
          }}
          onCancel={() => setCreating(undefined)}
        />
      )}
      <SpaceBetween direction="vertical">
        <VStack gap={10} width="container">
          <VStack gap={20} width="container">
            <Container gap={10} padding="none" inset="bottom" stack="vertical">
              <Heading bold>My Boards</Heading>
              {!isWorkspaceId(space.id) && (
                <HStack gap={4}>
                  <Text subtle>Boards for </Text>
                  <SpaceFilterSelect
                    value={showAll ? wId : space.id}
                    onChanged={(id) => setShowAll(id === wId)}
                    caret={true}
                    searchable={false}
                    casing="lower"
                  />
                </HStack>
              )}
            </Container>
          </VStack>

          <Container padding="vertical"> </Container>

          <Menu>
            {map(entities, (type) => (
              <BoardsMenuGroup
                key={type}
                type={type}
                {...rest}
                me={me}
                forFilter={
                  isWorkspaceId(space.id) || showAll ? undefined : space.id
                }
              />
            ))}

            <MenuGroup>
              <MenuItem icon={PlusIcon} onClick={() => setCreating("task")}>
                <Text subtle>New board</Text>
              </MenuItem>
            </MenuGroup>
          </Menu>
        </VStack>
      </SpaceBetween>
    </SideNav>
  );
};

type BoardsMenuGroupProps = SideHeaderProps & {
  type: EntityType;
  forFilter: Maybe<ID>;
};

const BoardsMenuGroup = ({
  me,
  type,
  active,
  forFilter,
  onActiveChanged,
}: BoardsMenuGroupProps) => {
  const all = useLazyViewsForFilter(
    useMemo(
      () => ({ type, for: forFilter, location: me.id }),
      [forFilter, type]
    )
  );

  const filtered = useMemo(
    () =>
      filter(
        all,
        (v) =>
          (!!equalsAny(type, ["task"]) || !isTemplateId(v.id)) &&
          !!(!forFilter || v.for?.id?.startsWith(forFilter))
      ),
    [all, forFilter]
  );
  const toLabel = useEntityLabels(me.id);
  const engine = useEngine("view");
  const {
    visible: views,
    hasMore,
    moreCount,
    showMore,
  } = useShowMore(filtered, 5);

  if (!views?.length) {
    return <></>;
  }

  return (
    <MenuGroup label={`${toLabel(type, { plural: true })}`}>
      {map(views, (view) =>
        render(engine.asMenuItem, {
          key: view.id,
          item: view,
          selected: view.id === active || view.alias === active,
          onOpen: (v) => onActiveChanged(v.id),
        })
      )}
      {hasMore && <ShowMoreMenuItem count={moreCount} onClick={showMore} />}
    </MenuGroup>
  );
};
