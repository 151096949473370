import { every, isFunction, some } from "lodash";

import { Fn, isFunc, Pred } from "./fn";
import { isDefined,Maybe } from "./maybe";

export const not =
  <T>(func: (arg: T) => boolean) =>
  (arg: T) =>
    !func(arg);

const ensureFn = <V>(v: V | Fn<void, V>) => (isFunction(v) ? v : () => v);

export const ifDo = <T>(
  cond: Maybe<boolean> | Fn<void, Maybe<boolean>>,
  val: T | Fn<void, T>
) => {
  if (ensureFn(cond)()) {
    return ensureFn(val)();
  }
  return undefined;
};
export const whenTrue = ifDo;

export const ifDo_ =
  <T>(pred: Maybe<boolean> | Fn<void, Maybe<boolean>>, work: Fn<void, T>) =>
  () =>
    ifDo(pred, work);

export function switchEnum<E extends string | number | symbol, R>(
  e: E,
  handlers: { else: R | Fn<E, R> } & Partial<{ [P in E]: R | Fn<P, R> }>
): R;
export function switchEnum<E extends string | number | symbol, R>(
  e: E,
  handlers: { [P in E]: R | Fn<P, R> }
): R;
export function switchEnum<R>(
  e: string,
  handlers: Record<string, Maybe<Fn<string, R>>> & { else?: Fn<string, R> }
): R {
  const handler = handlers[e] || handlers.else;

  if (!isDefined(handler)) {
    throw new Error(`Unhandled switchEnum statement for value (${e}).`);
  }

  return isFunc(handler) ? handler(e) : handler;
}

export function equalsAny<T, V extends T>(thing: T, vals: V[]): thing is V {
  return vals.includes(thing as V);
}

export const or =
  <T>(...funcs: Pred<T>[]) =>
  (arg: T) =>
    some(funcs, (f) => f(arg));

export const and =
  <T>(...funcs: Pred<T>[]) =>
  (arg: T) =>
    every(funcs, (f) => f(arg));

export const toggle = <T>(v: T) => !v;
