import { createClient } from "@supabase/supabase-js";
import { forEach, isString, without } from "lodash";

import { pushDirty } from "@utils/array";
import { getEnvVar } from "@utils/env";
import { Fn } from "@utils/fn";
import { JsonObject } from "@utils/json";
import { Maybe, SafeRecord } from "@utils/maybe";

import {
  RealtimeChannel,
  RealtimeEvent,
  RealtimePayload,
  RealtimePresence,
  RealtimePresences,
} from "./types";

let config: Maybe<{ workspace: string; person: string }> = undefined;

const client = createClient(
  getEnvVar("SUPABASE_URL"),
  getEnvVar("SUPABASE_KEY"),
  {}
);
const openChannels: Record<string, Promise<RealtimeChannel>> = {};
const listeners: SafeRecord<string, Fn<any, void>[]> = {};

const toChannelId = (channel: string | RealtimeChannel) =>
  isString(channel) ? channel : channel.topic?.replace("realtime:", "");
const toListenerKey = (channel: string, event: string) => `${channel}:${event}`;

const ensureChannel = (
  channelId: string | RealtimeChannel,
  initial?: Record<string, any>
) => {
  if (typeof channelId !== "string") {
    return Promise.resolve(channelId);
  }

  if (!openChannels[channelId]) {
    openChannels[channelId] = new Promise((resolve, reject) => {
      const c = client.channel(channelId, {
        config: {
          presence: {
            key: config?.person,
          },
        },
      });
      c.subscribe((status) => {
        if (status === "SUBSCRIBED") {
          c.on("broadcast", { event: "*" }, (m) => {
            const key = toListenerKey(channelId, m.event);
            const payload = m.payload as RealtimePayload<any>;
            forEach(listeners[key], (l) => l(payload));
          });
          resolve(c);
        } else if (status === "CHANNEL_ERROR") {
          reject();
        }
      }).track(initial || {});
    });
  }

  return openChannels[channelId];
};

const ensureSubscribed = ensureChannel;

export const setConfig = (workspace: Maybe<string>, person: Maybe<string>) => {
  config = workspace && person ? { workspace, person } : undefined;
};

export const broadcast = async <E extends RealtimeEvent>(
  channel: string | RealtimeChannel,
  event: E,
  payload: RealtimePayload<E>
) =>
  ensureSubscribed(channel)
    // Send a message once the client is subscribed
    .then((c) => {
      c.send({
        type: "broadcast",
        event: event,
        payload: payload,
      });
    });

export const listen = <E extends RealtimeEvent>(
  channel: string | RealtimeChannel,
  event: E,
  onMessage: Fn<RealtimePayload<E>, void>
) => {
  // Subscribe and attach a listener
  const id = toChannelId(channel);
  const key = toListenerKey(id, event);
  listeners[key] = pushDirty(listeners[key] || [], onMessage);
  return ensureChannel(channel);
};

export const unlisten = <E extends RealtimeEvent>(
  channel: string | RealtimeChannel,
  event: E,
  onMessage: Fn<RealtimePayload<E>, void>
) => {
  // Subscribe and attach a listener
  const id = toChannelId(channel);
  const key = toListenerKey(id, event);
  listeners[key] = without(listeners[key] || [], onMessage);
};

export const syncPresence = <S extends JsonObject>(
  channelId: string,
  initial: RealtimePresence<S>,
  onChanged: Fn<RealtimePresences<S>, void>
) => {
  return ensureChannel(channelId, initial).then((c) => {
    c.on("presence", { event: "sync" }, () => {
      const newState = c.presenceState<S>();
      onChanged(newState);
    });
    // .on("presence", { event: "join" }, ({ key, newPresences }) => {
    //   debug("join", key, newPresences);
    // })
    // .on("presence", { event: "leave" }, ({ key, leftPresences }) => {
    //   debug("leave", key, leftPresences);
    // })

    return c;
  });
};

export const updatePresence = <S extends JsonObject>(
  channelId: string | RealtimeChannel,
  state: RealtimePresence<S>
) =>
  ensureChannel(channelId).then((c) => {
    c.track(state);
    return c;
  });

export const closeChannel = (channel: string | RealtimeChannel) =>
  ensureChannel(channel).then((c) => c.unsubscribe());
