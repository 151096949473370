import { atomFamily } from "recoil";

import { ID } from "@api";

import { WorkspaceWrappedAtom } from "@state/workspace";

import { PointDate } from "@utils/date-fp";
import { Maybe, SafeRecord } from "@utils/maybe";

import { appendKey, localStorageEffect } from "../local-storage-effect";

export interface RecentsState {
  lookup: SafeRecord<ID, PointDate>;
  updatedAt: Maybe<PointDate>;
}

export const WorkspaceRecentsAtom = atomFamily<RecentsState, ID>({
  key: "WorkspaceRecentsAtom",
  default: { lookup: {}, updatedAt: undefined },
  effects: (wid) => [
    localStorageEffect<RecentsState>({
      key: appendKey("traction.store.recents", wid),
    }),
  ],
});

export const RecentsAtom = WorkspaceWrappedAtom(
  "RecentsAtom",
  WorkspaceRecentsAtom
);
