import { useEffect, useMemo } from "react";

import { Entity, toTitleOrName } from "@api";

import { useSmartSearch } from "@state/generic";

import { Maybe, when } from "@utils/maybe";

export const useSuggestedProcesses = (entity: Maybe<Entity>) => {
  const { onSearch, loading, options } = useSmartSearch(
    "process",
    entity?.source.scope,
    { method: "fuzzy", limit: 5, empty: false }
  );

  useEffect(() => {
    if (!entity || loading) {
      return;
    }

    onSearch(toTitleOrName(entity));
  }, [when(entity, toTitleOrName)]);

  return useMemo(() => ({ options, loading }), [options, loading]);
};
