import { RefObject } from "react";
import {
  ConnectDragSource,
  ConnectDropTarget,
  useDrag,
  useDrop,
} from "react-dnd";

import { Entity, PropertyDef } from "@api";

import { Maybe } from "@utils/maybe";

export type DropPosition = "between" | "before" | "after";

export type DragRef = {
  def: PropertyDef<Entity>;
  order: number;
};

export type DragToRef = {
  def?: PropertyDef<Entity>;
  order?: number;
  position: DropPosition;
};

interface DragProps {
  property: PropertyDef<Entity>;
  onReorder: (t: DragRef, to: DragToRef) => void;
  ref: RefObject<HTMLDivElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
}

type DropProps = {
  property?: PropertyDef<Entity>;
  ref: RefObject<HTMLDivElement | HTMLDivElement>;
  connect?: ConnectDropTarget | ConnectDragSource;
};

export const usePropertyDefDrag = ({
  property,
  onReorder,
  ref,
  connect,
}: DragProps) => {
  const [{ opacity }, dragRef] = useDrag<
    DragRef,
    DragToRef,
    { opacity?: number }
  >(
    () => ({
      type: "property-def",
      item: () => ({
        def: property,
        order: property.order ?? 0,
      }),

      collect: (monitor) => (monitor.isDragging() ? { opacity: 0.5 } : {}),
      end: (_dragged, monitor) => {
        const target = monitor.getDropResult();
        const item = monitor.getItem();
        if (target) {
          onReorder?.(item, target);
        }
      },
    }),
    [property, onReorder]
  );

  if (connect) {
    connect(dragRef(ref));
  } else {
    dragRef(ref);
  }

  return [{ opacity }, dragRef] as [{ opacity: number }, ConnectDragSource];
};

export const usePropertyDefDrop = ({ property, connect, ref }: DropProps) => {
  const [{ dropping }, dropRef] = useDrop<
    DragRef[],
    DragToRef,
    { dropping: Maybe<DropPosition> }
  >(
    () => ({
      accept: "property-def",
      canDrop: (_t, m) => m.isOver({ shallow: true }),
      collect: (monitor) => ({
        dropping: monitor.canDrop() && monitor.isOver() ? "before" : undefined,
      }),
      drop: () => ({
        def: property,
        order: property?.order,
        position: "before",
      }),
    }),
    [property]
  );

  if (connect) {
    connect(dropRef(ref));
  } else {
    dropRef(ref);
  }

  return [{ dropping }, dropRef] as [
    { dropping: Maybe<DropPosition> },
    ConnectDropTarget
  ];
};

export const usePropertyDefDragDrop = (props: DragProps) => {
  const [dragProps, dragRef] = usePropertyDefDrag(props);
  const [dropProps] = usePropertyDefDrop({ ...props, connect: dragRef });

  return { ...dragProps, ...dropProps };
};
