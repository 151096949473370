
import { cx } from "@utils/class-names";

import { Container, Props as ContainerProps } from "@ui/container";

import styles from "./card-header.module.css";

type Props = {
  toEdge?: boolean;
  border?: boolean;
} & ContainerProps;

export const CardHeader = ({
  children,
  toEdge,
  border = true,
  className,
  padding,
  ...rest
}: Props) => (
  <Container
    className={cx(
      styles.header,
      border && styles.border,
      padding && styles[padding],
      !toEdge && styles.inset,
      className
    )}
    {...rest}
  >
    {children}
  </Container>
);
