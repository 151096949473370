import { useCallback, useState } from "react";

import { Person, RichText } from "@api";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { toMarkdown } from "@utils/rich-text";

import { FillSpace, SpaceBetween, VStack } from "@ui/flex";
import { TextBox } from "@ui/rich-text";

import { Button } from "./button";
import { Icon, PersonIcon, Send } from "./icon";

import styles from "./message-box.module.css";

interface Props {
  me?: Person;
  placeholder?: string;
  showSend?: boolean;
  disabled?: boolean;
  onChange?: Fn<string, void>;
  onMessage: Fn<string, void>;
  onFocus?: Fn<void, void>;
  onClick?: Fn<void, void>;
  className?: string;
}

export const MessageBox = ({
  me,
  showSend = true,
  placeholder,
  onMessage,
  onFocus,
  onClick,
  disabled,
  onChange: _onChange,
  className,
}: Props) => {
  const [message, setMessage] = useState<RichText>({});

  const onSubmit = useCallback(
    (message: RichText) => {
      onMessage(toMarkdown(message));
      setMessage({});
    },
    [message]
  );

  const onChanged = useCallback(
    (value: RichText) => {
      setMessage(value);
      _onChange?.(toMarkdown(value));
    },
    [setMessage, _onChange]
  );

  return (
    <VStack fit="container" onClick={() => onClick?.()}>
      <SpaceBetween gap={5}>
        {me && <Icon icon={<PersonIcon person={me} />} size="large" />}

        <FillSpace>
          <TextBox
            key="message-box"
            text={message}
            className={cx(styles.messageBox, className)}
            onFocus={(e) => onFocus?.()}
            onChanged={onChanged}
            disabled={disabled}
            updateOn="change"
            placeholder={placeholder || "Start typing..."}
            submitOnEnter={true}
            onEnter={onSubmit}
          />
        </FillSpace>

        {showSend && (
          <Button subtle icon={Send} onClick={() => onSubmit(message)} />
        )}
      </SpaceBetween>
    </VStack>
  );
};
