import { map } from "lodash";
import { useMemo } from "react";

import { Entity, EntityType } from "@api";

import { useLazyPropertyValue } from "@state/databases";
import { useNestedSource, useOrderedEntities } from "@state/generic";

import { justOne } from "@utils/array";
import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { asUpdate } from "@utils/property-mutations";
import { getPropertyValue } from "@utils/property-refs";
import { withoutStar } from "@utils/wildcards";

import { Button } from "@ui/button";
import { ListItemOpts, render, useEngine } from "@ui/engine";
import { AngleDownIcon, AngleRightIcon } from "@ui/icon";
import { useMutate } from "@ui/mutate";
import { WithViewingWithin } from "@ui/viewing-within";

import styles from "./sub-list-items.module.css";

type SubListItemsProps<T extends Entity> = Omit<ListItemOpts<T>, "key"> & {
  field: string;
};

type SubListButtonProps<T extends Entity> = SubListItemsProps<T> & {
  open: boolean;
  setOpen: Fn<boolean, void>;
};

export const SubListButton = <T extends Entity>({
  item,
  field,
  open,
  setOpen,
}: SubListButtonProps<T>) => {
  const value = useMemo(
    () => getPropertyValue<T>(item, { field, type: "relations" }),
    [item, field]
  );

  if (!value.relations?.length) {
    return <></>;
  }

  return (
    <Button
      subtle
      size="small"
      className={styles.button}
      onClick={withHardHandle(() => setOpen?.(!open))}
      icon={open ? AngleDownIcon : AngleRightIcon}
    />
  );
};

export const SubListItems = <T extends Entity>({
  item,
  field,
  showProps: _showProps,
  ...rest
}: SubListItemsProps<T>) => {
  const childProp = useLazyPropertyValue(item, { field, type: "relations" });
  const subitems = useOrderedEntities(childProp?.value?.relations || []);
  const nestedSource = useNestedSource(
    item,
    justOne(withoutStar(childProp?.def?.options?.references))
  );
  const showProps = useMemo(
    () => (nestedSource.type === item.source.type ? _showProps : []),
    [nestedSource, _showProps]
  );
  const engine = useEngine<EntityType>(nestedSource.type);
  const mutate = useMutate();

  return (
    <WithViewingWithin scope={nestedSource.scope}>
      <div className={styles.inset}>
        {map(subitems, (t) =>
          render(engine?.asListItem, {
            ...(rest as ListItemOpts<Entity>),
            onChange: (c) => mutate(asUpdate(t, c)),
            item: t as Entity,
            showProps,
            key: t.id,
          })
        )}
      </div>
    </WithViewingWithin>
  );
};
