import { addMilliseconds } from "date-fns";
import { filter, flatMap,map, omit, reduce, set } from "lodash";

import { CreateOrUpdate, Entity, Error, ErrorHandle,ID, Update } from "@api";

import { append, prepend } from "@utils/array";
import { toPointDate } from "@utils/date-fp";
import { composel, Fn, use, using } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";
import { now } from "@utils/now";
import { orZero } from "@utils/number";
import { fromPropertyValueRefs } from "@utils/property-refs";

import { SimpleStoreState, StoreState } from "./atoms";
import {
  aliasedID,
  applyMutation,
  applyMutations,
  asCreateOrUpdate,
  getItem,
  persistedID,
  reverseUpdate,
  stableID,
  updatesForId,
  withPersistedTimestamp,
  withQueuedTimestamp,
} from "./utils";

const withoutUpdate = <T extends Entity>(
  updates: Maybe<Update<T>[]>,
  remove: Update<T>
) =>
  filter(
    updates || [],
    (u) =>
      !(
        u.id === remove.id &&
        u.method === remove.method &&
        u.queued === remove.queued &&
        u.transaction === remove.transaction
      )
  );

/*
 * Simple Store Actions
 */

export const clearStore =
  <T>() =>
  <S extends SimpleStoreState<T>>(_store: S) => ({
    updatedAt: undefined,
    lookup: {},
  });

export const modifySimpleStoreState =
  <T>(id: ID, modify: Fn<Maybe<T>, Maybe<T>>) =>
  <S extends SimpleStoreState<T>>(store: S) => ({
    ...store,
    lookup: { ...store?.lookup, [id]: modify(store?.lookup?.[id]) },
  });

export const setSimpleStoreItem =
  <T>(id: ID, state: T) =>
  <S extends SimpleStoreState<T>>(store: S): S => ({
    ...store,
    lookup: { ...store?.lookup, [id]: state },
  });

export const setSimpleStoreItems =
  <T extends { id: ID }>(items: T[]) =>
  <S extends SimpleStoreState<T>>(store: S): S => ({
    ...store,
    lookup: reduce(items, (merged, i) => set(merged, i.id, i), {
      ...store.lookup,
    }),
  });

export const removeSimpleStoreItem =
  <T>(id: ID) =>
  <S extends SimpleStoreState<T>>(store: S): S => ({
    ...store,
    lookup: omit(store?.lookup || {}, id),
  });

/*
 * Generic
 */

// Sets the item in the store AND applies any mutations
export const setItem = <T extends Entity>(item: T) =>
  composel(
    aliasIfSet(item),
    (state: StoreState<T>): StoreState<T> =>
      using(
        [
          persistedID(item.id, state.aliases) || item.id,
          updatesForId(item.id, state),
        ],
        (id, updates) =>
          // Nothing changed, return original state
          item === state.lookup[id] && !updates.length
            ? state
            : {
                ...state,
                lookup: {
                  ...state.lookup,
                  [id]: applyMutations(item, updates),
                },
                updatedAt: now(),
              }
      )
  );
export const setItemPure = setItem;

export const setItems = <T extends Entity>(items: T[]) =>
  composel(
    (state: StoreState<T>): StoreState<T> => ({
      ...state,
      lookup: {
        ...state.lookup,
        ...reduce(
          items,
          (merged, i) =>
            set(
              merged,
              persistedID(i.id, state.aliases) || i.id,
              applyMutations(i, updatesForId(i.id, state))
            ),
          {}
        ),
      },
      updatedAt: now(),
    }),
    (state) => reduce(items, (s, i) => aliasIfSet(i)(s), state)
  );
export const setItemsPure = setItems;

// Combines the existing item in the store, with the new one,
// and applies any updates
export const mergeItem = <T extends Entity>(item: { id: ID } & Partial<T>) =>
  composel((state: StoreState<T>): StoreState<T> => {
    const existing = getItem(state, item.id);

    if (!existing) {
      return state;
    }

    return {
      ...state,
      lookup: {
        ...state.lookup,
        [item.id]: applyMutations(
          { ...existing, ...item },
          updatesForId(item.id, state)
        ),
      },
      updatedAt: now(),
    };
  }, aliasIfSet(item as T));

export const mergeItems = <T extends Entity>(items: T[]) =>
  composel(
    (state: StoreState<T>): StoreState<T> => ({
      ...state,
      lookup: {
        ...state.lookup,
        ...reduce(
          items,
          (merged, i) =>
            set(
              merged,
              i.id,
              applyMutations(
                { ...getItem(state, i.id), ...i },
                updatesForId(i.id, state)
              )
            ),
          {}
        ),
      },
      updatedAt: now(),
    }),
    (state) => reduce(items, (s, i) => aliasIfSet(i)(s), state)
  );

export const removeItemPure =
  <T extends Entity>(id: ID) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    lookup: omit(state.lookup, id),
    updatedAt: now(),
  });

export const removeItem =
  <T extends Entity>(id: ID) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    lookup: omit(
      state.lookup,
      id,
      stableID(id, state.aliases),
      persistedID(id, state.aliases) || id
    ),
    updatedAt: now(),
  });

export const removeItems =
  <T extends Entity>(ids: ID[]) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    lookup: omit(
      state.lookup,
      ...flatMap(ids, (id) => [
        id,
        stableID(id, state.aliases),
        persistedID(id, state.aliases) || id,
      ])
    ),
    updatedAt: now(),
  });

/*
 * Updating items
 */

const incrementAttempt = <T extends Entity>(update: Update<T>) => ({
  ...update,
  attempt: (update.attempt || 0) + 1,
  nextAttempt: toPointDate(
    addMilliseconds(now(), 500 * ((update.attempt || 0) + 1))
  ),
});

export const retryUpdate =
  <T extends Entity>(update: Update<T>) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    unsaved: map(state.unsaved, (u) =>
      u.id === update.id
        ? (omit(update, "attempt", "nextAttempt") as Update<T>)
        : u
    ),
  });

export const discardUpdate =
  <T extends Entity>(update: Update<T>) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    updating: withoutUpdate(state.updating, update),
    unsaved: withoutUpdate(state.unsaved, update),
    lookup: {
      ...state.lookup,
      [update.id]: applyMutations(getItem(state, update.id), [
        reverseUpdate(update),
      ]),
    },
  });

export const failUpdate =
  <T extends Entity>(update: Update<T>) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    updating: withoutUpdate(state.updating, update),
    unsaved: prepend(incrementAttempt(update), state.unsaved),
  });

export const handleError = <T extends Entity>(
  update: Update<T>,
  error: Error
) =>
  error?.handle === ErrorHandle.Discard ||
  orZero(asCreateOrUpdate(update)?.attempt) > 10
    ? discardUpdate(update)
    : failUpdate(update);

export const finishUpdate =
  <T extends Entity>(update: Update<T>, entity: Maybe<T>) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    history: append(state.history, withPersistedTimestamp<T>(update, entity)),
    updating: withoutUpdate(state.updating, update),
    updatedAt: now(),
  });

export const finishRestore = <T extends Entity>(
  update: Update<T>,
  entity: Maybe<T>
) =>
  composel(finishUpdate(update, entity), when(entity, setItem) || ((s) => s));

export const finishChangeUpdate = <T extends Entity>(
  update: Update<T>,
  latest?: T
) =>
  composel(
    finishUpdate(update, latest),
    (state: StoreState<T>): StoreState<T> =>
      when(latest, (latest) => ({
        ...state,
        lookup: {
          ...state.lookup,
          [latest.id]: applyMutations(latest, updatesForId(latest.id, state)),
        },
      })) || state
  );

export const addAlias =
  <T extends Entity>(id: string, id2: string) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    aliases: { ...state.aliases, [id]: id2, [id2]: id },
  });

export const aliasIfSet =
  <T extends Entity>(thing: T) =>
  (state: StoreState<T>): StoreState<T> =>
    when((thing as Maybe<{ alias?: string }>)?.alias, (alias) =>
      state?.aliases?.[alias] !== thing.id
        ? addAlias<T>(thing.id, alias)(state)
        : undefined
    ) || state;

export const finishCreate = <T extends Entity>(update: Update<T>, entity: T) =>
  composel(
    (state: StoreState<T>): StoreState<T> => ({
      ...state,
      dirty: [...(state.dirty || []), entity.id],
      lookup: omit(state.lookup, update.id),
    }),
    addAlias(update.id, entity.id),
    finishChangeUpdate(update, entity)
  );

export const startUpdate =
  <T extends Entity>(update: Update<T>) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    unsaved: withoutUpdate(state.unsaved, update),
    updating: append(state.updating, update),
  });

export const applyUpdate =
  <T extends Entity>(update: Update<T>) =>
  (state: StoreState<T>): StoreState<T> => {
    // Remove item from store
    if (update.method === "delete") {
      return composel(
        removeItem<T>(update.id),
        (s) =>
          when(aliasedID(update.id, s.aliases), (id) => removeItem<T>(id)(s)) ||
          s
      )(state);
    }

    if (update.method === "restore") {
      return update.restored ? setItemPure(update.restored)(state) : state;
    }

    // Create entity from create changes
    if (update.method === "create") {
      const existing = getItem(state, update.id);
      const flattened = fromPropertyValueRefs<T>(
        update.id,
        update.source,
        (update as CreateOrUpdate<T>).changes
      );
      return setItemPure({ ...existing, ...flattened })({
        ...state,
        dirty: [...(state.dirty || []), update.id],
      });
    }

    // Merge new changes with existing value
    const updated = when(
      when(persistedID(update.id, state.aliases) || update.id, (id) =>
        getItem(state, id)
      ),
      (existing: T) => applyMutation(existing, update)
    );

    if (updated) {
      return setItemPure(updated)({
        ...state,
        dirty: [...(state.dirty || []), update.id],
      });
    }

    return state;
  };

export const applyUpdates =
  <T extends Entity>(updates: Update<T>[]) =>
  (state: StoreState<T>): StoreState<T> =>
    reduce(updates, (s, u) => applyUpdate(u)(s), state);

export const clearTempUpdates =
  <T extends Entity>(id: ID) =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    unsaved: filter(
      state.unsaved,
      (u) =>
        !(
          u.mode === "temp" &&
          (u.id === id || u.id === aliasedID(id, state.aliases))
        )
    ),
  });

export const clearAllTempUpdates =
  <T extends Entity>() =>
  (state: StoreState<T>): StoreState<T> => ({
    ...state,
    unsaved: filter(state.unsaved, (u) => !(u.mode === "temp")),
  });

export const queueUpdate = <T extends Entity>(
  update: Update<T>
): Fn<StoreState<T>, StoreState<T>> =>
  use(withQueuedTimestamp(update), (timestamped) =>
    composel(
      (state) => ({ ...state, unsaved: append(state.unsaved, timestamped) }),
      applyUpdate(timestamped)
    )
  );

export const syncUpdate = <T extends Entity>(
  update: Update<T>
): Fn<StoreState<T>, StoreState<T>> =>
  use({ ...update, mode: "sync" } as Update<T>, (withSync) =>
    composel(
      (state) => ({
        ...state,
        history: append(state.history, withSync),
      }),
      applyUpdate(withSync)
    )
  );

export const queueUpdates = <T extends Entity>(
  updates: Update<T>[]
): Fn<StoreState<T>, StoreState<T>> =>
  use(map(updates, withQueuedTimestamp), (timestamped) =>
    composel(
      (state) => ({
        ...state,
        unsaved: [...(state.unsaved || []), ...timestamped],
      }),
      applyUpdates(timestamped)
    )
  );

/*
 * Temp Updates Actions
 */

export const queueTempUpdates =
  <T extends Entity>(updates: Update<T>[]) =>
  (state: Update<T>[]): Update<T>[] =>
    [...state, ...updates];
