import { createContext, useContext } from "react";

import { ID } from "@api";

import { Maybe } from "@utils/maybe";

export const AppPageContext = createContext<Maybe<ID>>(undefined);

export const useAppPageContext = () => {
  return useContext(AppPageContext);
};

export const usePageId = useAppPageContext;

export const useContextPageId = useAppPageContext;
