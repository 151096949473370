import { groupBy, isString } from "lodash";
import { useMemo } from "react";
import { GroupBase } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";

import { Status } from "@api";

import { maybeMap } from "@utils/array";
import { cx } from "@utils/class-names";
import { when } from "@utils/maybe";
import { STATUS_GROUPS, toLabel } from "@utils/status";

import { MenuItem } from "@ui/menu-item";
import { StatusTag } from "@ui/tag";

import { Select, SelectProps } from "./single-select";

import styles from "./select.module.css";

export { STATUS_GROUPS } from "@utils/status";

export const StatusSelectOverrides: Partial<
  SelectComponents<Status, false, GroupBase<Status>>
> = {
  Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => (
    <div ref={innerRef} {...innerProps}>
      <MenuItem
        className={cx(
          styles.menuItem,
          isFocused && styles.focused,
          isSelected && styles.active
        )}
      >
        <StatusTag status={data}>{data?.name}</StatusTag>
      </MenuItem>
    </div>
  ),
  MultiValueLabel: ({ data }) => (
    <StatusTag status={data}>{data?.name}</StatusTag>
  ),
};

export const StatusSelect = ({
  children,
  className,
  footer,
  // Used for subtasks where we want to go from not-started to done
  simpleSuggestions = false,
  options,
  ...props
}: SelectProps<Status> & {
  simpleSuggestions?: boolean;
  options: Status[];
}) => {
  const grouped = useMemo((): GroupBase<Status>[] => {
    const byGroup = groupBy(options, (o) => o.group);
    return maybeMap(STATUS_GROUPS, (group) =>
      when(byGroup[group], (vs) => ({
        label: toLabel({ group } as Partial<Status>),
        options: vs,
      }))
    );
  }, [options]);

  return (
    <Select
      searchable={false}
      {...props}
      options={grouped}
      className={{
        ...(isString(className) ? {} : className),
        select: cx(
          styles.statusSelect,
          isString(className) ? className : className?.select
        ),
      }}
      overrides={StatusSelectOverrides}
      footer={footer}
    >
      {children}
    </Select>
  );
};
