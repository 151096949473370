import { filter } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";

import { ID, Integration, Ref, Team } from "@api";

import { useLazyFetchResults } from "@state/fetch-results";
import {
  useCreateFromObject,
  useLazyEntities,
  useLazyEntity,
} from "@state/generic";
import { useMe } from "@state/persons";
import { useQueueUpdateEffect } from "@state/store";
import { ActiveWorkspaceId } from "@state/workspace";

import { ensureMany, findPreferential, OneOrMany } from "@utils/array";
import { useGoTo } from "@utils/navigation";
import { toNotionUrl } from "@utils/notion";
import { toUpdate } from "@utils/property-mutations";
import { toRef } from "@utils/property-refs";
import { containsRef } from "@utils/relation-ref";
import { toScope } from "@utils/scope";

import { TeamStoreAtom } from "./atoms";
import { newTeam } from "./utils";

export function useLazyGetTeam(teamId: ID) {
  return useLazyEntity<"team">(teamId);
}

export function useLazyAllTeams() {
  const teams = useLazyFetchResults(
    "all-teams",
    "team",
    useMemo(() => ({ and: [] }), []),
    { archived: false, templates: false }
  );

  return teams;
}

export function useLazyTeam(teamId: ID) {
  return useLazyEntity<"team">(teamId);
}

export function useCreateTeam(pageId?: ID, defaults?: Partial<Team>) {
  const me = useMe();
  const createInStore = useCreateFromObject("team", defaults?.source?.scope);
  const workspaceID = useRecoilValue(ActiveWorkspaceId);
  const [team, setTeam] = useState(
    newTeam({
      owner: toRef(me),
      people: me ? [{ id: me.id }] : [],
      ...defaults,
      source: {
        source: Integration.Traction,
        type: "team",
        scope: toScope(workspaceID),
      },
    })
  );

  const create = useCallback(() => {
    const [saved] = createInStore?.([team]) || [];
    return saved;
  }, [team, createInStore]);

  return useMemo(
    () => ({
      team,
      setTeam,
      create,
    }),
    [team, create]
  );
}

export function useLinkToNotion(teamId: ID) {
  const team = useLazyGetTeam(teamId);
  const update = useQueueUpdateEffect(TeamStoreAtom);

  return useCallback(
    async (id: string) => {
      if (team && !team.notionId) {
        const notionUrl = toNotionUrl(id);

        // Save notionId to DB
        update([
          toUpdate(team, { field: "notionId", type: "text" }, id),
          // toUpdate(team, { field: "links", type: "links" }, [
          //   ...(team.links || []),
          //   { text: "Team Docs", url: notionUrl },
          // ]),
        ]);
      }
    },
    [team?.notionId]
  );
}

export const useLazyTeamsFor = (personId: string) => {
  const teams = useLazyAllTeams();
  return filter(teams, (t) => containsRef(t.people, { id: personId }));
};

export const useLazyMyTeams = () => {
  const me = useMe();
  return useLazyTeamsFor(me?.id || "");
};

// TODO: Add default team settings to
export const useDefaultTeam = () => {
  const me = useMe();
  const teams = useLazyAllTeams();

  return findPreferential(
    teams,
    (t) => containsRef(t.people, { id: me.id }),
    () => true
  );
};

export const useLazyPeopleForTeam = (teamId: ID) => {
  const team = useLazyGetTeam(teamId);
  return useLazyEntities<"person">(team?.people || []);
};

export const useTeamPushTo = (teamId: string) => {
  const goTo = useGoTo();
  return useCallback(
    (r: OneOrMany<string | Ref>, params?: Record<string, string>) =>
      goTo([{ id: teamId }, ...ensureMany(r)], params),
    [teamId]
  );
};
