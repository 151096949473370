import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { authenticate, authorize, Integration, WorkspaceConfig } from "@api";

import { AuthScope } from "@graph/types";

import {
  addWorkspaceConfig,
  MaybeActiveWorkspaceSessionAtom,
  WorkspaceSessionStateAtom,
} from "@state/workspace";

import { useAsyncEffect } from "@utils/effects";
import { redirect } from "@utils/url";

import { Divider } from "@ui/divider";
import { VStack } from "@ui/flex";
import { Icon, SpinnerIcon } from "@ui/icon";
import { AuthLayout } from "@ui/page-layout";
import { Sheet } from "@ui/sheet-layout";
import { Heading, Text } from "@ui/text";

import { PublicPage } from "./public-page";

import styles from "./public.module.css";

interface Props {
  code?: string;
  error?: string;
}

export default function NotionConnect({ code, error: err }: Props) {
  const { user: me } = useRecoilValue(MaybeActiveWorkspaceSessionAtom) || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(err);
  const setWorkspace = useSetRecoilState(WorkspaceSessionStateAtom);

  const setWorkspaceAndRedirect = (workspace: WorkspaceConfig) => {
    setWorkspace(addWorkspaceConfig(workspace));
    // Allow time for recoil to sync to local storage
    setTimeout(() => redirect("/home"), 1000);
  };

  useAsyncEffect(async () => {
    if (loading || !code) {
      return;
    }

    try {
      setLoading(true);

      const config = await (!!me
        ? authorize(code, Integration.Notion, AuthScope.Person)
        : authenticate(code, Integration.Notion));

      if (!config) {
        setError("No workspace found.");
        return;
      }

      setWorkspaceAndRedirect(config);

      setLoading(false);
    } catch (err) {
      setError((err as Error).message);
      setLoading(false);
    }
  }, [code]);

  return (
    <PublicPage>
      <AuthLayout>
        <VStack>
          <Sheet className={styles.authCard} height="content">
            <VStack gap={30}>
              <VStack>
                <Heading bold>Finalizing...</Heading>
                <Text subtle>
                  Please wait a second while we get your workspace ready.
                </Text>
              </VStack>

              <Divider />

              {loading && <Icon size="large" icon={SpinnerIcon} />}
              {error && <Text>{error}</Text>}
            </VStack>
          </Sheet>
        </VStack>
      </AuthLayout>
    </PublicPage>
  );
}
