import { filter } from "lodash";
import { useMemo } from "react";
import { useSetRecoilState } from "recoil";

import { HasTemplate, Schedule } from "@api";

import { AppCommandsAtom, useOpenAppCommands } from "@state/app";
import {
  useLazyEntities,
  useLazyEntity,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { toSentence, useCreateNextForSchedule } from "@state/schedule";

import { safeAs, when } from "@utils/maybe";
import { asMutation } from "@utils/property-mutations";

import { usePageId } from "@ui/app-page";
import { Button } from "@ui/button";
import { CollapsibleSection } from "@ui/collapsible-section";
import { Centered, Container } from "@ui/container";
import { EditableHeading } from "@ui/editable-heading";
import { EmptyState } from "@ui/empty-state";
import { EntityList } from "@ui/entity-list";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Slash } from "@ui/icon";
import { LocationBreadcrumb } from "@ui/location-button";
import { PackageTag } from "@ui/package-label";
import { PaneHeader } from "@ui/pane-header";
import { PropertyValueTile } from "@ui/property-value-tile";
import { Section } from "@ui/section";
import { Sheet } from "@ui/sheet-layout";
import { TemplateBanner } from "@ui/template-banner";
import { TemplateConfigure } from "@ui/template-configure";
import { Text, TextMedium } from "@ui/text";
import { WorkflowActions } from "@ui/workflow-action-button";

import { PaneOpts } from "../types";
import { ScheduleConfiguration } from "./editor";

import styles from "./styles.module.css";

export const SchedulePane = ({ id, item }: PaneOpts<Schedule>) => {
  const mutate = useUpdateEntity(id);
  const template = useLazyEntity(item.useTemplate?.id);

  const itemSource = useNestedSource(item, item.entity);

  return (
    <Sheet size="primary-thicc">
      {!!item.template && <TemplateBanner />}

      <Header item={item} />

      <Centered stack="vertical" gap={30}>
        <CollapsibleSection>
          <ScheduleConfiguration
            schedule={item}
            source={item.source}
            templateId={template?.id}
            showTemplate={false}
            showNext={false}
          />
        </CollapsibleSection>

        <Section
          title={
            item?.useTemplate ? (
              <TextMedium>Create from template</TextMedium>
            ) : item.entity ? (
              <TextMedium>
                Create new{" "}
                <PackageTag
                  disabled={true}
                  type={item.entity}
                  scope={item.source.scope}
                />{" "}
                with values
              </TextMedium>
            ) : undefined
          }
        >
          <TemplateConfigure
            template={item.useTemplate}
            overrides={item.overrides}
            source={itemSource}
            onChange={mutate}
          />
        </Section>
      </Centered>
    </Sheet>
  );
};

export const ScheduleInstancesPane = ({ id, item }: PaneOpts<Schedule>) => {
  const { create, loading: creating } = useCreateNextForSchedule(id);
  const allInstances = useLazyEntities(item.instances || []);
  const instances = useMemo(
    () => filter(allInstances, (i) => !safeAs<HasTemplate>(i)?.template),
    [allInstances]
  );

  return (
    <Sheet size="primary" height="content" transparency="mid">
      <PaneHeader
        title="Created by this schedule"
        actions={
          <Button
            subtle
            onClick={() => create?.()}
            size="small"
            loading={creating}
            disabled={!create}
          >
            <Text subtle>Force create next</Text>
          </Button>
        }
      />
      <Centered stack="vertical" gap={30} size="half" padding="horizontal">
        <EntityList items={instances || []} limit={30} />

        {!instances?.length && <EmptyState text={"Nothing created yet..."} />}
      </Centered>
    </Sheet>
  );
};

interface HeaderProps {
  item: Schedule;
}

const Header = ({ item }: HeaderProps) => {
  const pageId = usePageId();
  const setAppCommands = useSetRecoilState(AppCommandsAtom);
  const mutate = useUpdateEntity(item.id, pageId);

  const openCmdK = useOpenAppCommands(item);

  if (!item) {
    return <h1>Not found.</h1>;
  }

  return (
    <>
      <Centered className={styles.container}>
        <VStack gap={20}>
          <Container gap={20} padding="none" inset="bottom" stack="vertical">
            <Container
              padding="none"
              inset="left"
              stack="horizontal"
              fit="content"
            >
              <SpaceBetween>
                <LocationBreadcrumb location={item.location} />

                <HStack gap={2}>
                  <PackageTag type="schedule" scope={item.source.scope} />
                  <Button size="small" icon={Slash} subtle onClick={openCmdK}>
                    Modify
                  </Button>
                </HStack>
              </SpaceBetween>
            </Container>

            <VStack gap={0}>
              <Container padding="none" inset="left">
                <PropertyValueTile
                  entity={item}
                  label={false}
                  field={"status"}
                  onChange={mutate}
                />
              </Container>
              <SpaceBetween>
                <VStack gap={0}>
                  <TextMedium subtle={!!item.name} bold>
                    {toSentence(item)}
                  </TextMedium>
                  <EditableHeading
                    key={`${item.id}-heading`}
                    size="h3"
                    text={item.name || ""}
                    placeholder="Untitled schedule"
                    autoFocus={false}
                    onChange={(text) => {
                      when(text, (i) => {
                        mutate(asMutation({ field: "name", type: "text" }, i));
                      });
                    }}
                  />
                </VStack>

                <WorkflowActions entity={item} />
              </SpaceBetween>
            </VStack>
          </Container>
        </VStack>
      </Centered>
    </>
  );
};
