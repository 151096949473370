import { useCallback } from "react";

import { Entity } from "@api";

import { useShortcut } from "@utils/event";
import { when } from "@utils/maybe";
import { toLink } from "@utils/navigation";

import { Button, Props as ButtonProps } from "@ui/button";
import { copyToClipboard } from "@ui/clipboard";
import { LinkAlt } from "@ui/icon";
import { Text } from "@ui/text";

type CopyLinkProps = { link?: string; entity?: Entity };

export const CopyLinkButton = ({
  link,
  entity,
  children,
  ...props
}: ButtonProps & CopyLinkProps) => {
  const onClicked = useCallback(
    () => when(entity ? toLink(entity) : link, copyToClipboard),
    [link, entity]
  );

  useShortcut({ key: "KeyL", command: true }, onClicked, [onClicked]);

  return (
    <Button icon={LinkAlt} onClick={onClicked} subtle {...props}>
      <Text>Copy link</Text>
    </Button>
  );
};
