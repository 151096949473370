import { ReactNode } from "react";

import { cx } from "@utils/class-names";

import styles from "./on-hover.module.css";

export const Trigger = ({ children }: { children: ReactNode }) => {
  return <div className={styles.parent}>{children}</div>;
};

export const Target = ({
  children,
  show,
  opacity = "none",
}: {
  show?: boolean;
  opacity?: "none" | "partial";
  children: ReactNode;
}) => {
  return (
    <div className={cx(styles.target, styles[opacity], show && styles.show)}>
      {children}
    </div>
  );
};

export const OnHover = { Trigger, Target };
