import { Task } from "@api";

import { useIsShowing, useLazyPropertyValue } from "@state/databases";

import { cx } from "@utils/class-names";
import { useDebouncedMemo } from "@utils/hooks";
import { asMutation } from "@utils/property-mutations";
import { useSlowSelected } from "@utils/selectable";

import { CodeLabel } from "@ui/code-label";
import { DueDate } from "@ui/due-date";
import { EditableText } from "@ui/editable-text";
import { EntityContextMenu } from "@ui/entity-context-menu";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { PropertyValue } from "@ui/property-value";
import { PropertyValueStack } from "@ui/property-value-stack";
import { SelectableListCard } from "@ui/selectable-items";

import { ListCardOpts } from "../types";

import styles from "./list-card.module.css";

export const TaskListCard = (props: ListCardOpts<Task>) => {
  const { item, showProps, selection, setSelection, className, onChange } =
    props;
  const selected = useSlowSelected(item.id, selection);
  const isShowing = useIsShowing(showProps);
  const statusProp = useLazyPropertyValue(item, {
    field: "status",
    type: "status",
  });
  const assignedProp = useLazyPropertyValue(item, {
    field: "assigned",
    type: "relation",
  });

  const editable = useDebouncedMemo(() => !!selected, 300, [selected]);

  return (
    <EntityContextMenu
      entity={item}
      selection={selection}
      setSelection={setSelection}
    >
      <SelectableListCard className={cx(styles.task, className)} {...props}>
        <SpaceBetween className={styles.upper}>
          <CodeLabel code={item.code} />
          <HStack gap={0}>
            {item.end && !isShowing("end") && (
              <DueDate
                date={item.end}
                status={statusProp.value.status?.group}
                onClick={() => props.onOpen?.(item)}
              />
            )}
            {assignedProp && !isShowing("assigned") && (
              <PropertyValue
                source={item.source}
                valueRef={assignedProp}
                variant="icon-only"
                onChange={(e) =>
                  onChange?.(asMutation(assignedProp, e[assignedProp.type]))
                }
              />
            )}
            {statusProp && !isShowing("status") && (
              <PropertyValue
                source={item.source}
                parent={item}
                valueRef={statusProp}
                variant="icon-only"
                onChange={(e) => onChange?.(asMutation(statusProp, e.status))}
              />
            )}
          </HStack>
        </SpaceBetween>

        <VStack
          className={styles.middle}
          gap={0}
          fit="container"
          align="stretch"
        >
          <EditableText
            key={item.id}
            className={styles.title}
            text={item.title || ""}
            placeholder="Untitled"
            disabled={!editable}
            blurOnEnter={true}
            onChange={(v) =>
              onChange?.(asMutation({ field: "title", type: "text" }, v))
            }
          />

          <PropertyValueStack
            wrap
            item={item}
            editable={selected}
            props={showProps}
            blacklist={["status", "assigned", "title", "icon", "code"]}
            onChange={onChange}
            hideEmpty={props.hideEmpty}
          />
        </VStack>
      </SelectableListCard>
    </EntityContextMenu>
  );
};
