import {
  CSSProperties,
  DOMAttributes,
  forwardRef,
  ReactNode,
  Ref,
  useMemo,
} from "react";

type Props = {
  children: ReactNode;
  overflow?: "scroll" | "hidden" | "visible";
  className?: string;
  style?: CSSProperties;
  direction?: "horizontal" | "vertical";
  fit?: "content" | "container";
  wrap?: boolean;
  height?: "content" | "container";
  width?: "content" | "container";
  gap?: number;
} & DOMAttributes<HTMLDivElement>;

export const SpaceBetween = ({
  children,
  align,
  style,
  gap = 0,
  direction = "horizontal",
  fit = "container",
  width,
  ...rest
}: Props & {
  align?: "flex-start" | "flex-end" | "center" | "baseline" | "stretch";
}) => (
  <div
    style={{
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
      alignItems: align || "center",

      ...(direction === "horizontal"
        ? {
            flexDirection: "row",
            columnGap: `${gap}px`,
            width: fit === "container" ? "100%" : "",
          }
        : {
            flexDirection: "column",
            rowGap: `${gap}px`,
            height: fit === "container" ? "100%" : "",
            width: width === "container" ? "100%" : "",
          }),

      ...style,
    }}
    {...rest}
  >
    {children}
  </div>
);

export type StackHorizontalProps = Props & {
  gap?: number;
  justify?: "flex-start" | "flex-end" | "center";
  align?:
    | "stretch"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "stretch";
};

export const StackHorizontal = ({
  children,
  align,
  justify,
  gap,
  style,
  wrap,
  fit,
  overflow,
  ...rest
}: StackHorizontalProps) => (
  <div
    {...rest}
    style={{
      display: "flex",
      justifyContent: justify || "flex-start",
      alignItems: align || "center",
      columnGap: `${gap ?? 10}px`,
      rowGap: `${gap ?? 10}px`,
      width: fit === "container" ? "100%" : "",
      overflow:
        overflow === "scroll" ? "auto" : overflow === "hidden" ? "hidden" : "",
      flexWrap: wrap ? "wrap" : undefined,
      ...style,
    }}
  >
    {children}
  </div>
);
// Alias
export const HStack = StackHorizontal;

export type StackVerticalProps = Props & {
  gap?: number;
  justify?: "flex-start" | "flex-end" | "center";
  align?:
    | "stretch"
    | "flex-start"
    | "flex-end"
    | "center"
    | "baseline"
    | "stretch";
};

export const StackVertical = forwardRef(
  (
    {
      children,
      justify,
      align,
      gap,
      fit = "container",
      width = fit,
      height,
      wrap,
      style,
      overflow,
      ...rest
    }: StackVerticalProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <div
      ref={ref}
      {...rest}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: justify || "flex-start",
        alignItems: align || "flex-start",
        rowGap: `${gap ?? 10}px`,
        columnGap: `${gap ?? 10}px`,
        width: width === "container" ? "100%" : "",
        height: height === "container" ? "100%" : "",
        overflow:
          overflow === "scroll"
            ? "auto"
            : overflow === "hidden"
            ? "hidden"
            : "",
        flexWrap: wrap ? "wrap" : undefined,
        ...style,
      }}
    >
      {children}
    </div>
  )
);
// Alias
export const VStack = StackVertical;

export const FillSpace = ({
  style,
  className,
  fit,
  height = fit,
  width = fit,
  overflow,
  direction = "vertical",
  children,
}: Props) => (
  <div
    style={useMemo(
      () => ({
        minWidth: 0,
        flex: "1 1 auto",
        overflow:
          overflow === "scroll"
            ? "auto"
            : overflow === "hidden"
            ? "hidden"
            : "",
        width: width === "container" ? "100%" : "",
        height: height === "container" || direction == "vertical" ? "100%" : "",
        ...style,
      }),
      [style, width, height, overflow, direction]
    )}
    className={className}
  >
    {children}
  </div>
);

export const HalfSpace = ({ style, className, gap, fit, children }: Props) => (
  <div
    style={{
      width: `calc(50% - ${(gap ?? 4) / 2}px)`,
      height: fit === "container" ? "100%" : "",
      ...style,
    }}
    className={className}
  >
    {children}
  </div>
);

export const ThirdSpace = ({ style, gap, className, fit, children }: Props) => (
  <div
    style={{
      width: `calc(33.33% - ${((gap ?? 4) * 2) / 3}px)`,
      height: fit === "container" ? "100%" : "",
      ...style,
    }}
    className={className}
  >
    {children}
  </div>
);

export const Centered = ({
  direction,
  style,
  className,
  children,
  gap,
  fit,
  width,
  height,
}: Props) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      ...(direction === "horizontal"
        ? {
            flexDirection: "row",
            columnGap: `${gap}px`,
            width: fit === "container" ? "100%" : "",
          }
        : {
            flexDirection: "column",
            rowGap: `${gap}px`,
            height: fit === "container" ? "100%" : "",
            width: width === "container" ? "100%" : "",
          }),
      ...style,
    }}
    className={className}
  >
    {children}
  </div>
);
