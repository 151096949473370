import { map } from "lodash";
import { Fragment } from "react";

import { Ref } from "@api";

import { useLazyEntities } from "@state/generic";

import { cx } from "@utils/class-names";

import { Icon, Props as IconProps } from "@ui/icon";
import { PersonStatusIcon } from "@ui/person-status-icon";
import { RelationTooltip } from "@ui/relation-label";

import { HStack } from "./flex";

import styles from "./people-stack.module.css";

interface Props {
  people?: Ref[];
  size?: IconProps["size"];
}

export const PeopleStack = ({ people: _people, size = "default" }: Props) => {
  const people = useLazyEntities<"person">(_people || []);
  return (
    <HStack gap={0} className={cx(styles.stack, styles[size])}>
      {map(people, (p) => (
        <Fragment key={p.id}>
          <RelationTooltip relation={p} delay={100}>
            <Icon
              size={size}
              className={styles.icon}
              icon={<PersonStatusIcon person={p} />}
            />
          </RelationTooltip>
        </Fragment>
      ))}
    </HStack>
  );
};
