import { useCallback, useMemo,useState } from "react";

import { EntityType, Form, Ref } from "@api";

import { autoName, useAiUseCase } from "@state/ai";
import { useCreateFromObject } from "@state/generic";
import { useEntityLabels } from "@state/settings";
import { useActiveWorkspaceId } from "@state/workspace";

import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { useSlowMemo } from "@utils/hooks";
import { maybeTypeFromId } from "@utils/id";
import { when } from "@utils/maybe";
import { toRef } from "@utils/property-refs";

import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { DialogSplit } from "@ui/dialog-split";
import { EducationTip } from "@ui/education-tip";
import { FillSpace, SpaceBetween, VStack } from "@ui/flex";
import { Field, TextInput } from "@ui/input";
import { showError } from "@ui/notifications";
import { LocationSelect, TemplateSelect } from "@ui/select";
import { MagicEmojiSelect } from "@ui/select/emoji";
import { EntityTypeSelect } from "@ui/select/entity-type";
import { Text, TextSmall } from "@ui/text";

import styles from "./styles.module.css";

interface Props {
  defaults?: Partial<Omit<Form, "id">>;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const FormCreateDialog = ({ onCancel, onSaved, defaults }: Props) => {
  const wID = useActiveWorkspaceId();
  const scope = useMemo(
    () => defaults?.source?.scope || defaults?.location || wID,
    [defaults?.source?.scope]
  );
  const [smartName, setSmartName] = useState(true);
  const showName = !smartName;
  const [suggestedName, setSuggestedName] = useState("");
  const showRest = useSlowMemo(() => !!suggestedName, 800, [suggestedName]);
  const create = useCreateFromObject("form", scope);
  const toEntityLabel = useEntityLabels(scope);
  const [form, _setForm] = useState<Partial<Form>>({
    icon: "📋",
    ...defaults,
    entity:
      defaults?.entity ||
      when(defaults?.useTemplate?.id, maybeTypeFromId<EntityType>) ||
      undefined,
    template: "root",
    source: { type: "form", scope },
  });
  const setForm = useCallback(
    (f: Partial<Form>) => _setForm((r) => ({ ...r, ...f })),
    [_setForm]
  );
  const { run, loading } = useAiUseCase(autoName);

  const onCreate = useCallback(() => {
    if (!create) {
      return showError("Not ready.");
    }

    const [saved] = create([
      {
        name: smartName && suggestedName ? suggestedName : form.name,
        ...form,
      },
    ]);

    if (saved) {
      onSaved?.(saved);
    } else {
      onCancel?.();
    }
  }, [form, create, suggestedName, smartName, onSaved, onCancel]);

  const slowPurpose = useSlowMemo(
    () => form.purpose,
    [200, 2000],
    [form?.purpose]
  );

  useAsyncEffect(async () => {
    if (!form.name && !!form.purpose?.trim()) {
      const suggested = await run({ entity: form });
      setSuggestedName(suggested);
    }
  }, [slowPurpose]);

  return (
    <DialogSplit
      title={"New form"}
      onDismiss={onCancel}
      side={
        <SpaceBetween direction="vertical">
          <Text subtle>
            Forms are used to collect structured data. They can be attached to
            any entity.
          </Text>
          <EducationTip relevantTo={["form"]} />
        </SpaceBetween>
      }
      actions={
        <>
          <Button onClick={() => onCancel?.()}>Cancel</Button>
          <Button variant="primary" onClick={onCreate} disabled={!form.entity}>
            Create form
          </Button>
        </>
      }
    >
      <FillSpace direction="vertical">
        <Container gap={20} stack="vertical" fit="container">
          <>
            <Field>
              <MagicEmojiSelect
                key={form.id || "new-form"}
                entity={form as Form}
                size="xlarge"
                emoji={form.icon || ""}
                onChange={(icon) => setForm({ icon })}
              />
            </Field>

            <Field label="Purpose">
              <VStack gap={2}>
                <TextInput
                  value={form.purpose || ""}
                  onChange={(t) => setForm({ purpose: t })}
                  updateOn="change"
                  autoFocus={true}
                  placeholder="What is the purpose of this form?"
                />
                {!showName && (
                  <Container
                    padding="left"
                    size="half"
                    onClick={() => setSmartName((n) => !n)}
                  >
                    {suggestedName && (
                      <TextSmall subtle>Name: {suggestedName}</TextSmall>
                    )}
                    {!suggestedName && <TextSmall subtle> </TextSmall>}
                  </Container>
                )}
              </VStack>
            </Field>

            {showName && (
              <Field label="Name">
                <TextInput
                  value={form.name || ""}
                  onChange={(t) => setForm({ name: t })}
                  placeholder={suggestedName || "Give this form a name..."}
                  updateOn="change"
                  autoFocus={false}
                />
              </Field>
            )}

            {showRest && (
              <>
                <Field label="Creates" fit="container">
                  <EntityTypeSelect
                    value={form.entity}
                    portal
                    scope={scope}
                    placeholder="Select work type"
                    plural={false}
                    onChange={(entity) => setForm({ entity })}
                  />
                </Field>
                <Field label="Using template" fit="container">
                  <TemplateSelect
                    scope={scope}
                    type={form.entity}
                    allowed={form.entity ? [form.entity] : []}
                    allowNew={false}
                    value={form.useTemplate}
                    onChange={(r) => setForm({ useTemplate: toRef(r) })}
                    closeOnSelect={true}
                    placeholder="No template..."
                    className={{ trigger: styles.control }}
                  />
                </Field>
                <Field
                  label={`Saves ${toEntityLabel(form.entity, {
                    case: "lower",
                    plural: true,
                  })} to`}
                  fit="container"
                >
                  <LocationSelect
                    className={{ trigger: styles.control }}
                    location={form.inLocation || scope}
                    onChange={(l) => setForm({ inLocation: l })}
                    fit="container"
                    source={form.source}
                    showOpen={true}
                    variant="full"
                    showCaret={true}
                  />
                </Field>
              </>
            )}
          </>
        </Container>
      </FillSpace>
    </DialogSplit>
  );
};
