import { Container } from "@ui/container";
import { TextLarge } from "@ui/text";

import styles from "./empty-state.module.css";

interface Props {
  text?: string;
  image?: string;
  children?: React.ReactNode;
}

export const EmptyState = ({ text, image, children }: Props) => (
  <Container
    className={styles.container}
    stack="vertical"
    gap={10}
    justify="center"
    align="center"
  >
    {image && <img className={styles.image} src={image} />}
    {text && (
      <TextLarge bold subtle className={styles.text}>
        {text}
      </TextLarge>
    )}
    {children}
  </Container>
);
