import { ReactNode, useCallback, useState } from "react";

import { Fn } from "@utils/fn";

import { Button , Props as ButtonProps } from "./button";
import { ConfirmationDialog } from "./confirmation-dialog";

interface Props {
  dialogTitle?: string;
  dialogDescription?: string;
  destructive?: boolean;
  onCancel?: Fn<void, void>;
  onConfirm: Fn<void, void>;
  children?: ReactNode;
}

export const ConfirmationButton = ({
  children,
  dialogTitle,
  dialogDescription,
  destructive,
  onCancel,
  onConfirm,
  ...buttonProps
}: Props & ButtonProps) => {
  const [confirming, setConfirming] = useState(false);

  const toggleConfirming = useCallback(
    () => setConfirming(!confirming),
    [confirming]
  );

  const withDismiss =
    <T extends Function>(fn?: T) =>
    () => {
      toggleConfirming();
      fn?.();
    };

  return (
    <>
      <Button {...buttonProps} onClick={toggleConfirming}>
        {children}
      </Button>
      {confirming && (
        <ConfirmationDialog
          title={dialogTitle}
          description={dialogDescription}
          destructive={destructive}
          onConfirm={withDismiss(onConfirm)}
          onCancel={withDismiss(onCancel)}
        />
      )}
    </>
  );
};

export const DeleteButton = (props: Props & ButtonProps) => (
  <ConfirmationButton
    {...props}
    destructive={true}
    dialogTitle="Are you sure you want to delete this?"
    dialogDescription="This is hard to undo. You would have to speak to a real human to undo it..."
  />
);
