import { CSSProperties } from "react";

import { when } from "@utils/maybe";

import { Icon } from ".";
import { iconFromString } from "./utils";

interface Props {
  icon: string;
  className?: string;
  style?: CSSProperties;
}

export const PageIcon = ({ icon, ...props }: Props) =>
  when(iconFromString(icon), (i) => <Icon icon={i} {...props} />) || <></>;
