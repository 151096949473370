import {
  addDays,
  getDate,
  getDay,
  getDayOfYear,
  getWeek,
  setDate,
  setDay,
  setDayOfYear,
  setMonth,
  setWeek,
  setYear,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
} from "date-fns";
import { find, isEmpty, isNumber, map } from "lodash";

import { DayOfWeek, Period,Schedule } from "@api/types";

import { endOfPrevDay } from "@utils/date";
import {
  fromCalDate,
  ISODate,
  now as nowISO,
  useISODate,
} from "@utils/date-fp";
import { use } from "@utils/fn";
import { switchEnum } from "@utils/logic";
import { when } from "@utils/maybe";
import { plural } from "@utils/string";
import { now, parseTime, withTime } from "@utils/time";

export const toSentence = (s: Schedule) =>
  `Every${s.frequency === 1 ? "" : " " + s.frequency} ${plural(
    s.period,
    s.frequency
  )}${
    s.period === "week" && s.daysOfPeriod && !isEmpty(s.daysOfPeriod)
      ? ` on ${map(s.daysOfPeriod, toDayOfWeek).join(", ")}`
      : ""
  }${when(s.timeOfDay, (t) => ` at ${t}`) || ""}`;

export const toDayOfWeek = (date: Date | number): DayOfWeek =>
  switchEnum(isNumber(date) ? date : getDay(date), {
    0: () => DayOfWeek.Sunday,
    1: DayOfWeek.Monday,
    2: DayOfWeek.Tuesday,
    3: DayOfWeek.Wednesday,
    4: DayOfWeek.Thursday,
    5: DayOfWeek.Friday,
    6: DayOfWeek.Saturday,
  });

export const toDayOfPeriod = (date: ISODate, period: Period) =>
  useISODate(date, (d) =>
    switchEnum(period, {
      day: () => 0,
      week: () => getDay(d),
      month: () => getDate(d),
      quarter: () => getDayOfYear(d) % 90,
      year: () => getDayOfYear(d),
    })
  );

export const fromDayOfWeek = (day: DayOfWeek) =>
  switchEnum(day, {
    sunday: () => 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  });

export const addPeriod = (date: Date, period: Period, units: number = 1) =>
  switchEnum(period, {
    day: () => setDate(date, date.getDate() + units),
    week: () => setWeek(date, getWeek(date) + units),
    month: () => setMonth(date, date.getMonth() + units),
    quarter: () => setMonth(date, date.getMonth() + units * 3),
    year: () => setYear(date, date.getFullYear() + units),
  });

export const startOfPeriod = (date: Date, period: Period) =>
  switchEnum(period, {
    day: () => startOfDay(date),
    week: () => startOfWeek(date),
    month: () => startOfMonth(date),
    quarter: () => startOfQuarter(date),
    year: () => startOfYear(date),
  });

export const setDayInPeriod = (date: Date, period: Period, day: number) =>
  use(startOfPeriod(date, period), (start) =>
    switchEnum(period, {
      day: () => start,
      week: () => setDay(start, day),
      month: () => setDate(start, day),
      quarter: () => addDays(start, day),
      year: () => setDayOfYear(start, day),
    })
  );

export const toScheduleField = (schedule: Schedule): string =>
  switchEnum(schedule.entity || "task", {
    content: "publish",
    else: "start",
  });

// Returns the next date in the given period or undefined if there are no more dates in the period
export const nextInPeriod = (
  date: Date,
  period: Period,
  daysInPeriod: number[]
) =>
  switchEnum(period, {
    week: () => {
      const day = getDay(date);
      const remainingInWeek = find(daysInPeriod, (d) => d > day);
      return remainingInWeek ? setDay(date, remainingInWeek) : undefined;
    },
    month: () => {
      const day = getDate(date);
      const remainingInMonth = find(daysInPeriod, (d) => d > day);
      return remainingInMonth ? setDate(date, remainingInMonth) : undefined;
    },
    quarter: () => {
      // TODO: This is not accruate
      const day = getDate(date) % 90;
      const remainingInQuarter = find(daysInPeriod, (d) => d > day);
      return remainingInQuarter
        ? addDays(startOfPeriod(date, period), remainingInQuarter)
        : undefined;
    },
    year: () => {
      const dayOfYear = getDayOfYear(date);
      const remainingInYear = find(daysInPeriod, (d) => d > dayOfYear);
      return remainingInYear ? setDayOfYear(date, remainingInYear) : undefined;
    },
    else: () => undefined,
  });

export const toNextDate = (s: Schedule): ISODate => {
  return useISODate(s.last || s.from || nowISO(), (date) => {
    // If this is the first run without a last date, include today in the allowed periods.
    const from = s.last ? date : subDays(date, 1);

    // Find the next date within the period (supports multiple days in a period)
    const nextDate =
      nextInPeriod(from, s.period, s.daysOfPeriod || []) ||
      // If no next date is found, return the first date in the next period
      setDayInPeriod(
        addPeriod(from, s.period, s.frequency),
        s.period,
        s.daysOfPeriod?.[0] || 1
      );

    if (s.timeOfDay) {
      return withTime(nextDate, parseTime(s.timeOfDay));
    }

    return nextDate;
  });
};

export const needsScheduling = (schedule: Schedule) => {
  const next = when(schedule.next, fromCalDate) || toNextDate(schedule);
  const to = when(schedule.to, fromCalDate);
  // Make day before so that you don't create the thing on the next same day
  const precreate = endOfPrevDay(
    addPeriod(now(), schedule.period, schedule.precreate || 1)
  );

  // If the next date is before the end of the schedule
  return (!to || next < to) && (!precreate || next < precreate);
};
