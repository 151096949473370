import { filter, map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { GroupBase } from "react-select";

import { Agenda, Ref } from "@api";

import { isOpen } from "@state/agendas";
import { useLazyEntities, useLazyEntity } from "@state/generic";

import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { AngleRightIcon, ArrowLeft, CounterIcon } from "@ui/icon";

import { GlobalEntitySelect } from "./entity";
import { MultiRelationSelectProps } from "./relation";
import { Option, Select, TextSubtextOption } from "./single-select";

type Props = Omit<
  MultiRelationSelectProps,
  "options" | "onChange" | "value"
> & {
  scope: string;
  onChange?: Fn<Agenda[], void>;
};

export const AgendaSelect = ({
  children,
  scope,
  onChange,
  toIcon,
  ...rest
}: Props) => {
  const [open, _setOpen] = useState(false);
  const [meetingRef, _setMeeting] = useState<Ref>();
  const meeting = useLazyEntity<"meeting">(meetingRef?.id || "");

  const setMeeting = useCallback(
    (ref: Maybe<Ref>) => {
      _setMeeting(ref);
      _setOpen(true);
    },
    [_setMeeting]
  );
  const setOpen = useCallback(
    (o: boolean) => {
      _setOpen(o);
      _setMeeting(undefined);
    },
    [_setOpen]
  );

  const agendas = useLazyEntities<"agenda">(meeting?.refs.agendas || []);

  const agendaOpts = useMemo(
    () =>
      [
        {
          options: [
            { id: "back", name: meeting?.name || "Back", icon: ArrowLeft },
          ],
        },
        {
          options: [
            {
              id: "all",
              name: "All items",
              icon: <CounterIcon count={agendas?.length} />,
            },
            {
              id: "open",
              name: "Open items",
              icon: <CounterIcon count={filter(agendas, isOpen).length} />,
            },
          ],
          divider: true,
        },
        {
          label: "Agendas",
          options: map(agendas, (a) => ({
            id: a.id,
            name: a.title || "",
            subtext: "30 mins",
            icon: AngleRightIcon,
          })),
        },
      ] as (Option | GroupBase<Option>)[],
    [agendas, meeting]
  );

  const handleSelect = useCallback(
    (o: Maybe<Option>) => {
      if (o?.id === "back") {
        setMeeting(undefined);
        return;
      }

      if (o?.id === "all") {
        onChange?.(agendas || []);
      } else if (o?.id === "open") {
        onChange?.(filter(agendas, isOpen));
      } else {
        onChange?.(filter(agendas, (a) => a.id === o?.id));
      }
      setOpen(false);
    },
    [agendas, , onChange]
  );

  if (!meeting) {
    return (
      <GlobalEntitySelect
        type="meeting"
        scope={scope}
        open={open}
        setOpen={setOpen}
        allowed={["meeting"]}
        value={undefined}
        onChange={setMeeting}
        closeOnSelect={false}
        closeOnBlur={false}
      >
        {children}
      </GlobalEntitySelect>
    );
  }

  return (
    <Select
      {...rest}
      open={open}
      setOpen={setOpen}
      value={undefined}
      options={agendaOpts}
      overrides={{ Option: TextSubtextOption }}
      onChange={handleSelect}
      closeOnSelect={false}
      closeOnBlur={false}
      searchable={false}
    >
      {children}
    </Select>
  );
};
