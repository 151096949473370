import {
  endOfWeek,
  getDayOfYear,
  getMonth,
  getQuarter,
  getWeek,
  getYear,
  startOfWeek,
} from "date-fns";
import {
  every,
  filter,
  find,
  findIndex,
  first,
  get,
  isArray,
  isEmpty as _isEmpty,
  isString,
  map,
  orderBy,
  reduce,
  set,
  snakeCase,
  some,
} from "lodash";
import pluralize from "pluralize";

import {
  Color,
  DatabaseID,
  Entity,
  EntityType,
  ID,
  Orders,
  PropertyDef,
  PropertyFormat,
  PropertyRef,
  PropertyType,
  PropertyValue,
  PropertyValueRef,
  Ref,
  SelectOption,
  SettingsData,
  Status,
} from "@api";

import { ensureArray, ensureMany, OneOrMany, uniqBy } from "./array";
import { sansDefault } from "./color";
import {
  asTimestamp,
  formatDay,
  formatMonth,
  formatRelative,
  formatShort,
  formatTime,
  timeAgo,
  withParanthesis,
  withSpace,
} from "./date";
import { now, useISODate } from "./date-fp";
import { use } from "./fn";
import { strip } from "./html";
import { cid, isTeamId } from "./id";
import { equalsAny, or, switchEnum } from "./logic";
import {
  isDefined,
  Maybe,
  maybeMap,
  Primitive,
  safeAs,
  when,
  when_,
} from "./maybe";
import { setDirty } from "./object";
import { toOrder, toOrders } from "./ordering";
import {
  isEmpty as isEmptyRT,
  isRichText,
  toHtml as toHtmlRT,
} from "./rich-text";
import { fromScope, toBaseScope } from "./scope";
import { plural, sentenceCase } from "./string";

export { toOrders };

export const toId = (id: Maybe<string | { id?: string }>) =>
  !id || isString(id) ? id : id?.id;

export const toCustomField = (field: string) =>
  "custom." + snakeCase(field?.replace("custom.", "")) + "_" + cid(4);

export const CUSTOM_PROPERTY_TYPES: PropertyType[] = [
  "select",
  "multi_select",
  "status",
  "text",
  "number",
  "date",
  "boolean",
  // "email",
  // "phone",
  // "url",
  // "links",
  // "person",
  "relation",
  "relations",
];

export const isFilterableProp = <T extends Entity>(p: PropertyDef<T>) =>
  p.visibility !== "hidden" && !["json"]?.includes(p.type);

export const isVisibleProp = <T extends Entity>(p: PropertyDef<T>) =>
  p.visibility !== "hidden" && !["json"]?.includes(p.type);

export const isEmptyValue = (v: PropertyValue[PropertyType]) =>
  !isDefined(v) ||
  (isArray(v) && _isEmpty(v)) ||
  (isString(v) && !v) ||
  (isRichText(v) && isEmptyRT(v));

export const isEmptyRef = <T extends Entity>(p: PropertyValueRef<T>) =>
  isEmptyValue(p.value?.[p.type]);

export const omitEmptyRef = <T extends Entity>(
  p: PropertyValueRef<T>
): Maybe<PropertyValueRef<T>> => (isEmptyRef(p) ? undefined : p);

export const isArrayType = (type: PropertyType) =>
  ["multi_select", "relations", "links"].includes(type);

export const isRelations = <T, E extends Entity>(
  p: PropertyRef<E, T> | PropertyRef<E, "relations">
): p is PropertyRef<E, "relations"> => p.type === "relations";

export const isAnyRelation = <
  R extends { type: Extract<PropertyType, "relations" | "relation"> },
  T extends { type: PropertyType }
>(
  p: T | R
): p is R => p.type === "relations" || p.type === "relation";

export const isAnyProperty = <
  R extends { type: Extract<PropertyType, "properties" | "property"> },
  T extends { type: PropertyType }
>(
  p: T | R
): p is R => p.type === "properties" || p.type === "property";

export const isAnySelect = <
  R extends {
    type: Extract<PropertyType, "select" | "multi_select">;
  },
  T extends { type: PropertyType }
>(
  p: T | R
): p is R => p.type === "select" || p.type === "multi_select";

export const isSelectLike = <
  R extends {
    type: Extract<PropertyType, "select" | "status" | "multi_select">;
  },
  T extends { type: PropertyType }
>(
  p: T | R
): p is R =>
  p.type === "select" || p.type === "multi_select" || p.type === "status";

export const isField = (field: OneOrMany<string>) => (p: PropertyRef) =>
  isArray(field) ? field.includes(p.field) : p.field === field;

export const isAnyText = <
  R extends {
    type: Extract<
      PropertyType,
      "email" | "person" | "phone" | "title" | "url" | "rich_text" | "text"
    >;
  },
  T extends { type: PropertyType }
>(
  p: T | R
): p is R =>
  p.type === "email" ||
  p.type === "person" ||
  p.type === "phone" ||
  p.type === "title" ||
  p.type === "url" ||
  p.type === "rich_text" ||
  p.type === "text";

export const isParent = <E extends Entity, T extends PropertyType>(
  p: PropertyDef<E, T>
) => isAnyRelation(p) && p.options?.hierarchy === "parent";

export const isPropertyDef = <T>(
  d: T | PropertyDef<Entity, PropertyType>
): d is PropertyDef<Entity, PropertyType> =>
  !!(d as { scope: Maybe<string> }).scope;

export function toRef(id: string | { id: string }): { id: string };
export function toRef(
  id: Maybe<string | { id: string }>
): Maybe<{ id: string }>;
export function toRef(
  id: Maybe<string | { id: string }>
): Maybe<{ id: string }> {
  if (isString(id)) {
    // Don't return for empty strings ""
    return id ? { id } : undefined;
  }

  // Remove additional fields
  return id?.id ? { id: id.id } : undefined;
}

export const toConnectingRefsProp = (type: EntityType): PropertyRef => ({
  field: `refs.${plural(type)}`,
  type: "relations",
});

export const formatLocation = (
  p: PropertyValueRef,
  format: Maybe<PropertyFormat> = p.def?.format
) =>
  switchEnum(format || "", {
    team: () => toBaseScope(p.value.text || ""),
    root: () => {
      const [r1, r2] = fromScope(p.value.text);
      return isTeamId(r1) && r2 ? r2 : r1;
    },
    else: () => p.value.text,
  });

export const toSelect = (id: string | SelectOption) => {
  if (isString(id)) {
    return { id };
  }

  return id;
};

export function toPrimitive(valueRef: PropertyValueRef) {
  if (isEmptyRef(valueRef)) {
    return undefined;
  }

  switch (valueRef.type) {
    case "relation":
      return valueRef.value.relation?.id;
    case "relations":
      return map(valueRef.value.relations, (r) => r.id);
    case "status":
      return valueRef.value.status?.id;

    case "select":
      // If the ID is not yet set (not saved) then return the {name} object (non-primitive)
      return (
        valueRef.value.select?.id ||
        (valueRef.value.select?.name ? valueRef.value.select : undefined)
      );
    case "multi_select":
      // If the ID is not yet set (not saved) then return the {name} object (non-primitive)
      return map(
        valueRef.value.multi_select,
        (r) => r.id || (r.name ? r : undefined)
      );
    default:
      return valueRef.value[valueRef.type];
  }
}

export const isTimestamp = (prop: string) => prop?.startsWith("stamps.");
export const isSetting = (prop: string) => prop?.startsWith("settings.");
export const isCustom = (prop: string) => prop?.startsWith("custom.");
export const isRefs = (prop: string) => prop?.startsWith("refs.");
export const isNestedProp = or(isSetting, isCustom, isRefs, isTimestamp);
export const toNestedKeys = (prop: string) =>
  use(prop?.split("."), ([k1, ...res]) => [k1, res.join(".")]);

export const toPropertyFormatLabel = (t: Maybe<PropertyFormat>) =>
  t
    ? switchEnum(t, {
        float: () => "Decimal number",
        int: () => "Whole number",
        int_commas: () => "Whole number with commas",
        root: () => "Top Parent",
        parent: () => "Parent",
        else: () => sentenceCase(t),
      })
    : "No formatting";

export const toPropertyTypeLabel = (propType: PropertyType) =>
  switchEnum(propType, {
    number: "Number",
    text: "Text",
    rich_text: "Rich Text",
    title: "Title",
    email: "Email",
    person: "Person",
    date: "Date",
    boolean: "Checkbox",
    select: "Select",
    multi_select: "Multi-Select",
    status: "Status",
    checklist: "Checklist",
    url: "Url",
    formula: "Formula",
    link: "Link (one)",
    links: "Links (many)",
    relation: "Relation (one)",
    relations: "Relation (many)",
    phone: "Phone",
    json: "Configuration",
    property: "Configuration",
    properties: "Configuration",
  });

export const asValue = <T extends keyof PropertyValue>(
  type: T,
  value: PropertyValue[T]
) => ({ [type]: value });

export const getPropertyValue = <
  T extends Entity,
  P extends PropertyType = PropertyType
>(
  thing: Partial<T>,
  prop: PropertyRef<T, P>
): PropertyValue =>
  asValue(
    prop.type,
    isRefs(prop.field as string)
      ? // Refs are stored as a Refs[] dictionary (RefsData), always stored as an array
        when(get(thing, prop.field) || [], (vs: (ID | Ref)[]) =>
          switchEnum<PropertyType, PropertyValue[PropertyType]>(prop.type, {
            relation: () => when(first(ensureMany(vs)), (v) => toRef(v)),
            relations: () => map(ensureMany(vs) || [], (i) => toRef(i)),
            else: () => undefined,
          })
        )
      : isNestedProp(prop.field as string)
      ? // Custom values are not inflated by the graph layer but may be via a setter
        when(get(thing, prop.field), (v) =>
          switchEnum(prop.type as PropertyType, {
            select: () => toSelect(v),
            status: () => toSelect(v),
            multi_select: () => map(ensureMany(v), toSelect),
            relation: () => toRef(v),
            relations: () => map(ensureMany(v), toRef),
            property: () => (isString(v) ? JSON.parse(v) : v),
            properties: () => (isString(v) ? JSON.parse(v) : v),
            else: () => v,
          })
        )
      : // Apply blocked state to non-empty status
      prop.field === "status" && !!get(thing, "status")
      ? {
          ...(get(thing, "status") as Maybe<Status>),
          blocked: get(thing, "blocked"),
        }
      : equalsAny(prop.field, ["name", "title"])
      ? // Treat name/title as the same thing
        get(thing, "name") || get(thing, "title")
      : // Just return what is set on the object
        get(thing, prop.field)
  );

export const getRelationValue = <T extends Entity>(
  thing: T,
  field: string
): Ref =>
  getPropertyValue<Entity, "relation">(thing, { field, type: "relation" })
    .relation as Ref;

export const fallbackPropertyValue = <T extends Entity, P extends PropertyType>(
  things: Maybe<T>[],
  prop: PropertyRef<T, P>
): PropertyValue =>
  reduce(
    things,
    (v, t) =>
      !!v[prop.type] ? v : when(t, (tt) => getPropertyValue(tt, prop)) || v,
    {
      ...prop,
      value: undefined,
    } as PropertyValue
  );

// TODO: This should respect Mutation.op Add and Remove
export function setValueRef<E extends Entity>(
  thing: E,
  value: PropertyValueRef
): E {
  // Non-nested fields are just the value itself
  if (!isNestedProp(value.field as string)) {
    return set(thing, value.field, value.value?.[value.type]);
  }

  // Refs are stored as a Refs[] dictionary (RefsData), always stored as an array
  if (isRefs(value.field as string)) {
    return set(
      thing,
      toNestedKeys(value.field as string),
      value.value?.[value.type]
    );
  }

  // Nested fields (timestamps, custom, settings) only store primitive/ID references
  return set(
    thing,
    toNestedKeys(value.field as string),
    toPrimitive(value as PropertyValueRef)
  );
}

export const flattenProps = <E extends Entity>(
  refs: PropertyValueRef<E>[],
  defaults: Partial<E> = {}
): Partial<E> =>
  reduce(refs, (res, c) => setValueRef(res, c as PropertyValueRef), {
    ...defaults,
  } as E);

export const fromPropertyValueRefs = <E extends Entity>(
  id: ID,
  source: DatabaseID,
  refs: PropertyValueRef<E>[]
): E =>
  ({
    // Populated server side
    createdAt: now(),
    updatedAt: now(),
    ...flattenProps(refs),
    id,
    source,
  } as E);

export const toPropertyValueRefs = <E extends Entity>(
  thing: E,
  props: PropertyRef<E>[]
): PropertyValueRef<E>[] =>
  reduce(
    props,
    (changes, prop) => [...changes, toPropertyValueRef(thing, prop)],
    [] as PropertyValueRef<E>[]
  );

export const toPropertyValueRef = <
  E extends Entity,
  T extends PropertyType,
  P extends keyof E | string
>(
  thing: E,
  prop: PropertyRef<E, T, P>
): PropertyValueRef<E, T, P> => ({
  field: prop.field,
  type: prop.type,
  value: getPropertyValue(thing, prop),
  def: isPropertyDef(prop) ? prop : undefined,
});

export const asPropertyValueRef = <T extends Entity, P extends PropertyType>(
  prop: PropertyRef<T, P>,
  value: PropertyValue
): PropertyValueRef<T> => ({
  field: prop.field,
  type: prop.type,
  value: {
    [prop.type]: switchEnum<PropertyType, PropertyValue[keyof PropertyValue]>(
      prop.type,
      {
        multi_select: () => ensureArray(value.multi_select),
        relations: () => ensureArray(value?.relations),
        else: () => value[prop.type],
      }
    ),
  },
  def: isPropertyDef(prop) ? prop : undefined,
});

const padLead = (float: number | string, size: number = 5) => {
  const [lead, decimals] = String(float)?.split(".");
  return lead.padStart(size, "0") + "." + (decimals || "0");
};

export function toSortable<T extends Entity>(
  p: PropertyValueRef<T>,
  scope: string,
  fallback?: number
): string | number;
export function toSortable<T extends Entity>(
  p: PropertyValueRef<T>,
  scope: string,
  fallback?: number
): Maybe<string | number> {
  if ((p.field === "orders" || p.field === "order") && p.type === "json") {
    const order = toOrder((p.value.json || {}) as Orders, scope) || fallback;

    return when(order, padLead);
  }

  switch (p.type) {
    case "person":
      return map(p.value?.person, (p) => p?.id)?.join("|");
    case "relations":
      return p.value.relations?.[0]?.id;
    case "relation":
      return p.value.relation?.id;
    case "number":
      return p.value.number;
    case "boolean":
      return p.value.boolean ? 1 : 0;
    case "link":
      return p.value.link?.url;
    case "links":
      return p.value.links?.[0]?.url;
    case "date":
      return asTimestamp(p.value.date);
    case "status":
      return (
        when(p.def, (d) => {
          const index = findIndex(d.values?.status, { id: p.value.status?.id });
          return index < 0 ? undefined : index;
        }) ??
        p.value.status?.name ??
        p.value.status?.id
      );
    case "select":
      return (
        when(p.def, (d) => {
          const index = findIndex(d.values?.select, { id: p.value.select?.id });
          return index < 0 ? undefined : index;
        }) ??
        p.value.select?.name ??
        p.value.select?.id
      );
    case "multi_select":
      return (
        when(p.def, (d) =>
          findIndex(d.values?.multi_select, {
            id: p.value.multi_select?.[0]?.id,
          })
        ) ?? map(p.value.multi_select, (v) => v.name ?? v.id).join(", ")
      );
    case "property":
    case "properties":
    case "json":
      return undefined;
    case "checklist":
    case "rich_text":
      return (p.value.rich_text?.html || p.value.rich_text?.markdown)?.slice(
        0,
        20
      );
    default:
      return p.value[p.type];
  }
}

export const toPlaceholder = <T extends Entity>(
  p: PropertyValueRef<T>,
  labelled: boolean = true,
  editable: boolean = true
) => {
  if (!labelled && editable) {
    return `Set ${(p.def?.label || toFieldName(p))?.toLowerCase()}`;
  }

  if (!labelled && !editable) {
    return `No ${(p.def?.label || toFieldName(p))?.toLowerCase()}`;
  }

  switch (p.type) {
    case "person":
      return "Not assigned";

    default:
      return "Not set";
  }
};

export const toFieldName = <T extends Entity>(
  p: PropertyValueRef<T> | PropertyDef<T> | PropertyRef<T>
): Maybe<string> =>
  (p as PropertyValueRef<T>)?.def?.label ||
  (p as PropertyDef<T>)?.label ||
  // Fallback to extracting name from fields in format of either "<name>" or "refs?.<name>" or "custom.<name>_1234"
  when(p.field as Maybe<string>, (field) => {
    // TODO: Convert this to support any nested fields
    const match = field.match(
      /^(?:refs\.)?(?:custom\.)?(?:form\.)?(?:var\.)?(.+?)(?:_\w+)?$/
    );
    return sentenceCase(match ? match[1] || match[2] : field);
  }) ||
  "Deleted";

export const toLabel = <T extends Entity>(
  p: PropertyValueRef<T>,
  format: Maybe<PropertyFormat> = p.def?.format || p.def?.options?.format
): Maybe<string> => {
  if (isEmptyRef(p)) {
    return toPlaceholder(p);
  }

  if (p.field === "location" && p.value.text) {
    return formatLocation(p as PropertyValueRef<Entity>, format);
  }

  switch (p.type) {
    case "person":
      return p.value?.person ? `1 person` : "Nobody";

    case "relations":
      return p.value.relations?.length === 1
        ? p.value.relations?.[0]?.name
        : `${p.value.relations?.length || "No"} ${pluralize(
            p.field as string,
            p.value?.relations?.length
          )}`;

    case "relation":
      return p.value.relation?.name;

    case "boolean":
      return String(p.value?.boolean);

    case "number":
      return when(p.value?.number, (num) =>
        switchEnum(format || "", {
          float: () => num?.toFixed(2),
          int: () => String(Math.round(num)),
          int_commas: () => String(Math.round(num).toLocaleString()),
          percent: () => `${Math.round(num * 100)}%`,
          dollar: () =>
            `$${num.toLocaleString("en-US", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}`,
          days: () => `${num} days`,
          minutes: () => `${num} ${plural("min", num)}`,
          else: () => String(num),
        })
      );
    case "date":
      return useISODate(
        p.value.date,
        when_<Date, string>((date) =>
          switchEnum(format || "", {
            time_ago: () => timeAgo(date),
            time: () => formatTime(date),
            year: () => `${getYear(date)}`,
            quarter: () => `Q${getQuarter(date)}`,
            month: () => `${formatMonth(date)}`,
            week: () =>
              `${formatShort(startOfWeek(date))} - ${formatShort(
                endOfWeek(date)
              )}`,
            day: () =>
              withParanthesis(
                formatDay(date),
                formatRelative(date, () => "") // Don't include relative when not close to today
              ),
            else: () =>
              withSpace(
                formatShort(date),
                formatRelative(date, (d) => `${getYear(d)}`) // format year if not close to today
              ),
          })
        )
      );
    case "link":
      return p.value.link?.text;
    case "links":
      return map(p.value.links, (l) => l.text).join(", ");
    case "status": {
      const value =
        find(p.def?.values.status, { id: p.value.status?.id }) ||
        p.value.status;
      return format === "group" ? value?.group : value?.name;
    }
    case "select": {
      const value =
        find(p.def?.values.select, { id: p.value.select?.id }) ||
        p.value.select;
      return value?.name;
    }
    case "multi_select": {
      const ids = maybeMap(p.value.multi_select, (s) => s.id);
      const value =
        filter(
          p.def?.values.multi_select,
          (v) => !!v.id && ids?.includes(v.id)
        ) || p.value.multi_select;
      return map(value, (v) => v.name).join(", ");
    }
    case "json":
    case "property":
    case "properties":
      return undefined;
    case "rich_text":
    case "checklist":
      return p.value?.rich_text?.html || p.value?.rich_text?.markdown;
    default:
      return p.value[p.type];
  }
};

export const toHtml = <T extends Entity>(
  p: PropertyValueRef<T>,
  format: Maybe<PropertyFormat> = p.def?.format || p.def?.options?.format
): Maybe<string> => {
  if (isEmptyRef(p)) {
    return toPlaceholder(p);
  }

  if (p.field === "location" && p.value.text) {
    return formatLocation(p as PropertyValueRef<Entity>, format);
  }

  switch (p.type) {
    // TODO: Add status/tag tags with colors as tiptap extension
    case "status":
    case "select":
      return strip(`<code>${toLabel(p, format)}</code>`);

    case "multi_select":
      return map(
        p.value.multi_select,
        (v) =>
          `<code>${toLabel({
            ...p,
            type: "select",
            value: { select: v },
          })}</code>`
      ).join(" ");

    case "checklist":
    case "rich_text":
      return toHtmlRT(p.value.rich_text);

    case "relation":
      return strip(`
        <a data-mention-id="${p.value.relation?.id}">${toLabel(p, format)}</a>
      `);
    case "relations":
      return map(
        p.value.relations,
        (r) => `<a data-mention-id="${r.id}">${r.name}</a>`
      ).join(" ");

    case "json":
    case "property":
    case "properties":
      return undefined;

    default:
      return `<span>${toLabel(p, format)}</span>`;
  }
};

export const toKey = <T extends Entity>(
  p: PropertyValueRef<T>,
  format?: PropertyFormat,
  emptyKey?: string
): Maybe<string> => {
  if (isEmptyRef(p)) {
    return emptyKey ?? `empty-${String(p.field)}`;
  }

  // TODO: Location should become a property type (scope)
  if (p.field === "location") {
    return formatLocation(p as PropertyValueRef<Entity>, format);
  }

  switch (p.type) {
    case "person":
      return map(p.value.person, (p) => p?.id)?.join(",");
    case "relation":
      return p.value?.relation?.id;
    case "relations":
      return map(p.value.relations, (t) => t.id).join(",");
    case "select":
      return p.value.select?.id || p.value.select?.name;
    case "multi_select":
      return map(p.value.multi_select, (v) => v.id || v.name).join(", ");
    case "status":
      return p.value.status?.id || p.value.status?.name;
    case "link":
      return p.value.link?.url;
    case "links":
      return map(p.value.links, (l) => l.url).join(", ");
    case "property":
    case "properties":
    case "json":
      return undefined;
    case "date":
      return useISODate(
        p.value.date,
        when_((date) =>
          switchEnum(format || "", {
            time_ago: () => timeAgo(date),
            year: () => String(getYear(date)),
            quarter: () => String(getQuarter(date)),
            month: () => String(getMonth(date)),
            week: () => String(getWeek(date)),
            // day & fallback use same value
            else: () =>
              String(getYear(date)) + "-" + String(getDayOfYear(date)),
          })
        )
      );

    case "boolean":
      return String(p.value.boolean);

    case "number":
      return String(
        format
          ? when(p.value?.number, (num) =>
              switchEnum<PropertyFormat, string | number>(format, {
                float: () => num?.toFixed(2),
                int: () => Math.round(num),
                int_commas: () => Math.round(num),
                percent: () => Math.round(num * 100),
                dollar: () => num?.toFixed(2),
                else: () => num,
              })
            )
          : p.value.number
      );

    case "rich_text":
    case "checklist":
      return p.value.rich_text?.html || p.value.rich_text?.markdown;

    case "text":
    default:
      return p.value[p.type];
  }
};

export const toGroupKeys = <T extends Entity>(
  p: PropertyValueRef<T>,
  format?: PropertyFormat,
  emptyKey?: string
): Maybe<string | string[]> => {
  if (p.field === "location") {
    return formatLocation(p as PropertyValueRef<Entity>, format);
  }

  switch (p.type) {
    case "relations":
      return maybeMap(p.value?.relations || [], (t) => t.id);
    case "multi_select":
      return maybeMap(p.value?.multi_select || [], (t) => t.id);
    default:
      return toKey(p, format, emptyKey);
  }
};

export const toMenuItem = (p: PropertyValueRef) => ({
  label: toLabel(p),
  value: String(toKey(p)),
});

export const isEditableProp = <T extends Entity, P extends PropertyType>(
  p: PropertyDef<T, P>
): boolean =>
  !p.readonly &&
  p.visibility !== "hidden" &&
  ![
    "title",
    "summary",
    "subtasks",
    "parent",
    "code",
    "orders",
    "thread",
  ].includes(p.field as string);

export const hasValue = <E extends Entity, P extends PropertyType>(
  value: PropertyValueRef<E, P>,
  values: PropertyDef<E, P>["values"]
) =>
  switchEnum(value.type as PropertyType, {
    select: () => some(values.select, (v) => v.id === value.value.select?.id),
    multi_select: () => {
      const local = map(values.multi_select, (v) => v.id);
      return every(value.value.multi_select, (v) => local.includes(v?.id));
    },
    else: () => true,
  });

export const inflateValue = <E extends Entity, P extends PropertyType>(
  value: PropertyValueRef<E, P>,
  values: PropertyDef<E, P>["values"],
  def?: PropertyDef<E, P>
): PropertyValueRef<E, P> => {
  const { type } = value;

  switch (type) {
    case "relation":
      return {
        ...value,
        def: def || value.def,
        value: {
          [type]:
            find(values.relation, (v) => v.id === value.value.relation?.id) ||
            value.value[type],
        },
      };

    case "relations":
      return {
        ...value,
        def: def || value.def,
        value: {
          [type]: map(
            value.value.relations || [],
            (val) => find(values.relations, (v) => v.id === val?.id) || val
          ),
        },
      };

    case "multi_select":
      return {
        ...value,
        def: def || value.def,
        value: {
          [type]: map(
            value.value.multi_select || [],
            (val) => find(values.multi_select, (v) => v.id === val?.id) || val
          ),
        },
      };

    case "select":
      return {
        ...value,
        def: def || value.def,
        value: {
          [type]:
            find(values.select, (v) => v.id === value.value.select?.id) ||
            value.value.select,
        },
      };

    case "status":
      return {
        ...value,
        def: def || value.def,
        value: {
          [type]: when(
            find(values.status, (v) => v.id === value.value.status?.id) ||
              value.value["status"],
            (s) => ({
              ...s,
              blocked: value?.value["status"]?.blocked,
            })
          ),
        },
      };

    default:
      return { ...value, def: def || value.def };
  }
};

export const inflateProperty = <E extends Entity>(
  thing: E,
  prop?: PropertyDef
): E =>
  when(prop, (prop) =>
    setDirty(
      { ...thing },
      prop.field as keyof E,
      inflateValue(toPropertyValueRef(thing, prop), prop.values)?.value?.[
        prop.type
      ] as E[keyof E]
    )
  ) || thing;

export const inflateStatuses = <E extends Entity>(
  thing: E,
  props: PropertyDef<E>[]
): E =>
  reduce(
    props,
    (res, p) => (p.type === "status" ? inflateProperty(res, p) : res),
    thing
  );

export const inflateStatus = <E extends Entity, P extends PropertyType>(
  status: Maybe<Status>,
  props: PropertyDef<E, P>[]
): Maybe<Status> => {
  const prop = find(props, (p) => p.field === "status");

  return prop
    ? inflateValue(asPropertyValueRef(prop, { status }), prop.values)?.value
        .status
    : undefined;
};

export const getSetting = <T extends Primitive>(
  settings: Maybe<SettingsData>,
  key: string
): Maybe<T> => settings?.[snakeCase(key)] as Maybe<T>;

export const setSetting = <T extends Primitive>(
  settings: Maybe<SettingsData>,
  key: string,
  value: Primitive
): SettingsData => ({
  ...settings,
  [snakeCase(key)]: value,
});

export const colorForTag = (tag: SelectOption): Color =>
  sansDefault(
    tag.color !== "default"
      ? tag.color
      : switchEnum<string, Color | "default">(
          (tag as Maybe<Status>)?.group || "default",
          {
            planning: "gray",
            "not-started": "gray",
            "in-progress": "blue",
            done: "green",
            else: "default",
          }
        )
  );

export const defaultFormatForType = ({
  field,
  type,
}: PropertyRef): Maybe<PropertyFormat> =>
  field === "location"
    ? "team"
    : switchEnum<PropertyType, Maybe<PropertyFormat>>(type, {
        date: () => "day",
        number: () => "float",
        else: () => undefined,
      });

export const formatsForType = ({
  field,
  type,
}: PropertyRef): PropertyFormat[] =>
  field === "location"
    ? ["team", "root", "parent"]
    : switchEnum<PropertyType, PropertyFormat[]>(type, {
        date: () => ["day", "week", "month", "quarter", "year"],
        number: () => ["percent", "dollar", "float", "int", "int_commas"],
        // TODO: Needs more work to support
        // status: () => ["group"],
        else: () => [],
      });

const isSelectish = (type: PropertyType) =>
  ["select", "multi_select", "status"].includes(type);

// Merge the values from two different property values with the same field
// e.g. two different status definitions
export const mergeDefs = (d1: PropertyDef, d2: PropertyDef) =>
  d1.type !== d2.type
    ? d2
    : isSelectish(d1.type)
    ? {
        ...d1,
        values: {
          [d1.type]: orderBy(
            uniqBy(
              [
                ...(d1.values?.[d1.type] || []),
                ...(d2.values?.[d2.type] || []),
              ] as SelectOption[],
              (i) => i.id
            ),
            (v) =>
              Math.max(
                d1.values?.[d1.type as "select"]?.indexOf(v) || -1,
                d2.values?.[d2.type as "select"]?.indexOf(v) || -1,
                0
              )
          ),
        },
      }
    : { ...d1, ...d2 };

const isSpecialProp = (p: PropertyRef) =>
  equalsAny(p.field, ["location", "status", "assigned", "owner"]);

export const sortByUseful = <T extends PropertyRef | PropertyDef>(ts: T[]) =>
  orderBy(
    ts, // Preferred group by field types, sorts left to right here
    (p) =>
      `${
        isSpecialProp(p)
          ? 0
          : [
              "status",
              "select",
              "multi_select",
              "date",
              "number",
              "boolean",
              "checklist",
              "relation",
              "relations",
              "text",
              "rich_text",
            ]
              .indexOf(p.type)
              .toString()
              .padStart(2, "0") + 1
      }.${safeAs<PropertyDef>(p)?.order || 0}`,
    "asc"
  );
