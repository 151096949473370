import { forwardRef,Ref } from "react";

import { cx } from "@utils/class-names";

import { Props as ContainerProps } from "@ui/container";

import styles from "./list-card.module.css";

type Props = React.HTMLProps<HTMLLIElement> & {
  padding?: ContainerProps["padding"];
  selectable?: boolean;
  selected?: boolean;
  active?: boolean;
};

export const ListCard = forwardRef(
  (
    {
      children,
      className,
      padding,
      style,
      selected,
      selectable = true,
      active,
      ...rest
    }: Props,
    ref: Ref<HTMLLIElement>
  ) => {
    return (
      <li
        ref={ref}
        {...rest}
        className={cx(
          styles.card,
          padding === "none" && styles.noPadding,
          selected && styles.selected,
          selectable && styles.selectable,
          active && styles.active,
          className
        )}
        style={style}
      >
        {children}
      </li>
    );
  }
);
