import { filter, find, map, orderBy } from "lodash";
import { useCallback, useEffect, useMemo } from "react";

import {
  DatabaseID,
  Entity,
  HasLocation,
  hasLocation,
  isPerson,
  isTeam,
  PropertyMutation,
  PropertyType,
  RelationRef,
  Team,
  toTitleOrName,
} from "@api";

import { useLazyProperties, useLazyPropertyDef } from "@state/databases";
import { useGetItemFromAnyStore, useSearch } from "@state/generic";
import { useMe } from "@state/persons";
import { useLazyAllTeams } from "@state/teams";

import { justOne, maybeMap } from "@utils/array";
import { useShowMore } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { asMutation, asUpdate, toUpdate } from "@utils/property-mutations";
import { getPropertyValue, isAnyRelation } from "@utils/property-refs";
import { fromScope, toChildLocation, toLocation, toScope } from "@utils/scope";
import { plural } from "@utils/string";
import { throwStar } from "@utils/wildcards";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { SpaceBetween } from "@ui/flex";
import { EllipsisH, EyeSlash } from "@ui/icon";
import {
  RelationIcon,
  RelationLabel,
  RelationLocationLabel,
} from "@ui/relation-label";
import { Text } from "@ui/text";

import { useCommandSearch } from "../utils";
import { SetPropertyCommands } from "./types";

export const LocationSelectCommands = ({
  property,
  entities,
  mutate,
  ...rest
}: SetPropertyCommands) => {
  if (property.field !== "location") {
    throw new Error(
      "LocationSelectCommands can only be used for choosing a scoped location."
    );
  }

  const getItem = useGetItemFromAnyStore();
  const currentLocation = useMemo(
    () =>
      when(entities?.[0]?.id, (id) => (getItem(id) as HasLocation)?.location),
    [entities]
  );
  const currentTeam = useMemo(
    () =>
      (when(
        find(fromScope(currentLocation), (id) => id.startsWith("tm_")),
        getItem
      ) || undefined) as Maybe<Team>,
    [currentLocation]
  );
  const query = useCommandSearch();
  const teams = useLazyAllTeams();
  const me = useMe();
  const props = useLazyProperties(entities?.[0]?.source);
  const parentProps = useMemo(
    () =>
      orderBy(
        filter(
          props,
          (p) => isAnyRelation(p) && p.options?.hierarchy === "parent"
        ),
        (p) =>
          ["outcome", "task"]?.includes(justOne(p.options?.references) || "")
            ? 1
            : 0
      ),
    [props]
  );

  const handleChange = useCallback(
    (value: RelationRef) => {
      mutate(
        maybeMap(entities, (ref) => {
          const entity = getItem(ref.id);
          const newParent = getItem(value.id);

          if (!entity || !newParent) {
            return undefined;
          }

          // Team and person don't nest the location
          const newLocation =
            hasLocation(newParent) && !isTeam(newParent) && !isPerson(newParent)
              ? toScope(newParent.location, newParent.id)
              : toScope(newParent.id);

          const prev = getPropertyValue<Entity, PropertyType>(entity, property);

          return toUpdate(entity, property, newLocation, prev);
        })
      );
    },
    [mutate]
  );

  return (
    <>
      {map(parentProps, (p) => (
        <CommandGroup key={p.field} label={plural(p.label, 2)}>
          <LocationRelationSelectCommands
            team={currentTeam}
            property={p}
            entities={entities}
            mutate={mutate}
            query={query}
            {...rest}
          />
        </CommandGroup>
      ))}

      {me && me.id !== currentLocation && (
        <CommandGroup label="Make private">
          <CommandItem
            key={me.id}
            value={`change to private ${toTitleOrName(me)} ${me.id}`}
            icon={EyeSlash}
            onClick={() => handleChange(me)}
          >
            Private
          </CommandItem>
        </CommandGroup>
      )}
      <CommandGroup label="Change team">
        {map(teams, (relation) =>
          currentLocation?.includes(relation.id) ? (
            false
          ) : (
            <CommandItem
              key={relation.id}
              value={`change to ${toTitleOrName(relation)} ${relation.id}`}
              icon={<RelationIcon relation={relation} />}
              onClick={() => handleChange(relation)}
            >
              <RelationLabel icon={false} relation={relation} />
            </CommandItem>
          )
        )}
      </CommandGroup>
    </>
  );
};

export const LocationRelationSelectCommands = ({
  property,
  entities,
  mutate,
  team,
  query,
}: SetPropertyCommands & { team?: Team; query?: string }) => {
  const propType = property.type;

  if (propType !== "relation" && propType !== "relations") {
    throw new Error(
      'Location Relation Select Commands can only be used on "relation" properties.'
    );
  }

  const source = entities[0]?.source as DatabaseID;
  const def = useLazyPropertyDef(source, property);
  const { setQuery, results } = useSearch(
    justOne(throwStar(def?.options?.references)) || "task",
    team?.id || ""
  );
  const { visible, hasMore, showMore } = useShowMore(results, 3, !!query);

  useEffect(() => setQuery(query || ""), [query]);

  const handleChange = useCallback(
    (value: Entity) => {
      mutate(
        maybeMap(entities, (entity) => {
          const newValue = value;
          const scope = value.source.scope;

          if (!scope || !newValue) {
            throw new Error("Failed to get entity.");
          }

          if (property.type === "relations") {
            return asUpdate<Entity>(entity as Pick<Entity, "id" | "source">, [
              asMutation(property, [newValue]) as PropertyMutation<Entity>,
              asMutation(
                { field: "location", type: "text" },
                toChildLocation(toLocation(scope), value.id)
              ),
            ]);
          }

          if (property.type === "relation") {
            return asUpdate(entity as Pick<Entity, "id" | "source">, [
              asMutation(property, newValue) as PropertyMutation<Entity>,
              asMutation(
                { field: "location", type: "text" },
                toChildLocation(toLocation(scope), value.id)
              ),
            ]);
          }

          return undefined;
        })
      );
    },
    [mutate]
  );

  if (!visible?.length) {
    return <></>;
  }

  return (
    <>
      {map(visible, (relation) => {
        return (
          <CommandItem
            key={relation.id}
            value={`move to ${toTitleOrName(relation)} ${relation.id}`}
            onSelectAction="close"
            onClick={() => handleChange(relation)}
          >
            <SpaceBetween>
              <RelationLocationLabel relation={relation} fit="content" />
            </SpaceBetween>
          </CommandItem>
        );
      })}
      {hasMore && (
        <CommandItem
          value={`show more for ${property.field} ${query}`}
          icon={EllipsisH}
          onSelectAction="none"
          onClick={showMore}
        >
          <Text subtle>Show more</Text>
        </CommandItem>
      )}
    </>
  );
};
