import { atomFamily } from "recoil";

import { Event, ID } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type EventStoreState = StoreState<Event>;

export const WorkspaceEventStoreAtom = atomFamily<EventStoreState, ID>({
  key: "WorkspaceEventStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "event")],
});

export const EventStoreAtom = WorkspaceWrappedAtom(
  "EventStoreAtom",
  WorkspaceEventStoreAtom
);
