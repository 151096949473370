import { last, map } from "lodash";
import { useEffect, useMemo } from "react";

import { Calendar, EntityType, ID } from "@api";

import {
  useOpenAppCommands,
  usePageUndoRedo,
  useRegisterPage,
} from "@state/app";
import {
  useLazyEntity,
  useLazyQuery,
  useNestedSource,
  useUpdateEntity,
} from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";
import { useAddToRecents } from "@state/recents";
import { toTemplateViewId } from "@state/views";

import { warn } from "@utils/debug";
import { useShowMore, useStickyState } from "@utils/hooks";
import { Maybe, when } from "@utils/maybe";
import { useGoTo } from "@utils/navigation";
import { asMutation } from "@utils/property-mutations";
import { getSetting } from "@utils/property-refs";
import { fromScope, toChildLocation } from "@utils/scope";
import { useSyncPathnameID } from "@utils/url";

import { AddWorkButton } from "@ui/add-work-dialog";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { Button } from "@ui/button";
import { Container } from "@ui/container";
import { Divider } from "@ui/divider";
import { EditableHeading } from "@ui/editable-heading";
import { render, useEngine } from "@ui/engine";
import { FormsCreatingHere } from "@ui/engine/form";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { FollowButton } from "@ui/follow-button";
import { PlusIcon, Slash } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { ShowMoreMenuItem } from "@ui/menu-item";
import { PackageTag } from "@ui/package-label";
import { Main, PageLayout, SideNav } from "@ui/page-layout";
import { LabelledValue } from "@ui/property-value";
import { LabelledPropertyValueList } from "@ui/property-value-tile";
import { EmojiSelect } from "@ui/select/emoji";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import ViewPane from "@ui/view-pane";

import AppPage from "./app-page";

import styles from "./calendar-page.module.css";

export const CalendarPage = ({ id }: { id: ID }) => {
  const calendar = useLazyEntity<"calendar">(id);
  const [viewId, setViewId] = useStickyState<Maybe<string>>(
    undefined,
    `calendar-${id}-view`
  );

  const view = useLazyEntity(viewId);

  const [page] = useRegisterPage(id, calendar);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, calendar?.id);

  // Add to recents
  useAddToRecents(id);

  // When calendar has loaded, set default view
  useEffect(() => {
    if (calendar && (!viewId || !view)) {
      const itemType = getSetting(
        calendar?.settings,
        "child_type"
      ) as Maybe<EntityType>;

      if (!itemType) {
        warn("Calendar missing child_type setting", calendar);
        return;
      }

      setViewId(
        toTemplateViewId(`calendar-${itemType}`, {
          parent: id,
          entity: itemType,
        })
      );
    }
  }, [calendar?.id, view?.id, id]);

  if (!calendar) {
    return <></>;
  }

  return (
    <AppPage page={page}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        <Sheet size="full" transparency="mid" interactable={false}>
          <PageLayout>
            <SideHeader id={id} calendar={calendar} pageId={page.id} />

            <Main className={styles.main}>
              {viewId && (
                <ViewPane
                  className={styles.viewPane}
                  viewId={viewId}
                  onChangeView={(v) => setViewId(v.id)}
                  showTitle={false}
                  showViewsBar={false}
                />
              )}
            </Main>
          </PageLayout>
        </Sheet>
      </StackContainer>
    </AppPage>
  );
};

type HeaderProps = {
  id: ID;
  pageId: ID;
  calendar: Calendar;
};

const SideHeader = ({ id, pageId, calendar: item }: HeaderProps) => {
  const mutate = useUpdateEntity(item.id, pageId);
  const goTo = useGoTo();
  const engine = useEngine("calendar");
  const nestedSource = useNestedSource(
    item,
    getSetting(item?.settings, "child_type")
  );
  const installed = useHasPackages(id, [SystemPackages.Forms]);
  const allCalendars = useLazyQuery(
    "calendar",
    useMemo(
      () => ({
        and: [
          {
            field: "location",
            type: "text",
            op: "ends_with",
            value: { text: last(fromScope(item.location)) },
          },
          {
            field: "id",
            type: "text",
            op: "does_not_equal",
            value: { text: item.id },
          },
        ],
      }),
      [item.location, item.id]
    ),
    { limit: 10, fetch: false }
  );
  const {
    visible: otherCalendars,
    hasMore,
    showMore,
  } = useShowMore(allCalendars, 3);

  const openCmdK = useOpenAppCommands(item);

  return (
    <SideNav className={styles.nav}>
      <SpaceBetween direction="vertical">
        <VStack gap={10} fit="container">
          <VStack gap={20} fit="container">
            <Container gap={10} padding="none" inset="bottom" stack="vertical">
              <SpaceBetween>
                <EmojiSelect
                  size="xlarge"
                  emoji={item.icon || "🗓️"}
                  onChange={(emoji) =>
                    mutate(asMutation({ field: "icon", type: "text" }, emoji))
                  }
                />
                <HStack gap={2}>
                  <FollowButton
                    variant="icon-only"
                    entity={item}
                    mutate={mutate}
                  />
                  <PackageTag type="calendar" scope={item.source.scope} />
                </HStack>
              </SpaceBetween>

              <EditableHeading
                key={item.id}
                text={item.name || ""}
                size="h2"
                autoFocus={!item.name}
                onChange={(text) => {
                  when(text, (i) =>
                    mutate(asMutation({ field: "name", type: "text" }, i))
                  );
                }}
              />
            </Container>
          </VStack>

          <SpaceBetween>
            <Button
              as="button"
              fit="container"
              icon={Slash}
              subtle
              onClick={openCmdK}
            >
              Modify
            </Button>

            <AddWorkButton
              fit="container"
              icon={PlusIcon}
              subtle
              defaults={{
                refs: { calendars: [{ id: item.id }] },
                location: toChildLocation(item.location, item.id),
              }}
              allowed={[nestedSource.type]}
            >
              Add new
            </AddWorkButton>
          </SpaceBetween>

          <Divider margin="on" />

          {installed[SystemPackages.Forms] && (
            <>
              <FormsCreatingHere item={item} />
              <Divider margin="on" />
            </>
          )}

          <LabelledPropertyValueList
            entity={item}
            onChange={mutate}
            stack="vertical"
          >
            <LabelledValue label="Organizing" fit="container">
              <Button subtle disabled inset size="small" wrapLabel={false}>
                <PackageTag {...nestedSource} disabled={true} plural={true} />
              </Button>
            </LabelledValue>
          </LabelledPropertyValueList>
        </VStack>

        {!!otherCalendars?.length && (
          <Menu>
            <MenuGroup label="Other Calendars">
              {map(otherCalendars, (cal) =>
                render(engine.asMenuItem, {
                  key: cal.id,
                  item: cal,
                  onOpen: goTo,
                })
              )}
              {hasMore && <ShowMoreMenuItem onClick={showMore} />}
            </MenuGroup>
          </Menu>
        )}
      </SpaceBetween>
    </SideNav>
  );
};

export default CalendarPage;
