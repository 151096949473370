import { useCallback, useState } from "react";

import { HasNotes, Ref, RichText } from "@api";

import { useCreateNote } from "@state/notes";
import { useMe } from "@state/persons";
import { useCreateThread } from "@state/resources";

import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { toMarkdown } from "@utils/rich-text";
import { toThreadText } from "@utils/slack";
import { redirect } from "@utils/url";

import { Button } from "@ui/button";
import { Dialog } from "@ui/dialog";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import { Field } from "@ui/input";
import { CheckMenuItem } from "@ui/menu-item";
import { TextBox } from "@ui/rich-text";

interface Props {
  entity: HasNotes;
  defaultMessage?: string;
  channel?: string;
  onSaved?: Fn<Ref, void>;
  onCancel?: Fn<void, void>;
}

export const SlackCreateDialog = ({
  entity,
  onCancel,
  onSaved,
  defaultMessage,
  channel,
}: Props) => {
  const me = useMe();
  const [summary, setSummary] = useState<RichText>(
    when(toThreadText(entity, me), (markdown) => ({ markdown })) || {}
  );
  const [message, setMessage] = useState<RichText>(
    when(defaultMessage, (markdown) => ({ markdown })) || {}
  );
  const [openAfter, setOpenAfter] = useState(true);
  const [saving, setSaving] = useState(false);
  const create = useCreateNote(entity);
  const createThread = useCreateThread(channel);

  const onCreate = useCallback(async () => {
    if (!channel || !me) {
      throw new Error("No channel specified for slack.");
    }

    setSaving(true);

    const { link } = await createThread(
      { message: toMarkdown(message), author: me },
      when(summary, (rt) => ({ message: toMarkdown(rt) }))
    );

    if (!link) {
      return;
    }

    const note = create([
      { type: "text", field: "type", value: { text: "discussion" } },
      {
        type: "links",
        field: "links",
        value: {
          links: link ? [{ text: "Slack thread", url: link }] : undefined,
        },
      },
      {
        field: "body",
        type: "rich_text",
        value: { rich_text: message },
      },
      {
        field: "author",
        type: "relation",
        value: { relation: { id: me?.id } },
      },
    ]);

    onSaved?.(note);

    if (openAfter) {
      redirect(link, true);
    }
    setSaving(false);
  }, [onSaved, summary, openAfter, message]);

  return (
    <Dialog
      title="Start a discussion"
      description="Discussions go straight to Slack, and don't show up in Traction inbox."
      onDismiss={onCancel}
      actions={
        <SpaceBetween>
          <CheckMenuItem
            text="Open Slack after sending"
            checked={openAfter}
            onChecked={setOpenAfter}
          />
          <HStack>
            <Button onClick={() => onCancel?.()}>Cancel</Button>
            <Button variant="primary" onClick={onCreate}>
              {saving ? "Sending..." : "Start discussion"}
            </Button>
          </HStack>
        </SpaceBetween>
      }
    >
      <VStack gap={10}>
        <Field>
          <TextBox
            key="slack-message"
            text={message || ""}
            focus={true}
            size="two-line"
            onChanged={setMessage}
            updateOn="change"
            placeholder="Type your message here..."
          />
        </Field>

        <Field>
          <TextBox
            key="slack-summary"
            text={summary || ""}
            onChanged={setSummary}
            updateOn="change"
            placeholder="Type your message here..."
          />
        </Field>
      </VStack>
    </Dialog>
  );
};
