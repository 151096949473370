import { useChat } from "ai/react"; // Hacked in @types because don't know how to fix
import { filter, findLast, first, isString, last, map } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";

import {
  AiAssist,
  DatabaseID,
  Entity,
  EntityType,
  getPromptResponse,
  HasLocation,
  HasNotes,
  HasOrders,
  hasOrders,
  HasResources,
  ID,
  PropertyMutation,
  PropertyType,
  PropertyValue,
  Ref,
  RichText,
  Update,
} from "@api";

import { PropertyDefStoreAtom, useLazyProperties } from "@state/databases";
import {
  useAllStores,
  useCreateEntity,
  useLazyEntity,
  useQueueUpdates,
} from "@state/generic";
import { useScopeSettings } from "@state/settings";
import { reverseUpdate } from "@state/store";
import { useActiveWorkspaceId } from "@state/workspace";

import { ensureMany, justOne, maybeMap, OneOrMany } from "@utils/array";
import { toISODate } from "@utils/date-fp";
import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { useDebouncedMemo } from "@utils/hooks";
import { newID } from "@utils/id";
import { switchEnum } from "@utils/logic";
import { cleanFormattedMarkdown, optimisticExtractJSON } from "@utils/markdown";
import { isDefined, Maybe, SafeRecord, when, whenTruthy } from "@utils/maybe";
import { now } from "@utils/now";
import {
  DecimalOrdering,
  evenlyBetween,
  toNewOrders,
  toOrder,
} from "@utils/ordering";
import { asMutation, asUpdate, toMutation } from "@utils/property-mutations";
import { isEmptyValue, toRef } from "@utils/property-refs";
import { toScope } from "@utils/scope";

import { usePageId } from "@ui/app-page";

import { autoUpdate, bulkCreate, taskPropertiesAutoFill } from "./definitions";
import { AiUseCase } from "./types";

export function useAiAutoFields(entityOrId: ID | Entity) {
  const pageId = usePageId();
  const entityId = isString(entityOrId) ? entityOrId : entityOrId.id;
  const mutate = useQueueUpdates(pageId);
  const entity = isString(entityOrId) ? useLazyEntity(entityId) : entityOrId;
  const allProps = useLazyProperties(entity?.source, false);
  const props = useMemo(
    () => filter(allProps, (p) => p.assist !== AiAssist.Off),
    [allProps]
  );
  const [lastUpdates, setLastUpdates] = useState<Update<Entity>[]>();
  const onResponse = useCallback(
    (suggestedProps: PropertyMutation<Entity>[]) => {
      if (!entity) {
        return;
      }
      // Automatically save all suggestions
      const update = asUpdate<Entity>(entity, suggestedProps);
      mutate(update);
      setLastUpdates([update]);
    },
    []
  );
  const { run, loading } = useAiUseCase(
    taskPropertiesAutoFill,
    onResponse,
    entity?.source.scope
  );

  const revert = useCallback(() => {
    if (!entity) {
      return;
    }

    map(lastUpdates, (u) => mutate(reverseUpdate(u)));

    setLastUpdates(undefined);
  }, [lastUpdates]);

  return useMemo(
    () => ({
      run: () => entity && run({ entity, props }),
      fetch,
      revert,
      loading,
      canRun:
        !!entity && !!props?.length && !entity?.source.scope?.startsWith("u_"),
      canRevert: !!lastUpdates,
    }),
    [run, props, fetch, loading, revert]
  );
}

export function useAutoUpdate<T extends HasNotes & HasResources & HasLocation>(
  entity: T
) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const stores = useAllStores();
  const props = useRecoilValue(PropertyDefStoreAtom);
  // TODO: Type checking hotspot... dodn't know why
  const settings = useScopeSettings(entity.source.scope);
  const context = useMemo(
    () => ({ stores: { ...stores, props }, settings }),
    [stores, settings, props]
  );

  const { messages, setMessages, append, reload, isLoading } = useChat({
    api: "/api/chat/stream",
    onResponse: () => {
      setMessage(undefined);
      setLoading(false);
    },
  });

  const reply = useCallback(
    async (text: string) => {
      setLoading(true);
      setMessage("Replying....");
      append({ content: text, role: "user", createdAt: now() });
      reload();
    },
    [setMessage, reload]
  );

  const generate = useCallback(async () => {
    setMessage("Collecting information....");
    setLoading(true);
    const data = await autoUpdate.prepareData?.(
      { entity: entity as Entity },
      context
    );
    const prompt = data && autoUpdate.toPrompt?.(data, context);

    if (prompt) {
      setMessage("Summarizing....");
      setMessages([
        {
          id: "base-message",
          content: prompt,
          role: "system",
          createdAt: now(),
        },
      ]);
      reload();
      // complete(prompt);
    }
  }, [entity?.id, context, stores]);

  return useMemo(
    () => ({
      generate,
      completion: when(last(messages), (m) =>
        m.role === "assistant" ? m.content : undefined
      ),
      message,
      reply,
      loading: loading || isLoading,
    }),
    [generate, messages, message, reply, loading, isLoading]
  );
}

export function useAssistantUpdateChat(
  entity: Maybe<HasNotes & HasResources & HasLocation>
) {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<string>();
  const stores = useAllStores();
  const props = useRecoilValue(PropertyDefStoreAtom);
  const settings = useScopeSettings(entity?.source.scope);
  const context = useMemo(
    () => ({ stores: { ...stores, props }, settings }),
    [stores, settings, props]
  );
  const { messages, append } = useChat({
    api: "/api/chat/stream",
    onFinish: () => {
      setStatus(undefined);
      setLoading(false);
    },
  });

  const reply = useCallback(
    async (text: string) => {
      setLoading(true);
      setStatus("Replying....");
      append({ content: text, role: "user", createdAt: now() });
    },
    [setStatus, append]
  );

  useAsyncEffect(async () => {
    if (!entity) {
      return;
    }

    setStatus("Collecting information....");
    const data = await autoUpdate.prepareData?.(
      { entity: entity as Entity },
      context
    );
    const prompt = data && autoUpdate.toPrompt?.(data, context);
    if (prompt) {
      setStatus("Summarizing....");
      append({ content: prompt, role: "system", createdAt: now() });
    }
  }, [entity?.id, context]);

  return { messages, status, reply, loading };
}

export function useAssistantCreate(
  mutate: Maybe<Fn<Partial<SafeRecord<string, OneOrMany<string>>>[], Ref[]>>,
  onCreated: Fn<Ref[], void>,
  scope?: string
) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const created = useRef<ID[]>([]);
  const [data, setData] =
    useState<
      Awaited<ReturnType<Exclude<typeof bulkCreate.prepareData, undefined>>>
    >();
  const stores = useAllStores();
  const settings = useScopeSettings(scope);
  const props = useRecoilValue(PropertyDefStoreAtom);
  const context = useMemo(
    () => ({ stores: { ...stores, props }, settings }),
    [stores, settings, props]
  );
  const { messages, setMessages, append, reload, isLoading } = useChat({
    api: "/api/chat/stream",
    onFinish: () => {
      setMessage(undefined);
      setLoading(false);
    },
  });

  const response = useDebouncedMemo(
    () =>
      !!data
        ? when(findLast(messages, { role: "assistant" }), (m) => {
            return bulkCreate.parseCompletion?.(
              optimisticExtractJSON(m.content),
              data,
              context
            );
          })
        : undefined,
    [100, 500],
    [loading, isLoading, data, messages, context]
  );

  const reply = useCallback(
    async (text: string) => {
      setLoading(true);
      setMessage("Replying....");
      append({ content: text, role: "user", createdAt: now() });
      reload();
    },
    [setMessage, reload]
  );

  const generate = useCallback(
    async (location: string, type: EntityType, dump: RichText) => {
      setMessage("Collecting information....");
      setLoading(true);
      const data = await bulkCreate.prepareData?.(
        { location, type, dump },
        context
      );
      setData(data);
      const prompt = data && bulkCreate.toPrompt?.(data, context);

      if (prompt) {
        setMessage("Thinking....");
        setMessages([
          {
            id: "base-message",
            content: prompt,
            role: "system",
            createdAt: now(),
          },
        ]);
        reload();
        created.current = [];
        // complete(prompt);
      }
    },
    [context]
  );

  useEffect(() => {
    if (response && !!response?.length) {
      const ready = filter(
        response as SafeRecord<string, OneOrMany<string>>[],
        (r, i) =>
          !!r.id &&
          !created.current?.includes(justOne(r.id) || "") &&
          // When loading, don't process the last item as won't be complete yet
          (!(loading && isLoading) || i !== response.length - 1)
      );

      if (!ready.length) {
        return;
      }

      created.current = [
        ...created.current,
        ...maybeMap(ready, (r) => justOne(r.id)),
      ];

      onCreated(mutate?.(ready) || []);
    }
  }, [loading, response, mutate]);

  return useMemo(
    () => ({
      generate,
      completion: when(last(messages), (m) =>
        m.role === "assistant" ? m.content : undefined
      ),
      message,
      reply,
      loading: loading || isLoading,
    }),
    [generate, messages, message, reply, loading, isLoading]
  );
}

export function useAiUseCase<I, R>(
  usecase: AiUseCase<I, R, any>,
  onResponse?: (r: R) => void,
  scope?: string
) {
  const settings = useScopeSettings(scope);
  const [loading, setLoading] = useState(false);
  const stores = useAllStores();
  const props = useRecoilValue(PropertyDefStoreAtom);

  const run = useCallback(
    async (input: I) => {
      setLoading(true);
      const context = {
        stores: { ...stores, props },
        settings,
      };
      const data = (await usecase.prepareData?.(input, context)) || input;
      const prompt = usecase.toPrompt?.(data, context);
      const response = usecase.parseCompletion(
        await getPromptResponse(prompt),
        input,
        context
      );

      if (response && onResponse) {
        onResponse(response);
      }

      setLoading(false);

      return response;
    },
    [stores, onResponse]
  );

  return useMemo(() => ({ run, loading: loading }), [run, loading]);
}

export function useStreamAIUseCase<I, R, D>(
  usecase: AiUseCase<I, R, D>,
  onResponse: (response: R, finished: boolean) => void,
  scope?: string
) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();
  const [data, setData] = useState<D>();
  const stores = useAllStores();
  const settings = useScopeSettings(scope);
  const props = useRecoilValue(PropertyDefStoreAtom);
  const context = useMemo(
    () => ({ stores: { ...stores, props }, settings }),
    [stores, settings, props]
  );

  const { messages, setMessages, append, reload, isLoading } = useChat({
    api: "/api/chat/stream",
    onFinish: () => {
      setMessage(undefined);
      setLoading(false);
    },
  });

  const reply = useCallback(
    async (text: string) => {
      setLoading(true);
      setMessage("Replying....");
      append({ content: text, role: "user", createdAt: now() });
      reload();
    },
    [setMessage, reload]
  );

  const generate = useCallback(
    async (input: I) => {
      setMessage("Collecting information....");
      setLoading(true);
      const data = await usecase.prepareData?.(input, context);

      setData(data);
      const prompt = data && usecase.toPrompt?.(data, context);

      if (prompt) {
        setMessage("Thinking....");
        setMessages([
          {
            id: "base-message",
            content: prompt,
            role: "system",
            createdAt: now(),
          },
        ]);
        reload();
        // complete(prompt);
      }
    },
    [stores]
  );

  const response = useDebouncedMemo(
    () =>
      !!data
        ? when(findLast(messages, { role: "assistant" }), (m) => {
            return usecase.parseCompletion?.(
              cleanFormattedMarkdown(m.content),
              data,
              context
            );
          })
        : undefined,
    [10, 500],
    [loading, isLoading, data, messages, stores, props]
  );

  useEffect(() => {
    if (!!response) {
      onResponse?.(response, !(isLoading || loading));
    }
  }, [response, isLoading, loading]);

  return useMemo(
    () => ({
      run: generate,
      completion: when(last(messages), (m) =>
        m.role === "assistant" ? m.content : undefined
      ),
      message,
      reply,
      loading: loading || isLoading,
    }),
    [generate, messages, message, reply, loading, isLoading]
  );
}

// Accepts work in the form of a string dictionary that is parsed into fields
export function useCreateFromAIObject(
  type: EntityType,
  scope?: string,
  pageId?: ID,
  temp?: boolean
) {
  const workspaceId = useActiveWorkspaceId();
  const source = useMemo(
    (): DatabaseID => ({
      type: type,
      scope: scope || toScope(workspaceId),
    }),
    [type, scope, workspaceId]
  );
  const createCore = useCreateEntity(type, source.scope, pageId, temp);
  const props = useLazyProperties(source);

  const create = useCallback(
    (
      ts: Partial<SafeRecord<string, OneOrMany<string>>>[],
      transaction: string = newID()
    ) => {
      const orders = whenTruthy(
        ts.length > 1 && hasOrders(ts[0]) && (first(ts) as Partial<HasOrders>),
        (first) => {
          const start = Number(toOrder(first?.orders, "default")) || 1;
          const end = DecimalOrdering.bump(start);
          return evenlyBetween([start, end], ts.length, DecimalOrdering);
        }
      );

      return map(ts, (t, i) =>
        createCore(
          [
            // Use IDs from AI as they reference each other
            { field: "id", type: "text", value: { text: justOne(t.id) } },

            ...maybeMap(props, (p) => {
              // Filter out empty changes
              const rawValue = t[p.field];
              const val = switchEnum<PropertyType, PropertyValue>(p.type, {
                text: () => ({ text: justOne(rawValue) }),
                rich_text: () => ({
                  rich_text: { markdown: justOne(rawValue) },
                }),
                date: () => ({
                  date: when(justOne(rawValue), (d) =>
                    toISODate(
                      new Date(d),
                      p.options?.mode || "calendar",
                      "local"
                    )
                  ),
                }),
                number: () => ({ number: Number(justOne(rawValue)) || 0 }),
                select: () => ({ select: toRef(justOne(rawValue)) }),
                status: () => ({ status: toRef(justOne(rawValue)) }),
                multi_select: () => ({
                  multi_select: map(ensureMany(rawValue), (id) =>
                    id?.length > 2 && id?.length < 6 ? { id: id } : { name: id }
                  ),
                }),
                relation: () => ({ relation: toRef(justOne(rawValue)) }),
                relations: () => ({
                  relations: map(ensureMany(rawValue), (id) => toRef(id)),
                }),
                boolean: () => ({
                  boolean: String(justOne(rawValue)) === "true",
                }),
                else: () => ({ [p.type]: justOne(rawValue) }),
              });

              if (p.field === "orders" && !!orders) {
                return asMutation(p, toNewOrders("default", orders[i]));
              }

              return isDefined(rawValue) && !isEmptyValue(val?.[p.type])
                ? toMutation(t, p, val?.[p.type])
                : undefined;
            }),
          ],
          transaction
        )
      );
    },
    [createCore, props]
  );

  return props?.length ? create : undefined;
}
