import { useSetRecoilState } from "recoil";

import { AppCommandsAtom, setMode } from "@state/app";
import { useMe } from "@state/persons";
import { useEntityLabels } from "@state/settings";
import { toTemplateViewId } from "@state/views";
import { useActiveWorkspaceId } from "@state/workspace";

import { useGoTo } from "@utils/navigation";

import { CommandGroup, CommandItem } from "@ui/command-menu";
import { ClockHistory, EmojiIcon, PersonIcon, Slash } from "@ui/icon";

import { SearchCommand } from "./search";
import { AppCommandsProps } from "./types";
import { useCommandSearch } from "./utils";

export const NavigationCommands = (props: AppCommandsProps) => {
  const me = useMe();
  const wID = useActiveWorkspaceId();
  const goTo = useGoTo();
  const query = useCommandSearch();
  const setCommands = useSetRecoilState(AppCommandsAtom);
  const toEntityLabel = useEntityLabels(wID, { case: "lower" });
  const { actingType, actingOn, actingSource } = props;

  return (
    <CommandGroup>
      <CommandItem
        value="home"
        icon={<EmojiIcon emoji="🏠" />}
        onClick={() => goTo("/home")}
      >
        Home
      </CommandItem>
      <CommandItem
        value="inbox"
        icon={<EmojiIcon emoji="📥" />}
        onClick={() => goTo("/inbox")}
      >
        Inbox
      </CommandItem>
      <CommandItem
        value="my work"
        icon={<PersonIcon person={me} />}
        onClick={() =>
          goTo(["boards", toTemplateViewId("my-tasks", { parent: me.id })])
        }
      >
        My work
      </CommandItem>

      {!query.length && <SearchCommand {...props} />}

      <CommandItem
        value="recently viewed"
        icon={ClockHistory}
        onClick={() => setCommands(setMode("recents"))}
        onSelectAction="clear"
      >
        Recently viewed
      </CommandItem>

      {actingOn?.length && (
        <CommandItem
          value="use commands"
          onSelectAction="clear"
          icon={Slash}
          onClick={() => setCommands(setMode("commands"))}
        >
          Modify{" "}
          {actingSource === "selection"
            ? `${actingOn?.length} selected`
            : `open ${toEntityLabel(actingType)}`}
        </CommandItem>
      )}
    </CommandGroup>
  );
};
