import { find } from "lodash";
import { useMemo } from "react";

import { Note } from "@api";

import { useMe } from "@state/persons";

import { cx } from "@utils/class-names";
import { formatHuman } from "@utils/date";
import { usePointDate } from "@utils/date-fp";
import { respectHandled, withHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";

import { Button } from "@ui/button";
import { SpaceBetween, VStack } from "@ui/flex";
import { Icon, Pin, SlackColor, UnreadIndicator } from "@ui/icon";
import { Label } from "@ui/label";
import { RelationLabel } from "@ui/relation-label";
import { ReadonlyTextBox } from "@ui/rich-text";
import { Text, TextSmall } from "@ui/text";

import styles from "./card.module.css";

interface Props {
  note: Note;
  showSource?: boolean;
  showSeenStatus?: boolean;
  selected?: boolean;
  onClick: Fn<Note, void>;
  onTogglePin?: Fn<Note, void>;
}

export const NoteCardItem = ({
  note,
  showSource = true,
  showSeenStatus,
  selected,
  onClick,
  onTogglePin,
}: Props) => {
  const me = useMe();
  const seen = useMemo(
    () => find(note?.refs?.seenBy, (r) => r.id === me?.id),
    [me, note]
  );
  const isThreaded = useMemo(() => (note?.links?.length || 0) > 0, [note]);
  return (
    <div
      className={cx(
        styles.note,
        seen && showSeenStatus ? styles.seen : styles.unseen,
        selected && styles.selected
      )}
      onClick={respectHandled(() => onClick(note))}
    >
      <VStack gap={2}>
        <SpaceBetween>
          <SpaceBetween>
            {note?.author && (
              <RelationLabel relation={note.author} className={styles.person} />
            )}
            {note.createdAt && (
              <TextSmall subtle>
                {usePointDate(note?.createdAt, formatHuman)}
              </TextSmall>
            )}
          </SpaceBetween>

          {onTogglePin && (
            <Button
              onClick={withHandle(() => onTogglePin?.(note))}
              size="small"
              subtle
              className={cx(styles.pin, note.pinned && styles.pinned)}
              icon={<Icon className={styles.icon} icon={<Pin />} />}
            ></Button>
          )}

          {!seen && <UnreadIndicator className={styles.unreadIndicator} />}
        </SpaceBetween>

        <ReadonlyTextBox
          key={note?.id}
          className={cx(styles.updateBody, styles.bounded)}
          text={note.body}
        />

        {showSource &&
          when(note?.refs?.backlinks?.[0], (ref) => (
            <RelationLabel className={styles.relation} relation={ref} />
          ))}

        {isThreaded && (
          <SpaceBetween className={styles.threadReplies}>
            <Label icon={<Icon icon={SlackColor} />}>1+ replies</Label>
            <Text subtle>View thread {"->"}</Text>
          </SpaceBetween>
        )}
      </VStack>
    </div>
  );
};
