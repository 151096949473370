import { atomFamily } from "recoil";

import { Contact, ID } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type ContactStoreState = StoreState<Contact>;

export const WorkspaceContactStoreAtom = atomFamily<ContactStoreState, ID>({
  key: "WorkspaceContactStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "contact")],
});

export const ContactStoreAtom = WorkspaceWrappedAtom(
  "ContactStoreAtom",
  WorkspaceContactStoreAtom
);
