import { Editor } from "@tiptap/core";
import { some } from "lodash";

import { safeAs } from "@utils/maybe";

export const isFocused = (editor: Editor) =>
  editor.view.hasFocus() ||
  some(
    editor.state.plugins,
    (p) =>
      p.getState(editor.state)?.active || safeAs<{ active: boolean }>(p)?.active
  ) ||
  // TODO: Remove, as I don't think this works...
  !!editor.state.tr.getMeta("plugin-focused");

export const getExtensionOptions = (name: string, editor: Editor) =>
  editor.extensionManager.extensions.find((ext) => ext.name === name)?.options;
