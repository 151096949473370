import { Backlog } from "@api";

import { withHandle } from "@utils/event";
import { when } from "@utils/maybe";

import { EntityContextMenu } from "@ui/entity-context-menu";
import { VStack } from "@ui/flex";
import { Icon, iconFromString } from "@ui/icon";
import { Label } from "@ui/label";
import { MenuItem } from "@ui/menu-item";
import { SelectableListCard } from "@ui/selectable-items";

import { EntityEngine } from "../fallback";
import { UIEngine } from "../types";
import { BacklogCreateDialog } from "./create-dialog";

import styles from "./styles.module.css";

export const BacklogEngine: UIEngine<Backlog> = {
  asMenuItem: function ({ item, onOpen, ...rest }) {
    return (
      <EntityContextMenu entity={item}>
        <MenuItem
          {...rest}
          onClick={withHandle(() => onOpen?.(item))}
          icon={when(item.icon, iconFromString)}
          text={item.name}
        />
      </EntityContextMenu>
    );
  },
  asListCard: (props) => (
    <EntityContextMenu entity={props.item}>
      <SelectableListCard {...props}>
        <VStack className={styles.bar}>
          {when(iconFromString(props.item.icon), (i) => (
            <Icon size="large" className={styles.icon} icon={i} />
          ))}

          <Label className={styles.title}>{props.item.name || "Backlog"}</Label>
        </VStack>
      </SelectableListCard>
    </EntityContextMenu>
  ),
  asListItem: EntityEngine.asListItem as UIEngine<Backlog>["asListItem"],
  asCreateDialog: BacklogCreateDialog,
};
