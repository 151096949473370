import { CSSProperties } from "react";

import { cx } from "@utils/class-names";

import styles from "./styles.module.css";

export const NoneIcon = ({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) => <span className={cx(styles.icon, className)} style={style}></span>;
