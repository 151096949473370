import { useCallback, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { ID } from "@api";

import { useLazyEntity } from "@state/generic";

import { typeFromId } from "@utils/id";
import { switchEnum } from "@utils/logic";

import { AppSpaceAtom } from "./atoms";

export const useActiveSpace = () => {
  const space = useRecoilValue(AppSpaceAtom);
  const entity = useLazyEntity<"team" | "workspace" | "person">(space.id);

  return useMemo(
    () => ({ ...space, mode: entity?.source.type, entity }),
    [space?.id, entity]
  );
};

export const useSetSpace = () => {
  const setSpace = useSetRecoilState(AppSpaceAtom);

  return useCallback(
    (id: ID) =>
      setSpace({
        id,
        scopes: switchEnum(typeFromId(id), {
          workspace: "*",
          team: [id],
          "person:": [id],
          else: () => [],
        }),
      }),
    [setSpace]
  );
};

export const useSpaceState = () => {
  const space = useActiveSpace();
  const setSpace = useSetSpace();

  return [space, setSpace] as const;
};
