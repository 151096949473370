import { useMemo } from "react";

import {
  EntityType,
  HasLocation,
  hasResources,
  HasTemplate,
  ID,
  toTitleOrName,
} from "@api";

import { usePageUndoRedo, useRegisterPage } from "@state/app";
import { useLazyEntity, useMarkAsSeen } from "@state/generic";
import { SystemPackages, useHasPackages } from "@state/packages";

import { typeFromId } from "@utils/id";
import { safeAs, when } from "@utils/maybe";
import { usePushTo } from "@utils/navigation";
import { getRelationValue } from "@utils/property-refs";
import { toChildLocation } from "@utils/scope";
import { useSyncPathnameID } from "@utils/url";

import { usePageId } from "@ui/app-page";
import { SmartBreadcrumbSheet } from "@ui/breadcrumb-sheet";
import { getEngine, render } from "@ui/engine";
import { RelatedMeetingsPane } from "@ui/engine/meeting";
import { RelatedProcessesPane } from "@ui/engine/process";
import { ScheduleInstancesPane } from "@ui/engine/schedule";
import { ClockHistory, Comments, EmojiIcon, Process, Search } from "@ui/icon";
import { EntityMessagesPane } from "@ui/messages-pane";
import { PaneItem, PaneManager } from "@ui/pane-manager";
import { ResourcesPane } from "@ui/resources-pane";
import { SearchPane } from "@ui/search-pane";
import { Sheet, StackContainer } from "@ui/sheet-layout";
import { TemplatePaneManager } from "@ui/template-pane-manager";

import AppPage from "./app-page";

interface Props {
  id: ID;
}

export const EntityPage = ({ id }: Props) => {
  const pageId = usePageId();
  const entity = useLazyEntity(id);
  const engine = useMemo(() => getEngine(typeFromId<EntityType>(id)), [id]);
  const pushTo = usePushTo();
  const fullLocation = useMemo(
    () =>
      when(entity, (t) => toChildLocation((t as HasLocation).location, t.id)),
    [entity?.id, (entity as HasLocation)?.location]
  );
  const installed = useHasPackages(id || "", [
    SystemPackages.Outcomes,
    SystemPackages.Meetings,
    SystemPackages.Processes,
  ]);

  const [page] = useRegisterPage(id, entity);
  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, entity?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!entity) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!entity} title={toTitleOrName(entity)}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        {render(engine.asPrimaryPane, { id, item: entity })}

        {!safeAs<HasTemplate>(entity)?.template && (
          <PaneManager size="secondary">
            <PaneItem id="search" title="Search" icon={Search}>
              <SearchPane parentId={entity.id} onOpen={(n) => pushTo(n)} />
            </PaneItem>

            {hasResources(entity) && fullLocation && (
              <PaneItem
                id="resources"
                title="Attachments"
                icon={<EmojiIcon emoji="🔗" />}
              >
                <ResourcesPane location={fullLocation} />
              </PaneItem>
            )}

            <PaneItem
              id="messages"
              title="Messages"
              icon={<EmojiIcon emoji="💬" />}
            >
              <EntityMessagesPane entityId={id} />
            </PaneItem>

            {when(getRelationValue(entity, "refs.repeat"), (ref) => (
              <PaneItem id="schedule" title="Schedule" icon={ClockHistory}>
                <ScheduleInstancesPane schedule={ref} instanceId={entity.id} />
              </PaneItem>
            ))}

            {installed[SystemPackages.Meetings] && (
              <PaneItem id="meetings" title="Meetings" icon={Comments}>
                <RelatedMeetingsPane entityId={entity.id} />
              </PaneItem>
            )}

            {installed[SystemPackages.Processes] && (
              <PaneItem id="processes" title="Processes" icon={Process}>
                <RelatedProcessesPane entityId={entity.id} />
              </PaneItem>
            )}
          </PaneManager>
        )}

        {!!safeAs<HasTemplate>(entity)?.template && (
          <TemplatePaneManager id={entity.id} entity={entity} />
        )}
      </StackContainer>
    </AppPage>
  );
};

export const EntityOnlyPage = ({ id }: Props) => {
  const pageId = usePageId();
  const entity = useLazyEntity(id);
  const engine = useMemo(() => getEngine(typeFromId<EntityType>(id)), [id]);
  const [page] = useRegisterPage(id, entity);

  usePageUndoRedo(page.id);

  // Hotswap temp ids out of url
  useSyncPathnameID(id, entity?.id);

  // Mark the note as seen by current user
  useMarkAsSeen(id, pageId);

  if (!entity) {
    return <>Not found.</>;
  }

  return (
    <AppPage page={page} loading={!entity} title={toTitleOrName(entity)}>
      <StackContainer>
        <SmartBreadcrumbSheet />
        {render(engine.asPrimaryPane, { id, item: entity })}

        <Sheet size="secondary" mode="sizing" />
      </StackContainer>
    </AppPage>
  );
};

export default EntityPage;
