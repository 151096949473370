import { cx } from "@utils/class-names";

import styles from "./styles.module.css";

export const SpinnerIcon = ({ spinning = true }: { spinning?: boolean }) => (
  <svg
    className={cx(spinning && styles.spinning)}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3448_7244)">
      <path d="M10 0C10.2652 0 10.5196 0.105357 10.7071 0.292893C10.8946 0.48043 11 0.734784 11 1V3C11 3.26522 10.8946 3.51957 10.7071 3.70711C10.5196 3.89464 10.2652 4 10 4C9.73478 4 9.48043 3.89464 9.29289 3.70711C9.10536 3.51957 9 3.26522 9 3V1C9 0.734784 9.10536 0.48043 9.29289 0.292893C9.48043 0.105357 9.73478 0 10 0Z" />
      <path
        opacity="0.1"
        d="M16.5619 7.36598L18.2939 6.36598C18.5218 6.23243 18.6875 6.0142 18.755 5.7589C18.8225 5.5036 18.7863 5.23197 18.6542 5.00329C18.5222 4.7746 18.3051 4.60742 18.0502 4.53823C17.7954 4.46904 17.5235 4.50346 17.294 4.63398L15.5619 5.63398C15.4473 5.69916 15.3467 5.78638 15.2659 5.89061C15.1851 5.99484 15.1257 6.11402 15.0911 6.24129C15.0566 6.36856 15.0475 6.50141 15.0645 6.63219C15.0815 6.76297 15.1242 6.8891 15.1902 7.00331C15.2561 7.11752 15.344 7.21756 15.4488 7.29767C15.5535 7.37778 15.6731 7.43637 15.8006 7.47008C15.9281 7.50379 16.061 7.51195 16.1917 7.49408C16.3223 7.47621 16.4482 7.43268 16.5619 7.36598Z"
      />
      <path
        opacity="0.1"
        d="M16.2929 9.29289C16.1054 9.48043 16 9.73478 16 10C16 10.2652 16.1054 10.5196 16.2929 10.7071C16.4804 10.8946 16.7348 11 17 11H19C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 10C20 9.73478 19.8946 9.48043 19.7071 9.29289C19.5196 9.10536 19.2652 9 19 9H17C16.7348 9 16.4804 9.10536 16.2929 9.29289Z"
      />
      <path
        opacity="0.2"
        d="M15.8032 12.5341C15.547 12.6027 15.3286 12.7703 15.196 13C15.0633 13.2297 15.0274 13.5026 15.0961 13.7588C15.1647 14.0149 15.3323 14.2334 15.5619 14.366L17.294 15.366C17.5235 15.4965 17.7954 15.5309 18.0502 15.4617C18.3051 15.3925 18.5222 15.2254 18.6542 14.9967C18.7863 14.768 18.8225 14.4963 18.755 14.2411C18.6875 13.9858 18.5218 13.7675 18.2939 13.634L16.5619 12.634C16.3323 12.5014 16.0593 12.4654 15.8032 12.5341Z"
      />
      <path
        opacity="0.3"
        d="M13.7588 15.0961C13.5026 15.0274 13.2297 15.0634 13 15.196C12.7703 15.3286 12.6028 15.547 12.5341 15.8032C12.4655 16.0594 12.5014 16.3323 12.634 16.562L13.634 18.294C13.7676 18.5218 13.9858 18.6875 14.2411 18.755C14.4964 18.8225 14.768 18.7863 14.9967 18.6543C15.2254 18.5222 15.3926 18.3051 15.4618 18.0502C15.531 17.7954 15.4965 17.5235 15.366 17.294L14.366 15.562C14.2334 15.3323 14.015 15.1647 13.7588 15.0961Z"
      />
      <path
        opacity="0.4"
        d="M10.7071 16.2929C10.5196 16.1054 10.2652 16 10 16C9.73478 16 9.48043 16.1054 9.29289 16.2929C9.10536 16.4804 9 16.7348 9 17V19C9 19.2652 9.10536 19.5196 9.29289 19.7071C9.48043 19.8946 9.73478 20 10 20C10.2652 20 10.5196 19.8946 10.7071 19.7071C10.8946 19.5196 11 19.2652 11 19V17C11 16.7348 10.8946 16.4804 10.7071 16.2929Z"
      />
      <path
        opacity="0.5"
        d="M5.8906 15.266C5.78638 15.3468 5.69916 15.4474 5.63398 15.562L4.63398 17.294C4.56728 17.4078 4.52375 17.5336 4.50588 17.6643C4.48801 17.795 4.49617 17.9279 4.52988 18.0554C4.56359 18.1829 4.62218 18.3024 4.70229 18.4072C4.7824 18.512 4.88244 18.5998 4.99665 18.6658C5.11086 18.7317 5.23699 18.7744 5.36777 18.7914C5.49855 18.8084 5.6314 18.7994 5.75867 18.7648C5.88594 18.7303 6.00513 18.6709 6.10935 18.5901C6.21358 18.5093 6.3008 18.4087 6.36598 18.294L7.36598 16.562C7.43268 16.4482 7.47621 16.3224 7.49408 16.1917C7.51194 16.0611 7.50379 15.9282 7.47008 15.8007C7.43637 15.6732 7.37777 15.5536 7.29767 15.4488C7.21756 15.3441 7.11752 15.2562 7.00331 15.1903C6.8891 15.1243 6.76297 15.0816 6.63219 15.0646C6.50141 15.0476 6.36856 15.0567 6.24129 15.0912C6.11401 15.1258 5.99483 15.1851 5.8906 15.266Z"
      />
      <path
        opacity="0.6"
        d="M3.43798 12.634L1.70598 13.634C1.47817 13.7676 1.31244 13.9858 1.24494 14.2411C1.17745 14.4964 1.21367 14.768 1.34571 14.9967C1.47774 15.2254 1.69488 15.3926 1.94972 15.4618C2.20456 15.531 2.47642 15.4965 2.70598 15.366L4.43798 14.366C4.55263 14.3008 4.65325 14.2136 4.73405 14.1094C4.81485 14.0052 4.87424 13.886 4.90879 13.7587C4.94335 13.6314 4.95238 13.4986 4.93538 13.3678C4.91839 13.237 4.87569 13.1109 4.80974 12.9967C4.7438 12.8825 4.65592 12.7824 4.55116 12.7023C4.4464 12.6222 4.32683 12.5636 4.19933 12.5299C4.07183 12.4962 3.93892 12.4881 3.80826 12.5059C3.67759 12.5238 3.55175 12.5673 3.43798 12.634Z"
      />
      <path
        opacity="0.8"
        d="M4.19681 7.46592C4.45299 7.39728 4.6714 7.22969 4.80402 7.00002C4.93662 6.77034 4.97255 6.4974 4.90391 6.24122C4.83528 5.98505 4.66769 5.76663 4.43802 5.63402L2.70602 4.63402C2.59224 4.56732 2.46641 4.52379 2.33574 4.50592C2.20508 4.48806 2.07217 4.49621 1.94467 4.52992C1.81717 4.56363 1.6976 4.62223 1.59284 4.70233C1.48808 4.78244 1.40019 4.88248 1.33425 4.99669C1.26831 5.1109 1.22561 5.23703 1.20861 5.36781C1.19161 5.49859 1.20065 5.63144 1.23521 5.75871C1.26976 5.88599 1.32915 6.00517 1.40995 6.10939C1.49075 6.21362 1.59137 6.30084 1.70602 6.36602L3.43802 7.36602C3.66769 7.49862 3.94064 7.53456 4.19681 7.46592Z"
      />
      <path
        opacity="0.7"
        d="M3 9H1C0.734783 9 0.480429 9.10536 0.292892 9.29289C0.105356 9.48043 0 9.73478 0 10C0 10.2652 0.105356 10.5196 0.292892 10.7071C0.480429 10.8946 0.734783 11 1 11H3C3.26522 11 3.51957 10.8946 3.70711 10.7071C3.89464 10.5196 4 10.2652 4 10C4 9.73478 3.89464 9.48043 3.70711 9.29289C3.51957 9.10536 3.26522 9 3 9Z"
      />
      <path
        opacity="0.9"
        d="M6.24123 4.9039C6.4974 4.97254 6.77035 4.9366 7.00003 4.804C7.2297 4.67139 7.39729 4.45297 7.46593 4.1968C7.53456 3.94063 7.49863 3.66768 7.36603 3.438L6.36603 1.706C6.30084 1.59136 6.21363 1.49074 6.1094 1.40993C6.00517 1.32913 5.88599 1.26974 5.75872 1.23519C5.63145 1.20064 5.4986 1.1916 5.36782 1.2086C5.23704 1.2256 5.11091 1.2683 4.9967 1.33424C4.88249 1.40018 4.78245 1.48806 4.70234 1.59282C4.62223 1.69758 4.56364 1.81716 4.52993 1.94465C4.49622 2.07215 4.48806 2.20506 4.50593 2.33573C4.52379 2.46639 4.56733 2.59223 4.63403 2.706L5.63403 4.438C5.76664 4.66767 5.98506 4.83526 6.24123 4.9039Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_3448_7244">
        <rect width="20" height="20" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
