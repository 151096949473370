import {
  DocumentEventListener,
  ElementEventListener,
  WindowEventListener,
} from "@react-hook/event";
import { isArray, isRegExp, map, some } from "lodash";
import { RefObject, useEffect } from "react";

import { ensureArray } from "./array";
import { Fn, Pred } from "./fn";
import { Maybe } from "./maybe";
import { isCommandKey } from "./mouse";

// Useful for debugging values. https://www.toptal.com/developers/keycode

type ModifierKeys =
  | "Alt"
  | "AltGraph"
  | "CapsLock"
  | "Control"
  | "Fn"
  | "FnLock"
  | "Hyper"
  | "Meta"
  | "NumLock"
  | "ScrollLock"
  | "Shift"
  | "Slash"
  | "Super"
  | "Symbol"
  | "SymbolLock";
type WhitespaceKeys = "Enter" | "Tab" | " " | "Space";
type ActionKeys = "Backspace" | "Delete" | "Escape";
type NavigationKeys =
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUp"
  | "End"
  | "Home"
  | "PageDown"
  | "PageUp";
type FunctionKeys =
  | "F1"
  | "F2"
  | "F3"
  | "F4"
  | "F5"
  | "F6"
  | "F7"
  | "F8"
  | "F9"
  | "F10"
  | "F11"
  | "F12"
  | "F13"
  | "F14"
  | "F15"
  | "F16"
  | "F17"
  | "F18"
  | "F19"
  | "F20"
  | "Soft1"
  | "Soft2"
  | "Soft3"
  | "Soft4";
type NumericKeypadKeys =
  | "Decimal"
  | "Key11"
  | "Key12"
  | "Multiply"
  | "Add"
  | "Clear"
  | "Divide"
  | "Subtract"
  | "Separator"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

type AlphaKeys =
  | "KeyA"
  | "KeyB"
  | "KeyC"
  | "KeyD"
  | "KeyE"
  | "KeyF"
  | "KeyG"
  | "KeyH"
  | "KeyI"
  | "KeyJ"
  | "KeyK"
  | "KeyL"
  | "KeyM"
  | "KeyN"
  | "KeyO"
  | "KeyP"
  | "KeyQ"
  | "KeyR"
  | "KeyS"
  | "KeyT"
  | "KeyU"
  | "KeyV"
  | "KeyQ"
  | "KeyR"
  | "KeyS"
  | "KeyT"
  | "KeyU"
  | "KeyV"
  | "KeyW"
  | "KeyX"
  | "KeyY"
  | "KeyZ";

export type Keys =
  | ModifierKeys
  | WhitespaceKeys
  | NavigationKeys
  | FunctionKeys
  | NumericKeypadKeys
  | ActionKeys
  | AlphaKeys;

export interface Handleable {
  preventDefault: Fn<void, void>;
  defaultPrevented?: boolean;
}

export const isWithinSelectable = (
  e: KeyboardEvent | React.MouseEvent | MouseEvent
) => !!(e.target as HTMLElement)?.closest('[data-selectable="true"]');

export const isWithinEditable = (
  e: KeyboardEvent | React.MouseEvent | MouseEvent
) =>
  (e.target as HTMLElement).nodeName === "INPUT" ||
  (e.target as HTMLElement).nodeName === "TEXTAREA" ||
  !!(e.target as HTMLElement).closest('[contentEditable="true"]');

export const handle = <T extends Handleable>(e: T) => e.preventDefault();

export const withHandle =
  <T extends Handleable>(callback: Fn<T, void>) =>
  (e: T) => {
    e.preventDefault();
    callback(e);
  };

export const withHardHandle =
  <T extends Handleable>(callback: Fn<T, void>) =>
  (e: T) => {
    e.preventDefault();
    (e as any).stopPropagation?.();
    callback(e);
    return false;
  };

export function respectHandled<T extends Handleable>(
  cb: Fn<T, void>
): Fn<T, void>;
export function respectHandled<T extends Handleable>(
  cb: Fn<Maybe<T>, void>
): Fn<Maybe<T>, void>;
export function respectHandled<T extends Handleable>(
  callback: Fn<Maybe<T>, void>
) {
  return (e: Maybe<T>) => {
    if (!e?.defaultPrevented) {
      callback(e);
    }
  };
}

export const respectSelectables =
  <T extends KeyboardEvent | React.MouseEvent | MouseEvent>(
    callback: Fn<Maybe<T>, void>
  ) =>
  (e: Maybe<T>) => {
    if (e && !isWithinSelectable(e)) {
      callback(e);
    }
  };

type AvailableEvents =
  | keyof GlobalEventHandlersEventMap
  | keyof WindowEventMap
  | keyof HTMLElementEventMap
  | keyof WindowEventHandlersEventMap
  | "beforeunload"
  | "paste";

export const useWindowEvent = <E extends AvailableEvents = AvailableEvents>(
  event: E,
  call: E extends keyof WindowEventMap
    ? WindowEventListener<E>
    : E extends keyof DocumentEventMap
    ? DocumentEventListener<E>
    : E extends keyof HTMLElementEventMap
    ? ElementEventListener<E>
    : any,
  skipHandled?: boolean,
  deps?: any[]
) => {
  if (typeof window === "undefined") {
    return;
  }

  useEffect(() => {
    const listener = (e: Event) => {
      // Don't bubble handled events
      if (skipHandled === true && e.defaultPrevented) {
        return;
      }
      (call as EventListener)(e);
    };

    // initiate the event handler
    window.addEventListener(event, listener);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener(event, listener);
    };
  }, [event, skipHandled, ...(deps || [])]);
};

type ShortCutKey =
  | Keys
  | KeyboardEvent["key"]
  | {
      command?: boolean;
      shift?: boolean;
      key: RegExp | Keys | KeyboardEvent["key"];
    };

export const isValidDoubleClick = (e: React.MouseEvent) =>
  !isWithinEditable(e) || !window.getSelection()?.toString();

export const validShortcut = (e: KeyboardEvent) =>
  !e.defaultPrevented && !isWithinEditable(e);

export const defaultInputPredicate = (e: KeyboardEvent) => !e.defaultPrevented;

export const useShortcut = (
  key: ShortCutKey | ShortCutKey[],
  callbacks:
    | Fn<KeyboardEvent, void>
    | [Pred<KeyboardEvent>, Fn<KeyboardEvent, void>],
  deps: any[] = []
) => {
  const keyDeps: (string | RegExp)[] = [];
  const shortcuts = map(ensureArray(key), (key) => {
    const shortcut = typeof key === "object" ? key : { command: false, key };
    keyDeps.push(shortcut.key);
    return shortcut;
  });
  const [predicate, callback] = isArray(callbacks)
    ? callbacks
    : [validShortcut, callbacks];

  useWindowEvent(
    "keydown",
    (e) => {
      if (
        some(
          shortcuts,
          (shortcut) =>
            (shortcut.command ?? false) === isCommandKey(e) &&
            (shortcut.shift ?? false) === e.shiftKey &&
            (isRegExp(shortcut.key)
              ? shortcut.key.test(String.fromCharCode(e.keyCode))
              : e.code === shortcut.key)
        ) &&
        predicate(e)
      ) {
        callback(e);
        e.preventDefault();
      }
    },
    false,
    [...keyDeps, ...deps]
  );
};

export const useInputShortcut = (
  input: RefObject<HTMLElement>,
  key: ShortCutKey | ShortCutKey[],
  callbacks:
    | Fn<KeyboardEvent, void>
    | [Pred<KeyboardEvent>, Fn<KeyboardEvent, void>],
  deps?: any[]
) => {
  const shortcuts = map(ensureArray(key), (key) =>
    typeof key === "object" ? key : { command: false, key }
  );
  const [predicate, callback] = isArray(callbacks)
    ? callbacks
    : [defaultInputPredicate, callbacks];

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (
        some(
          shortcuts,
          (shortcut) =>
            (shortcut.command ?? false) === isCommandKey(e) &&
            (shortcut.shift ?? false) === e.shiftKey &&
            e.code === shortcut.key
        ) &&
        predicate(e)
      ) {
        callback(e);
        e.preventDefault();
      }
    };

    const attachListener = () =>
      input.current?.addEventListener("keydown", listener);

    const removeListener = () =>
      input.current?.removeEventListener("keydown", listener);

    // Attach the key listener only when focused
    input.current?.addEventListener("focus", attachListener);
    // Remove the listener on blur
    input.current?.removeEventListener("blur", removeListener);

    // Already focused
    if (input.current === document.activeElement) {
      attachListener();
    }

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      input.current?.removeEventListener("focus", attachListener);
      input.current?.removeEventListener("blur", removeListener);
      input.current?.removeEventListener("keydown", listener);
    };
  }, [...(deps || []), input.current]);
};

export const respectInputs = <
  T extends KeyboardEvent | React.MouseEvent | MouseEvent
>(
  callback: Fn<Maybe<T>, void>
) =>
  respectHandled((e: Maybe<T>) => {
    if (!e || !isWithinEditable(e)) {
      return callback(e);
    }
  });
