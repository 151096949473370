import { atomFamily } from "recoil";

import { ID, Project } from "@api";

import { indexedDBStorageForStore, StoreState } from "@state/store";
import { WorkspaceWrappedAtom } from "@state/workspace";

export type ProjectStoreState = StoreState<Project>;

export const WorkspaceProjectStoreAtom = atomFamily<ProjectStoreState, ID>({
  key: "WorkspaceProjectStoreAtom",
  default: { updatedAt: undefined, lookup: {} },
  effects: (wid) => [indexedDBStorageForStore(wid, "project")],
});

export const ProjectStoreAtom = WorkspaceWrappedAtom(
  "ProjectStoreAtom",
  WorkspaceProjectStoreAtom
);
