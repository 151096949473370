import { map, reduce } from "lodash";

import * as Api from "@api/types";

import * as Graph from "@graph/types";

import { OneOrMany } from "./array";
import { switchEnum } from "./logic";
import { Maybe } from "./maybe";
import { sentenceCase } from "./string";

type PropertyDef = Api.PropertyDef<Api.Task>;
// type PropertyDef = Graph.PropertyDef | Api.PropertyDef<Api.Task>;

export const toAIField = (p: PropertyDef | Api.PropertyValueRef): string =>
  (p as { label: Maybe<string> }).label ||
  (p as Api.PropertyValueRef)?.def?.label ||
  sentenceCase(p.field);

export type PropertyMap = Record<
  string,
  Maybe<OneOrMany<{ id?: string; name?: string }>>
>;

const formatTagValues = (p: PropertyDef): PropertyMap => ({
  [p.field]: reduce(
    [
      ...(p.values[p.type as Api.PropertyType] as Api.Ref[]),
      { id: "null", name: "Unknown" },
    ],
    (res, v) => ({
      ...res,
      [v.id]: v.name,
    }),
    {}
  ),
});

export const toAiPropertyDef = (props: PropertyDef[]): PropertyMap =>
  reduce(
    props,
    (res, p) =>
      switchEnum(p.type as Graph.PropertyType, {
        status: () => ({ ...res, ...formatTagValues(p) }),
        select: () => ({ ...res, ...formatTagValues(p) }),
        multi_select: () => ({ ...res, ...formatTagValues(p) }),
        relation: () => ({ ...res, ...formatTagValues(p) }),
        relations: () => ({ ...res, ...formatTagValues(p) }),
        else: () => res,
      }),
    {} as PropertyMap
  );

export const toAiPropertyValue = (props: Api.PropertyValueRef[]): PropertyMap =>
  reduce(
    props,
    (res, p) =>
      switchEnum(p.type as Graph.PropertyType, {
        select: () => ({
          ...res,
          [toAIField(p)]: {
            id: p.value?.select?.id,
            name: p.value?.select?.name,
          },
        }),
        relation: () => ({
          ...res,
          [toAIField(p)]: {
            id: p.value?.relation?.id,
            name: p.value?.relation?.name,
          },
        }),
        relations: () => ({
          ...res,
          [toAIField(p)]: map(p.value?.relations, (r) => ({
            id: r?.id,
            name: r?.name,
          })),
        }),
        multi_select: () => ({
          ...res,
          [toAIField(p)]: map(p.value?.multi_select, (r) => ({
            id: r?.id,
            name: r?.name,
          })),
        }),
        else: () => res,
      }),
    {} as PropertyMap
  );
