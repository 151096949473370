import { isString } from "lodash";
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useLayoutEffect,
  useRef,
} from "react";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useForwardedRef } from "@utils/hooks";
import { ComponentOrNode } from "@utils/react";

import { SpaceBetween } from "@ui/flex";

import { Divider } from "./divider";
import { Ellipsis } from "./ellipsis";
import { SpinnerIcon } from "./icon";
import { SectionLabel } from "./text";

import styles from "./menu-group.module.css";

export interface MenuGroupProps {
  label?: string | ReactNode;
  icon?: ComponentOrNode;
  divider?: boolean;
  loading?: boolean;
  indent?: number;
  inset?: boolean;
  className?: string;
  labelClassName?: string;
  listClassName?: string;
  hideEmpty?: boolean;
  bold?: boolean;
  onLabelClicked?: Fn<React.MouseEvent, void>;
  onClick?: Fn<React.MouseEvent, void>;
  style?: CSSProperties;
  children?: ReactNode;
  actions?: ReactNode;
}

export type MenuSeparatorProps = Omit<MenuGroupProps, "listClassName">;

export const MenuSeparator = ({
  label,
  icon,
  actions,
  loading,
  bold,
  divider,
  className,
  labelClassName,
  onLabelClicked,
  onClick,
}: MenuSeparatorProps) => {
  return (
    <SpaceBetween
      align="center"
      gap={6}
      className={cx(styles.header, className)}
      onClick={onClick}
    >
      <div className={styles.title}>
        <Ellipsis>
          {!isString(label) ? (
            label
          ) : (
            <SectionLabel
              bold={bold}
              className={cx(onLabelClicked && styles.clickable, labelClassName)}
              icon={icon}
              iconRight={loading ? <SpinnerIcon /> : undefined}
              onClick={onLabelClicked}
            >
              {label}
            </SectionLabel>
          )}
        </Ellipsis>
      </div>

      {divider !== false && <Divider subtle />}

      {actions}
    </SpaceBetween>
  );
};

export const MenuDivider = MenuSeparator;

export const MenuGroup = forwardRef<HTMLDivElement, MenuGroupProps>(
  (
    {
      label,
      children,
      inset = true,
      divider,
      style,
      labelClassName,
      listClassName,
      className,
      onLabelClicked,
      hideEmpty = true,
      ...rest
    },
    forwarded
  ) => {
    const ref = useForwardedRef<HTMLDivElement>(forwarded);
    const container = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
      if (!container.current || !hideEmpty) {
        return;
      }

      if (ref?.current?.childNodes?.length === 0) {
        container.current.style.display = "none";
      } else if (container?.current) {
        container.current.style.display = "";
      }
    }, [ref?.current?.childNodes?.length]);

    return (
      <div
        ref={container}
        className={cx(styles.group, inset && styles.inset, className)}
        style={style}
      >
        {(label || divider) && (
          <MenuSeparator
            {...rest}
            labelClassName={labelClassName}
            label={label}
            onLabelClicked={onLabelClicked}
            divider={divider}
          />
        )}
        <div ref={ref} className={cx(styles.items, listClassName)}>
          {children}
        </div>
      </div>
    );
  }
);
