import { isString } from "lodash";

import { SelectOption } from "@api";

import { cx } from "@utils/class-names";
import { isLocalID } from "@utils/id";

import { Button } from "@ui/button";
import { CheckIcon } from "@ui/icon";
import { MenuItem } from "@ui/menu-item";
import { Tag } from "@ui/tag";
import { PlaceholderText } from "@ui/text";

import { MultiProps, MultiSelect } from "./multi-select";
import { Select,SelectProps } from "./single-select";

import styles from "./select.module.css";

const TagSelectOverrides: SelectProps<SelectOption>["overrides"] = {
  Option: ({ innerRef, innerProps, isFocused, data }) => (
    <div ref={innerRef} {...innerProps}>
      <MenuItem className={cx(styles.menuItem, isFocused && styles.focused)}>
        {isLocalID(data.id) && "Create "}
        <Tag color={data?.color}>{data?.name}</Tag>
      </MenuItem>
    </div>
  ),
  MultiValueLabel: ({ innerProps, data }) => (
    <Tag {...innerProps} color={data?.color}>
      {data?.name}
    </Tag>
  ),
};

export const TagSelect = ({
  children,
  placeholder,
  ...props
}: SelectProps<SelectOption>) => (
  <Select createable={true} {...props} overrides={TagSelectOverrides}>
    {children || (
      <Button
        subtle
        className={
          isString(props.className) ? undefined : props?.className?.trigger
        }
      >
        {props.value ? (
          <Tag color={props.value?.color}>{props.value?.name}</Tag>
        ) : (
          <PlaceholderText>{placeholder || "Select..."}</PlaceholderText>
        )}
      </Button>
    )}
  </Select>
);

const MultiTagSelectOverrides: MultiProps<SelectOption>["overrides"] = {
  Option: ({ innerRef, innerProps, isSelected, isFocused, data }) => (
    <div ref={innerRef} {...innerProps}>
      <MenuItem
        className={cx(styles.menuItem, isFocused && styles.focused)}
        icon={<CheckIcon checked={isSelected} />}
      >
        {isLocalID(data.id) && "Create "}
        <Tag color={data?.color}>{data?.name}</Tag>
      </MenuItem>
    </div>
  ),
  MultiValueLabel: ({ innerProps, data }) => (
    <Tag {...innerProps} color={data?.color}>
      {data?.name}
    </Tag>
  ),
};

export const TagMultiSelect = (props: MultiProps<SelectOption>) => (
  <MultiSelect
    createable={true}
    {...props}
    overrides={MultiTagSelectOverrides}
  />
);
