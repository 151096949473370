import { useCallback } from "react";

import { DatabaseID, Entity, Link } from "@api";

import { Fn } from "@utils/fn";
import { useRefState } from "@utils/hooks";
import { Maybe } from "@utils/maybe";

import { LinkDialog } from "./link-dialog";

type LinkType = "document" | "thread" | "url" | "all";
type Mode = "new-link";

export interface Props {
  entity: Entity;
  link: Maybe<Link>;
  type?: LinkType;
  mode?: Mode;
  source?: DatabaseID;
  onChanged?: Fn<Link, void>;
  onCancel?: Fn<void, void>;
}

export const ResourceCreateDialog = ({
  entity,
  link: _link,
  type,
  mode: _mode,
  onCancel,
  onChanged,
}: Props) => {
  const [action, actionRef, setAction] = useRefState<Mode>("new-link");

  const onClose = useCallback(
    (source: "new-link") => {
      if (source === actionRef.current) {
        onCancel?.();
      }
    },
    [action]
  );

  if (action === "new-link") {
    return (
      <LinkDialog
        link={undefined}
        onLinked={onChanged}
        onCancel={() => onClose("new-link")}
      />
    );
  }

  return <></>;
};
