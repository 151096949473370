import { Entity, PropertyDef, SingleFilterQuery } from "@api";

import { isEmptyFilter } from "@utils/filtering";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";

import { Dropdown, useOpenState } from "@ui/dropdown";
import { CheckMenuItem } from "@ui/menu-item";

import { OpHeader } from "./op-header";
import { TriggerButton } from "./trigger-button";

import styles from "./input.module.css";

export interface Props {
  filter: SingleFilterQuery;
  definition: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  editing?: boolean;
}

export default function PropertyFilterCheckbox({
  filter,
  definition,
  onChanged,
  editing,
}: Props) {
  const [open, setOpen] = useOpenState(editing);
  if (!["boolean"]?.includes(filter.type)) {
    throw new Error("Cannot use FilterCheckbox for non-boolean filters.");
  }

  const selected = filter.value?.boolean;

  return (
    <Dropdown
      open={open}
      setOpen={setOpen}
      portal={true}
      trigger={
        <TriggerButton
          filter={filter}
          prop={definition}
          onChanged={onChanged}
        />
      }
    >
      <OpHeader
        filter={filter}
        prop={definition}
        onChanged={onChanged}
        setOpen={setOpen}
      />

      {!isEmptyFilter(filter) && (
        <CheckMenuItem
          className={styles.input}
          checked={selected || false}
          onChecked={(v) =>
            onChanged?.({
              field: filter.field,
              type: filter.type,
              op: filter.op,
              value: { [filter.type]: v },
            })
          }
        />
      )}
    </Dropdown>
  );
}
