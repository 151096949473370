import { find } from "lodash";

import { onClient } from "@utils/ssr";

import { WorkspaceSessionState } from "./types";

export const toActive = (config: WorkspaceSessionState) =>
  find(config?.workspaces, (w) => w.workspace?.id === config.active);

export const onUnauthed = () =>
  onClient(() => {
    if (!window.location.href.includes("/login")) {
      window.location.href = "/public";
    }
  });
