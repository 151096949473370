import { map } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileDrop } from "react-file-drop";

import { FileMeta } from "@api";

import { log } from "@utils/debug";
import { useWindowEvent } from "@utils/event";
import { Fn } from "@utils/fn";
import { Maybe, when } from "@utils/maybe";

import { Button, Props as ButtonProps } from "@ui/button";
import { Container } from "@ui/container";
import { Upload } from "@ui/icon";
import { Modal } from "@ui/modal";
import { showError } from "@ui/notifications";
import ProgressBar from "@ui/progress-bar";
import { Text } from "@ui/text";
import { useUpload } from "@ui/upload";

import styles from "./upload-modal.module.css";

interface Props {
  scope: string;
  onUploaded: Fn<FileMeta, void>;
  onCancel: Fn<void, void>;
}

export default function UploadModal({ scope, onUploaded, onCancel }: Props) {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);
  const [percent, setPercent] = useState(0);
  const upload = useUpload(scope);

  const handleFileUpload = useCallback(
    async (file: File) => {
      setUploading(true);

      try {
        const uploaded = await upload(file);
        onUploaded(uploaded);
      } catch (error) {
        log(error);
        showError("Failed to upload file. Please try again.");
      } finally {
        setUploading(false);
      }
    },
    [upload, onUploaded, scope]
  );

  useWindowEvent(
    "paste",
    (e) => {
      map(e.clipboardData?.items, (item) => {
        if (item.kind === "file") {
          when(item.getAsFile(), handleFileUpload);
        }
      });
    },
    false,
    [handleFileUpload]
  );

  useEffect(() => {
    if (uploading) {
      const interval = setInterval(() => {
        setPercent((prev) =>
          Math.min(prev + Math.round(Math.random() * 10), 98)
        );
      }, 100);
      return () => clearInterval(interval);
    }
  }, [uploading]);

  return (
    <Modal open={true} onOpenChanged={(open) => !open && onCancel?.()}>
      <input
        onChange={({ target: { files } }) => when(files?.[0], handleFileUpload)}
        ref={inputFileRef}
        type="file"
        className={styles.hidden}
      />

      <FileDrop
        targetClassName={styles.target}
        onTargetClick={() => inputFileRef.current?.click()}
        onDrop={(files) => when(files?.[0], handleFileUpload)}
      >
        {uploading && (
          <Container>
            <ProgressBar percent={percent} />
          </Container>
        )}
        {!uploading && (
          <Text className={styles.text}>
            Drop, paste, or <span className={styles.underline}>select</span>{" "}
            files...
          </Text>
        )}
      </FileDrop>
    </Modal>
  );
}

type UploadFileButtonProps = Pick<
  ButtonProps,
  "children" | "icon" | "fit" | "className"
> &
  Omit<Props, "onCancel">;

export const UploadFileButton = ({
  onUploaded,
  scope,
  icon,
  children,
  ...rest
}: UploadFileButtonProps) => {
  const [collecting, setCollecting] = useState(false);

  const handleAdded = useCallback(
    (o: Maybe<FileMeta>) => {
      when(o, onUploaded);
      setCollecting(false);
    },
    [onUploaded]
  );

  return (
    <>
      {collecting && (
        <UploadModal
          scope={scope}
          onUploaded={handleAdded}
          onCancel={() => setCollecting(false)}
        />
      )}

      <Button
        size="small"
        subtle
        icon={icon !== false ? icon || Upload : undefined}
        onClick={() => setCollecting(true)}
        {...rest}
      >
        {children || "Upload"}
      </Button>
    </>
  );
};
