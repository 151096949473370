/* @ts-ignore */
import emojiRegex from "emoji-regex";

/* @ts-ignore */
export default function asidePlugin(md) {
  /* @ts-ignore */
  function renderAside(tokens, idx, options, env, self) {
    const token = tokens[idx];
    // Get content of token without leading/trailing <aside> and </aside>
    const content = token.content.trim().replace(/<\/?aside>/g, "");

    // check if the content starts with an emoji
    const emoji = content.match(emojiRegex());
    const icon = emoji ? emoji[0] : "";

    const bodyText = (icon ? content.replace(icon, "") : content).trim();

    if (!icon) {
      return `<aside>${md.renderInline(bodyText)}</aside>\n`;
    }

    return `<aside data-icon="${icon}">${md.renderInline(bodyText)}</aside>\n`;
  }

  /* @ts-ignore */
  function asideRule(state, startLine, endLine, silent) {
    const startPos = state.bMarks[startLine] + state.tShift[startLine];

    // Check if line starts with <aside>
    if (state.src.slice(startPos, startPos + 7) !== "<aside>") {
      return false;
    }

    if (silent) return true;

    let nextLine = startLine;
    let content = "";

    // Loop through lines until </aside> is found
    while (nextLine < endLine) {
      const lineStart = state.bMarks[nextLine] + state.tShift[nextLine];
      const lineEnd = state.eMarks[nextLine];
      const line = state.src.slice(lineStart, lineEnd);

      if (line.trim() === "</aside>") {
        break;
      }

      // Add content to the token
      content += line + "\n";
      nextLine++;
    }

    // Adjust state for the line after </aside>
    state.line = nextLine + 1;

    const token = state.push("aside_open", "aside", 1);
    token.content = content.trim();

    return true;
  }

  md.block.ruler.before("paragraph", "aside", asideRule);
  md.renderer.rules.aside_open = renderAside;
}
