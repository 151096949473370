import { Link } from "@api";

import { composel } from "@utils/fn";
import { replaceAll_ } from "@utils/string";

import { User, UserReference } from "./types";

export const isUser = (p: UserReference | User): p is User =>
  !!(p as User).type;

const replaceUnsafe = composel(
  replaceAll_("[", "%5B"),
  replaceAll_("]", "%5D"),
  replaceAll_(",", "%2C")
);

const MARKDOWN_LINK = /^\[([^\]]*)]\(([^\)]*)\)$/;
export const parseMarkdownLink = (s: string): Link => {
  const [_, text, url] = s.match(MARKDOWN_LINK) || [];
  return { text: decodeURIComponent(text), url };
};

export const toMarkdownLink = ({ text, url }: Link) =>
  `[${replaceUnsafe(text || "")}](${url})`;
