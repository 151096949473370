import { MarkType, NodeType } from "@remirror/pm/model";
import {   combineTransactionSteps,
Editor ,
  findChildrenInRange,
  getChangedRanges,
  getMarksBetween,
Node ,   NodeWithPos,
} from "@tiptap/core";
import { Plugin, PluginKey } from "@tiptap/pm/state";
import {
  mergeAttributes,
  NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
} from "@tiptap/react";
import { find as findLink } from "linkifyjs";
import { isString, last } from "lodash";
import { useCallback, useMemo } from "react";

import { getUrlMeta,Link } from "@api";

import { cx } from "@utils/class-names";
import { useAsyncEffect } from "@utils/effects";
import { Fn } from "@utils/fn";
import { isTraction, toTractionIds } from "@utils/link";
import { safeAs } from "@utils/maybe";
import { onClient } from "@utils/ssr";
import { isExternal, isImage, toDomain, toPath } from "@utils/url";

import { ActionItem, ActionLabel, ActionMenu } from "@ui/action-menu";
import { Button } from "@ui/button";
import { Ellipsis } from "@ui/ellipsis";
import { HStack, SpaceBetween, VStack } from "@ui/flex";
import {
  EllipsisH,
  Icon,
  ImageV,
  LinkChain,
  Mention,
  Rename,
  TextAlt,
} from "@ui/icon";
import { LinkIcon } from "@ui/link-button";
import { Text } from "@ui/text";
import { Tooltip } from "@ui/tooltip";

import styles from "./embed-extension.module.css";

type AutolinkOptions = {
  type: MarkType | NodeType;
  editor: Editor;
  validate?: (url: string) => boolean;
};

export const formatHtml = (link: Link) =>
  `<embed 
    data-embed-url="${link.url}" 
    data-embed-type="${toEmbedType(link.url)}" 
    data-embed-title="${link.text || ""}">`;

// If is an image then return "image". Check with regex
const toEmbedType = (url: string) =>
  url.match(/\.(jpeg|jpg|gif|png)$/)
    ? "image"
    : url?.includes("notion.so") && !url?.includes("file.notion.so")
    ? "notion"
    : "link";

export function autolink(options: AutolinkOptions): Plugin {
  return new Plugin({
    key: new PluginKey("autolinkEmbed"),
    appendTransaction: (transactions, oldState, newState) => {
      // Skip if the user is pasting with shift key pressed
      if (
        safeAs<{ input: { shiftKey: boolean } }>(options.editor.view)?.input
          ?.shiftKey
      ) {
        return;
      }

      const docChanges =
        transactions.some((transaction) => transaction.docChanged) &&
        !oldState.doc.eq(newState.doc);
      const preventAutolink = transactions.some((transaction) =>
        transaction.getMeta("preventAutolink")
      );

      if (!docChanges || preventAutolink) {
        return;
      }

      const { tr } = newState;
      const transform = combineTransactionSteps(oldState.doc, [
        ...transactions,
      ]);
      const changes = getChangedRanges(transform);

      changes.forEach(({ newRange }) => {
        // Now let’s see if we can add new links.
        const nodesInChangedRanges = findChildrenInRange(
          newState.doc,
          newRange,
          (node) => node.isTextblock
        );

        let textBlock: NodeWithPos | undefined;
        let textBeforeWhitespace: string | undefined;

        if (nodesInChangedRanges.length > 1) {
          // Grab the first node within the changed ranges (ex. the first of two paragraphs when hitting enter).
          textBlock = nodesInChangedRanges[0];
          textBeforeWhitespace = newState.doc.textBetween(
            textBlock.pos,
            textBlock.pos + textBlock.node.nodeSize,
            undefined,
            " "
          );
        } else if (
          nodesInChangedRanges.length &&
          // We want to make sure to include the block seperator argument to treat hard breaks like spaces.
          newState.doc
            .textBetween(newRange.from, newRange.to, " ", " ")
            .endsWith(" ")
        ) {
          textBlock = nodesInChangedRanges[0];
          textBeforeWhitespace = newState.doc.textBetween(
            textBlock.pos,
            newRange.to,
            undefined,
            " "
          );
        }

        if (textBlock && textBeforeWhitespace) {
          const wordsBeforeWhitespace = textBeforeWhitespace
            .split(" ")
            .filter((s) => s !== "");

          if (wordsBeforeWhitespace.length <= 0) {
            return false;
          }

          const lastWordBeforeSpace =
            wordsBeforeWhitespace[wordsBeforeWhitespace.length - 1];
          const lastWordAndBlockOffset =
            textBlock.pos +
            textBeforeWhitespace.lastIndexOf(lastWordBeforeSpace);

          if (!lastWordBeforeSpace) {
            return false;
          }

          findLink(lastWordBeforeSpace)
            .filter((link) => link.isLink)
            // Calculate link position.
            .map((link) => ({
              ...link,
              from: lastWordAndBlockOffset + link.start + 1,
              to: lastWordAndBlockOffset + link.end + 1,
            }))
            // ignore link inside code mark
            .filter((link) => {
              if (!newState.schema.marks.code) {
                return true;
              }

              return !newState.doc.rangeHasMark(
                link.from,
                link.to,
                newState.schema.marks.code
              );
            })
            // validate link
            .filter((link) => {
              if (options.validate) {
                return options.validate(link.value);
              }
              return true;
            })
            // Add link mark.
            .forEach((link) => {
              if (
                getMarksBetween(link.from, link.to, newState.doc).some(
                  (item) => item.mark.type === options.type
                )
              ) {
                return;
              }

              tr.replaceRangeWith(
                link.from,
                link.to,
                (options.type as NodeType).create({
                  url: link.href,
                })
              );
            });
        }
      });

      if (!tr.steps.length) {
        return;
      }

      return tr;
    },
  });
}

type PasteHandlerOptions = {
  editor: Editor;
  type: MarkType | NodeType;
  handle: Fn<Link, boolean>;
};

export function pasteHandler({
  editor,
  handle,
  type,
}: PasteHandlerOptions): Plugin {
  return new Plugin({
    key: new PluginKey("handlePasteEmbed"),
    props: {
      handlePaste: (view, event, slice) => {
        const { state } = view;
        const { selection } = state;

        // Leave to link hyperlinking when there is a selection
        if (!selection.empty) {
          return false;
        }

        // Skip if the user is pasting with shift key pressed
        if (
          safeAs<{ input: { shiftKey: boolean } }>(editor.view)?.input?.shiftKey
        ) {
          return false;
        }

        let textContent = "";

        slice.content.forEach((node) => {
          textContent += node.textContent;
        });

        const link = findLink(textContent).find(
          (item) => item.isLink && item.value === textContent
        );

        if (!textContent || !link || !link.isLink) {
          return false;
        }

        return handle({ url: link.href, text: link.value });
      },
    },
  });
}

let navigate = (url: string) =>
  onClient(() => {
    if (isExternal(url)) {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  });

export const LinkTypeSelect = (
  props: NodeViewProps & { className?: string }
) => {
  const type = props.node.attrs.type;
  const canEmbed = useMemo(
    () => isImage(props.node.attrs.url),
    [props.node.attrs.url]
  );
  const canMention = useMemo(
    () => isTraction(props.node.attrs.url),
    [props.node.attrs.url]
  );

  const focus = useCallback(() => {
    props.editor.commands.setNodeSelection(props.getPos());
  }, [props]);

  const setType = useCallback(
    (type: string) => {
      if (type === "none") {
        props.editor.commands.unsetEmbed({ url: props.node.attrs.url });
        return true;
      }

      if (type === "mention") {
        const url = props.node.attrs.url;
        const id = last(toTractionIds(url)) ?? undefined;
        if (id) {
          props.editor.commands.setMention({ id });
          return true;
        }
      }

      props.editor.commands.setEmbed({
        ...(props.node.attrs as EmbedOptions),
        type,
      });
    },
    [props.updateAttributes]
  );

  const handleRename = useCallback(() => {
    const title = props.node.attrs.title;
    const updated = window.prompt("Link Description", title);

    // cancelled
    if (!!updated) {
      props.updateAttributes({ title: updated });
    }

    focus();
  }, []);

  return (
    <ActionMenu
      onOpen={(open) => open && focus()}
      trigger={
        <Button className={styles.actionButton} icon={EllipsisH} size="tiny" />
      }
      className={cx(styles.actionContainer, props.className)}
    >
      <ActionItem icon={Rename} text="Rename" onClick={handleRename} />

      <ActionLabel>Show as</ActionLabel>

      {canEmbed && (
        <ActionItem
          selected={type === "image"}
          icon={ImageV}
          text="Image"
          onClick={() => setType("image")}
        />
      )}
      {canMention && (
        <ActionItem
          selected={type === "mention"}
          icon={Mention}
          text="Mention"
          onClick={() => setType("mention")}
        />
      )}
      <ActionItem
        selected={type === "link"}
        icon={LinkChain}
        text="Mention"
        onClick={() => setType("link")}
      />
      <ActionItem
        selected={type === "none"}
        icon={TextAlt}
        text="Plain Text"
        onClick={() => setType("none")}
      />
    </ActionMenu>
  );
};

const useLinkTitles = (url: string, title?: string) => {
  return useMemo(
    () => ({
      title: title || toDomain(url),
      subtitle: title ? toDomain(url) : toPath(url),
    }),
    [url, title]
  );
};

export const RichLinkEmbedComp = (props: NodeViewProps) => {
  const url = useMemo(() => props.node.attrs.url, [props.node.attrs.url]);
  const { title, subtitle } = useLinkTitles(
    props.node.attrs.url,
    props.node.attrs.title
  );

  useAsyncEffect(async () => {
    const { title } = props.node.attrs;

    if (!!title && !url?.includes(title)) {
      return;
    }

    const meta = await getUrlMeta(url);

    if (meta.text && meta.text !== title) {
      props.updateAttributes({ title: meta.text });
    }
  }, []);

  return (
    <NodeViewWrapper
      style={{ verticalAlign: "-5px" }}
      as="span"
      className={cx(
        styles.embed,
        styles.link,
        props.selected && styles.selected
      )}
    >
      <SpaceBetween gap={6} fit="container">
        <Ellipsis>
          <HStack gap={4} onClick={() => navigate(url)} fit="container">
            <Icon className={styles.icon} icon={<LinkIcon url={url} />} />

            <Tooltip text={subtitle ? `${title} - ${subtitle}` : title}>
              <Text className={styles.text}>{title}</Text>
            </Tooltip>
          </HStack>
        </Ellipsis>

        <LinkTypeSelect className={styles.onHover} {...props} />
      </SpaceBetween>
    </NodeViewWrapper>
  );
};

export const ImageEmbedComp = (props: NodeViewProps) => {
  const url = useMemo(
    () => props.node.attrs.url as string,
    [props.node.attrs.url]
  );
  const title = useMemo(
    () => props.node.attrs.title || toDomain(url),
    [url, props.node.attrs.title]
  );

  return (
    <NodeViewWrapper>
      <VStack
        className={cx(
          styles.embed,
          styles.image,
          props.selected && styles.selected
        )}
        align="flex-end"
      >
        <VStack gap={4}>
          <img src={url} />
          <SpaceBetween direction="horizontal" className={styles.details}>
            <Ellipsis>
              <Button
                variant="link"
                onClick={() => navigate(url)}
                className={styles.text}
              >
                <Tooltip text={`${title} – ${toDomain(url)}`}>
                  <Text subtle>{title}</Text>
                </Tooltip>
              </Button>
            </Ellipsis>

            <LinkTypeSelect {...props} />
          </SpaceBetween>
        </VStack>
      </VStack>
    </NodeViewWrapper>
  );
};

export const EmbedComp = (props: NodeViewProps) =>
  props.node?.attrs?.type === "image" ? (
    <ImageEmbedComp {...props} />
  ) : (
    <RichLinkEmbedComp {...props} />
  );

type EmbedOptions = {
  url: string;
  title?: string;
  type: string;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    embed: {
      setEmbed: (options: EmbedOptions) => ReturnType;
      unsetEmbed: (options: { url: string }) => ReturnType;
    };
  }
}

export const Embed = Node.create({
  name: "embed",

  excludes: "_",

  group: "inline",

  inline: true,

  atom: true,

  selectable: true,

  draggable: false,

  addProseMirrorPlugins() {
    return [
      autolink({
        type: this.type,
        editor: this.editor,
      }),
      pasteHandler({
        type: this.type,
        editor: this.editor,
        handle: (link) => {
          this.editor.commands.setEmbed({
            type: toEmbedType(link.url),
            title: link.text,
            url: link.url,
          });
          return true;
        },
      }),
    ];
  },

  addCommands() {
    return {
      setEmbed:
        (options) =>
        ({ tr, dispatch }) => {
          const { selection } = tr;
          const isBlock = options.type === "image";
          const node = this.editor.extensionManager.schema.nodes[
            isBlock ? "blockEmbed" : "inlineEmbed"
          ].create({
            url: options.url,
            title: options.title,
            type: options.type || "link",
          });

          if (dispatch) {
            tr.replaceRangeWith(selection.from, selection.to, node);
          }

          return true;
        },
      // Converts the embed back to a normal link using commands.setLink
      unsetEmbed:
        ({ url }) =>
        ({ tr, state }) => {
          const { selection } = tr;
          const node = state.schema.text(url + " ", [
            state.schema.marks.link.create({ href: url }),
          ]);

          tr.replaceSelectionWith(node);
          // tr.replaceRangeWith(selection.from, selection.to, node);
          tr.setMeta("preventAutolink", true);

          return true;
        },
    };
  },

  addAttributes() {
    return {
      type: {
        default: "link",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-embed-type"),
        renderHTML: (attributes) => {
          return {
            "data-embed-type": attributes.type,
          };
        },
      },

      url: {
        default: "",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-embed-url"),
        renderHTML: (attributes) => {
          return {
            "data-embed-url": attributes.url,
          };
        },
      },

      title: {
        default: "",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-embed-title"),
        renderHTML: (attributes) => {
          return {
            "data-embed-title": attributes.title,
          };
        },
      },

      markdown: {
        default: "",
        parseHTML: (element: HTMLElement) =>
          element.getAttribute("data-embed-markdown"),
        renderHTML: (attributes) => {
          return {
            "data-embed-markdown": attributes.markdown,
          };
        },
      },
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(EmbedComp);
  },

  // Rules for extracting from html
  parseHTML() {
    return [
      {
        tag: "embed",
        priority: 2000,
        excludes: "_",
        getAttrs: (element) => {
          if (isString(element)) {
            return false;
          }

          // Above attribute parsing extracts all values
          return {};
        },
      },
    ];
  },
  renderHTML(props) {
    const { HTMLAttributes } = props;
    return [
      "embed",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});

export const BlockEmbed = Embed.extend({
  name: "blockEmbed",
  inline: false,
  group: "block",
  draggable: true,
  atom: true,
  selectable: true,
  tag: "embed",
  parseHTML() {
    return [
      {
        tag: "embed",
        priority: 2000,
        excludes: "_",
        getAttrs: (element) => {
          if (isString(element)) {
            return false;
          }

          // Above attribute parsing extracts all values
          return {};
        },
      },
    ];
  },
});

export const InlineEmbed = Embed.extend({
  name: "inlineEmbed",
  inline: true,
  group: "inline",
  draggable: false,
  atom: true,
  selectable: true,
  tag: "embed",
  parseHTML() {
    return [
      {
        tag: "embed",
        priority: 2001,
        context: "paragraph/",
        excludes: "_",
        getAttrs: (element) => {
          if (isString(element)) {
            return false;
          }

          // Above attribute parsing extracts all values
          return {};
        },
      },
    ];
  },
});
