import { reduce } from "lodash";

import { Job } from "@api";

import { removeSimpleStoreItem, setSimpleStoreItem } from "@state/store";

import { ISODate, now } from "@utils/date-fp";

import { JobStore } from "./atoms";

export const setJob = (job: Job) => (state: JobStore) =>
  job.status === "completed" || job.status === "failed"
    ? removeSimpleStoreItem(job.id)(state)
    : setSimpleStoreItem(job.id, job)(state);

export const setLastChecked = (date: ISODate) => (state: JobStore) => ({
  ...state,
  lastChecked: date,
});

export const clearLastChecked = () => (state: JobStore) => ({
  ...state,
  lastChecked: undefined,
});

export const setJobs = (jobs: Job[]) => (state: JobStore) =>
  setLastChecked(now())(reduce(jobs, (s, j) => setJob(j)(s), state));
