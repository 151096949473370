import { map } from "lodash";
import { useMemo } from "react";

import { Entity, FilterOperation, PropertyDef, SingleFilterQuery } from "@api";

import { availableOps, toLabel as toOpLabel } from "@utils/filtering";
import { Fn } from "@utils/fn";
import { Maybe } from "@utils/maybe";
import { sentenceCase } from "@utils/string";

import { Button } from "@ui/button";
import { AngleDownIcon, Icon } from "@ui/icon";
import { Select } from "@ui/select";

import styles from "./op-header.module.css";

export interface Props {
  filter: SingleFilterQuery;
  prop: PropertyDef<Entity>;
  onChanged?: Fn<Maybe<SingleFilterQuery>, void>;
  setOpen?: Fn<boolean, void>;
}

const toOpOption = (op: FilterOperation) => ({
  name: toOpLabel(op),
  value: op,
});

export const OpHeader = ({ prop, filter, onChanged, setOpen }: Props) => {
  const allOps = availableOps(filter.type);
  const ops = useMemo(() => map(allOps, toOpOption), [allOps]);

  return (
    <div className={styles.header}>
      <span className={styles.propTitle}>
        {prop?.label || sentenceCase(filter.field) || "Property"}
        <Select
          value={toOpOption(filter.op)}
          options={ops}
          className={{
            select: styles.opSelect,
            popover: styles.opPopover,
          }}
          searchable={false}
          closeOnSelect={true}
          onChange={(v) => {
            v &&
              onChanged?.({
                ...filter,
                op: v.value,
              } as SingleFilterQuery);
          }}
        >
          <span className={styles.opSelectTrigger}>
            {toOpLabel(filter.op)}
            <Icon icon={AngleDownIcon} />
          </span>
        </Select>
      </span>
      {setOpen && (
        <Button size="tiny" onClick={() => setOpen?.(false)}>
          Done
        </Button>
      )}
    </div>
  );
};
