import { some } from "lodash";

import { Action } from "@api";

import { isCreateOrUpdate } from "@state/store";
import { WorkflowDefinitionConfig, WorkflowTrigger } from "@state/workflows";

import { asMutation, asUpdate } from "@utils/property-mutations";

export const markStatusConverted: WorkflowTrigger<Action> = {
  id: "markStatusConverted",
  trigger: "WILL_UPDATE",
  type: "action",

  allowed: ({ update, entity }, { props, session }) =>
    isCreateOrUpdate(update) &&
    some(update.changes, { field: "refs.convertedTo" }),

  execute: ({ entity }, { session, stores, props }) => {
    return asUpdate(
      entity,
      asMutation({ field: "status", type: "status" }, { id: "CNV" })
    );
  },
};

export const definitions: WorkflowDefinitionConfig<Action> = {
  triggers: [markStatusConverted],
  suggestions: [],
  actions: [],
};

export default definitions;
