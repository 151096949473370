import { useEffect } from "react";

import { useLogout } from "@state/generic";

import { VStack } from "@ui/flex";
import { TractionHorizontal } from "@ui/icon";
import { AuthLayout } from "@ui/page-layout";
import { Sheet } from "@ui/sheet-layout";
import { TextLarge } from "@ui/text";

import { PublicPage } from "./public-page";

import styles from "./public.module.css";

export default function ResetAndLogout() {
  const logout = useLogout();

  useEffect(() => logout(), []);

  return (
    <PublicPage>
      <AuthLayout>
        <VStack>
          <Sheet className={styles.authCard} height="content">
            <VStack gap={30}>
              <div className={styles.logoCenter}>
                <TractionHorizontal />
              </div>
              <VStack gap={2}>
                <TextLarge bold>Resetting traction...</TextLarge>
              </VStack>
            </VStack>
          </Sheet>
        </VStack>
      </AuthLayout>
    </PublicPage>
  );
}
