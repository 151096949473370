import { RichText } from "@api";

import { Maybe } from "./maybe";
import { toHtml } from "./rich-text";

export const toCounts = (rt: Maybe<RichText>) => {
  const str = toHtml(rt);
  const incomplete = (str.match(/checked="false"/g) || []).length;
  const complete = (str.match(/checked="true"/g) || []).length;
  const total = incomplete + complete;
  return {
    incomplete,
    complete,
    total: incomplete + complete,
    percent: total === 0 ? 0 : Math.round((complete / total) * 100),
  };
};

export const isEmpty = (rt: Maybe<RichText>) => {
  const html = toHtml(rt);
  const total = (html.match(/data-type="taskItem"/g) || []).length;
  return (
    !html ||
    total === 0 ||
    (total === 1 && html?.includes("><div><p></p></div></li"))
  );
};
