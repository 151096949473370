import { useCallback } from "react";

import { Ref } from "@api";

import { withHardHandle } from "@utils/event";
import { Fn } from "@utils/fn";
import { useGoTo, usePushTo } from "@utils/navigation";

import { Button } from "@ui/button";
import { ArrowUpRight } from "@ui/icon";
import { Text } from "@ui/text";

interface Props {
  item?: Ref;
  href?: string;
  mode?: "push" | "go";
  text?: string;
  onClick?: Fn<React.MouseEvent, void>;
  className?: string;
}

export const GoToButton = ({
  item,
  className,
  href,
  text,
  mode = "go",
  onClick,
}: Props) => {
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const handle = useCallback(
    withHardHandle((e: React.MouseEvent) => {
      (mode === "go" ? goTo : pushTo)(item || href || "");
      onClick?.(e);
    }),
    [item?.id, onClick]
  );

  if (!(item || href)) {
    return <></>;
  }

  return (
    <Button
      className={className}
      size="tiny"
      onClick={handle}
      iconRight={ArrowUpRight}
    >
      {text && <Text subtle>{text}</Text>}
    </Button>
  );
};
