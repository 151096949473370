import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { cx } from "@utils/class-names";
import { useGoTo, usePushTo } from "@utils/navigation";

import { ArrowRight, Box } from "@ui/icon";

import { CollapsibleMenuItem, CollapsibleMenuItemProps } from "./collapsible";

import styles from "./styles.module.css";

const trimTrailingSlash = (str: string) =>
  str.endsWith("/") ? str.slice(0, -1) : str;

export function RouteMenuItem({
  text,
  route,
  onClick,
  children,
  iconRight,
  icon,
  mode = "goto",
  collapsed = false,
  ...props
}: Partial<CollapsibleMenuItemProps> & {
  route: string;
  mode?: "push" | "goto";
}) {
  const goTo = useGoTo();
  const pushTo = usePushTo();
  const open = mode === "push" ? pushTo : goTo;

  const location = useLocation();
  const active = useMemo(
    () =>
      trimTrailingSlash(location.pathname).endsWith(trimTrailingSlash(route)),
    [route, location]
  );

  return (
    <CollapsibleMenuItem
      className={cx(active && styles.routeActive)}
      onClick={(e) => (route && !onClick ? open(route) : onClick?.(e))}
      highlight="shadow"
      iconRight={iconRight || (active ? ArrowRight : undefined)}
      icon={icon || Box}
      collapsed={collapsed}
      {...props}
    >
      {text || children}
    </CollapsibleMenuItem>
  );
}
