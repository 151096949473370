import { intersection, map } from "lodash";
import { useEffect, useMemo, useState } from "react";

import { Entity, EntityType, Ref, Task } from "@api";

import { useLazyEntity, useNestedSource, useSearch } from "@state/generic";
import { useInstalledEntities } from "@state/packages";
import { useEntityLabels } from "@state/settings";

import { Fn } from "@utils/fn";
import { plural } from "@utils/string";

import { EditableText } from "@ui/editable-text";
import { render, useEngine } from "@ui/engine";
import { FillSpace, SpaceBetween } from "@ui/flex";
import { Icon, Search, SpinnerIcon } from "@ui/icon";
import { Menu } from "@ui/menu";
import { MenuGroup } from "@ui/menu-group";
import { EmptyMenuItem } from "@ui/menu-item";
import { Sheet } from "@ui/sheet-layout";

import styles from "./search-pane.module.css";

interface Props {
  parentId: string;
  onOpen: Fn<Ref, void>;
}

interface SubProps {
  query: string;
  type: EntityType;
  parent: Entity;
  onOpen: Fn<Ref, void>;
  onLoading: Fn<boolean, void>;
}

const SubEntitySearch = ({
  query,
  type,
  parent,
  onOpen,
  onLoading,
}: SubProps) => {
  const scope = useNestedSource(parent, type);
  const engine = useEngine(type);
  const { setQuery, loading, results } = useSearch(type, scope?.scope, {
    debounce: 400,
    empty: false,
  });
  const toLabel = useEntityLabels(scope?.scope);

  useEffect(() => setQuery(query), [query]);
  useEffect(() => onLoading(loading), [loading]);

  if (!results?.length) {
    return <></>;
  }

  return (
    <MenuGroup label={plural(toLabel(type))}>
      {map(results as Task[], (r) =>
        render(engine.asMenuItem, {
          key: r.id,
          item: r,
          onOpen: onOpen,
        })
      )}
    </MenuGroup>
  );
};

export const SearchPane = ({ parentId, onOpen }: Props) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const parent = useLazyEntity(parentId);
  const toLabel = useEntityLabels(parent?.source.scope);
  const allTypes = useInstalledEntities(parentId);
  const childTypes = useMemo(
    () =>
      [
        ...intersection(allTypes, ["task", "content", "outcome"]),
        "note",
        "resource",
      ] as EntityType[],
    [allTypes]
  );

  if (!parent) {
    return <></>;
  }

  return (
    <Sheet size="secondary" height="content" className={styles.pane}>
      <SpaceBetween className={styles.header}>
        <Icon dark icon={Search} />
        <FillSpace>
          <EditableText
            key="search"
            className={styles.textbox}
            placeholder={`Search within ${toLabel(
              parent?.source.type
            )?.toLowerCase()}...`}
            highlight={false}
            text={query}
            onChange={setQuery}
            updateOn="change"
          />
        </FillSpace>
        {loading && <Icon icon={SpinnerIcon} />}
      </SpaceBetween>

      {!!query && (
        <Menu className={styles.menu}>
          {map(childTypes, (type) => (
            <SubEntitySearch
              key={type}
              query={query}
              type={type}
              parent={parent}
              onOpen={onOpen}
              onLoading={setLoading}
            />
          ))}
          <MenuGroup className={styles.topEmpty}>
            <EmptyMenuItem text="Nothing found." />
          </MenuGroup>
        </Menu>
      )}
    </Sheet>
  );
};
