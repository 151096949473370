import { ReactNode } from "react";

import styles from "./border-trail.module.css";

type BorderTrailProps = { children: ReactNode };

export function BorderTrail({ children }: BorderTrailProps) {
  return (
    <>
      <div className={styles.stroke}></div>
      {children}
    </>
  );
}
