import { Editor } from "@tiptap/core";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import {
  EditorContent,
  EditorOptions,
  Extension,
  useEditor,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { camelCase } from "change-case";
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";

import { RichText } from "@api/types";

import { cx } from "@utils/class-names";
import { Fn } from "@utils/fn";
import { useDebouncedCallback } from "@utils/hooks";
import { toPlainText } from "@utils/html";
import { Maybe } from "@utils/maybe";
import { isEmpty, toHtml, toMarkdown } from "@utils/rich-text";

import { Link, useInternalNavigator } from "./link-extension";
import { GlobalSuggestion, Mentioner } from "./mention-extension";
import { FixedMenu, FormattingMenu } from "./menus";
import { isFocused } from "./utils";

import contentStyles from "./document-editor.module.css";
import styles from "./text-box.module.css";

interface ReadonlyProps {
  text: Maybe<RichText>;
  placeholder?: string;
  className?: string;
  onClick?: Fn<React.MouseEvent, void>;
}

interface RichTextProps {
  key: string;
  text: Maybe<RichText>;
  className?: string;
  placeholder?: string;
  focus?: boolean;
  size?: "one-line" | "two-line";
  updateOn?: "blur" | "change";
  submitOnEnter?: boolean;
  disabled?: boolean;
  onChanged?: Fn<RichText, void>;
  onBlur?: Fn<RichText, void>;
  onEnter?: Fn<RichText, void>;
  onFocus?: Fn<void, void>;
}

interface ExtensionOpts {
  placeholder?: string;
  onEnter?: MutableRefObject<Maybe<Fn<RichText, void>>>;
}

const extensions = ({ placeholder, onEnter }: ExtensionOpts) => [
  Document.extend({
    name: "enterHandler",
    addKeyboardShortcuts() {
      return {
        ...this?.parent?.(),
        Enter: () => {
          if (onEnter) {
            onEnter?.current?.({ html: this.editor?.getHTML() || "" });
            this.editor?.commands?.clearContent();
            return true;
          }

          return false;
        },
      };
    },
  }),

  Extension.create({
    addKeyboardShortcuts() {
      return {
        "Shift-Enter": ({ editor }) => {
          editor.commands.enter();
          return true;
        },
      };
    },
  }),

  Placeholder.configure({
    emptyEditorClass: styles.placeholder,
    showOnlyWhenEditable: false,
    placeholder: placeholder,
  }),

  TextStyle.configure({}),

  Mentioner.configure({
    suggestion: GlobalSuggestion({}),
  }),

  Link.configure({
    autolink: false,
    linkOnPaste: true,
  }),

  StarterKit.configure({
    hardBreak: false,
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
];

export const TextBox = ({
  text,
  focus = false,
  className,
  placeholder,
  updateOn = "change",
  size = "one-line",
  submitOnEnter = size === "one-line",
  disabled = false,
  onChanged,
  onBlur,
  onFocus,
  onEnter,
}: RichTextProps) => {
  const onEnterRef = useRef(onEnter);
  const onChangedRef = useRef(onChanged);
  const onChangeDebounced = useDebouncedCallback(
    (editor: Editor) => onChangedRef.current?.({ html: editor.getHTML() }),
    10,
    { trailing: true }
  );
  const editorProps = useMemo(
    (): Partial<EditorOptions> => ({
      extensions: extensions({
        placeholder,
        onEnter: submitOnEnter ? onEnterRef : undefined,
      }),

      editable: !disabled,

      content: toHtml(text),

      enablePasteRules: ["paragraph", "mention", "link"],

      // triggered on every change
      onUpdate: ({ editor }) => {
        if (updateOn === "change") {
          onChangeDebounced?.(editor);
        }
      },
    }),
    [submitOnEnter && onEnterRef, onChangeDebounced]
  );

  useInternalNavigator();

  const editor = useEditor(editorProps);

  const handleBlur = useCallback(() => {
    if (!editor || !isFocused(editor)) {
      onBlur?.({ html: editor?.getHTML() });
    }
  }, [editor]);

  useEffect(() => {
    onEnterRef.current = onEnter;
  }, [onEnter]);

  // Update editor editable when param changes
  useEffect(() => {
    // Important: Second param is to prevent the editor from firing the update event
    // Otherwise causes an infinite loop of updates with old values
    editor?.setEditable(!disabled, false);
  }, [disabled]);

  useEffect(() => {
    if (focus) {
      editor?.chain().focus().run();
    }
  }, [focus, editor]);

  useEffect(() => {
    if (!text?.html && editor?.getHTML()) {
      editor.commands.clearContent();
    }
  }, [text?.html]);

  // When content changes, check if the editor is focused and if not set the contnet
  useEffect(() => {
    if (!!editor && (!isEmpty(text) || !editor.isEmpty) && !isFocused(editor)) {
      editor.commands.setContent(toHtml(text));
    }
  }, [text]);

  // Keep onChange callback in sync
  useEffect(() => {
    onChangedRef.current = onChanged;
  }, [onChanged]);

  if (!editor) {
    return <></>;
  }

  return (
    <div
      data-selectable-ignore-drags="true"
      data-selectable-ignore-clicks="true"
      className={cx(styles.box, className)}
      onClick={() => editor.chain().focus().run()}
    >
      <EditorContent
        className={cx(
          styles.richText,
          contentStyles.tiptap,
          styles[camelCase(size)]
        )}
        placeholder={placeholder}
        editor={editor}
        onBlur={handleBlur}
        onFocus={() => onFocus?.()}
      />
      {size === "two-line" && <FixedMenu editor={editor} />}
      <FormattingMenu editor={editor} />
    </div>
  );
};

export const PlainText = ({
  text,
  focus = false,
  className,
  placeholder,
  updateOn = "change",
  size = "one-line",
  submitOnEnter = size === "one-line",
  onChanged,
  onBlur,
  onFocus,
}: RichTextProps) => {
  const editorProps = useMemo(
    (): Partial<EditorOptions> => ({
      extensions: [
        Document.extend({
          name: "enterHandler",
          addKeyboardShortcuts() {
            return {
              ...this?.parent?.(),
              Enter: () => {
                this.editor?.chain().blur().run();
                return true;
              },
            };
          },
        }),
        Paragraph,
        Text,

        Placeholder.configure({
          emptyEditorClass: styles.placeholder,
          showOnlyWhenEditable: false,
          placeholder: placeholder,
        }),

        Mentioner.configure({
          suggestion: GlobalSuggestion({}),
        }),

        Link.configure({ autolink: false, linkOnPaste: true }),
      ],

      content: toHtml(text),

      enablePasteRules: ["paragraph", "mention", "link"],

      // triggered on every change
      onUpdate: ({ editor }) => {
        if (updateOn === "change") {
          onChanged?.({ html: editor.getHTML() });
        }
      },
    }),
    [onChanged]
  );

  useInternalNavigator();

  const editor = useEditor(editorProps);

  const handleBlur = useCallback(() => {
    onBlur?.({ html: editor?.getHTML() });
  }, [editor]);

  useEffect(() => {
    if (focus) {
      editor?.chain().focus().run();
    }
  }, [focus, editor]);

  useEffect(() => {
    if (!text?.html && editor?.getHTML()) {
      editor.commands.clearContent();
    }
  }, [text?.html]);

  if (!editor) {
    return (
      <ReadonlyPlainText
        className={cx(styles.plainText, className)}
        text={text}
        placeholder={placeholder}
      />
    );
  }

  return (
    <div
      data-selectable-ignore-drags="true"
      data-selectable-ignore-clicks="true"
      className={cx(className)}
      onClick={() => editor.chain().focus().run()}
    >
      <EditorContent
        className={cx(styles.plainText, contentStyles.tiptap)}
        placeholder={placeholder}
        editor={editor}
        onBlur={handleBlur}
        onFocus={() => onFocus?.()}
      />
    </div>
  );
};

export const ReadonlyPlainText = ({
  text,
  onClick,
  className,
  placeholder,
}: ReadonlyProps) => {
  const markdown = useMemo(() => toPlainText(toHtml(text)?.trim()), [text]);
  return (
    <div
      data-selectable-ignore-drags="true"
      data-selectable-ignore-clicks="true"
      className={cx(
        contentStyles.tiptap,
        !markdown && styles.subtle,
        className
      )}
      onMouseDown={(e) => onClick?.(e)}
    >
      {markdown || placeholder}
    </div>
  );
};

export const ReadonlyTextBox = ({
  text,
  onClick,
  className,
}: ReadonlyProps) => {
  const config = useMemo(
    () => ({
      extensions: extensions({}),
      content: toHtml(text),
      editable: false,
    }),
    []
  );

  useInternalNavigator();

  const editor = useEditor(config);
  const plaintext = useMemo(
    () => toPlainText(toMarkdown(text)?.trim()),
    [text]
  );

  if (!editor) {
    return (
      <div
        data-selectable-ignore-drags="true"
        data-selectable-ignore-clicks="true"
        className={cx(className)}
        onClick={onClick}
      >
        {plaintext}
      </div>
    );
  }

  return (
    <div
      data-selectable-ignore-drags="true"
      data-selectable-ignore-clicks="true"
    >
      <EditorContent
        className={cx(styles.richText, contentStyles.tiptap, className)}
        editor={editor}
      />
    </div>
  );
};

export default TextBox;
