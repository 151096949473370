import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { ReactNode } from "react";

import { useAuthedUser } from "@state/workspace";

export const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const me = useAuthedUser();
  return (
    <SentryErrorBoundary showDialog dialogOptions={{ user: me }}>
      {children}
    </SentryErrorBoundary>
  );
};
