import { useCallback } from "react";

import { redirect } from "@utils/url";

import { Button, Props as ButtonProps } from "@ui/button";
import { Notion } from "@ui/icon";

export const NotionAuthorize = ({ children, ...props }: ButtonProps) => {
  const onAuthorize = useCallback(() => {
    // redirect_uri has to be the last query param otherwise notion breaks...
    redirect(
      `https://api.notion.com/v1/oauth/authorize?client_id=02563b31-b401-418a-99ee-ea89a43dc459&response_type=code&redirect_uri=${encodeURIComponent(
        window.location.protocol + "//" + window.location.host + "/auth/notion"
      )}`
    );
  }, []);

  return (
    <Button icon={Notion} onClick={onAuthorize} variant="secondary" {...props}>
      {children || "Authorize Notion"}
    </Button>
  );
};
