import { find } from "lodash";
import { useMemo } from "react";

import { Note } from "@api";

import { useMe } from "@state/persons";

import { cx } from "@utils/class-names";
import { timeAgo } from "@utils/date";
import { fromPointDate } from "@utils/date-fp";
import { respectHandled } from "@utils/event";
import { Fn } from "@utils/fn";
import { when } from "@utils/maybe";
import { now } from "@utils/now";
import { toMarkdown } from "@utils/rich-text";

import { Icon, SlackColor } from "@ui/icon";
import { RelationLabel } from "@ui/relation-label";
import { SlackText } from "@ui/slack-message";
import { Text } from "@ui/text";

import { FillSpace, SpaceBetween, VStack } from "./flex";

import styles from "./discussion-item.module.css";

interface Props {
  discussion: Note;
  showSource?: boolean;
  showSeenStatus?: boolean;
  selected?: boolean;
  onClick: Fn<Note, void>;
}

export const DiscussionItem = ({
  discussion: u,
  showSource = true,
  showSeenStatus,
  selected,
  onClick,
}: Props) => {
  const me = useMe();
  const seen = useMemo(
    () => find(u?.refs?.seenBy, (r) => r.id === me?.id),
    [me, u]
  );
  return (
    <div
      className={cx(
        styles.note,
        seen && showSeenStatus ? styles.seen : styles.unseen,
        selected && styles.selected
      )}
      onClick={respectHandled(() => onClick(u))}
    >
      <VStack gap={0}>
        <SpaceBetween gap={6} align="flex-start">
          <Icon size="medium" icon={SlackColor} />
          <FillSpace>
            <VStack gap={0}>
              <SlackText
                className={styles.discussionBody}
                text={toMarkdown(u.body)}
              />
              <Text className={styles.sentence}>
                Thread linked by{" "}
                {u.createdBy && (
                  <RelationLabel
                    icon={false}
                    className={styles.creator}
                    relation={u.createdBy}
                  />
                )}{" "}
                {timeAgo(fromPointDate(u.createdAt) || now())}
              </Text>
            </VStack>
          </FillSpace>
        </SpaceBetween>

        {showSource &&
          when(u?.refs?.backlinks?.[0], (ref) => (
            <RelationLabel className={styles.relation} relation={ref} />
          ))}
      </VStack>
    </div>
  );
};
