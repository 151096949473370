import { isString } from "lodash";
import { Dispatch, ReactNode, useMemo, useState } from "react";

import { cx } from "@utils/class-names";

import { Button } from "@ui/button";
import { Divider } from "@ui/divider";
import { AngleDownIcon, AngleRightIcon } from "@ui/icon";
import { Props as LabelProps,SectionLabel } from "@ui/label";

import { FillSpace, SpaceBetween, VStack } from "./flex";

import styles from "./collapsible-section.module.css";

interface Props {
  title?: string | ReactNode;
  count?: number;
  defaultOpen?: boolean;
  forceOpen?: boolean;
  labelSize?: LabelProps["size"];
  open?: boolean;
  divider?: boolean;
  padded?: boolean;
  setOpen?: Dispatch<boolean>;
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
}

export const CollapsibleSection = ({
  title,
  count,
  children,
  actions,
  forceOpen,
  divider = true,
  labelSize,
  padded = true,
  open: _open,
  setOpen: _setOpen,
  className,
  defaultOpen = true,
}: Props) => {
  const [open, setOpen] = !!_setOpen
    ? [_open, _setOpen]
    : useState(!title ? true : defaultOpen);
  const label = useMemo(
    () =>
      isString(title) ? (!count ? title : `${title} (${count})`) : undefined,
    [title, count]
  );

  return (
    <VStack fit="container" gap={padded ? 10 : 0} className={className}>
      {(title || label) && (
        <SpaceBetween gap={4} className={cx(styles.header)}>
          <span onClick={() => setOpen(!open)}>
            {label ? (
              <Button variant="secondary" size="tiny" inset={true} subtle>
                <SectionLabel
                  iconRight={
                    forceOpen
                      ? undefined
                      : open
                      ? AngleDownIcon
                      : AngleRightIcon
                  }
                  text={label}
                  size={labelSize}
                />
              </Button>
            ) : (
              title
            )}
          </span>
          {divider && (
            <FillSpace direction="horizontal">
              <Divider direction="horizontal" />
            </FillSpace>
          )}
          {actions}
        </SpaceBetween>
      )}
      {(open || forceOpen) && children}
    </VStack>
  );
};
